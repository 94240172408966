import React, { Component } from "react";
import { BUTTON_TYPE } from "prefab";
import classNames from "classnames";
import moment from "moment";
import { isEmpty, omit, isEqual, isNumber, inRange } from "lodash";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch, showToast } from "../../../../utils";
import { VIEW_PORT_SIZE, SCHEDULE_STATUSES } from "../../../../constants";
import { formatDateTime } from "../../../../utils";
import InfoBlockLoading from "../../../common/InfoBlockLoading";
import PageHeader from "../../../common/PageHeader";
import LocalizedButton from "../../../common/LocalizedButton";
import styles from "../../../../styles/Schedules/createSchedule.module.scss";
import pageStyles from "../../../../styles/App.module.scss";
import UploadCPLPanel from "../../compositions/actions/UploadCplPanel";
import ScheduleDetails from "./ScheduleDetails";
import AddPlacement from "./AddPlacement";
import AddScreens from "./AddScreens";

export class CreateSchedules extends Component {
  getInitialState = () => ({
    isMobile: this.props.app.pageDimensions?.width <= VIEW_PORT_SIZE.MOBILE,
    isLoading: true,
    hasFormSaved: false,
    isCPLPanelOpen: false,
    scheduleScreens: [],
    errors: [],
    schedule: {
      name: "",
      validFrom: null,
      validTo: null,
      orderNumber: "",
      subOrderNumber: "",
      scheduleNumber: "",
      customerPurchaseOrderNumber: "",
      cplId: null,
      placement: {
        numberOfPlays: null,
        segmentId: "",
        position: "",
        plays: [],
      },
    },
  });

  constructor(props) {
    super(props);
    this.placementForm = React.createRef();
    this.addScreenForm = React.createRef();
  }

  state = this.getInitialState();

  _isMounted = false;

  componentDidMount = async () => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;
    this._isMounted = true;
    if (params?.scheduleId) {
      await actions.getScheduleDetailsById(params, () => history.push(`/schedules`));
      await actions.getScheduleScreenList(params);
      this.validateScheduleOrderStatus();
    }
    await actions.fetchPlaylistTemplate();
    await actions.getTimesOfDayList();

    if (this._isMounted) {
      this.setState({
        isLoading: false,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const {
      schedules: { schedule, scheduleScreens },
      compositions: { composition },
    } = this.props;
    if (!this._isMounted) return null;

    if (!isEmpty(schedule) && !isEqual(schedule, prevProps?.schedules?.schedule)) {
      const { placement } = schedule;
      this.validateScheduleOrderStatus();
      if (!isEmpty(placement?.plays) && placement) {
        placement.numberOfPlays = placement?.plays[0]?.numberOfPlays;
      }
      this.setState({
        schedule: { ...schedule, placement: { ...placement } },
      });
    }

    if (!isEmpty(composition) && !isEqual(composition, prevProps?.compositions?.composition)) {
      this.setState({
        schedule: {
          ...this.state.schedule,
          cplId: composition?.id,
          cplName: composition?.name,
        },
        composition,
      });
    }

    if (!isEqual(scheduleScreens, prevProps?.schedules?.scheduleScreens)) {
      this.setState({
        scheduleScreens,
      });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    this.props.actions.resetSchedule();
  }

  validateScheduleOrderStatus = () => {
    const { schedule } = this.state;

    if (schedule?.status === SCHEDULE_STATUSES.EXPIRED) {
      showToast("Toast.cannotEditExpiredOrder", false);
      this.handleCancelChanges();
    }

    return null;
  };

  handleUploadCPL = (isOpen = true) => {
    this.setState({ isCPLPanelOpen: isOpen });
  };

  handleCancelChanges = () => {
    const { history } = this.props;
    this.setState(this.getInitialState());
    history.push(`/schedules`);
  };

  updateSuggestedScreens = (params, filter, scheduleId) => {
    const { schedule } = this.state;
    const {
      schedules: { schedule: scheduleData },
    } = this.props;
    this.props.actions.getSuggestedScheduleScreens(
      params,
      filter,
      scheduleId,
      scheduleId && !isEqual(schedule, scheduleData),
      schedule
    );
  };

  updateScreenList = (screenList) => {
    if (screenList?.data.length > 0) {
      this.props.actions.updateScheduleScreens(screenList?.data ?? []);
    }
  };

  handleRemoveScreens = (screenIds) => {
    const { scheduleScreens, schedule } = this.state;
    const updatedScreens = scheduleScreens.filter((screen) => !screenIds.includes(screen.id));
    this.props.actions.resetScheduleErrors();
    if (schedule.id) {
      this.props.actions.updateScheduleOrderScreens(
        schedule.id,
        updatedScreens.map((screen) => screen.id)
      );
    }
    this.props.actions.updateScheduleScreens(updatedScreens);
  };

  handleInputChange = (data, key, childAccessor = null) => {
    const { schedule } = this.state;
    if (key === "validity") {
      if (childAccessor === "validFrom") {
        if (moment(data).isAfter(schedule.validTo)) {
          schedule.validTo = null;
        }
        schedule.validFrom = formatDateTime(data, "YYYY-MM-DD HH:mm");
        if (
          new Date(schedule.validFrom).getHours() === 0 &&
          new Date(schedule.validFrom).getMinutes() === 0
        ) {
          schedule.validFrom = `${formatDateTime(data, "YYYY-MM-DD")} 00:00`;
        }
      } else if (childAccessor === "validTo") {
        schedule.validTo = formatDateTime(data, "YYYY-MM-DD HH:mm");
        if (
          new Date(schedule.validTo).getHours() === 0 &&
          new Date(schedule.validTo).getMinutes() === 0
        ) {
          schedule.validTo = `${formatDateTime(data, "YYYY-MM-DD")} 23:59`;
        }
      }
    } else if (key === "composition") {
      schedule.cplId = data?.id;
      schedule.cplName = data?.name;
      schedule.advertiserTypeName = data?.advertiserTypeName;
      schedule.advertiserTypeId = data?.advertiserTypeId;
    } else if (key === "placement") {
      const re = /^[-0-9]+$/;
      let fieldData = data;
      if (childAccessor === "position") {
        if (!re.test(fieldData)) fieldData = null;
      }
      schedule.placement[childAccessor] = fieldData;
    } else {
      schedule[key] = data;
    }

    this.setState({
      schedule: { ...schedule },
    });
  };

  validateCreateSchedule = () => {
    let isValid = true;
    const { schedule, scheduleScreens } = this.state;
    const scrollToRef = (ref) => ref.scrollIntoView({ behavior: "smooth", block: "start" });
    if (
      isEmpty(schedule.name) ||
      isEmpty(schedule.validFrom) ||
      isEmpty(schedule.validTo) ||
      isEmpty(schedule.scheduleNumber) ||
      isEmpty(schedule.cplId)
    ) {
      isValid = false;
    }

    if (
      schedule?.advertiserTypeName === "Government" &&
      isEmpty(schedule.customerPurchaseOrderNumber)
    ) {
      isValid = false;
    }

    if (
      isEmpty(schedule.placement.segmentId) ||
      (!isNumber(schedule.placement?.numberOfPlays) && isEmpty(schedule.placement?.numberOfPlays)
        ? true
        : parseInt(schedule.placement.numberOfPlays) < 1) ||
      !inRange(schedule.placement?.position, 1, -101) ||
      schedule.placement?.plays?.length === 0
    ) {
      isValid = false;
      scrollToRef(this.placementForm);
    }

    if (scheduleScreens?.length === 0) {
      scrollToRef(this.addScreenForm);
      showToast("Toast.minimumScreenRequired", false);
      isValid = false;
    }
    return isValid;
  };

  handlePageUpdate = (scheduleOrderData, isSuccess) => {
    const { history, actions } = this.props;
    if (isSuccess) {
      history.push(`/schedules`);
    } else {
      actions.getScheduleDetailsById({ scheduleId: scheduleOrderData?.id });
      history.push(`/schedules/${scheduleOrderData.id}/edit`);
    }
  };

  createOrUpdateScheduleDetails = () => {
    const { schedule } = this.state;
    if (this.validateCreateSchedule()) {
      schedule.screenIds = this.state.scheduleScreens.map((screen) => screen.id);
      this.props.actions.createOrUpdateSchedule(omit(schedule, ["cplName"]), this.handlePageUpdate);
    }
    this.setState({
      hasFormSaved: true,
    });
  };

  renderPageActions = () => {
    const { schedule } = this.state;
    return (
      <div className={styles.actionContainer}>
        <LocalizedButton
          buttonType={BUTTON_TYPE.SECONDARY}
          text="Button.cancel"
          onClick={this.handleCancelChanges}
        />
        <LocalizedButton
          iconName="ConfirmRoundedIcon"
          text={schedule.id ? "Button.updateSchedule" : "Button.newSchedule"}
          buttonType={BUTTON_TYPE.PRIMARY}
          onClick={this.createOrUpdateScheduleDetails}
        />
      </div>
    );
  };

  onSaveCPL = () => {
    this.handleUploadCPL(false);
    this.props.actions.resetComposition();
  };

  render() {
    const {
      isLoading,
      isMobile,
      hasFormSaved,
      errors,
      schedule,
      scheduleScreens,
      isCPLPanelOpen,
    } = this.state;
    const {
      match,
      history,
      userData,
      playlistTemplates,
      schedules: { isCreateScheduleLoading, scheduleErrors, errorScreenList },
      compositions,
      timesOfDay: { data: timesOfDay },
      actions,
    } = this.props;

    if (isLoading || isCreateScheduleLoading)
      return (
        <div className={styles.loadingContainer}>
          <PageHeader name="PageHeader.newSchedule" isLoading />
          <div className={styles.content}>
            <InfoBlockLoading />
            <InfoBlockLoading />
          </div>
        </div>
      );
    return (
      <div className="col-12 clearfix">
        <PageHeader
          className={styles.stickyHeader}
          name={schedule?.id ? "PageHeader.updateSchedule" : "PageHeader.newSchedule"}
          renderRightSideComponent={this.renderPageActions}
        />
        <div className={classNames("clearfix", pageStyles.pageContainer)}>
          <ScheduleDetails
            scheduleDetails={schedule}
            onInputChange={this.handleInputChange}
            duplicateScheduleDetails={(scheduleId) =>
              actions.duplicateScheduleDetailsById(scheduleId)
            }
            isMobile={isMobile}
            hasFormSaved={hasFormSaved}
            onUploadCpl={this.handleUploadCPL}
            userData={userData}
            errors={errors}
          />
          <AddPlacement
            componentRef={(ref) => (this.placementForm = ref)}
            timesOfDay={timesOfDay}
            placement={schedule.placement || {}}
            onInputChange={this.handleInputChange}
            isMobile={isMobile}
            playlistTemplate={playlistTemplates.totalCount > 0 ? playlistTemplates.data[0] : null}
            hasFormSaved={hasFormSaved}
            errors={errors}
          />
          <AddScreens
            componentRef={(ref) => (this.addScreenForm = ref)}
            match={match}
            history={history}
            userData={userData}
            actions={actions}
            isError={hasFormSaved && scheduleScreens.length === 0}
            scheduleScreens={scheduleScreens}
            scheduleDetails={schedule}
            onInputChange={this.handleInputChange}
            addOrUpdateScheduleScreens={this.updateScreenList}
            removeScheduleScreens={this.handleRemoveScreens}
            handleSuggestedScreens={this.updateSuggestedScreens}
            scheduleErrors={scheduleErrors}
            errorScreenList={errorScreenList}
          />
        </div>
        <UploadCPLPanel
          isOpen={isCPLPanelOpen}
          isUploading={compositions.isUploading}
          onClose={() => {
            this.handleUploadCPL(false);
            this.props.actions.resetComposition();
          }}
          onSave={this.onSaveCPL}
          actions={this.props.actions}
          composition={compositions}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.app,
  (state) => state.schedules,
  (state) => state.compositions,
  (state) => state.userData,
  (state) => state.playlistTemplates,
  (state) => state.timesOfDay,
  (app, schedules, compositions, userData, playlistTemplates, timesOfDay) => ({
    app,
    schedules,
    compositions,
    userData: userData.user,
    playlistTemplates: playlistTemplates.data,
    timesOfDay,
  })
);

export default connect(mapStateToProps, bindDispatch)(CreateSchedules);
