import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch, locationColumn } from "../../../../utils";
import * as constants from "../../../../constants";
import ActionIcon from "../../../common/ActionIcon";
import TheatreTable from "../../../tables/Theatre";
import StatusIcon from "../../../common/StatusIcon";
import WithFetchList from "../../../hoc/withFetchList";
import { ActiveTableCell } from "../../../common/ActiveTableCell";

class MovieTheatres extends Component {
  state = {};
  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    this.props.fetchData(null, true, [{ movieId: params.movieId }]);
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  handleViewButtonClick = (original, viewType) => {
    const { history } = this.props;
    history.push(`/${viewType}/${original.id}/details`);
  };

  getMovieStatusList = (data) => {
    const dataStatus = data.map((item) => item.movieStatus);
    return constants.MOVIE_STATUS_LIST.map((status) => ({
      name: status.displayName,
      count: dataStatus.filter((d) => d === status.id).length,
      type: constants.STATUS_ICON_TYPES.MOVIES,
      tagType: constants.TAG_TYPE.MOVIE_STATUS,
      color: status.color,
      id: status.id,
    }));
  };

  getTheatreColumns = () => {
    return [
      {
        id: "theatreName",
        Header: "Theatre/Screens",
        parentAccessor: "name",
        childAccessor: "name",
      },
      {
        id: "chainName",
        Header: "Chain",
        parentAccessor: (d) =>
          ActiveTableCell(d.chain.id, d.chain.name, () => this.props.onFilterIdSelect(d.chain.id)),
      },
      locationColumn(this.props.onFilterIdSelect),
      {
        id: "movieStatus",
        Header: "Movie Status",
        accessor: (d) => this.renderMovieStatus(d),
      },

      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        Cell: (props) => (
          <ActionIcon
            iconProps={[
              {
                toolTip: "Tooltip.view",
                onClick: () =>
                  this.handleViewButtonClick(
                    props.original,
                    !props.original.screens ? "screens" : "theatres"
                  ),
                iconName: "ViewIcon",
              },
            ]}
          />
        ),
      },
    ];
  };

  renderMovieStatus = ({ movieStatus }) => {
    if (!movieStatus) return "-";
    return (
      <div className="flex align-center" onClick={() => this.props.onFilterIdSelect(movieStatus)}>
        <StatusIcon
          color={constants.MOVIE_STATUS_LIST.find((item) => item.id === movieStatus).color}
          type={constants.STATUS_ICON_TYPES.MOVIES}
        />
        &nbsp;
        {movieStatus}
      </div>
    );
  };

  render = () => {
    const {
      movies: { movieTheatres, isPartialLoading },
      isFilterLoading,
    } = this.props;

    return (
      <TheatreTable
        {...this.props}
        withToggleSwitch
        theatreList={movieTheatres}
        isLoading={isPartialLoading || isFilterLoading}
        selectedColumns={["Movie Status"]}
        columns={this.getTheatreColumns()}
        statusList={this.getMovieStatusList(movieTheatres?.data)}
        tagTypes={[
          constants.TAG_TYPE.SCREEN,
          constants.TAG_TYPE.THEATRE,
          constants.TAG_TYPE.CHAIN,
          constants.TAG_TYPE.LOCATION,
        ]}
      />
    );
  };
}

export const MovieTheatresWithFilters = WithFetchList("getMovieTheatres", {
  sort: [
    {
      id: "theatreName",
      desc: false,
    },
  ],
})(MovieTheatres);

const mapStateToProps = createSelector(
  (state) => state.movies,
  (movies) => ({ movies })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(MovieTheatresWithFilters));
