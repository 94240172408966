import React, { Component } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { bindDispatch, getBrandType } from "../../../../utils";
import * as constants from "../../../../constants";
import PageHeader from "../../../common/PageHeader";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import HeaderButton from "../../../common/HeaderButtons";
import TabView from "../../../common/Tabs";
import { DEFAULT_BRANDS_PATH } from "./brandConstants";
import ParentBrands from "./Tabs/ParentBrands";
import IndividualBrands from "./Tabs/IndividualBrands";
import Products from "./Tabs/Products";
import Variants from "./Tabs/Variants";

class BrandList extends Component {
  addNewButtonLabel = (tabId) => {
    switch (tabId) {
      case DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS:
        return { buttonLabel: "Button.addIndividualBrand" };
      case DEFAULT_BRANDS_PATH.PRODUCTS:
        return { buttonLabel: "Button.addProduct" };
      case DEFAULT_BRANDS_PATH.VARIANTS:
        return { buttonLabel: "Button.addVariant" };
      default:
        return { buttonLabel: "Button.addParentBrand" };
    }
  };
  addNewBrand = () => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    ReactGA.event({
      category: "Adding Brand",
      action: "Brand Added in list page",
      label: `Added ${params.tabId}`,
    });
    actions.addBrand(params);
    history.replace(`/brands/${params.tabId}/new`);
  };
  cancelEdit = () => {
    const {
      actions,
      history,
      match: { params },
      brands: { brandTagType },
    } = this.props;
    actions.cancelEditBrand();
    history.push(`/brands/${getBrandType(params.tabId, brandTagType)}`);
  };
  queryString = () => {
    const { history } = this.props;
    return queryString.parse(history.location.search);
  };
  editBrand = (brandId) => {
    const { history } = this.props;
    history.push({
      search: queryString.stringify({ ...this.queryString(), editBrandId: brandId }),
    });
  };
  render = () => {
    const {
      history,
      match: { params },
      brands: { brandTagType },
      userData,
    } = this.props;

    return (
      <div>
        <PageHeader
          name="PageHeader.brands"
          renderRightSideComponent={() => (
            <div className="flex align-center flex-wrap">
              <HeaderButton
                addButton
                logsButton
                history={history}
                addButtonScope={constants.SCOPES.BRANDS}
                addButtonScopeAction={constants.SCOPE_ACTIONS.WRITE}
                userData={userData}
                addNewField={this.addNewBrand}
                logsPath={`/logs/source/${AUDIT_LOG_SOURCE.BRANDS}`}
                logsOnlyForSlateAdmin
                {...this.addNewButtonLabel(params.tabId)}
              />
            </div>
          )}
        />
        <TabView
          id="navbar"
          selectedTabId={getBrandType(params.tabId, brandTagType)}
          tabs={[
            {
              id: "parent-brands",
              title: "Parent Brands",
              goto: `/brands/parent-brands`,
              panel: (
                <ParentBrands
                  addNewField={this.addNewBrand}
                  editBrand={this.editBrand}
                  cancelEdit={this.cancelEdit}
                />
              ),
            },
            {
              id: "individual-brands",
              title: "Individual Brands",
              count: 0,
              goto: `/brands/individual-brands`,
              panel: (
                <IndividualBrands
                  addNewField={this.addNewBrand}
                  editBrand={this.editBrand}
                  cancelEdit={this.cancelEdit}
                />
              ),
            },
            {
              id: "products",
              title: "Products",
              count: 0,
              goto: `/brands/products`,
              panel: (
                <Products
                  addNewField={this.addNewBrand}
                  editBrand={this.editBrand}
                  cancelEdit={this.cancelEdit}
                />
              ),
            },
            {
              id: "variants",
              title: "Variants",
              count: 0,
              goto: `/brands/variants`,
              panel: (
                <Variants
                  addNewField={this.addNewBrand}
                  editBrand={this.editBrand}
                  cancelEdit={this.cancelEdit}
                />
              ),
            },
          ]}
        />
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.brands,
  (state) => state.businessTypes,
  (state) => state.productIdentificationNumberType,
  (state) => state.userData,
  (brands, businessTypes, productIdentificationNumberType, userData) => ({
    brands,
    businessTypes,
    productIdentificationNumberType,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(BrandList);
