import { CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST } from "../../../../constants";

const validateCampaignsAction = (dataList, selection, actionType) => {
  const selectedCampaigns = dataList?.data?.filter(
    (campaign) =>
      selection.parentSelection.includes(campaign.id) &&
      CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[actionType].includes(campaign.status)
  );
  return selectedCampaigns?.length === selection.parentSelection.length;
};

export default validateCampaignsAction;
