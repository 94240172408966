import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { FormattedNumber } from "react-intl";
import queryString from "query-string";
import { ExpandTable } from "workbench";
import classNames from "classnames";
import { Icons } from "prefab";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  createdByColumn,
  createdAtColumn,
  formatDateTime,
  emptyDataMessage,
} from "../../../../utils";
import pageStyles from "../../../../styles/App.module.scss";
import ButtonStyles from "../../../../styles/Button.module.scss";
import * as constants from "../../../../constants";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import PageHeader from "../../../common/PageHeader";
import HeaderButtons from "../../../common/HeaderButtons";
import LocalizedButton from "../../../common/LocalizedButton";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import TableLoading from "../../../common/TableLoading";
import FilterChips from "../../../common/Filters/FilterChips";
import ActionIcon from "../../../common/ActionIcon";
import WithFetchList from "../../../hoc/withFetchList";
import TableControls from "../../../common/TableControls";
import FooterControls from "../../../common/FooterControls";
import { ActiveTableCell } from "../../../common/ActiveTableCell";

const { LogsIcon } = Icons;
class CampaignApprovalsList extends Component {
  state = {
    selectedColumns: [
      "Campaign",
      "Advertiser",
      "Start Date",
      "End Date",
      "Max Plays per Screen",
      "Created On",
      "Created By",
    ],
  };
  componentDidMount = () => {
    this.props.fetchData();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  onRowSelect = (selection) =>
    this.setState({
      selection,
    });

  renderIconColumn = (props, url) => (
    <ActionIcon
      iconProps={checkScopes(
        [
          {
            toolTip: "Tooltip.view",
            url: url,
            iconName: "ViewIcon",
          },
          {
            toolTip: "Tooltip.moreActions",
            iconName: "MoreVerticalIcon",
            dropdown: [
              {
                text: "Logs",
                icon: <LogsIcon />,
              },
            ],
          },
        ],
        this.props.userData
      )}
    />
  );

  render = () => {
    const {
      history,
      campaignApprovals: { campaignApprovals, isLoading },
      filters,
      userData,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;

    if (isLoading && !campaignApprovals.data) return <PageLoadingWithTable />;

    const { selectedColumns, reorderedColumns } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = campaignApprovals.totalCount || 0;

    const columns = [
      {
        id: "name",
        Header: "Campaign",
        accessor: "name",
        width: 200,
      },
      {
        id: "createdFor",
        Header: "Advertiser",
        accessor: (d) =>
          ActiveTableCell(d.advertiserId, d.advertiserName, () =>
            this.props.onFilterIdSelect(d.advertiserId)
          ),
      },
      {
        id: "startDate",
        Header: "Start Date",
        accessor: (d) => formatDateTime(`${d.validity.fromDate} ${d.validity.startTime}`),
      },
      {
        id: "endDate",
        Header: "End Date",
        accessor: (d) => formatDateTime(`${d.validity.toDate} ${d.validity.endTime}`),
      },
      {
        id: "maxPlaysPerScreen",
        Header: "Max Plays per Screen",
        accessor: "maxPlaysPerScreen",
        Cell: (props) => <FormattedNumber value={props.value} />,
      },
      createdAtColumn(),
      createdByColumn(this.props.onFilterIdSelect),
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        width: 96,
        parentAccessor: (props) =>
          this.renderIconColumn(props, `/campaign-approvals/${props.id}/details`),
        childAccessor: (props) =>
          this.renderIconColumn(props, `/campaigns/target-groups/${props.id}`),
      },
    ];

    return (
      <div>
        <PageHeader
          name="PageHeader.campaignApprovals"
          count={campaignApprovals.totalCount}
          renderRightSideComponent={() => (
            <>
              <LocalizedButton
                className={ButtonStyles.buttonSpacing}
                url={`/campaigns/rejected-proposals`}
                text="Button.rejectedProposals"
              />
              <HeaderButtons
                history={history}
                logsButton
                logsPath={`/logs/source/${AUDIT_LOG_SOURCE.CAMPAIGNS}`}
                userData={userData}
              />
            </>
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={campaignApprovals}
              query={query}
              ps={ps}
              page={page}
              tagTypes={[constants.TAG_TYPE.CAMPAIGNS, constants.TAG_TYPE.CAMPAIGN_STATUS]}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={resultsCount}
              onFilterChange={this.props.onFilterChange}
            />
            <ExpandTable
              data={campaignApprovals.data}
              loading={isLoading || isFilterLoading}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              defaultPageSize={constants.DEFAULT_PAGE_SIZE}
              sorted={sort}
              onSortedChange={onSortedChange}
              onSelect={this.onRowSelect}
              childIdentificationKey="targetGroups"
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
              emptyDataMessage={emptyDataMessage(
                `EmptyDataMessage.noCampaignFound`,
                `EmptyDataMessage.noCampaignFoundBody`
              )}
              onRowClick={({ original }) =>
                history.push(`/campaign-approvals/${original.id}/details`)
              }
              onChildRowClick={({ original }) =>
                history.push(`/campaigns/target-groups/${origin.id}`)
              }
            />
            <FooterControls
              pagination
              data={campaignApprovals}
              query={query}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
      </div>
    );
  };
}

export const CampaignApprovalsListWithFilters = WithFetchList("getCampaignApprovals", {
  sort: [
    {
      id: "status",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(CampaignApprovalsList);

const mapStateToProps = createSelector(
  (state) => state.campaignApprovals,
  (state) => state.userData,
  (campaignApprovals, userData) => ({ campaignApprovals, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(CampaignApprovalsListWithFilters);
