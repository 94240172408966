import React, { Component } from "react";
import PropTypes from "prop-types";
import { TablePagination } from "workbench";
import styles from "../../styles/Footer.module.scss";
import LocalizedButton from "./LocalizedButton";

class FooterControls extends Component {
  static propTypes = {
    addButton: PropTypes.bool,
    addButtonScope: PropTypes.string,
    addButtonScopeAction: PropTypes.string,
    pagination: PropTypes.bool,
    buttonLabel: PropTypes.string,
  };

  static defaultProps = {
    addButton: false,
    pagination: false,
    buttonLabel: "Button.addNew",
  };

  render() {
    const {
      addButton,
      addButtonScope,
      buttonLabel,
      addButtonScopeAction,
      pagination,
      addNewField,
      onRowSizeChange,
      data = [],
      ps,
      page,
      userData,
    } = this.props;
    return (
      <div className={styles.FooterControls}>
        {addButton && (
          <LocalizedButton
            id="add"
            iconName="AddFilledIcon"
            onClick={addNewField}
            text={buttonLabel}
            scope={addButtonScope}
            scopeAction={addButtonScopeAction}
            userData={userData}
          />
        )}
        {pagination && (
          <>
            <div className="flex-auto" />
            <TablePagination
              totalRecords={data.totalCount || 0}
              pageSize={ps}
              pageNeighbours={1}
              onPageChange={(v) => onRowSizeChange("page", v)}
              currentPage={page}
            />
          </>
        )}
      </div>
    );
  }
}
export default FooterControls;
