import React, { Component } from "react";
import PropTypes from "prop-types";
import PageLoadingWithTabs from "../../../../common/PageLoadingWithTabs";
import { isSlateAdminCompanyUser } from "../../../../../utils";
import styles from "../../../../../styles/Campaigns/Campaigns.module.scss";
import { CAMPAIGN_STATUSES } from "../../../../../constants";
import { getCampaignTree as getCampaignTreeApi } from "../../../../../api";
import CampaignTree from "../../CampaignStructure/CampaignTree";
import {
  getContactDetails,
  getBillingInfo,
  getApprovalDetails,
  getCampaignBasicDetails,
  getRequestedChanges,
  getRejectionDetails,
  getSummaryBlock,
} from "../../utils";
import CampaignActionDetails from "../../utils/CampaignActions";

export default class CampaignDetails extends Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    showCampaignActions: PropTypes.bool,
    withCampaignTree: PropTypes.bool,
    showEstimates: PropTypes.bool,
  };

  static defaultProps = {
    showEstimates: false,
    showCampaignActions: false,
    withCampaignTree: false,
  };

  state = {
    isCampaignStructureLoading: true,
    campaignStructure: {},
    isCampaignStructureError: false,
  };

  componentDidMount = async () => {
    if (this.props.withCampaignTree) await this.fetchCampaignTreeData();
  };

  fetchCampaignTreeData = () => {
    const { params } = this.props;

    getCampaignTreeApi(params.campaignId)
      .then((campaignRes) =>
        this.setState({
          isCampaignStructureLoading: false,
          campaignStructure: campaignRes,
        })
      )
      .catch((err) => {
        this.setState({
          isCampaignStructureLoading: false,
          isCampaignStructureError: true,
          campaignStructure: null,
        });
      });
  };

  renderCampaignActionDetails = (campaign) => {
    const unapprovedStatuses = [
      CAMPAIGN_STATUSES.DRAFT,
      CAMPAIGN_STATUSES.PROPOSED,
      CAMPAIGN_STATUSES.PLANNED,
    ];

    if (campaign.status === CAMPAIGN_STATUSES.REJECTED) {
      return getRejectionDetails(campaign);
    } else if (!unapprovedStatuses.includes(campaign.status)) {
      return getApprovalDetails(campaign);
    } else {
      return getRequestedChanges(campaign.requestedChanges);
    }
  };

  renderCampaignPauseAction = (campaign) => {
    const statusChanges = campaign?.statusChanges.filter(
      (changes) => changes.status === campaign.status
    );

    return <CampaignActionDetails actionChanges={statusChanges} />;
  };

  render = () => {
    const {
      campaign,
      isLoading,
      showCampaignActions,
      showEstimates,
      userData,
      withCampaignTree,
    } = this.props;
    const isAdminApp = isSlateAdminCompanyUser(userData);
    const { isCampaignStructureLoading, campaignStructure, isCampaignStructureError } = this.state;

    if (isLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={2} />;
    }

    return (
      <div className="col-12 clearfix flex">
        <div className={styles.details}>
          {showEstimates && getSummaryBlock(campaign)}
          {getCampaignBasicDetails(campaign, isAdminApp)}
          {campaign?.contacts?.length > 0 && getContactDetails(campaign)}
          {getBillingInfo(campaign.billingCycle)}
          {campaign.status === CAMPAIGN_STATUSES.PAUSED && (
            <CampaignActionDetails actionList={campaign.statusChanges} status={campaign.status} />
          )}
          {showCampaignActions && this.renderCampaignActionDetails(campaign)}
        </div>
        {withCampaignTree && !isCampaignStructureError && (
          <div className={styles.campaignTree}>
            <CampaignTree
              isLoading={isCampaignStructureLoading}
              campaignTreeData={campaignStructure}
            />
          </div>
        )}
      </div>
    );
  };
}
