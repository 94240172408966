import React, { Component } from "react";
import PropTypes from "prop-types";
import { FORM_FIELD_TYPE } from "../../../../../constants";
import RightPanel from "../../../../common/RightPanel";
import Form from "../../../../common/Form";

export default class ApproveCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: "",
      hasError: false,
    };
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onApprove: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
  };

  cancelApproval = () => {
    this.setState({ hasError: false, notes: "" });
    this.props.onClose();
  };

  approve = () => {
    const { notes } = this.state;
    //TODO: remove if validation is not needed
    if (notes !== "") {
      this.props.onApprove(notes);
      this.cancelApproval();
    } else {
      return this.setState({ hasError: true });
    }
  };

  inputOnChange = (value) => {
    this.setState({
      notes: value,
    });
  };

  render = () => {
    const { isOpen } = this.props;
    const { notes, hasError } = this.state;

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelApproval()}
        header="RightPanelHeader.approve"
        showFooter={true}
        primaryButtonProps={{
          text: "Button.approve",
          onClick: this.approve,
        }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelApproval }}
      >
        <Form
          config={[
            {
              id: "notes",
              type: FORM_FIELD_TYPE.TEXT_AREA,
              title: "Notes",
              placeholder: "Enter Notes for Approval",
              value: notes,
              onChange: (e) => this.inputOnChange(e.target.value),
              error: hasError,
              errorMessage: "Invalid Notes",
            },
          ]}
        />
      </RightPanel>
    );
  };
}
