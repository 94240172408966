import React, { Component } from "react";
import ClassNames from "classnames";
import { Tooltip, Position } from "@blueprintjs/core";
import { isEmpty, isNumber } from "lodash";

import { Icons } from "prefab";
import { formatValidityPeriod } from "../../../../../utils";
import styles from "../../../../../styles/Campaigns/CampaignTree.module.scss";

const { ChevronUpIcon, ChevronDownIcon } = Icons;
const defaultMediaInfo = [
  {
    id: "contentDurationInSeconds",
    label: "Duration",
    value: "Enter Duration",
  },
  {
    id: "numberOfPlays",
    label: "Total Plays",
    value: "Enter Total Plays",
  },
  {
    id: "validity",
    label: "Validity",
    value: "Select Media Validity",
  },
];

export default class MediaCard extends Component {
  state = {
    isExpanded: this.props.isExpanded,
  };

  handleCardToggle = (isExpanded) => {
    this.setState({
      isExpanded: isExpanded,
    });
  };

  formatMediaValues = (defaultValue, mediaData) => {
    const value = mediaData && mediaData[defaultValue.id];

    if (isEmpty(value) && !isNumber(value)) return defaultValue.value;

    if (defaultValue.id === "validity") {
      return formatValidityPeriod(value, "DD MMM YYYY");
    }

    return value;
  };

  render() {
    const { title, showCollapse, isExpanded, mediaData } = this.props;
    return (
      <div className={ClassNames(styles.cardContainer, styles.mediaContainer)}>
        <div className={styles.header}>
          <div className={styles.contentWrapper}>
            <div className={styles.titleContent}>
              <Tooltip
                className={styles.toolTip}
                boundary={true}
                position={Position.AUTO}
                content={title}
                usePortal={false}
              >
                <div className={ClassNames(styles.title, styles.mediaCardTitle)}>{title}</div>
              </Tooltip>
            </div>
          </div>
          {showCollapse && (
            <div className={styles.actions}>
              {isExpanded ? (
                <span onClick={() => this.handleCardToggle(false)}>
                  <ChevronUpIcon />
                </span>
              ) : (
                <span onClick={() => this.handleCardToggle(true)}>
                  <ChevronDownIcon />
                </span>
              )}
            </div>
          )}
        </div>
        <div className={styles.cardItemWrapper}>
          {defaultMediaInfo.map((ItemDefaultData, index) => {
            return (
              <div key={index} className={styles.itemContainer}>
                <div className={styles.labelContainer}>
                  <div className={styles.infoIcon}>{ItemDefaultData.icon}</div>
                  <div className={styles.label}>{ItemDefaultData.label}</div>
                </div>
                <div className={styles.infoValue}>
                  {this.formatMediaValues(ItemDefaultData, mediaData)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
