import moment from "moment";
import { compact } from "lodash";
import { getCurrentActionPeriod, isCampaignStatusChangesAvailable } from ".";

const validateSelectedCampaignsDurations = (data) => {
  if (data < 2) return true;
  const currentActionPeriod = getCurrentActionPeriod(data[0]);

  if (isCampaignStatusChangesAvailable(data) && currentActionPeriod) {
    const campaignsPeriodList = compact(data.map((campaign) => getCurrentActionPeriod(campaign)));
    return (
      campaignsPeriodList.length === data.length &&
      campaignsPeriodList.filter(
        (data) =>
          moment(currentActionPeriod.fromDate).isSame(data.fromDate) &&
          moment(currentActionPeriod.toDate).isSame(data.toDate)
      ).length === data.length
    );
  }
  return false;
};

export default validateSelectedCampaignsDurations;
