import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "@blueprintjs/core";
import styles from "../../styles/Tabs.module.scss";
import { checkScopeAuth } from "../../utils";

class TabView extends Component {
  static propTypes = {
    tabs: PropTypes.array.isRequired,
    animate: PropTypes.bool,
    renderActiveTabPanelOnly: PropTypes.bool,
    scopeCheck: PropTypes.bool,
    hideSingleTab: PropTypes.bool,
    isLoader: PropTypes.bool,
  };

  static defaultProps = {
    animate: true,
    large: true,
    renderActiveTabPanelOnly: true,
    onChange: () => {},
    scopeCheck: false,
    hideSingleTab: false,
    isLoader: false,
  };

  renderTitle = (goto, name, count, isActive) => {
    const title = (
      <div className="flex">
        <div className={`flex ${styles.tabName}`}>{name}</div>
        {count ? (
          <div className={`flex ${isActive ? styles.activeTabCount : styles.tabCount}`}>
            {count}
          </div>
        ) : (
          ""
        )}
      </div>
    );
    if (goto)
      return (
        <Link draggable={false} to={goto}>
          {title}
        </Link>
      );
    else return title;
  };
  render() {
    const {
      animate,
      onChange,
      selectedTabId,
      tabs,
      large,
      renderActiveTabPanelOnly,
      scopeCheck,
      hideSingleTab,
      isLoader,
    } = this.props;
    return (
      <Tabs
        className={classNames(styles.tabs, {
          [styles.hidePanel]: hideSingleTab && tabs.length === 1,
          [styles.loaderContainer]: isLoader,
        })}
        animate={animate}
        id="tab"
        large={large}
        onChange={onChange}
        selectedTabId={selectedTabId}
        renderActiveTabPanelOnly={renderActiveTabPanelOnly}
      >
        {tabs
          .filter((tab) =>
            scopeCheck ? checkScopeAuth(tab.userData, tab.scope, tab.scopeAction) : true
          )
          .map((tab, i) => (
            <Tab
              key={i}
              id={tab.id}
              title={
                !hideSingleTab &&
                this.renderTitle(tab.goto, tab.title, tab.count, selectedTabId === tab.id)
              }
              panel={tab.panel}
            />
          ))}
      </Tabs>
    );
  }
}
export default TabView;
