import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { ExpandTable } from "workbench";
import { withRouter } from "react-router-dom";
import { bindDispatch, modifyTableColumns, locationColumn } from "../../../../../utils";
import * as constants from "../../../../../constants";
import PageLoadingWithTable from "../../../../common/PageLoadingWithTable";
import FilterChips from "../../../../common/Filters/FilterChips";
import ActionIcon from "../../../../common/ActionIcon";
import WithFetchList from "../../../../hoc/withFetchList";
import TableControls from "../../../../common/TableControls";
import TableLoading from "../../../../common/TableLoading";
import FooterControls from "../../../../common/FooterControls";

class TargetGroupTheatres extends Component {
  state = {
    selectedColumns: ["Theatre", "Location", "Average Weekly Occupancy"],
  };

  componentDidMount = () => {
    this.props.fetchData();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  excludeTheatre = (id) => this.props.onExclusion(id, "theatre");

  render = () => {
    const {
      campaignCreate: { criteriaTheatres, isLoading, isPartialLoading },
      filters,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable />;
    const { selectedColumns, reorderedColumns } = this.state;
    const resultsCount = criteriaTheatres.totalCount || 0;

    const columns = [
      {
        id: "name",
        Header: "Theatre",
        parentAccessor: "name",
        childAccessor: "name",
      },
      locationColumn(this.props.onFilterIdSelect),
      {
        id: "averageWeeklyOccupancy",
        Header: "Average Weekly Occupancy",
        accessor: "averageWeeklyOccupancy",
      },
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        Cell: (d) => (
          <ActionIcon
            iconProps={[
              {
                toolTip: "Exclude",
                iconName: "CancelFilledIcon",
                onClick: () => this.excludeTheatre(d.id),
              },
            ]}
          />
        ),
      },
    ];

    return (
      <div className="col-12 clearfix">
        <TableControls
          searchBar
          columnFilter
          showRowSize
          pagination
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          data={criteriaTheatres.data}
          ps={ps}
          page={page}
          tagTypes={[constants.TAG_TYPE.THEATRE, constants.TAG_TYPE.LOCATION]}
          onSearchFilterSelect={this.props.onFilterSelect}
          onFilterChange={this.props.onFilterChange}
          onColumnFilterSelect={this.onColumnSelect}
          onRowSizeChange={onQueryChange}
          filters={filters}
        />
        <FilterChips
          selected={filters}
          showResultsCount
          resultsCount={resultsCount}
          onFilterChange={this.props.onFilterChange}
        />
        <ExpandTable
          data={criteriaTheatres.data}
          loading={isPartialLoading || isFilterLoading}
          columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
          defaultPageSize={constants.DEFAULT_PAGE_SIZE}
          sorted={sort}
          onSortedChange={onSortedChange}
          selectable={false}
          childIdentificationKey="screens"
          manual={false}
          LoadingComponent={
            <TableLoading
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          }
        />
        <FooterControls
          pagination
          data={criteriaTheatres.data}
          ps={ps}
          page={page}
          onRowSizeChange={onQueryChange}
        />
      </div>
    );
  };
}

export const TargetGroupTheatresWithFilters = WithFetchList("getTargetGroupTheatresByCriteria", {
  sort: [{ id: "name", desc: false }],
})(TargetGroupTheatres);

const mapStateToProps = createSelector(
  (state) => state.userData,
  (state) => state.campaignCreate,
  (userData, campaignCreate) => ({
    userData: userData.user,
    campaignCreate: campaignCreate,
  })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(TargetGroupTheatresWithFilters));
