import React, { Component } from "react";
import moment from "moment";
import classNames from "classnames";
import InfoBlock from "../../../../common/InfoBlock";
import Form from "../../../../common/Form";
import {
  FORM_FIELD_TYPE,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  CAMPAIGN_ACTIONS,
} from "../../../../../constants";
import { getDateRange, getRestrictedDateLimit } from "../../utils/campaignCreate";
import campaignForm from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";

export default class BasicDetails extends Component {
  state = {
    duplicateName: false,
    minDate: undefined,
    maxDate: undefined,
  };

  componentDidMount() {
    const { campaign } = this.props;
    const { fromDate, toDate } = getRestrictedDateLimit(campaign.validity, null);
    this.setState({
      minDate: fromDate ? new Date(fromDate) : undefined,
      maxDate: toDate ? new Date(toDate) : undefined,
    });
  }

  onNameChange(value) {
    const { targetGroups, onInputChange } = this.props;
    onInputChange(value, "name");
    const allTargetGroupNames = targetGroups.map((t) => t.name);
    if (allTargetGroupNames.includes(value)) this.setState({ duplicateName: true });
    else this.setState({ duplicateName: false });
  }

  render() {
    const { targetGroup, onInputChange, hasFormSaved } = this.props;
    const allowModifyingStartDate = CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
      CAMPAIGN_ACTIONS.EDIT_VALIDITY
    ].includes(targetGroup.status);
    return (
      <InfoBlock header="Details" childClassName={campaignForm.childWrapper}>
        <div
          className={classNames(
            "flex flex-wrap clearfix",
            campaignForm.formContainer,
            campaignForm.basicDetails
          )}
        >
          <Form
            isFormGroup={false}
            config={[
              {
                id: "name",
                label: "Target Group Name",
                type: FORM_FIELD_TYPE.INPUT,
                size: {
                  lg: 6,
                  md: 6,
                },
                placeholder: "Enter Name",
                value: targetGroup.name || "",
                onChange: (e) => this.onNameChange(e.target.value),
                error: (hasFormSaved && !targetGroup.name) || this.state.duplicateName,
                errorMessage: this.state.duplicateName
                  ? "Duplicate Target Group Name"
                  : "Please Enter Name",
              },
              {
                id: "validity",
                type: FORM_FIELD_TYPE.DATE_RANGE_PICKER,
                formatDate: (date) => moment(date).format("DD/MM/YYYY"),
                title: "Validity",
                size: {
                  lg: 6,
                  md: 6,
                },
                handleDateChange: (dateRange) => onInputChange(dateRange, "validity"),
                dateRange: getDateRange(targetGroup.validity),
                minDate: this.state.minDate,
                maxDate: this.state.maxDate,
                errorMessage: "Please Enter Validity",
                error: hasFormSaved && !targetGroup?.validity,
                isHidden: !allowModifyingStartDate,
              },
              {
                id: "startDate",
                type: FORM_FIELD_TYPE.LABEL,
                title: "Validity (Start Date)",
                size: { lg: 3, md: 3 },
                value: moment(targetGroup.validity.fromDate).format("MMM DD, YYYY"),
                isHidden: allowModifyingStartDate,
              },
              {
                id: "endDate",
                type: FORM_FIELD_TYPE.DATE,
                title: "Validity (End Date)",
                size: { lg: 3, md: 3 },
                onChange: (v) => onInputChange([null, v], "validity"),
                value: new Date(targetGroup.validity.toDate),
                placeholder: "End Date",
                isHidden: allowModifyingStartDate,
              },
              {
                id: "maxPlaysPerScreen",
                title: "Max Plays Per Screen",
                type: FORM_FIELD_TYPE.NUMERIC_INPUT,
                size: {
                  lg: 4,
                  md: 4,
                },
                placeholder: "Enter Plays Per Screen",
                errorMessage: "Please Enter Max Plays Per Screen",
                error: hasFormSaved && !targetGroup?.maxPlaysPerScreen,
                onValueChange: (value) => onInputChange(value, "maxPlaysPerScreen"),
                value: targetGroup.maxPlaysPerScreen,
              },
            ]}
          />
        </div>
      </InfoBlock>
    );
  }
}
