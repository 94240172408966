import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { createSelector } from "reselect";
import { omit } from "lodash";
import { BUTTON_TYPE } from "prefab";
import { bindDispatch, checkScopeAuth } from "../../../../utils";
import * as constants from "../../../../constants";
import { FORM_FIELD_TYPE } from "../../../../constants";
import { fetchBillingCycles } from "../../../../api";
import { searchCurrencies } from "../../../../api";
import ButtonStyles from "../../../../styles/Button.module.scss";
import PageStyles from "../../../../styles/App.module.scss";
import styles from "../../../../styles/Settings/CompanySettings.module.scss";
import InfoBlockLoading from "../../../common/InfoBlockLoading";
import InfoBlock from "../../../common/InfoBlock";
import Form from "../../../common/Form";
import LocalizedButton from "../../../common/LocalizedButton";

export class Settings extends Component {
  state = {
    isEdit: false,
    settings: {},
  };

  componentDidMount = async () => {
    const {
      userData,
      match: { params },
      actions,
    } = this.props;
    await actions.getCompanyAdvertiserPreferences(userData?.company.id, params.advertiserId);
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      companies: { advertiserSettings: settings },
    } = this.props;

    if (prevState.settings !== settings) {
      settings.campaignDefaultCurrencyId = settings?.campaignDefaultCurrency?.id ?? "";
      settings.campaignBillingCycleId = settings?.campaignBillingCycle?.id ?? "";
      this.setState({
        settings: settings,
      });
    }
  }

  editCampaignAdvertisersSettings = () => {
    const {
      history,
      match: {
        params: { advertiserId, advertiserTabId },
      },
    } = this.props;
    history.push(`/companies/advertisers/${advertiserId}/${advertiserTabId}/edit`);
  };

  getBillingCycleValue = (isEdit) => {
    const { settings } = this.state;
    let billingCycle = "Select Billing Cycle";

    if (settings?.campaignBillingCycle?.id) {
      billingCycle = settings?.campaignBillingCycle?.name;
    } else if (!settings?.campaignBillingCycle?.name && !isEdit) {
      billingCycle = "-";
    }

    return billingCycle;
  };

  getNumericValue = (data, isEdit) => {
    let value = data;
    if (!isEdit && (!data || data === 0)) value = "-";

    return value;
  };

  cancelEdit = () => {
    const {
      actions,
      history,
      userData,
      match: {
        params: { advertiserId, advertiserTabId },
      },
    } = this.props;
    actions.getCompanyAdvertiserPreferences(userData?.company.id, advertiserId);
    history.push(`/companies/advertisers/${advertiserId}/${advertiserTabId}`);
  };

  onInputChange = (data, key) => {
    const { settings } = this.state;
    if (key === "campaignDefaultCurrencyId") {
      settings.campaignDefaultCurrency = data;
      settings.campaignDefaultCurrencyId = data.id;
    } else if (key === "campaignBillingCycleId") {
      settings.campaignBillingCycle = data;
      settings.campaignBillingCycleId = data.id;
    } else {
      settings[key] = data ? data : 0;
    }
    this.setState({
      settings: settings,
    });
  };

  onSaveCompanySettings = () => {
    const { settings } = this.state;
    const {
      userData,
      actions,
      match: { params },
    } = this.props;
    const settingFormData = omit(settings, ["campaignDefaultCurrency", "campaignBillingCycle"]);
    actions.saveAdvertiserPreferences(
      settingFormData,
      userData?.company.id,
      params,
      this.cancelEdit
    );
  };

  render() {
    const {
      match: { params },
      userData,
      companies: { isAdvertisersSettingsLoading },
    } = this.props;
    const hasWriteScope = checkScopeAuth(
      userData,
      constants.SCOPES.COMPANIES_PREFERENCES,
      constants.SCOPE_ACTIONS.WRITE
    );
    const { settings } = this.state;
    const isEdit = params.brandTabId === "edit";
    const rightSideIcons = [
      {
        toolTip: "Edit",
        onClick: this.editCampaignAdvertisersSettings,
        isHidden: !hasWriteScope || params.brandTabId === "edit",
        iconName: "EditIcon",
      },
    ];

    if (isAdvertisersSettingsLoading) {
      return (
        <div className={classNames("col col-12", PageStyles.pageContainer)}>
          <InfoBlockLoading />
        </div>
      );
    }

    return (
      <div className={classNames("col col-12", PageStyles.pageContainer)}>
        <InfoBlock
          header="Default Campaign Settings"
          childClassName={classNames("flex flex-wrap", styles.content)}
          rightSideIcons={rightSideIcons}
          className={styles.blockContainer}
        >
          <Form
            isFormGroup={false}
            config={[
              {
                id: "campaignDurationBlockInDays",
                label: "Campaign Duration Blocks (in Days)",
                isLabel: !isEdit,
                type: FORM_FIELD_TYPE.INPUT,
                maxLength: 3,
                size: {
                  lg: 4,
                  md: 4,
                  sm: 6,
                },
                onlyNumbers: true,
                placeholder: "Enter Campaign Duration Blocks",
                value: this.getNumericValue(settings?.campaignDurationBlockInDays, isEdit),
                onChange: (value) => this.onInputChange(value, "campaignDurationBlockInDays"),
              },
              {
                id: "billingCycle",
                type: FORM_FIELD_TYPE.API_SELECT,
                isLabel: !isEdit,
                title: "Billing Cycle",
                label: "",
                size: {
                  lg: 4,
                  md: 4,
                  sm: 6,
                },
                className: styles.billingCycle,
                placeholder: "Select Billing Cycle",
                value: this.getBillingCycleValue(isEdit),
                fetchAction: () => fetchBillingCycles(),
                parseResults: (response) => {
                  return response.map((item) => ({
                    id: item.id,
                    value: item.name,
                    code: item.code,
                  }));
                },
                selected: (list) =>
                  settings?.campaignBillingCycle?.id
                    ? list.filter((item) => item.id === settings.campaignBillingCycle?.id)
                    : ["Select Billing Cycle"],
                singleSelect: true,
                showLabelInButton: false,
                onSelect: ([data]) => this.onInputChange(data, "campaignBillingCycleId"),
              },
              {
                id: "campaignDefaultCurrency",
                label: "Currency",
                title: "Currency",
                isLabel: !isEdit,
                type: FORM_FIELD_TYPE.API_SEARCH,
                size: {
                  lg: 4,
                  md: 4,
                  sm: 6,
                },
                placeholder: "Search currency",
                onSelect: (data) => this.onInputChange(data, "campaignDefaultCurrencyId"),
                query: isEdit ? settings?.campaignDefaultCurrency?.name : "-",
                searchIcon: "right",
                value: settings?.campaignDefaultCurrency?.name
                  ? settings.campaignDefaultCurrency.name
                  : "-",
                showSubText: false,
                parseResults: (data) =>
                  data?.map(({ id, code, name }) => ({
                    id,
                    name,
                    code,
                  })),
                fetchAction: (text) => searchCurrencies(text),
              },
            ]}
          />
        </InfoBlock>
        <InfoBlock
          header="Default Media Settings"
          rightSideIcons={rightSideIcons}
          childClassName={classNames("flex flex-wrap", styles.content)}
        >
          <Form
            isFormGroup={false}
            config={[
              {
                id: "maxPlayPerScreenCount",
                label: "Max Number of Plays Per Day",
                isLabel: !isEdit,
                type: FORM_FIELD_TYPE.INPUT,
                maxLength: 3,
                size: {
                  lg: 4,
                  md: 4,
                  sm: 6,
                },
                placeholder: "Enter Max Number of Plays",
                onlyNumbers: true,
                value: this.getNumericValue(settings?.campaignMaxPlaysPerScreen, isEdit),
                onChange: (value) => this.onInputChange(value, "campaignMaxPlaysPerScreen"),
              },
            ]}
          />
        </InfoBlock>
        {isEdit && (
          <div className={styles.actions}>
            <LocalizedButton
              className={ButtonStyles.buttonSpacing}
              onClick={this.onSaveCompanySettings}
              text="Button.saveChanges"
              buttonType={BUTTON_TYPE.PRIMARY}
              iconName="TickIcon"
              scope={constants.SCOPES.COMPANIES_PREFERENCES}
              scopeAction={constants.SCOPE_ACTIONS.WRITE}
              userData={userData}
            />
            <LocalizedButton
              onClick={this.cancelEdit}
              text="Button.cancel"
              scope={constants.SCOPES.COMPANIES_PREFERENCES}
              scopeAction={constants.SCOPE_ACTIONS.WRITE}
              userData={userData}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.companies,
  (state) => state.userData,
  (companies, userData) => ({ companies, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(Settings);
