import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import classNames from "classnames";
import { BUTTON_TYPE, ICON_POS } from "prefab";
import pageStyles from "../../../../styles/App.module.scss";
import PageLoadingWithTabs from "../../../common/PageLoadingWithTabs";
import { MoviebuffLogo } from "../../../../assets";
import { bindDispatch, sortByEarliestDate } from "../../../../utils";
import { MOVIEBUFF_WEBSITE_URL } from "../../../../config";
import { getHoursFromSeconds } from "../../../../utils/timeUtils";
import styles from "../../../../styles/Catalogue/Movie.module.scss";
import PageHeader from "../../../common/PageHeader";
import Breadcrumb from "../../../common/BreadCrumb";
import LocalizedButton from "../../../common/LocalizedButton";
import TabView from "../../../common/Tabs";
import InfoBlock from "../../../common/InfoBlock";
import TableInfoBlock from "../../../common/TableInfoBlock";
import MovieTheatres from "./MovieTheatres";
import MovieScreens from "./MovieScreens";

const _LINKS = [{ name: "PageHeader.movies", path: "/movies" }];

export class MovieView extends Component {
  componentDidMount = async () => {
    await this.getMovie();
  };

  getMovie = () => {
    const {
      match: { params },
      actions,
    } = this.props;
    actions.getMovie(params);
  };

  handlePageToggleSwitch = (type) => {
    const {
      history,
      match: { params },
    } = this.props;

    history.push(`/movies/${params.movieId}/${type}`);
  };

  getEarliestReleaseYear = (certificatesAndReleaseDates) => {
    const [earliestDate] = sortByEarliestDate(
      certificatesAndReleaseDates,
      "releaseDate",
      "YYYY-MM-DD"
    );
    if (!earliestDate) {
      return "-";
    }
    const parsedEarliestDate = moment(earliestDate.releaseDate, "YYYY-MM-DD");
    if (!parsedEarliestDate.isValid()) {
      return "-";
    }
    return `${parsedEarliestDate.year()}`;
  };

  renderDetails = (movie) => {
    return (
      <div className={classNames("flex col-12 flex-wrap clearfix", pageStyles.pageContainer)}>
        <InfoBlock
          header={"Basic Details"}
          config={[
            { title: "Runtime", value: getHoursFromSeconds(movie.runningTime) },
            { title: "Language", value: movie.language },
            {
              title: "More Info On",
              value: (
                <div className={`${styles.moreInfoText}`}>
                  <a
                    className="moviebuffLink"
                    href={`${MOVIEBUFF_WEBSITE_URL}${movie.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={MoviebuffLogo} alt="moviebuff logo" />
                  </a>
                </div>
              ),
            },
          ]}
        />
        <TableInfoBlock
          header=""
          data={movie.certificatesAndReleaseDates}
          isLargeHeader={false}
          columns={[
            {
              Header: "Country",
              accessor: "country",
            },
            {
              Header: "Certification",
              accessor: "certification",
            },
            {
              id: "releaseDate",
              Header: "Release Date",
              accessor: (d) => {
                const parsedDate = moment(d.releaseDate, "YYYY-MM-DD");
                return parsedDate.isValid() ? parsedDate.format("MMM DD, YYYY") : "-";
              },
            },
            {
              Header: "Week Number",
              accessor: "weekNumber",
            },
          ]}
        />
      </div>
    );
  };

  render = () => {
    const {
      history,
      movies: { movie, isLoading, movieScreens, movieTheatres },
      match: { params },
    } = this.props;

    if (isLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={4} />;
    }

    return (
      <div className="col-12 clearfix">
        <div className="col-12 clearfix">
          <Breadcrumb
            isLoading={isLoading}
            history={history}
            links={[..._LINKS, { name: movie.name }]}
          />
        </div>
        <PageHeader
          name={`${movie.name} · ${movie.language} · ${this.getEarliestReleaseYear(
            movie.certificatesAndReleaseDates
          )}`}
          isLoading={isLoading}
          renderRightSideComponent={() => (
            <LocalizedButton
              text="Button.editOnMoviebuff"
              buttonType={BUTTON_TYPE.SECONDARY}
              iconPos={ICON_POS.RIGHT}
              iconName="ExportIcon"
              onClick={() => {
                window.open(`${MOVIEBUFF_WEBSITE_URL}movies/${movie.url}/edit`, "_blank");
              }}
            />
          )}
        />
        <TabView
          id="navbar"
          selectedTabId={
            ["screens", "theatres"].includes(params.tabId) ? "screensAndTheatres" : params.tabId
          }
          tabs={[
            {
              id: "details",
              title: "Details",
              goto: `/movies/${movie.id}/details`,
              panel: this.renderDetails(movie),
            },
            {
              id: "screensAndTheatres",
              title: "Screens/Theatres",
              goto: `/movies/${movie.id}/screens`,
              count: `${movieScreens.totalCount || 0}/${movieTheatres.data.length || 0}`,
              panel:
                params.tabId === "theatres" ? (
                  <MovieTheatres onToggleSwitch={this.handlePageToggleSwitch} />
                ) : (
                  <MovieScreens onToggleSwitch={this.handlePageToggleSwitch} />
                ),
            },
          ]}
        />
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.movies,
  (movies) => ({ movies })
);

export default connect(mapStateToProps, bindDispatch)(MovieView);
