import * as types from "../types/targetGroups";

const initialState = {
  isLoading: true,
  isError: null,
  targetGroup: {},
  isTabLoading: true,
  targetGroupSummary: {},
  targetGroupMedia: {},
  targetGroupTheatres: {},
  targetGroupScreens: {},
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.TARGET_GROUP:
      return { ...state, targetGroup: payload };
    case types.IS_TAB_LOADING:
      return { ...state, isTabLoading: payload };
    case types.TARGET_GROUP_SUMMARY:
      return { ...state, targetGroupSummary: payload };
    case types.TARGET_GROUP_MEDIA:
      return { ...state, targetGroupMedia: payload };
    case types.TARGET_GROUP_THEATRES:
      return { ...state, targetGroupTheatres: payload };
    case types.TARGET_GROUP_SCREENS:
      return { ...state, targetGroupScreens: payload };
    default:
      return state;
  }
}
