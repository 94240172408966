import {
  getCampaignApprovals as getCampaignApprovalsApi,
  getRejectedProposals as getRejectedProposalsApi,
  getCampaign,
  approveCampaign as approveCampaignApi,
  requestChangesCampaign as requestChangesCampaignApi,
  rejectCampaign as rejectCampaignApi,
  getCampaignMedia as getCampaignMediaApi,
  getMedia,
  getCampaignSummary as getCampaignSummaryApi,
  getCampaignTargetGroups,
  getTargetGroupSummary,
  getTargetGroup,
} from "../../api";
import * as types from "../types/campaignApprovals";
import * as constants from "../../constants";
import { showToast } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateCampaignApprovals = (payload) => {
  return { type: types.CAMPAIGN_APPROVALS, payload: payload };
};

const updateCampaignApproval = (payload) => {
  return { type: types.CAMPAIGN_APPROVAL, payload: payload };
};

const updateRejectedProposals = (payload) => {
  return { type: types.REJECTED_PROPOSALS, payload: payload };
};

const updateCampaignApprovalMedia = (payload) => {
  return { type: types.CAMPAIGN_APPROVAL_MEDIA, payload: payload };
};

const updateCampaignApprovalMediaDetails = (payload) => {
  return { type: types.CAMPAIGN_APPROVAL_MEDIA_DETAILS, payload: payload };
};

const updateCampaignApprovalTargetGroups = (payload) => {
  return { type: types.CAMPAIGN_APPROVAL_TARGET_GROUPS, payload: payload };
};

const updateCampaignApprovalTargetGroupDetails = (payload) => {
  return { type: types.CAMPAIGN_APPROVAL_TARGET_GROUP_DETAILS, payload: payload };
};

const getCampaignApprovals = (params = {}, filters = [], getList = getCampaignApprovalsApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateCampaignApprovals(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getRejectedProposals = (params = {}, filters = [], getList = getRejectedProposalsApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "rejectedBy:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateRejectedProposals(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getCampaignApproval = (params) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data: details } = await getCampaign(params.campaignId);
      const { data: summary } = await getCampaignSummaryApi(params.campaignId);
      dispatch(
        updateCampaignApproval({
          ...details,
          summary,
        })
      );
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const openCampaignAction = (action) => (dispatch) => {
  dispatch(actionCreator(types.ACTION, action));
};

const closeCampaignAction = () => (dispatch) => {
  dispatch(actionCreator(types.ACTION, null));
};

const approveCampaign = (notes, onSuccess, approve = approveCampaignApi) => {
  return async () => {
    try {
      await approve(notes);
      showToast("Toast.campaignApprovedSuccessfully");
      onSuccess();
    } catch (error) {
      showToast(error.message, false);
      return error;
    }
  };
};

const requestChangesCampaign = (notes, onSuccess, requestChange = requestChangesCampaignApi) => {
  return async () => {
    try {
      await requestChange(notes);
      showToast("Toast.campaignRequestedChangesSuccessfully");
      onSuccess();
    } catch (error) {
      showToast(error.message, false);
      return error;
    }
  };
};

const rejectCampaign = (reason, notes, onSuccess, reject = rejectCampaignApi) => {
  return async () => {
    try {
      await reject(reason, notes);
      showToast("Toast.campaignRejectedSuccessfully");
      onSuccess();
    } catch (error) {
      showToast(error.message, false);
      return error;
    }
  };
};

const getCampaignApprovalMedia = (params = {}, filters = [], getList = getCampaignMediaApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getList(params.campaignId, -1, 0, "createdAt:asc", {});
      dispatch(updateCampaignApprovalMedia(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

const getCampaignApprovalMediaDetails = (mediaId) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.CAMPAIGN_APPROVAL_MEDIA_DETAILS_LOADING, true));
      const { data } = await getMedia(mediaId);
      dispatch(updateCampaignApprovalMediaDetails(data));
      dispatch(actionCreator(types.CAMPAIGN_APPROVAL_MEDIA_DETAILS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.CAMPAIGN_APPROVAL_MEDIA_DETAILS_LOADING, false));
      dispatch(actionCreator(types.IS_ERROR, error.message));
    }
  };
};

const getCampaignApprovalTargetGroups = (
  params = {},
  filters = [],
  getList = getCampaignTargetGroups
) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getList(params.campaignId, -1, 0, "createdAt:asc", {});
      dispatch(updateCampaignApprovalTargetGroups(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

const getCampaignApprovalTargetGroupDetails = (targetGroupId) => {
  return async (dispatch) => {
    try {
      const { data: details } = await getTargetGroup(targetGroupId);
      const { data: summary } = await getTargetGroupSummary(targetGroupId);
      dispatch(actionCreator(types.CAMPAIGN_APPROVAL_TARGET_GROUP_DETAILS_LOADING, false));
      dispatch(
        updateCampaignApprovalTargetGroupDetails({
          ...details,
          summary,
        })
      );
      dispatch(actionCreator(types.CAMPAIGN_APPROVAL_TARGET_GROUP_DETAILS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.CAMPAIGN_APPROVAL_TARGET_GROUP_DETAILS_LOADING, false));
      dispatch(actionCreator(types.IS_ERROR, error.message));
    }
  };
};

export {
  getCampaignApprovals,
  getRejectedProposals,
  getCampaignApproval,
  approveCampaign,
  requestChangesCampaign,
  rejectCampaign,
  openCampaignAction,
  closeCampaignAction,
  getCampaignApprovalMedia,
  getCampaignApprovalMediaDetails,
  getCampaignApprovalTargetGroups,
  getCampaignApprovalTargetGroupDetails,
};
