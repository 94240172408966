import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Position } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import styles from "../../styles/MultiSelect.module.scss";
import customMenuStyles from "../../styles/CustomMenu.module.scss";

class MultiSelector extends Component {
  static propTypes = {
    className: PropTypes.string,
    multiSelectorClassName: PropTypes.string,
    items: PropTypes.array.isRequired,
    itemPredicate: PropTypes.func,
    onQueryChange: PropTypes.func,
    noResults: PropTypes.object,
    onItemSelect: PropTypes.func.isRequired,
    popoverProps: PropTypes.object,
    tagRenderer: PropTypes.func,
    tagInputProps: PropTypes.object,
    selectedItems: PropTypes.array,
  };

  static defaultProps = {
    label: false,
    placeholder: "Search",
  };

  onQueryChange = (data, event) => {
    const { onQueryChange } = this.props;
    if (event && data.length) return onQueryChange(data);
    else return null;
  };

  render() {
    const {
      label,
      className,
      items,
      itemPredicate,
      itemRenderer,
      onQueryChange,
      noResults,
      onItemSelect,
      popoverProps,
      tagRenderer,
      tagInputProps,
      selectedItems,
      placeholder,
      ...rest
    } = this.props;
    return (
      <div className={`${styles.multiSelect} ${className}`}>
        {label && <div className={`col-12 semi h5 ${styles.label}`}>{label}</div>}
        <MultiSelect
          className={classNames(
            styles.inputValue,
            customMenuStyles.menu,
            rest.multiSelectorClassName,
            {
              [customMenuStyles.error]: rest.error,
            }
          )}
          openOnKeyDown={items.length && selectedItems.length === 0 ? true : false}
          items={items}
          itemPredicate={itemPredicate}
          itemRenderer={itemRenderer}
          noResults={noResults}
          onItemSelect={onItemSelect}
          popoverProps={{
            minimal: true,
            usePortal: false,
            disabled: tagInputProps.disabled,
            position: Position.BOTTOM,
          }}
          onQueryChange={this.onQueryChange}
          tagRenderer={tagRenderer}
          tagInputProps={tagInputProps}
          selectedItems={selectedItems}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    );
  }
}

export default MultiSelector;
