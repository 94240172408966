import React, { Component } from "react";
import PropTypes from "prop-types";
import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import Selector from "./Selector";

/**
 * Wrapper around selector which fetch data from api
 *
 */
class ApiSelect extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    parseResults: PropTypes.func.isRequired,
    fetchAction: PropTypes.func.isRequired,
    closeOnSelect: PropTypes.bool,
    singleSelect: PropTypes.bool,
    showLabelInButton: PropTypes.bool,
    defaultIsOpen: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    placeholder: "Select",
    singleSelect: true,
    showLabelInButton: false,
    closeOnSelect: true,
    defaultIsOpen: false,
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      list: [],
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.fetchData();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchData = async () => {
    try {
      const { data } = await this.props.fetchAction();

      if (this._isMounted) {
        this.setState({
          list: this.props.parseResults(data),
          isLoading: false,
        });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ list: [], isLoading: false });
      }
    }
  };

  render() {
    const { list, isLoading } = this.state;
    const {
      placeholder,
      error,
      showLabelInButton,
      closeOnSelect,
      singleSelect,
      defaultIsOpen,
      selected,
      onSelect,
      disabled,
    } = this.props;

    const selectedList = selected(list);

    return (
      <Selector
        label=""
        list={list}
        className={classNames({ [Classes.SKELETON]: isLoading })}
        selectedList={selectedList}
        placeHolder={placeholder}
        onSelect={onSelect}
        singleSelect={singleSelect}
        showLabelInButton={showLabelInButton}
        defaultIsOpen={defaultIsOpen}
        usePortal={false}
        closeOnSelect={closeOnSelect}
        error={error}
        disabled={list.length < 1 || disabled}
      />
    );
  }
}

export default ApiSelect;
