import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { createSelector } from "reselect";
import { isEmpty } from "lodash";
import { Redirect } from "react-router-dom";

import { getDisplayName, bindDispatch } from "../../../../utils";
import { validateMandatoryCampaignFields } from "../utils/campaignCreate";
import PageLoadingWithTabs from "../../../common/PageLoadingWithTabs";
import { CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST, CAMPAIGN_ACTIONS } from "../../../../constants";
import EmptyPage from "../../../common/EmptyPage";

export const campaignRouteHandler = (ChildComponent) => {
  class CampaignRouteHandler extends Component {
    state = {
      isCampaignValid: false,
      hasTargetGroups: false,
      hasMediaList: false,
      isFetching: true,
    };
    componentDidMount = async () => {
      const {
        actions,
        history,
        match: { params, path },
      } = this.props;
      const query = queryString.parse(history.location.search);
      await actions.getNewCampaignDetails(params);
      if (params.campaignId) {
        await actions.getNewCampaignTargetGroups(params);
        await actions.getNewCampaignMediaList(params);

        if (path.includes("target-groups")) {
          if (!isEmpty(params.action) && params.action !== "list")
            this.handleFetchAction(params, actions.getTargetGroupById, query?.id);
        }
        if (path.includes("media")) {
          if (!isEmpty(params.action) && params.action !== "list")
            this.handleFetchAction(params, actions.getMediaById, query?.id);
        }
      }
      this.setState({
        isFetching: false,
      });
    };
    componentWillUnmount() {
      this.props.actions.resetCampaignCreateError();
    }

    handleFetchAction = async (params, fetchAction, id) => {
      if (params.action === "create" && id) {
        await fetchAction(id, "duplicate");
      } else if (params.action !== "create") {
        await fetchAction(params.action, "edit");
      }
    };

    handleComponentRedirect = (url) => {
      const {
        location: { pathname },
      } = this.props;

      if (pathname !== url) return <Redirect replace to={url} />;

      return <ChildComponent {...this.props} />;
    };

    handleReviewAndProposalRoutes = (url) => {
      const {
        match: {
          params: { campaignId },
        },
        campaignCreate: { campaign, campaignTargetGroups, campaignMediaList },
      } = this.props;
      const isCampaignValid = campaignId && validateMandatoryCampaignFields(campaign);
      const hasTargetGroups = campaignTargetGroups?.data?.length > 0;
      const hasMediaList = campaignMediaList?.data?.length > 0;

      if (isCampaignValid) {
        if (hasTargetGroups) {
          if (hasMediaList) {
            return <ChildComponent {...this.props} />;
          } else {
            return this.handleComponentRedirect(`/campaign-create/${campaignId}/media/create`);
          }
        } else {
          return this.handleComponentRedirect(
            `/campaign-create/${campaignId}/target-groups/create`
          );
        }
      } else {
        return this.handleComponentRedirect(`/campaign-create`);
      }
    };

    handleMediaRoutes = () => {
      const {
        match: {
          params: { campaignId, action },
        },
        campaignCreate: { campaign, campaignMediaList },
      } = this.props;
      const hasMediaList = campaignMediaList?.data?.length > 0;
      if (isEmpty(campaign))
        return <ChildComponent {...this.props} isCampaignInvalid={isEmpty(campaign)} />;

      if (isEmpty(action)) {
        if (hasMediaList) {
          return this.handleComponentRedirect(`/campaign-create/${campaignId}/media/list`);
        } else return this.handleComponentRedirect(`/campaign-create/${campaignId}/media/create`);
      }

      if (action === "list" && !hasMediaList)
        return this.handleComponentRedirect(`/campaign-create/${campaignId}/media/create`);

      return <ChildComponent {...this.props} isCampaignInvalid={isEmpty(campaign)} />;
    };

    handleTargetGroupRoutes = () => {
      const {
        match: {
          params: { campaignId, action },
        },
        campaignCreate: { campaign, campaignTargetGroups },
      } = this.props;
      const hasTargetGroups = campaignTargetGroups?.data?.length > 0;

      if (isEmpty(campaign)) return <ChildComponent {...this.props} isCampaignInvalid />;

      if (isEmpty(action)) {
        if (hasTargetGroups) {
          return this.handleComponentRedirect(`/campaign-create/${campaignId}/target-groups/list`);
        } else {
          return this.handleComponentRedirect(
            `/campaign-create/${campaignId}/target-groups/create`
          );
        }
      }

      if (action === "list" && !hasTargetGroups)
        return this.handleComponentRedirect(`/campaign-create/${campaignId}/target-groups/create`);

      return <ChildComponent {...this.props} isCampaignInvalid={isEmpty(campaign)} />;
    };

    render() {
      const { isFetching } = this.state;
      const {
        match: {
          params: { campaignId },
          url,
        },
        campaignCreate: { campaign },
      } = this.props;
      const isCampaignValid = campaignId && validateMandatoryCampaignFields(campaign);

      // Loading and Error States
      if (isFetching) return <PageLoadingWithTabs />;
      // Campaign Routes
      if (!campaignId) return this.handleComponentRedirect(`/campaign-create`);
      if (campaignId) {
        if (!CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(campaign.status))
          return (
            <EmptyPage
              heading="EmptyState.campaignNotEditableHeading"
              body="EmptyState.campaignNotEditableMessage"
              isHomeLinkVisible
            />
          );
        // Target Group
        if (url.includes("target-groups")) return this.handleTargetGroupRoutes();

        // Media
        if (url.includes("media")) return this.handleMediaRoutes();

        // Review And Finish
        if (url.includes("review") || url.includes("finish"))
          return this.handleReviewAndProposalRoutes();

        if (!isEmpty(campaign) && (!isCampaignValid || !url.includes("details"))) {
          return this.handleComponentRedirect(`/campaign-create/${campaignId}/details`);
        }

        return <ChildComponent {...this.props} isCampaignInvalid={isEmpty(campaign)} />;
      }
    }
  }

  CampaignRouteHandler.displayName = `CampaignRouteHandler(${getDisplayName(ChildComponent)})`;

  const mapStateToProps = createSelector(
    (state) => state.campaignCreate,
    (campaignCreate) => ({
      campaignCreate,
    })
  );

  return connect(mapStateToProps, bindDispatch)(CampaignRouteHandler);
};

export default campaignRouteHandler;
