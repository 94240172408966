import React, { Component } from "react";
import queryString from "query-string";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { isEmpty } from "lodash";
import { validateToken } from "../../api";
import { bindDispatch } from "../../utils";
import PageLoadingWithTable from "../common/PageLoadingWithTable";
import { LOCAL_STORAGE_KEY } from "../../constants";

class AuthHandler extends Component {
  state = {
    tokenChecked: false,
  };

  validate = (token, ct) => {
    validateToken(token, ct)
      .then((res) => {
        this.props.actions.loginUser({ loggedIn: true, token, ...res.data });
        this.setState({ tokenChecked: true, loggedIn: true });
      })
      .catch(this.invalidate);
  };

  invalidate = (err) => {
    this.props.actions.logoutUser(err);
    this.setState({ tokenChecked: true, loggedIn: false });
  };

  componentDidMount() {
    const {
      location: { hash, search },
    } = this.props;
    if (hash && hash.length > 0 && hash.includes("access_token")) {
      const hashParams = queryString.parse(hash.replace("#", ""));
      const accessToken = hashParams.access_token;
      const queryParams = queryString.parse(search.replace("?", ""));
      const { ct } = queryParams;
      this.validate(accessToken, ct);
    } else {
      try {
        const token = localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN);
        if (token) this.validate(token);
        else this.invalidate();
      } catch {
        // eslint-disable-next-line no-console
        console.log("Local Storage Error");
      }
    }
  }

  render() {
    const { tokenChecked, loggedIn } = this.state;
    if (tokenChecked) {
      let nextUrl;
      try {
        nextUrl = localStorage.getItem(LOCAL_STORAGE_KEY.NEXT_URL);
        localStorage.removeItem(LOCAL_STORAGE_KEY.NEXT_URL); // Clear once used to avoid always redirecting to next url
      } finally {
        if (isEmpty(nextUrl)) nextUrl = "/dashboard";
      }
      if (loggedIn) return <Redirect replace to={`${nextUrl}`} />;
      else return <Redirect replace to="/login" />;
    }
    return <PageLoadingWithTable />;
  }
}

const mapStateToProps = createSelector(
  (state) => state.userData,
  (userData) => ({ userData })
);

export default connect(mapStateToProps, bindDispatch)(AuthHandler);
