import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { SCOPES, SCOPE_ACTIONS } from "../../../../constants";
import { QUBE_WIRE_APP_URL } from "../../../../config";
import { bindDispatch } from "../../../../utils";
import WithFetchList from "../../../hoc/withFetchList";
import PageHeader from "../../../common/PageHeader";
import ContentTable from "../common/ContentTable";
import LocalizedButton from "../../../common/LocalizedButton";
import Popup from "../../../common/Popup";
import EditContent from "./EditContent";

class ContentList extends Component {
  state = {
    isPopupOpen: false,
  };
  componentDidMount = async () => {
    const {
      actions,
      match: { params },
      fetchData,
    } = this.props;
    fetchData(null, false, [{ archived: false }]);
    if (params.id) actions.getContentById(params, true);
  };
  handlePopup = () => this.setState((prevState) => ({ isPopupOpen: !prevState.isPopupOpen }));

  mapBrand = (brand) => {
    const { actions, history } = this.props;
    history.push(`/content/${brand.id}`);
    actions.updateMapBrand({ isOpen: true, brand });
    actions.getContentById({ id: brand.id }, true);
  };
  onCancel = () => {
    const { actions, history } = this.props;
    actions.updateMapBrand({ isOpen: false, brand: {} });
    history.push(`/content`);
  };
  renderUploadContent = () => {
    const { userData } = this.props;
    return (
      <div>
        <p>
          The free Qube Wire desktop application for Windows and Mac can optionally encrypt Digital
          Cinema Package (DCPs) and automatically upload the DCPs and the Distribution Key Delivery
          Messages (DKDMs) to your company’s account on Qube Wire.
        </p>
        <LocalizedButton
          scope={SCOPES.CONTENT}
          scopeAction={SCOPE_ACTIONS.READ}
          userData={userData}
          text="Button.download"
          iconName="DownloadIcon"
          onClick={() => {
            window.open(`${QUBE_WIRE_APP_URL}/apps`, "_blank");
          }}
        />
      </div>
    );
  };
  render() {
    const {
      actions,
      content: {
        content,
        languages,
        contentDetails,
        editContent,
        isContentLoading,
        isCreateContentLoading,
        mapBrand,
      },
      userData,
    } = this.props;
    return (
      <div>
        <PageHeader
          name="PageHeader.content"
          count={content.totalCount}
          buttons={[
            {
              text: "Button.upload",
              onClick: this.handlePopup,
              iconName: "DownloadIcon",
              checkScope: true,
              scope: SCOPES.CONTENT,
              scopeAction: SCOPE_ACTIONS.CANCEL,
              userData,
            },
          ]}
        />
        <ContentTable
          data={content}
          isLoading={isContentLoading}
          onAssign={this.mapBrand}
          {...this.props}
        />
        <EditContent
          actions={actions}
          isLoading={isCreateContentLoading}
          editContent={editContent}
          languages={languages}
          mapBrand={mapBrand}
          content={contentDetails}
          onCancel={this.onCancel}
        />
        <Popup
          isOpen={this.state.isPopupOpen}
          onClose={this.handlePopup}
          showIcon={false}
          title="Popup.contentPopupTitle"
          body={this.renderUploadContent()}
        />
      </div>
    );
  }
}
const ContentWithFilters = WithFetchList("getCompanyContent", {
  sort: [
    {
      id: "name",
      desc: false,
    },
  ],
})(ContentList);

const mapStateToProps = createSelector(
  (state) => state.content,
  (state) => state.userData,
  (content, userData) => ({ content, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(ContentWithFilters));
