import React, { Component } from "react";
import classNames from "classnames";
import { isEmpty, map } from "lodash";
import { Icons } from "prefab";
import ApiSelect from "../../../../common/ApiSelect";
import InputRange from "../../../../common/InputRange";
import TextInput from "../../../../common/TextInput";
import SelectByIdPanel from "../../../../common/SelectByIdPanel";
import InputDimensions from "../../../../common/DimensionsRangeInput";
import Selector from "../../../../common/Selector";
import LocalizedButton from "../../../../common/LocalizedButton";
import { THEATRE_CRITERIA_MENU_LIST, THEATRE_CRITERIA_TYPES } from "../../../../../constants";
import {
  getTheatreTypes,
  getTheatreLocationTypes,
  getDigitalIntegrators,
  getProjectionTypes,
  getCoolingTypes,
  getExperiences,
  searchSegmentTypes as getSegmentTypes,
  searchPlaylistTemplate as getPlaylistTemplate,
  fetchPlaylistPackTypes as getPlaylistPackTypes,
  getUserTheatres,
  getUserScreens,
} from "../../../../../api";
import styles from "../../../../../styles/CampaignCreate/TheatreBasedInput.module.scss";

const { AddFilledIcon, CancelIcon } = Icons;

class TheatreBasedInput extends Component {
  state = {
    selectedCriteriaValue: null,
    selectedIdList: [],
    selectedCriteriaType: THEATRE_CRITERIA_MENU_LIST[0],
    isPanelOpen: false,
    isPanelLoading: false,
  };

  handleCriteriaSelectValue = ([data]) => {
    this.setState({
      selectedCriteriaValue: {
        id: data.id,
        name: data.value,
      },
    });
  };

  handlePanelClose = () => this.setState({ isPanelOpen: false });

  updateSelectedIds = (data) => {
    this.setState({ selectedIdList: data });
  };

  updateCriteriaInputValue = (value, accessor, parentAccessor = "") => {
    const criteriaInput = this.state.selectedCriteriaValue || {};

    if (parentAccessor) {
      criteriaInput[parentAccessor] = {
        ...criteriaInput[parentAccessor],
        [accessor]: value,
      };
    } else {
      criteriaInput[accessor] = value;
    }

    this.setState({
      selectedCriteriaValue: {
        ...criteriaInput,
        isRange: true,
      },
    });
  };

  handleCriteriaListUpdate = (criteriaListType) => {
    const { selectedCriteriaValue, selectedCriteriaType } = this.state;
    if (this.props.updateCriteriaList) {
      this.props.updateCriteriaList(
        {
          ...selectedCriteriaValue,
          type: selectedCriteriaType.id,
          typeDisplayName: selectedCriteriaType.label,
        },
        criteriaListType
      );

      this.setState({
        selectedCriteriaValue: null,
      });
    }
  };

  getSelectionData = async () => {
    const { selectedIdList, selectedCriteriaType } = this.state;
    let selectionData = [];
    if (selectedIdList.length === 0) return [];
    // TODO: Update once actual api is completed
    if (selectedCriteriaType.id === THEATRE_CRITERIA_TYPES.THEATRE_BY_ID) {
      const { data } = await getUserTheatres(selectedIdList);
      selectionData = data.data;
    } else {
      const { data } = await getUserScreens(selectedIdList);
      selectionData = data.data;
    }

    this.setState({
      isPanelLoading: false,
      selectionData: selectionData,
    });

    return selectionData;
  };

  updateSelectedIdsByCriteriaType = async (type) => {
    this.setState({
      isPanelLoading: true,
    });
    const selectedData = await this.getSelectionData();

    const { selectedCriteriaType } = this.state;
    if (this.props.updateCriteriaList && selectedData.length > 0) {
      map(selectedData, (item) => {
        this.props.updateCriteriaList(
          {
            // TODO: update name once api is completed
            name: item.name,
            id: item.id,
            type: selectedCriteriaType.id,
            typeDisplayName: selectedCriteriaType.label,
          },
          type
        );
      });

      this.setState({
        selectedCriteriaValue: null,
        selectedIdList: [],
        isPanelOpen: false,
      });
    }
  };

  handleSelectByIdExclusion = async () => {
    this.setState({
      isPanelLoading: true,
    });
    const selectedData = await this.getSelectionData();
    this.updateCriteriaSelectionById("exclusions", selectedData);
  };

  validateCriteriaRangeValue = (data) => {
    if (isEmpty(data)) return false;
    const { rangeFrom, rangeTo } = data;
    if (rangeFrom && rangeTo) {
      return rangeFrom !== rangeTo && rangeFrom < rangeTo;
    }

    return rangeFrom || rangeTo;
  };

  validateSelectedCriteriaValue = () => {
    const { selectedCriteriaValue, selectedCriteriaType } = this.state;
    const { TICKET_PRICE, SEATING_CAPACITY } = THEATRE_CRITERIA_TYPES;

    if (selectedCriteriaType.id === THEATRE_CRITERIA_TYPES.AUDITORIUM_DIMENSIONS) {
      const { auditoriumWidth, auditoriumHeight, auditoriumLength } = selectedCriteriaValue;
      return (
        this.validateCriteriaRangeValue(auditoriumLength) &&
        this.validateCriteriaRangeValue(auditoriumWidth) &&
        this.validateCriteriaRangeValue(auditoriumHeight)
      );
    } else if ([TICKET_PRICE, SEATING_CAPACITY].includes(selectedCriteriaType.id)) {
      return this.validateCriteriaRangeValue(selectedCriteriaValue);
    }

    return !isEmpty(selectedCriteriaType);
  };

  renderSelectWithApi = (getDataList) => {
    const { selectedCriteriaType, selectedCriteriaValue } = this.state;
    return (
      <ApiSelect
        placeholder={`Select ${selectedCriteriaType.label}`}
        onSelect={this.handleCriteriaSelectValue}
        value={selectedCriteriaValue && selectedCriteriaValue.name}
        fetchAction={() => getDataList()}
        parseResults={(res) => {
          return res.data.map((item) => ({
            id: item.id,
            value: item.name,
          }));
        }}
        selected={(list) =>
          selectedCriteriaValue ? list.filter((item) => item.id === selectedCriteriaValue.id) : []
        }
        singleSelect
        showLabelInButton={false}
      />
    );
  };

  renderSearchByIdInput = () => {
    return (
      <TextInput
        className={styles.criteriaTextInput}
        onFocus={() => this.setState({ isPanelOpen: true })}
        placeholder={`Select ${this.state?.selectedCriteriaType?.label}`}
      />
    );
  };

  renderInputByCriteriaType = () => {
    const { selectedCriteriaType, selectedCriteriaValue } = this.state;
    switch (selectedCriteriaType.id) {
      case THEATRE_CRITERIA_TYPES.THEATRE_BY_ID:
      case THEATRE_CRITERIA_TYPES.SCREEN_BY_ID:
        return this.renderSearchByIdInput();
      case THEATRE_CRITERIA_TYPES.THEATRE_TYPE:
        return this.renderSelectWithApi(getTheatreTypes);
      case THEATRE_CRITERIA_TYPES.THEATRE_LOCATION_TYPES:
        return this.renderSelectWithApi(getTheatreLocationTypes);
      case THEATRE_CRITERIA_TYPES.DIGITAL_INTEGRATORS:
        return this.renderSelectWithApi(getDigitalIntegrators);
      case THEATRE_CRITERIA_TYPES.PROJECTION_TYPES:
        return this.renderSelectWithApi(getProjectionTypes);
      case THEATRE_CRITERIA_TYPES.COOLING_TYPES:
        return this.renderSelectWithApi(getCoolingTypes);
      case THEATRE_CRITERIA_TYPES.EXPERIENCES:
        return this.renderSelectWithApi(getExperiences);
      case THEATRE_CRITERIA_TYPES.SEGMENT_TYPE:
        return this.renderSelectWithApi(getSegmentTypes);
      case THEATRE_CRITERIA_TYPES.PLAYLIST_PACK:
        return this.renderSelectWithApi(getPlaylistPackTypes);
      case THEATRE_CRITERIA_TYPES.PLAYLIST_TEMPLATE:
        return this.renderSelectWithApi(getPlaylistTemplate);
      case THEATRE_CRITERIA_TYPES.SEATING_CAPACITY:
      case THEATRE_CRITERIA_TYPES.TICKET_PRICE:
        return (
          <InputRange
            onChange={(value, accessor) => this.updateCriteriaInputValue(value, accessor)}
            minimumPlaceholder={selectedCriteriaType.minPlaceholder}
            maximumPlaceholder={selectedCriteriaType.maxPlaceholder}
            minValue={selectedCriteriaValue ? selectedCriteriaValue.rangeFrom : ""}
            maxValue={selectedCriteriaValue ? selectedCriteriaValue.rangeTo : ""}
          />
        );
      case THEATRE_CRITERIA_TYPES.AUDITORIUM_DIMENSIONS:
        return (
          <InputDimensions
            onChange={(value, accessor, parentAccessor) =>
              this.updateCriteriaInputValue(value, accessor, parentAccessor)
            }
            dimensionSeparator={<CancelIcon width={16} height={16} />}
            dimensionWidth={selectedCriteriaValue ? selectedCriteriaValue.auditoriumWidth : {}}
            dimensionHeight={selectedCriteriaValue ? selectedCriteriaValue.auditoriumHeight : {}}
            dimensionLength={selectedCriteriaValue ? selectedCriteriaValue.auditoriumLength : {}}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      isPanelOpen,
      selectedCriteriaValue,
      selectedCriteriaType,
      selectedIdList,
      isPanelLoading,
    } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <div className={styles.selector}>
            <div className={styles.title}>Theatre</div>
            <Selector
              singleSelect
              list={THEATRE_CRITERIA_MENU_LIST.map((item) => ({ ...item, value: item.label }))}
              onSelect={([selected]) =>
                this.setState({
                  selectedCriteriaType: selected,
                  selectedCriteriaValue: null,
                  errors: [],
                })
              }
              selectedList={[{ ...selectedCriteriaType, value: selectedCriteriaType.label }]}
              showLabelInButton={false}
              label=""
              usePortals
            />
          </div>
          <div className={styles.criteriaInput}>{this.renderInputByCriteriaType()}</div>
          <div className={styles.actions}>
            <LocalizedButton
              id="include"
              className={classNames(styles.button, styles.include)}
              iconName="AddIcon"
              disabled={isEmpty(selectedCriteriaValue) || !this.validateSelectedCriteriaValue()}
              onClick={() => this.handleCriteriaListUpdate("inclusions")}
              text="Button.include"
            />
            <LocalizedButton
              id="exclude"
              className={classNames(styles.button, styles.exclude)}
              iconName="CancelIcon"
              disabled={isEmpty(selectedCriteriaValue) || !this.validateSelectedCriteriaValue()}
              onClick={() => this.handleCriteriaListUpdate("exclusions")}
              text="Button.exclude"
            />
          </div>
        </div>
        <SelectByIdPanel
          isOpen={isPanelOpen}
          isLoading={isPanelLoading}
          onClose={this.handlePanelClose}
          header={
            selectedCriteriaType.id === THEATRE_CRITERIA_TYPES.THEATRE_BY_ID
              ? "RightPanelHeader.selectTheatreById"
              : "RightPanelHeader.selectScreenById"
          }
          getSelectedIds={this.updateSelectedIds}
          primaryButtonProps={[
            {
              text: "Button.include",
              onClick: this.updateSelectedIdsByCriteriaType("inclusions"),
              className: styles.include,
              icon: <AddFilledIcon />,
              disabled: selectedIdList.length === 0,
            },
            {
              text: "Button.exclude",
              onClick: this.updateSelectedIdsByCriteriaType("exclusions"),
              className: styles.exclude,
              icon: <CancelIcon />,
              disabled: selectedIdList.length === 0,
            },
          ]}
        />
      </div>
    );
  }
}

export default TheatreBasedInput;
