import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormGroup, TextArea } from "@blueprintjs/core";
import styles from "../../styles/SelectByIdPanel.module.scss";
import cleanIDSelection from "../../utils/cleanIDSelection";
import DetailsLoading from "./DetailsLoading";
import RightPanel from "./RightPanel";

export default class SelectByIdPanel extends Component {
  state = {
    hasFormSaved: false,
    idInputValue: "",
  };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    getSelectedIds: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  handleFormCancel = () => {
    this.props.onClose();

    this.setState({ idInputValue: "" });
  };

  handleInputChange = (event) => {
    if (this.props.getSelectedIds) {
      const selection = cleanIDSelection(event.target.value);
      this.props.getSelectedIds(selection);
    }
    this.setState({ idInputValue: "" });
  };

  renderPanelContent = () => {
    const { idInputValue } = this.state;

    if (this.props.isLoading) return <DetailsLoading />;

    return (
      <FormGroup
        contentClassName={styles.inputContainer}
        helperText="Separate multiple IDs with a new line or a comma. This will override your current selection."
      >
        <TextArea fill rows={15} onChange={this.handleInputChange} value={idInputValue} />
      </FormGroup>
    );
  };

  render = () => {
    const { isOpen, header, primaryButtonProps } = this.props;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={this.handleFormCancel}
        header={header}
        showFooter={true}
        primaryButtonProps={primaryButtonProps}
      >
        {this.renderPanelContent()}
      </RightPanel>
    );
  };
}
