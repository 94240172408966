import React, { Component } from "react";
import PropTypes from "prop-types";
import { BaseButton, BUTTON_TYPE, SIZE } from "prefab";
import { getTagTypeDisplayName } from "../../../utils";
import { TAG_RENDER_TYPE, TAG_RENDER_TYPE_MAP } from "../../../constants";
import styles from "../../../styles/FilterSection.module.scss";
import SearchFilterSection from "./SearchFilterSection";
import ListFilterSection from "./ListFilterSection";
import DateRangeFilterSection from "./DateRangeFilterSection";

class FilterSection extends Component {
  state = {
    isOpen: true,
  };

  static propTypes = {
    tagType: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
      .isRequired,
    selected: PropTypes.array,
    onFilterChange: PropTypes.func.isRequired,
    onFilterClear: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: [],
  };

  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  renderTagSelector = () => {
    const { tagType } = this.props;
    switch (TAG_RENDER_TYPE_MAP[tagType]) {
      case TAG_RENDER_TYPE.LIST:
        return <ListFilterSection {...this.props} />;
      case TAG_RENDER_TYPE.DATE_RANGE:
        return <DateRangeFilterSection {...this.props} />;
      case TAG_RENDER_TYPE.SEARCH:
      default:
        return <SearchFilterSection {...this.props} />;
    }
  };

  render() {
    const { tagType, selected, onFilterClear } = this.props;
    const { isOpen } = this.state;

    const iconProps = {
      onClick: this.toggle,
      buttonSize: SIZE.SMALL,
      buttonType: BUTTON_TYPE.SECONDARY,
    };

    let heading = getTagTypeDisplayName(tagType);
    if (selected.length > 0) heading += ` (${selected.length})`;

    return (
      <div className={`${styles.container}, ${styles.border}`}>
        <div className={styles.label}>
          <span className={styles.heading}>{heading}</span>
          {selected.length > 0 ? (
            <span className={styles.clear} onClick={onFilterClear}>
              Clear
            </span>
          ) : null}
          <span className="flex-auto" />
          {isOpen ? (
            <BaseButton iconName="ChevronUpIcon" {...iconProps} />
          ) : (
            <BaseButton iconName="ChevronDownIcon" {...iconProps} />
          )}
        </div>
        {isOpen && <div className={styles.section}>{this.renderTagSelector()}</div>}
      </div>
    );
  }
}

export default FilterSection;
