import React, { Component } from "react";
import { isEmpty, map, inRange } from "lodash";
import { FormattedMessage } from "react-intl";
import InfoBlock from "../../../common/InfoBlock";
import Form from "../../../common/Form";
import { FORM_FIELD_TYPE } from "../../../../constants";
import { getPlaylistTemplatesById } from "../../../../api";
import styles from "../../../../styles/Schedules/addPlacement.module.scss";
import PlacementTable from "./PlacementTable";

export default class AddPlacement extends Component {
  render() {
    const {
      hasFormSaved,
      errors,
      playlistTemplate,
      timesOfDay,
      onInputChange,
      placement,
      componentRef,
    } = this.props;
    return (
      <div ref={componentRef}>
        <div className={styles.errors}>
          {errors.map((error) => (
            <FormattedMessage id={error.errorCode} />
          ))}
        </div>
        <InfoBlock header="Placement and Spots" childClassName={styles.placementWrapper}>
          <Form
            isFormGroup={false}
            config={[
              {
                type: FORM_FIELD_TYPE.API_SELECT,
                id: "segmentId",
                label: placement.segmentId ? "Segment" : "Playlist Pack · Segment",
                placeholder: placement.segmentId
                  ? "Select Segment"
                  : "Select Playlist Pack · Segment",
                fetchAction: () =>
                  !isEmpty(playlistTemplate) && getPlaylistTemplatesById(playlistTemplate.id),
                size: {
                  lg: 4,
                  md: 4,
                },
                parseResults: (response) => {
                  const segmentList = [];
                  map(response?.playlistPacks, (playlistPack) => {
                    map(playlistPack?.segments, (segment) => {
                      segmentList.push({
                        id: segment.id,
                        name: segment.name,
                        segmentTypeId: segment.typeId,
                        playlistPackTypeId: playlistPack.typeId,
                        playlistPackId: playlistPack.id,
                        value: `${playlistPack.typeName} · ${segment.name}`,
                      });
                    });
                  });
                  return segmentList;
                },
                selected: (list) => list.filter((item) => item.id === placement?.segmentId),
                singleSelect: true,
                showLabelInButton: false,
                errorMessage: "Please Select Segment Type",
                error: hasFormSaved && !placement?.segmentId,
                disabled: isEmpty(playlistTemplate),
                onSelect: ([data]) => onInputChange(data.id, "placement", "segmentId"),
              },
              {
                id: "position",
                label: "Position",
                isOptional: true,
                type: FORM_FIELD_TYPE.INPUT,
                size: {
                  lg: 2,
                  md: 2,
                },
                placeholder: "Enter Position (In Negative)",
                value: placement?.position,
                maxLength: 4,
                errorMessage: "Please Specify Valid Position",
                error: hasFormSaved && !inRange(placement?.position, 1, -101),
                disabled: !placement.segmentId,
                onChange: (event) => onInputChange(event.target.value, "placement", "position"),
              },
              {
                id: "numberOfPlays",
                label: "Number of Plays",
                type: FORM_FIELD_TYPE.INPUT,
                size: {
                  lg: 2,
                  md: 2,
                },
                placeholder: "Enter Number of Plays",
                value: placement.numberOfPlays || null,
                errorMessage: "Please Enter Number of Plays",
                error: hasFormSaved && !placement?.numberOfPlays,
                disabled: !placement.segmentId,
                maxLength: 3,
                onlyNumbers: true,
                onChange: (value) => onInputChange(value, "placement", "numberOfPlays"),
              },
            ]}
          />
          <div className={styles.placementTable}>
            <PlacementTable
              isError={hasFormSaved && placement?.plays?.length === 0}
              timesOfDay={timesOfDay}
              plays={placement?.plays ?? []}
              onInputChange={onInputChange}
              numberOfPlays={placement.numberOfPlays}
            />
          </div>
        </InfoBlock>
      </div>
    );
  }
}
