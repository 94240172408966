const IS_LOADING = "inventory/campaigns/IS_LOADING";
const IS_PARTIAL_LOADING = "inventory/theatres/IS_PARTIAL_LOADING";
const IS_CAMPAIGN_FILE_LOADING = "inventory/theatres/IS_CAMPAIGN_FILE_LOADING";
const IS_ERROR = "inventory/campaigns/IS_ERROR";
const IS_CAMPAIGNS_LOADING = "inventory/campaigns/IS_CAMPAIGNS_LOADING";
const CAMPAIGNS = "inventory/campaigns/CAMPAIGNS";
const CAMPAIGN = "inventory/campaigns/CAMPAIGN";
const IS_TAB_LOADING = "inventory/campaigns/campaign/IS_TAB_LOADING";
const CAMPAIGN_SUMMARY = "inventory/campaigns/CAMPAIGN_SUMMARY";
const CAMPAIGN_TARGET_GROUPS = "inventory/campaigns/CAMPAIGN_TARGET_GROUPS";
const CAMPAIGN_MEDIA = "inventory/campaigns/CAMPAIGN_MEDIA";
const CAMPAIGN_THEATRES = "inventory/campaigns/CAMPAIGN_THEATRES";
const CAMPAIGN_SCREENS = "inventory/campaigns/CAMPAIGN_SCREENS";
const CAMPAIGN_FILES = "inventory/campaigns/CAMPAIGN_FILES";

export {
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_CAMPAIGN_FILE_LOADING,
  IS_ERROR,
  IS_CAMPAIGNS_LOADING,
  CAMPAIGNS,
  CAMPAIGN,
  CAMPAIGN_SUMMARY,
  IS_TAB_LOADING,
  CAMPAIGN_TARGET_GROUPS,
  CAMPAIGN_MEDIA,
  CAMPAIGN_THEATRES,
  CAMPAIGN_SCREENS,
  CAMPAIGN_FILES,
};
