import React, { Component } from "react";
import moment from "moment";
import classNames from "classnames";
import { startCase, isEmpty } from "lodash";
import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import Form from "../../../../common/Form";
import { CustomMenuItem } from "../../../../common";
import { SearchIcon } from "../../../../common/SearchIcon";
import RemoveIcon from "../../../../common/RemoveIcon";
import InfoBlock from "../../../../common/InfoBlock";
import {
  FORM_FIELD_TYPE,
  TAG_TYPE,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  CAMPAIGN_ACTIONS,
} from "../../../../../constants";
import { getDateRange, getRestrictedDateLimit } from "../../utils/campaignCreate";
import styles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import { searchContentTypes, searchBrands } from "../../../../../api";

export default class BasicDetails extends Component {
  state = {
    duplicateName: false,
    minDate: undefined,
    maxDate: undefined,
  };

  componentDidMount() {
    const { media, campaign } = this.props;
    const { fromDate, toDate } = getRestrictedDateLimit(
      media?.targetGroup?.validity,
      campaign.validity
    );
    this.setState({
      minDate: fromDate ? new Date(fromDate) : undefined,
      maxDate: toDate ? new Date(toDate) : undefined,
    });
  }

  getRestrictedDateLimit = () => {
    const { media, campaign } = this.props;
    const validity = isEmpty(media?.targetGroup?.validity)
      ? campaign.validity
      : media.targetGroup.validity;
    if (!isEmpty(validity)) {
      const { fromDate, toDate } = validity;
      this.setState({
        minDate: fromDate ? new Date(fromDate) : undefined,
        maxDate: toDate ? new Date(toDate) : undefined,
      });
    }
  };

  onNameChange(value) {
    const { mediaList, onInputChange } = this.props;
    const allMediaNames = mediaList.map((t) => t.name);
    onInputChange(value, "name", allMediaNames.includes(value));
  }

  validateMediaContent(field) {
    const { media } = this.props;
    if (!media.contentId) {
      return (
        media.contentTypeId && media.contentDurationInSeconds && media.brandId && media.categoryIds
      );
    }

    return Boolean(media[field]);
  }

  isSelectedCategory = (data) => {
    const {
      media: { brand },
    } = this.props;
    return brand?.categories?.find(
      (category) =>
        category.categoryId === data.categoryId &&
        category.subCategory1Id === data.subCategory1Id &&
        category.subCategory2Id === data.subCategory2Id
    );
  };

  arrowWithName = (n1, n2, n3) => {
    return [n1, n2, n3].filter((n) => n !== null && n.length > 0 && n !== undefined).join(" > ");
  };

  categoryMenuRenderer = (data) => {
    return this.arrowWithName(data.categoryName, data.subCategory1Name, data.subCategory2Name);
  };

  renderCategoryMenuItem = (data, { modifiers, handleClick }) => {
    return (
      <CustomMenuItem
        key={`${data.categoryId} ${data.subCategory1Id} ${data.subCategory2Id}`}
        active={modifiers?.active}
        icon={this.isSelectedCategory(data) ? IconNames.TICK : IconNames.BLANK}
        onClick={handleClick}
        text={this.categoryMenuRenderer(data)}
        shouldDismissPopover={false}
      />
    );
  };

  render() {
    const {
      hasFormSaved,
      targetGroups,
      media,
      onInputChange,
      isDuplicateName,
      removeCategory,
      categoryList,
      categorySearch,
    } = this.props;
    const showError = hasFormSaved;
    const allowModifyingStartDate = CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
      CAMPAIGN_ACTIONS.EDIT_VALIDITY
    ].includes(media.status);
    return (
      <InfoBlock header="Media Details" childClassName={styles.childWrapper}>
        <div
          className={classNames(
            "flex flex-wrap clearfix",
            styles.formContainer,
            styles.basicDetails
          )}
        >
          <Form
            isFormGroup={false}
            config={[
              {
                id: "name",
                label: "Media Name",
                type: FORM_FIELD_TYPE.INPUT,
                size: {
                  lg: 5,
                  md: 5,
                },
                placeholder: "Enter Name",
                value: media.name || "",
                onChange: (e) => this.onNameChange(e.target.value),
                error: (hasFormSaved && !media.name) || this.state.duplicateName,
                errorMessage: isDuplicateName ? "Duplicate Media Name" : "Please Enter Name",
              },
              {
                id: "targetGroup",
                key: "targetGroup",
                type: FORM_FIELD_TYPE.SELECT,
                size: {
                  lg: "col-lg-6 col-lg-offset-1",
                  md: "col-md-6 col-lg-offset-1",
                },
                containerClassName: styles.mediaFormField,
                title: "Target Group",
                list: targetGroups.map((t) => ({ ...t, value: t.name })),
                selectedList: !isEmpty(media.targetGroup) ? [media.targetGroup.name] : [],
                singleSelect: true,
                compareKey: "id",
                textKey: "name",
                usePortal: false,
                placeHolder: "Select Target Group",
                showLabelInButton: false,
                onSelect: ([data]) =>
                  onInputChange(
                    targetGroups.find((t) => t.id === data.id),
                    "targetGroup"
                  ),
                errorMessage: "Please Select Target Group",
                error: hasFormSaved && isEmpty(media.targetGroup),
              },
              {
                id: "validity",
                type: FORM_FIELD_TYPE.DATE_RANGE_PICKER,
                formatDate: (date) => moment(date).format("DD/MM/YYYY"),
                title: "Validity",
                containerClassName: "col-11",
                size: {
                  lg: 5,
                  md: 5,
                },
                handleDateChange: (dateRange) => onInputChange(dateRange, "validity"),
                dateRange: getDateRange(media?.validity),
                disabled: isEmpty(media.targetGroup),
                minDate: this.state.minDate,
                maxDate: this.state.maxDate,
                errorMessage: "Please Enter Validity",
                error: hasFormSaved && (isEmpty(media.targetGroup) || !media?.validity),
                isHidden: !allowModifyingStartDate,
              },
              {
                id: "startDate",
                type: FORM_FIELD_TYPE.LABEL,
                title: "Validity (Start Date)",
                size: { lg: 3, md: 3 },
                value: moment(media.validity.fromDate).format("MMM DD, YYYY"),
                isHidden: allowModifyingStartDate,
              },
              {
                id: "endDate",
                type: FORM_FIELD_TYPE.DATE,
                title: "Validity (End Date)",
                size: { lg: 3, md: 3 },
                onChange: (v) => onInputChange([null, v], "validity"),
                value: new Date(media.validity.toDate),
                placeholder: "End Date",
                isHidden: allowModifyingStartDate,
              },
              [
                {
                  id: "contentId",
                  label: "Content",
                  type: FORM_FIELD_TYPE.TAG_SEARCH,
                  size: {
                    lg: 5,
                    md: 5,
                  },
                  placeholder: "Select Content",
                  showSubText: false,
                  resetOnSelect: false,
                  tagTypes: TAG_TYPE.CONTENT,
                  onSelect: (content) => onInputChange(content.id, "contentId"),
                  // TODO: Remove once api is fixed
                  disabled: true,
                  errorMessage: "Please Select Content",
                  error: showError && !this.validateMediaContent(),
                },
                {
                  type: FORM_FIELD_TYPE.API_SELECT,
                  id: "contentTypeId",
                  title: "Content Type",
                  placeholder: "Select Content Type",
                  size: {
                    lg: "col-lg-2 col-lg-offset-1",
                    md: "col-md-2 col-lg-offset-1",
                  },
                  isLabel: Boolean(media.contentId),
                  fetchAction: () => searchContentTypes(-1, 0, "name"),
                  parseResults: (response) =>
                    response.data.map((item) => ({
                      id: item.id,
                      value: item.name,
                    })),
                  selected: (list) => list.filter((item) => item.id === media.contentTypeId),
                  singleSelect: true,
                  showLabelInButton: false,
                  errorMessage: "Please Select Content Type",
                  error: showError && !this.validateMediaContent("contentTypeId"),
                  onSelect: ([data]) => onInputChange(data.id, "contentTypeId"),
                },
                {
                  id: "contentDurationInSeconds",
                  title: "Duration (In Seconds)",
                  type: FORM_FIELD_TYPE.NUMERIC_INPUT,
                  size: {
                    lg: 3,
                    md: 3,
                  },
                  isLabel: Boolean(media.contentId),
                  placeholder: "Enter Duration",
                  value: media.contentDurationInSeconds,
                  errorMessage: "Please Enter Duration",
                  error: showError && !this.validateMediaContent("contentDurationInSeconds"),
                  onValueChange: (value) => onInputChange(value, "contentDurationInSeconds"),
                },
              ],
              {
                id: "brand",
                type: FORM_FIELD_TYPE.API_SEARCH,
                label: "Brand",
                placeholder: "Search for Brand",
                size: {
                  lg: 5,
                  md: 5,
                },
                onSelect: (item) => onInputChange(item, "brandId"),
                query: media.brand?.name ?? "",
                showSubText: true,
                isLabel: Boolean(media.contentId),
                parseResults: (data) =>
                  data.map((item) => ({
                    id: item[`${item.type}Id`],
                    name: item[`${item.type}Name`],
                    type: item.type,
                    tag: startCase(item.type),
                  })),
                fetchAction: (value) => searchBrands(value),
                errorMessage: "Please Add Brand",
                error: showError && !this.validateMediaContent("brandId"),
                clearSelectOnReset: true,
              },
              {
                id: "category",
                type: FORM_FIELD_TYPE.MULTI_SELECT,
                label: "Product Category",
                size: {
                  lg: "col-lg-6 col-lg-offset-1",
                  md: "col-md-6 col-lg-offset-1",
                },
                tagRenderer: this.categoryMenuRenderer,
                tagInputProps: {
                  placeholder: "Enter Product Category",
                  tagProps: { intent: Intent.NONE, minimal: true },
                  onRemove: (data, index) => removeCategory(index),
                  disabled: !media.brandId,
                  rightElement:
                    media?.categoryIds?.length > 0 ? (
                      <RemoveIcon onClick={() => this.clearMultiSelect("categoryIds")} />
                    ) : (
                      <SearchIcon />
                    ),
                },
                onQueryChange: categorySearch,
                selectedItems: media?.categories ?? [],
                items: categoryList ?? [],
                onItemSelect: (data) => onInputChange(data, "categoryIds"),
                itemRenderer: this.renderCategoryMenuItem,
                resetOnSelect: true,
                isLabel: Boolean(media.contentId),
                errorMessage: "Please Enter Product Category",
                error: showError && !this.validateMediaContent("categoryIds"),
              },
            ]}
          />
        </div>
      </InfoBlock>
    );
  }
}
