import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../styles/App.module.scss";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopeAuth,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
} from "../../../utils";
import { DEFAULT_ACTION_ICON_COLUMN, SCOPES } from "../../../constants";
import PageHeader from "../../common/PageHeader";
import HeaderButton from "../../common/HeaderButtons";
import ActionIcon from "../../common/ActionIcon";

const data = [
  {
    name: "Content Types",
    path: "/content-types",
    accessor: "contentTypes",
    scope: SCOPES.CONTENT_TYPES,
  },
  {
    name: "Notification Templates",
    path: "/notification-templates",
    accessor: "notificationTemplates",
    scope: SCOPES.NOTIFICATION_TEMPLATES,
  },
  {
    name: "Playlist Pack Types",
    path: "/playlist-pack-types",
    accessor: "playlistPackTypes",
    scope: SCOPES.PLAYLIST_PACK_TYPES,
  },
  {
    name: "Reasons",
    path: "/reasons",
    accessor: "reasons",
    scope: SCOPES.REASONS,
  },
  {
    name: "Rights",
    path: "/rights",
    accessor: "rights",
    scope: SCOPES.RIGHTS,
  },
  {
    name: "Segment Types",
    path: "/segment-types",
    accessor: "segmentTypes",
    scope: SCOPES.SEGMENT_TYPES,
  },
  {
    name: "Content Ratings",
    path: "/content-ratings",
    accessor: "ratings",
    scope: SCOPES.CERTIFICATIONS,
  },
  {
    name: "Product Categories",
    path: "/product-categories",
    accessor: "categories",
    scope: SCOPES.CATEGORIES,
  },
  {
    name: "Business Types",
    path: "/business-types",
    accessor: "businessTypes",
    scope: SCOPES.BRANDS,
  },
  {
    name: "Advertiser Types",
    path: "/advertiser-types",
    accessor: "advertiserTypes",
    scope: SCOPES.ADVERTISER_TYPES,
  },
  {
    name: "Product Identification Number Types",
    path: "/product-identification-number-types",
    accessor: "productIdentificationNumberTypes",
    scope: SCOPES.PRODUCT_IDENTIFICATION_NUMBER_TYPES,
  },
  {
    name: "Countries & Taxes",
    path: "/countries",
    accessor: "countries",
    scope: SCOPES.COUNTRIES,
  },
  {
    name: "Roles & Scopes",
    path: "/roles-scopes",
    accessor: "rolesScopes",
    scope: SCOPES.SCOPES,
  },
  {
    name: "Campaign Target Types",
    path: "/campaign-target-types",
    accessor: "campaignTargetType",
    scope: SCOPES.CAMPAIGN_TARGET_TYPE,
  },
  {
    name: "Billing Cycles",
    path: "/billing-cycles",
    accessor: "billingCycles",
    scope: SCOPES.BILLING_CYCLES,
  },
  {
    name: "Times of Day",
    path: "/times-of-day",
    accessor: "timesOfDay",
    scope: SCOPES.TIMES_OF_DAY,
  },
];

export class ListsPage extends Component {
  state = { selectedColumns: ["Name", "Count", "Last Updated On", "Last Updated By"] };

  static propTypes = {
    list: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { user, actions } = this.props;
    const lists = data.filter((l) => checkScopeAuth(user, l.scope)).map((l) => l.accessor);
    actions.fetchListDetails(lists);
  }

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  render() {
    const { list, history, user } = this.props;
    const { selectedColumns, reorderedColumns } = this.state;

    const columns = [
      {
        id: "name",
        Header: "Name",
        accessor: "name",
      },
      {
        id: "count",
        Header: "Count",
        width: 100,
        accessor: "count",
      },
      lastUpdatedByColumn(),
      lastUpdatedAtColumn(),
      {
        ...DEFAULT_ACTION_ICON_COLUMN,
        Cell: (props) => (
          <ActionIcon
            iconProps={[
              {
                toolTip: "Tooltip.view",
                onClick: () => history.push(`/lists${props.original.path}`),
                iconName: "ViewIcon",
              },
            ]}
          />
        ),
      },
    ];

    const tableData = data
      .filter((l) => checkScopeAuth(user, l.scope))
      .map((d) => ({
        ...d,
        updatedAt: list[d.accessor] && list[d.accessor].updatedAt,
        count: list[d.accessor] && list[d.accessor].count,
        updatedBy: (list[d.accessor] && list[d.accessor].updatedBy) || "-",
      }));
    return (
      <div>
        <PageHeader
          name="PageHeader.lists"
          renderRightSideComponent={() => (
            <HeaderButton
              columnFilter
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onColumnFilterSelect={this.onColumnSelect}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <Table
              sortable={false}
              data={tableData}
              defaultSorted={[{ id: "name", desc: false }]}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              onRowClick={({ original }) => history.push(`/lists${original.path}`)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.list,
  (state) => state.userData,
  (list, userData) => ({ list, user: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(ListsPage);
