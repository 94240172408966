import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { groupBy, values, inRange } from "lodash";
import styles from "../../styles/StatusIconLegend.module.scss";
import StatusIcon from "./StatusIcon";
import Divider from "./Divider";

export default class StatusIconLegend extends Component {
  static propTypes = {
    statusInfo: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        count: PropTypes.number,
        type: PropTypes.string,
        color: PropTypes.string,
        tagType: PropTypes.string,
      })
    ).isRequired,
    onFilterSelect: PropTypes.func,
  };

  static defaultProps = {
    onFilterSelect: null,
  };

  handleFilterSelect = ({ tagType, name, id }) => {
    if (this.props.onFilterSelect) {
      this.props.onFilterSelect({
        id,
        displayName: name,
        type: tagType,
      });
    }
  };

  renderStatus = (status, index) => {
    return (
      <div
        className={classNames(styles.statusIconLegend, {
          [styles.cursorPointer]: this.props.onFilterSelect,
        })}
        key={index}
        onClick={() => this.handleFilterSelect(status)}
      >
        <StatusIcon color={status.color} type={status.type} />
        <span className={styles.statusCount}>{status.count}</span>
        <span>{status.name}</span>
      </div>
    );
  };

  render() {
    const groupedStatusInfo = groupBy(this.props.statusInfo, "tagType");
    if (!values(groupedStatusInfo).some((group) => group.some((data) => data.count > 0)))
      return null;
    return (
      <>
        {values(groupedStatusInfo).map((statusInfoItem, index) => (
          <Fragment key={index}>
            {statusInfoItem.map(
              (status, statusIndex) => status.count > 0 && this.renderStatus(status, statusIndex)
            )}
            {inRange(index, 0, values(groupedStatusInfo).length - 1) && <Divider />}
          </Fragment>
        ))}
      </>
    );
  }
}
