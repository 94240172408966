const IS_LOADING = "inventory/campaigns/IS_LOADING";
const IS_ERROR = "inventory/campaigns/IS_ERROR";
const MEDIA = "inventory/campaigns/MEDIA";
const IS_TAB_LOADING = "inventory/campaigns/media/IS_TAB_LOADING";
const MEDIA_SUMMARY = "inventory/campaigns/MEDIA_SUMMARY";
const MEDIA_THEATRES = "inventory/campaigns/media/MEDIA_THEATRES";
const MEDIA_SCREENS = "inventory/campaigns/media/MEDIA_SCREENS";

export {
  IS_LOADING,
  IS_ERROR,
  MEDIA,
  MEDIA_SUMMARY,
  IS_TAB_LOADING,
  MEDIA_THEATRES,
  MEDIA_SCREENS,
};
