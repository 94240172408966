const IS_LOADING = "inventory/theatres/IS_LOADING";
const IS_PARTIAL_LOADING = "inventory/theatres/IS_PARTIAL_LOADING";
const IS_THEATRE_VIEW_LOADING = "inventory/theatres/IS_THEATRE_VIEW_LOADING";
const IS_THEATRE_SCREENS_LOADING = "inventory/theatres/THEATRE/SCREENS/IS_LOADING";
const IS_THEATRE_RIGHTS_LOADING = "inventory/theatres/THEATRE/RIGHTS/IS_LOADING";
const IS_THEATRE_RATE_CARDS_LOADING = "inventory/theatres/THEATRE/RATE_CARDS/IS_LOADING";
const IS_ERROR = "inventory/theatres/IS_ERROR";
const THEATRES = "inventory/theatres/THEATRES";
const THEATRE = "inventory/theatres/THEATRE";
const THEATRE_SCREENS = "inventory/theatres/THEATRE/SCREENS";
const THEATRE_PLAYLIST = "inventory/theatres/THEATRE/PLAYLIST";
const THEATRE_RIGHTS = "inventory/theatres/THEATRE/RIGHTS";
const THEATRE_RIGHTS_EDIT = "inventory/theatres/THEATRE/RIGHTS/EDIT";
const THEATRE_RATE_CARDS = "inventory/theatres/THEATRE/RATE_CARDS";

export {
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_ERROR,
  THEATRE,
  THEATRES,
  THEATRE_SCREENS,
  THEATRE_PLAYLIST,
  THEATRE_RIGHTS,
  THEATRE_RIGHTS_EDIT,
  IS_THEATRE_SCREENS_LOADING,
  IS_THEATRE_RIGHTS_LOADING,
  IS_THEATRE_RATE_CARDS_LOADING,
  IS_THEATRE_VIEW_LOADING,
  THEATRE_RATE_CARDS,
};
