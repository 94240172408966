import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { BUTTON_TYPE, Icons } from "prefab";
import styles from "../../../../styles/Campaigns/CampaignSteps.module.scss";
import LocalizedButton from "../../../common/LocalizedButton";
import { CAMPAIGN_STEPS } from "../../../../constants";

const { CampaignIcon, CheckboxSelectedIcon, TheatreIcon, ListIcon, AddIcon } = Icons;

const stepConfig = [
  {
    number: CAMPAIGN_STEPS.CAMPAIGN,
    name: "Campaign Details",
    icon: <CampaignIcon />,
  },
  {
    number: CAMPAIGN_STEPS.TARGET_GROUP,
    name: "Target Groups",
    icon: <TheatreIcon />,
  },
  {
    number: CAMPAIGN_STEPS.MEDIA,
    name: "Media",
    icon: <AddIcon />,
  },
  {
    number: CAMPAIGN_STEPS.REVIEW,
    name: "Review",
    icon: <ListIcon />,
  },
  {
    number: CAMPAIGN_STEPS.FINISH,
    name: "Finish",
    icon: <AddIcon />,
  },
];

class CampaignSteps extends Component {
  static propTypes = {
    activeStep: PropTypes.number.isRequired,
    validTillStep: PropTypes.number.isRequired,
    onChangeStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    onReset: PropTypes.func,
    onSendProposal: PropTypes.func,
    onCreateCampaign: PropTypes.func,
    isAdminUser: PropTypes.bool,
    disableSave: PropTypes.bool,
    disableReset: PropTypes.bool,
  };

  static defaultProps = {
    isAdminUser: false,
    disableSave: false,
    disableReset: false,
    onReset: null,
    onSendProposal: null,
    onCreateCampaign: null,
  };

  renderStep = (step) => {
    const { activeStep, validTillStep, onChangeStep } = this.props;
    const isActive = activeStep === step.number;
    const isValid = step.number <= validTillStep;
    const icon = step.number <= validTillStep && !isActive ? <CheckboxSelectedIcon /> : step.icon;
    return (
      <Fragment key={step.number}>
        <div
          onClick={() => (isValid && !isActive ? onChangeStep(step.number) : null)}
          className={classNames(styles.step, {
            [styles.valid]: isValid,
            [styles.active]: isActive,
          })}
        >
          <div className={classNames(styles.icon)}>{icon}</div>
          <div className={styles.name}>{`${step.number}. ${step.name}`}</div>
        </div>
        <span className={styles.separator} />
      </Fragment>
    );
  };

  render() {
    const {
      activeStep,
      onChangeStep,
      onSendProposal,
      onReset,
      onCreateCampaign,
      isAdminUser,
      disableSave,
      disableReset,
    } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.stepContainer}>{stepConfig.map(this.renderStep)}</div>
        <div className={styles.buttons}>
          {activeStep > CAMPAIGN_STEPS.CAMPAIGN && (
            <LocalizedButton
              id="back"
              buttonType={BUTTON_TYPE.SECONDARY}
              onClick={() => onChangeStep(activeStep - 1)}
              text="Button.back"
            />
          )}
          {activeStep !== CAMPAIGN_STEPS.FINISH && (
            <LocalizedButton
              id="save"
              disabled={disableSave}
              onClick={() => onChangeStep(activeStep + 1)}
              text="Button.saveAndContinue"
              buttonType={BUTTON_TYPE.PRIMARY}
              iconName="AddIcon"
            />
          )}
          <LocalizedButton
            id="reset"
            buttonType={BUTTON_TYPE.SECONDARY}
            onClick={onReset}
            disabled={disableReset}
            text="Button.reset"
          />
          {activeStep === CAMPAIGN_STEPS.FINISH &&
            (isAdminUser ? (
              <LocalizedButton
                id="createCampaign"
                text="Button.createCampaign"
                buttonType={BUTTON_TYPE.PRIMARY}
                onClick={onCreateCampaign}
              />
            ) : (
              <LocalizedButton
                id="sendProposal"
                text="Button.sendProposal"
                buttonType={BUTTON_TYPE.PRIMARY}
                onClick={onSendProposal}
              />
            ))}
        </div>
      </div>
    );
  }
}

export default CampaignSteps;
