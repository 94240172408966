import moment from "moment";
import { getUserTimeZone } from ".";

const formatDateTime = (dt, format = "MMM DD, YYYY HH:mm") => {
  if (!dt) return null;
  const timeZone = getUserTimeZone();
  const momentDate = moment(new Date(dt)).utcOffset(timeZone.offset);
  const formatted = momentDate.format(format);
  return formatted;
};

export default formatDateTime;
