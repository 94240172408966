import React, { Component } from "react";
import classNames from "classnames";
import moment from "moment";
import { flattenDeep, values, keys, map, isEqual, isEmpty, groupBy } from "lodash";
import { Tooltip } from "@blueprintjs/core";
import SecondaryTable from "../../../common/SecondaryTable";
import Checkbox from "../../../common/Checkbox";
import styles from "../../../../styles/Schedules/placementTable.module.scss";
import { DAY_OF_WEEK } from "../../../../constants";

const defaultIconProps = {
  width: "20px",
  height: "20px",
};

export default class PlacementTable extends Component {
  state = {
    placementTable: [],
    isAllSelected: false,
    weeks: [...DAY_OF_WEEK.slice(1), DAY_OF_WEEK[0]],
    timesOfDay: this.props.timesOfDay
      .filter((t) => t.isDefault)
      .sort((a, b) => moment(a.startTime, "HH:mm:ss") - moment(b.startTime, "HH:mm:ss")),
  };

  componentDidMount = () => {
    const { plays } = this.props;
    const { timesOfDay, weeks } = this.state;
    const groupedPlays = groupBy(plays, "dayOfWeek");
    const updatedWeeks = weeks.map((week) => ({
      ...week,
      isRowSelected: groupedPlays[week.value]?.length === timesOfDay.length,
    }));
    const placementData = this.generatePlayData();
    const allPlays = placementData.map((placement) =>
      placement?.plays?.map((play) => values(play))
    );
    this.setState({
      placementTable: placementData,
      weeks: updatedWeeks,
      isAllSelected: !flattenDeep(allPlays).includes(false),
    });
  };

  componentDidUpdate = (prevProps) => {
    const { plays } = this.props;
    const placementData = this.generatePlayData();
    const allPlays = placementData.map((placement) =>
      placement?.plays?.map((play) => values(play))
    );
    if (plays.length > 0 && !isEqual(plays, prevProps?.plays)) {
      this.setState({
        placementTable: this.generatePlayData(plays),
        isAllSelected: !flattenDeep(allPlays).includes(false),
      });
    }
  };

  generatePlayData = (modifiedPlays = []) => {
    const { plays } = this.props;
    const { weeks, timesOfDay } = this.state;
    const playData = modifiedPlays.length > 0 ? modifiedPlays : plays;
    return timesOfDay.map(({ id }) => {
      const currentPlay = playData?.filter((p) => p.timeOfDayId === id) ?? [];
      const weekPlays = map(weeks, ({ value }) => {
        if (!!currentPlay.find(({ dayOfWeek }) => dayOfWeek === value)) return { [value]: true };
        return { [value]: false };
      });
      return {
        plays: weekPlays,
        timeOfDayId: id,
      };
    });
  };

  filterPlacementData = (placementsData) => {
    const { numberOfPlays } = this.props;
    const filteredData = [];
    map(placementsData, (item) => {
      const dayList = item.plays?.filter((day) => !values(day).includes(false));
      map(dayList, (day) => {
        filteredData.push({
          timeOfDayId: item.timeOfDayId,
          dayOfWeek: parseInt(keys(day)[0]),
          numberOfPlays: parseInt(numberOfPlays),
        });
      });
    });
    return filteredData;
  };

  modifyPlay = (timeOfDayId, day) => {
    let { placementTable } = this.state;
    placementTable = map(placementTable, (item) => {
      if (item.timeOfDayId === timeOfDayId) {
        const currentIndex = item.plays?.findIndex((play) => keys(play).includes(day.toString()));
        if (currentIndex > -1) item.plays[currentIndex][day] = !item.plays[currentIndex][day];
      }
      return item;
    });
    const playsPerDay = this.filterPlacementData(placementTable);
    this.props.onInputChange(playsPerDay, "placement", "plays");
    this.setState({
      placementTable,
    });
  };

  isAllRowSelected = (timeOfDayId) => {
    const { placementTable } = this.state;
    const filteredPlay = placementTable.find((item) => item.timeOfDayId === timeOfDayId);
    return !filteredPlay?.plays?.find((item) => values(item).includes(false));
  };

  isAllColumnSelected = (day) => {
    const { placementTable } = this.state;
    const filteredPlay = placementTable.map(
      (item) => item.plays?.find((play) => keys(play)[0] === day.toString())[day]
    );
    return !filteredPlay.includes(false);
  };

  modifyPlaysByRowOrColumn = (data, type, isSelectAll = false) => {
    let { placementTable, weeks } = this.state;
    const { isAllSelected } = this.state;
    const currentDayIndex = weeks.findIndex((item) => item.value === data);
    placementTable = map(placementTable, (item) => {
      if (isSelectAll) {
        item.plays = item.plays?.map((play) => {
          const [dayValue] = keys(play);
          return { [dayValue]: !isAllSelected };
        });
      } else if (!isSelectAll && type === "day") {
        item.plays = item.plays?.map((play) => {
          const [dayValue] = keys(play);
          if (dayValue === data.toString())
            return {
              [dayValue]: !weeks[currentDayIndex].isRowSelected,
            };
          return play;
        });
      } else if (item.timeOfDayId === data && type === "timeOfDay") {
        item.plays = item.plays?.map((play) => {
          const [dayValue] = keys(play);
          return { [dayValue]: !this.isAllRowSelected(item.timeOfDayId) };
        });
      }
      return item;
    });

    if (isSelectAll) {
      weeks = map(weeks, (week) => {
        return {
          ...week,
          isRowSelected: !isAllSelected,
        };
      });
    } else if (type === "day" && !isSelectAll) {
      weeks[currentDayIndex].isRowSelected = !weeks[currentDayIndex].isRowSelected;
    }
    const playsPerDay = this.filterPlacementData(placementTable);

    this.props.onInputChange(playsPerDay, "placement", "plays");
    this.setState({
      placementTable,
      isAllSelected: !isAllSelected,
      weeks,
    });
  };

  renderHeader = (day, dayValue, isSelectAll = false) => {
    const { placementTable, weeks } = this.state;
    const currentDayIndex = weeks.findIndex((item) => item.value === dayValue);
    const isAllSelected = flattenDeep(
      map(placementTable, (item) => values(item.plays).map((day) => values(day)))
    );
    const isColumnSelected =
      !isSelectAll && (this.isAllColumnSelected(dayValue) || weeks[currentDayIndex].isRowSelected);
    const isChecked = !isSelectAll ? isColumnSelected : !isAllSelected.includes(false);

    return (
      <div className={classNames(styles.header, isSelectAll && styles.selectAll)}>
        <Checkbox
          {...defaultIconProps}
          disabled={this.props.numberOfPlays < 1}
          className={classNames(styles.checkboxContainer, {
            [styles.checked]: isChecked,
            [styles.disabled]: this.props.numberOfPlays < 1,
          })}
          checked={isChecked}
          onChange={() => this.modifyPlaysByRowOrColumn(dayValue, "day", isSelectAll)}
        />
        <div className={styles.title}>{isSelectAll ? "Entire Week" : day}</div>
      </div>
    );
  };

  renderPlay = (d, dayValue) => {
    const isChecked = !!d.plays.find((day) => day[dayValue]);
    return (
      <div className={styles.bodyColumn}>
        <Checkbox
          {...defaultIconProps}
          disabled={this.props.numberOfPlays < 1}
          className={classNames(styles.checkboxContainer, {
            [styles.checked]: isChecked,
            [styles.disabled]: this.props.numberOfPlays < 1,
          })}
          checked={isChecked}
          onChange={() => this.modifyPlay(d.timeOfDayId, dayValue, "day")}
        />
      </div>
    );
  };

  render() {
    const { isError, numberOfPlays } = this.props;
    const { timesOfDay, placementTable, weeks } = this.state;
    const allPlays = placementTable.map((placement) =>
      placement?.plays?.map((play) => values(play))
    );
    const totalPlays = flattenDeep(allPlays).filter((play) => !!play).length;
    if (isEmpty(placementTable)) return null;

    return (
      <div className={styles.placementContainer}>
        {isError && <div className={styles.errors}>Please Select the Placements</div>}
        <SecondaryTable
          data={placementTable}
          rowHeaderClass={styles.headerContainer}
          bodyColumnClass={styles.columnContainer}
          rowHeader={styles.headerColumn}
          columns={[
            {
              id: "timeOfDay",
              Header: this.renderHeader(null, null, true),
              accessor: (d) => {
                const timeOfDay = timesOfDay.find((day) => day.id === d.timeOfDayId);
                if (!timeOfDay) return null;
                return (
                  <div className={styles.timeOfDayHeader}>
                    <Checkbox
                      {...defaultIconProps}
                      className={classNames(styles.checkboxContainer, {
                        [styles.checked]: this.isAllRowSelected(d.timeOfDayId),
                        [styles.disabled]: numberOfPlays < 1,
                      })}
                      disabled={numberOfPlays < 1}
                      checked={this.isAllRowSelected(d.timeOfDayId)}
                      onChange={() =>
                        this.modifyPlaysByRowOrColumn(d.timeOfDayId, "timeOfDay", false)
                      }
                    />
                    <Tooltip
                      content={`${moment(timeOfDay.startTime, "HH:mm:ss").format("LT")} - ${moment(
                        timeOfDay.endTime,
                        "HH:mm:ss"
                      ).format("LT")}`}
                    >
                      <div className={styles.timeOfDayContent}>
                        <div className={styles.placementsTimeName}>{timeOfDay.name}</div>
                      </div>
                    </Tooltip>
                  </div>
                );
              },
              width: 140,
            },
            ...weeks.map((dayOfWeek) => ({
              id: dayOfWeek.value,
              Header: this.renderHeader(dayOfWeek.label, dayOfWeek.value),
              accessor: (d) => this.renderPlay(d, dayOfWeek.value),
              width: 80,
            })),
          ]}
        />
        <div className={styles.totalPlays}>{`Total Number of Plays: ${
          totalPlays * numberOfPlays
        }`}</div>
      </div>
    );
  }
}
