import { STATUS_ICON_COLORS } from "../constants";
import styles from "../styles/Campaigns/Campaigns.module.scss";

const getCampaignColorClassName = (color) => {
  switch (color) {
    case STATUS_ICON_COLORS.GREEN:
      return styles.green;
    case STATUS_ICON_COLORS.LIGHT_BLUE:
      return styles.lightBlue;
    case STATUS_ICON_COLORS.GREY:
      return styles.grey;
    case STATUS_ICON_COLORS.YELLOW:
      return styles.yellow;
    case STATUS_ICON_COLORS.RED:
      return styles.red;
    case STATUS_ICON_COLORS.PURPLE:
      return styles.purple;
    case STATUS_ICON_COLORS.LIGHT_GREY:
      return styles.lightGrey;
    default:
      return styles.primary;
  }
};

export default getCampaignColorClassName;
