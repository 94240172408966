import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import { bindDispatch } from "../../../../../utils";
import PageStyles from "../../../../../styles/App.module.scss";
import TargetGroupTheatres from "../TargetGroup/TargetGroupTheatres";
import { getSummaryBlock } from "../../utils";

export class CampaignApprovalTargetGroups extends Component {
  componentDidMount = async () => {
    const { targetGroupId, actions } = this.props;
    await actions.getTargetGroupById(targetGroupId);
  };

  handleTheatreExclusion = () => {
    // TODO: Make api call to update exclusion list
  };

  render = () => {
    const {
      campaignCreate: { campaignTargetGroup },
    } = this.props;

    return (
      <div>
        <div className={PageStyles.pageContainer}>{getSummaryBlock(campaignTargetGroup)}</div>
        <TargetGroupTheatres onExclusion={this.handleTheatreExclusion} />
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.campaignCreate,
  (state) => state.userData,
  (campaignCreate, userData) => ({
    campaignCreate,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(CampaignApprovalTargetGroups));
