import React from "react";

import { Icons } from "prefab";
import InfoBlock from "../../../common/InfoBlock";
import SummaryItem from "../../../common/SummaryItem";
import { pluralize, getDuration } from "../../../../utils";

const { CalendarIcon, LocationIcon, TheatreIcon, PlayIcon, PremiumIcon } = Icons;

const getSummaryBlock = (details, header = "Summary") => (
  <InfoBlock
    header={header}
    hideHeader={header === ""}
    config={[
      {
        title: "",
        value: (
          <SummaryItem
            icon={<CalendarIcon />}
            title={pluralize(details.summary?.estimated.days, "Day")}
            body={getDuration(details.summary?.estimated.duration, "MMM DD, YYYY")}
          />
        ),
        size: {
          md: 4,
          sm: 6,
        },
      },
      {
        title: "",
        value: (
          <SummaryItem
            icon={<LocationIcon />}
            title={pluralize(details.summary?.estimated.countStates, "State")}
            body={pluralize(details.summary?.estimated.countCities, "city", "s", false, "Cities")}
          />
        ),
        size: {
          md: 4,
          sm: 6,
        },
      },
      {
        title: "",
        value: (
          <SummaryItem
            icon={<TheatreIcon />}
            title={pluralize(details.summary?.estimated.countTheatres, "Theatre", "s")}
            body={pluralize(details.summary?.estimated.countScreens, "Screen", "s")}
          />
        ),
        size: {
          md: 4,
          sm: 6,
        },
      },
      {
        title: "",
        value: (
          <SummaryItem
            icon={<PlayIcon />}
            title="Max Plays Per Screen"
            body={details.maxPlaysPerScreen}
          />
        ),
        size: {
          md: 4,
          sm: 6,
        },
      },
      {
        title: "",
        value: (
          <SummaryItem
            icon={<PlayIcon />}
            title="Total No. of Plays"
            body={details.summary?.estimated.plays}
          />
        ),
        size: {
          md: 4,
          sm: 6,
        },
      },
      {
        name: "",
        value: (
          <SummaryItem
            icon={<PremiumIcon />}
            title="Estimated Reach"
            body={details.summary?.estimated.reach}
          />
        ),
        size: {
          md: 4,
          sm: 6,
        },
      },
    ]}
  />
);

export default getSummaryBlock;
