import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import classNames from "classnames";
import ButtonStyles from "../../../styles/Button.module.scss";
import { getTimeFromSeconds } from "../../../utils/timeUtils";
import * as constants from "../../../constants";
import ActionIcon from "../../common/ActionIcon";
import pageStyles from "../../../styles/App.module.scss";
import CPLStyles from "../../../styles/Content/cpls.module.scss";
import {
  bindDispatch,
  modifyTableColumns,
  createdByColumn,
  createdAtColumn,
  lastUpdatedAtColumn,
  lastUpdatedByColumn,
} from "../../../utils";
import { checkScopes } from "../../../utils";
import PageHeader from "../../common/PageHeader";
import HeaderButtons from "../../common/HeaderButtons";
import LocalizedButton from "../../common/LocalizedButton";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import TableLoading from "../../common/TableLoading";
import FilterChips from "../../common/Filters/FilterChips";
import WithFetchList from "../../hoc/withFetchList";
import TableControls from "../../common/TableControls";
import FooterControls from "../../common/FooterControls";
import { ActiveTableCell } from "../../common/ActiveTableCell";
import { AUDIT_LOG_SOURCE } from "../logs/logSource";
import UploadCPLPanel from "./actions/UploadCplPanel";

class CompositionsList extends Component {
  state = {
    selectedColumns: ["Composition Name", "Duration", "Content Type", "Advertiser Type"],
    isUploadCPLPanelOpen: false,
    isEdit: false,
  };
  componentDidMount = () => {
    this.props.fetchData();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  handleUploadCPLPanel = () => {
    this.setState({ isUploadCPLPanelOpen: true });
  };

  closeUploadCPLPanel = () => {
    this.setState({ isUploadCPLPanelOpen: false });
    this.props.actions.resetComposition();
  };

  onSaveCPL = (cplId, isEdit = false) => {
    this.props.fetchData();
    this.props.actions.resetComposition();
    this.setState({ isEdit, isUploadCPLPanelOpen: false });
  };

  editComposition = (prop) => {
    const { actions } = this.props;
    actions.updateCompositionData(prop.original);
    this.setState({ isUploadCPLPanelOpen: true, isEdit: true });
  };

  render = () => {
    const {
      compositions: { compositionsList, isLoading, isUploading, isPartialLoading },
      filters,
      onFilterIdSelect,
      ps,
      page,
      sort,
      onQueryChange,
      isFilterLoading,
      onSortedChange,
      user,
      history,
    } = this.props;

    const { selectedColumns, reorderedColumns, isUploadCPLPanelOpen, isEdit } = this.state;
    if (isLoading) return <PageLoadingWithTable />;
    const activeTableCell = (id, value) => ActiveTableCell(id, value, () => onFilterIdSelect(id));
    const columns = checkScopes(
      [
        {
          id: "name",
          Header: "Composition Name",
          accessor: (d) => d.name,
          Cell: (prop) => <span className={CPLStyles.name}>{prop.value}</span>,
        },
        {
          id: "durationInSeconds",
          Header: "Duration",
          accessor: (d) => getTimeFromSeconds(d.durationInSeconds),
        },
        {
          id: "contentTypeName",
          Header: "Content Type",
          accessor: (d) => activeTableCell(d.contentTypeId, d.contentTypeName),
        },
        {
          id: "advertiserTypeName",
          Header: "Advertiser Type",
          accessor: (d) => activeTableCell(d.advertiserTypeId, d.advertiserTypeName),
        },
        lastUpdatedAtColumn(),
        lastUpdatedByColumn(this.props.onFilterIdSelect),
        createdAtColumn("Added On"),
        createdByColumn(this.props.onFilterIdSelect, "Added By"),
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          scope: constants.SCOPES.COMPOSITIONS,
          scopeAction: constants.SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  id: "edit",
                  toolTip: "Tooltip.update",
                  onClick: () => this.editComposition(props),
                  iconName: "EditIcon",
                },
              ]}
            />
          ),
        },
      ],
      user
    );
    return (
      <div>
        <PageHeader
          name="PageHeader.compositions"
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          count={compositionsList.totalCount}
          renderRightSideComponent={() => (
            <>
              <LocalizedButton
                userData={user}
                scope={constants.SCOPES.COMPOSITIONS}
                scopeAction={constants.SCOPE_ACTIONS.WRITE}
                iconName="AddFilledIcon"
                text="Button.uploadCPL"
                className={ButtonStyles.buttonSpacing}
                onClick={() => this.handleUploadCPLPanel()}
              />
              <HeaderButtons
                history={history}
                logsButton
                logsPath={`/logs/source/${AUDIT_LOG_SOURCE.CPL}`}
                userData={user}
              />
            </>
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={compositionsList}
              ps={ps}
              page={page}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
              tagTypes={[
                constants.TAG_TYPE.CPL,
                constants.TAG_TYPE.CONTENT_TYPE,
                constants.TAG_TYPE.ADVERTISER_TYPE,
              ]}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={compositionsList.totalCount}
              onFilterChange={this.props.onFilterChange}
              loading={isPartialLoading}
            />
            <Table
              data={compositionsList.data}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              loading={isPartialLoading || isFilterLoading}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
            />
            <FooterControls
              pagination
              data={compositionsList}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
        <UploadCPLPanel
          isEdit={isEdit}
          isUploading={isUploading}
          isOpen={isUploadCPLPanelOpen}
          onClose={this.closeUploadCPLPanel}
          onSave={this.onSaveCPL}
          actions={this.props.actions}
          composition={this.props.compositions}
        />
      </div>
    );
  };
}

const CompositionsListWithFilters = WithFetchList("getCompositionList", {
  sort: [
    {
      id: "createdAt",
      desc: true,
    },
  ],
})(CompositionsList);

export { CompositionsList, CompositionsListWithFilters };

const mapStateToProps = createSelector(
  (state) => state.compositions,
  (state) => state.userData,
  (compositions, userData) => ({ compositions, user: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(CompositionsListWithFilters);
