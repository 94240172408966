import { compact } from "lodash";
import { TAG_TYPE } from "../constants";

const getTagDisplayName = (filter) => {
  if (filter.type !== TAG_TYPE.CITY && filter.type !== TAG_TYPE.PROVINCE) return filter.displayName;
  return compact([
    filter.displayName,
    filter.context?.province?.name,
    filter.context?.country?.name,
  ]).join(", ");
};

export default getTagDisplayName;
