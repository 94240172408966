import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Icons } from "prefab";
import Collapsible from "../../../../common/Collapsible";
import { formatValidityPeriod, getCategory } from "../../../../../utils";
import CampaignTree from "../../CampaignStructure/CampaignTree";
import styles from "../../../../../styles/Campaigns/Campaigns.module.scss";
import Popup from "../../../../common/Popup";
import PageLoadingWithTable from "../../../../common/PageLoadingWithTable";
import InfoBlockLoading from "../../../../common/InfoBlockLoading";
import LocalizedButton from "../../../../common/LocalizedButton";
import InfoBlock from "../../../../common/InfoBlock";
import { MediaPlacements } from "../../utils";
import { getCampaignTree as getCampaignTreeApi } from "../../../../../api";
import { CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST, CAMPAIGN_ACTIONS } from "../../../../../constants";

const { EditIcon, DeleteIcon, CopyIcon, ChevronDownIcon, AddFilledIcon } = Icons;

export default class MediaDetails extends Component {
  static propTypes = {
    mediaList: PropTypes.array.isRequired,
    withCampaignTree: PropTypes.bool,
    showEstimates: PropTypes.bool,
    showAdd: PropTypes.bool,
    showEdit: PropTypes.bool,
    showDuplicate: PropTypes.bool,
    showDelete: PropTypes.bool,
  };

  static defaultProps = {
    showAdd: false,
    showEstimates: false,
    withCampaignTree: false,
    showEdit: true,
    showDuplicate: true,
    showDelete: true,
  };

  state = {
    isPopupOpen: false,
    isCampaignStructureLoading: true,
    campaignStructure: {},
    isCampaignStructureError: false,
  };

  componentDidMount = async () => {
    if (this.props.withCampaignTree) await this.fetchCampaignTreeData();
  };

  fetchCampaignTreeData = () => {
    const { params } = this.props;

    getCampaignTreeApi(params.campaignId)
      .then((campaignRes) =>
        this.setState({
          isCampaignStructureLoading: false,
          campaignStructure: campaignRes,
        })
      )
      .catch((err) => {
        this.setState({
          isCampaignStructureLoading: false,
          isCampaignStructureError: true,
          campaignStructure: null,
        });
      });
  };

  handleEditClick = (event, id) => {
    event.stopPropagation();
    if (this.props.onEdit) {
      this.props.onEdit(id);
    }
  };

  handleDuplicateClick = (event, id) => {
    event.stopPropagation();
    if (this.props.onDuplicate) {
      this.props.onDuplicate(id);
    }
  };

  handleMediaCreate = (event) => {
    event.stopPropagation();
    if (this.props.onMediaCreate) {
      this.props.onMediaCreate();
    }
  };

  handleConfirmDelete = (event) => {
    event.stopPropagation();
    if (this.props.onDelete) {
      this.props.onDelete(this.state.selectedId);
    }

    this.setState({
      isPopupOpen: false,
    });
  };

  handleDeleteClick = (event, id) => {
    event.stopPropagation();

    this.setState({
      isPopupOpen: true,
      selectedId: id,
    });
  };

  onDiscardChanges = (event) => {
    event.stopPropagation();

    this.setState({
      isPopupOpen: false,
    });
  };

  renderInfoBlockDetails = (media, timesOfDay) => {
    if (this.props.isDetailsLoading) return <InfoBlockLoading />;

    return (
      <div className={styles.collapsibleBodyContainer}>
        <InfoBlock
          hideHeader
          config={[
            {
              title: "Content",
              value: media.content ? (
                media.content.name
              ) : (
                <LocalizedButton
                  id="add"
                  className={`${styles.addButton}`}
                  iconName="AddIcon"
                  onClick={() => {}}
                  text="Button.add"
                />
              ),
              size: {
                lg: 6,
                md: 4,
                sm: 6,
              },
            },
            {
              title: "Content Type",
              value: media.contentTypeName,
              size: {
                lg: 6,
                md: 4,
                sm: 6,
              },
            },
            {
              title: "Duration",
              value: `${media.contentDurationInSeconds || 0} Seconds`,
              size: {
                lg: 6,
                md: 4,
                sm: 6,
              },
            },
            {
              title: "Brand",
              value: media.brand
                ? [
                    media.brand.parentBrandName,
                    media.brand.individualBrandName,
                    media.brand.productName,
                    media.brand.variantName,
                  ]
                    .filter(Boolean)
                    .join(" Ã‚Â· ")
                : "",
              size: {
                lg: 6,
                md: 4,
                sm: 6,
              },
            },
            {
              title: "Category",
              value: media.categories
                ? media.categories.map((category, key) => (
                    <div key={key}>{getCategory(category)}</div>
                  ))
                : "",
              size: {
                lg: 6,
                md: 4,
                sm: 6,
              },
            },
          ]}
        />
        {media.placements && <MediaPlacements media={media} timesOfDay={timesOfDay} />}
      </div>
    );
  };

  render() {
    const {
      mediaList,
      timesOfDay,
      showDelete,
      showDuplicate,
      showEdit,
      showAdd,
      withCampaignTree,
      isLoading,
      getMediaDetails,
    } = this.props;
    const {
      isPopupOpen,
      isCampaignStructureLoading,
      campaignStructure,
      isCampaignStructureError,
    } = this.state;

    if (isLoading) return <PageLoadingWithTable />;

    return (
      <>
        <div className="col-12 clearfix flex">
          <div className="col-12">
            {mediaList.map((media, index) => (
              <Collapsible
                key={index}
                header={(isOpen, toggleCollapse) => (
                  <div
                    className={styles.collapsibleHeaderContainer}
                    onClick={() => {
                      getMediaDetails(media.id, "list");
                      toggleCollapse();
                    }}
                  >
                    <div className={styles.title}>
                      <span
                        className={classNames(styles.icon, {
                          [styles.open]: isOpen,
                          [styles.close]: !isOpen,
                        })}
                      >
                        <ChevronDownIcon />
                      </span>
                      {`${media.name} (${media.code})`}
                    </div>
                    <div className={styles.subTitle}>
                      <div className={styles.info}>{formatValidityPeriod(media)}</div>
                      <div className={styles.info}>
                        <strong>Target Group</strong> {media.targetGroupName}
                      </div>
                    </div>
                    <div className={styles.collapseActions}>
                      {showEdit &&
                        CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(
                          media.status
                        ) && (
                          <div
                            className={styles.button}
                            onClick={(event) => this.handleEditClick(event, media.id)}
                          >
                            <EditIcon />
                          </div>
                        )}
                      {showDuplicate && (
                        <div
                          className={styles.button}
                          onClick={(event) => this.handleDuplicateClick(event, media.id)}
                        >
                          <CopyIcon />
                        </div>
                      )}
                      {showDelete && (
                        <div
                          className={styles.button}
                          onClick={(event) => this.handleDeleteClick(event, media.id)}
                        >
                          <DeleteIcon />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                body={(isOpen) => this.renderInfoBlockDetails(media, timesOfDay)}
              />
            ))}
            {showAdd && (
              <LocalizedButton
                text="Button.createTargetGroup"
                icon={<AddFilledIcon width={16} height={16} />}
                onClick={(event) => this.handleMediaCreate(event)}
                className={`${styles.button} ${styles.createButton}`}
              />
            )}
          </div>
          {withCampaignTree && !isCampaignStructureError && (
            <div className={styles.campaignTree}>
              <CampaignTree
                isLoading={isCampaignStructureLoading}
                campaignTreeData={campaignStructure ?? []}
              />
            </div>
          )}
        </div>
        <Popup
          isOpen={isPopupOpen}
          onClose={this.onDiscardChanges}
          onConfirm={this.handleConfirmDelete}
          title="Popup.title"
          body="Popup.body"
        />
      </>
    );
  }
}
