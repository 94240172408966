import { getCurrentActionPeriod } from ".";

const isCampaignStatusChangesAvailable = (data, actionType) => {
  if (data.length === 1) {
    return Boolean(getCurrentActionPeriod(data[0], actionType));
  }
  return data.filter((campaign) => campaign.statusChanges?.length > 0).length > 0;
};

export default isCampaignStatusChangesAvailable;
