import { omit } from "lodash";
import {
  getCampaigns as getCampaignsApi,
  getCampaign as getCampaignApi,
  getCampaignSummary as getCampaignSummaryApi,
  getCampaignTargetGroups as getCampaignTargetGroupsApi,
  getCampaignMedia as getCampaignMediaApi,
  getCampaignTheatres as getCampaignTheatresApi,
  getCampaignScreens as getCampaignScreensApi,
  pauseOrResumeCampaigns as pauseOrResumeCampaignsApi,
  pauseOrResumeTargetGroup as pauseOrResumeTargetGroupApi,
  pauseOrResumeMedia as pauseOrResumeMediaApi,
  cancelCampaigns as cancelCampaignsApi,
  cancelTargetGroups as cancelTargetGroupsApi,
  cancelScreens as cancelScreensApi,
  cancelMedia as cancelMediaApi,
  getCampaignFiles as getCampaignFilesApi,
  getCampaignFileUrl as getCampaignFileUrlApi,
  createOrUpdateCampaign as createOrUpdateCampaignApi,
  createOrUpdateTargetGroup as createOrUpdateTargetGroupApi,
} from "../../api";
import * as types from "../types/campaigns";
import * as constants from "../../constants";
import { createFilterRequest, showToast } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateCampaigns = (payload) => {
  return { type: types.CAMPAIGNS, payload: payload };
};

const updateCampaign = (payload) => {
  return { type: types.CAMPAIGN, payload: payload };
};

const updateCampaignSummary = (payload) => {
  return { type: types.CAMPAIGN_SUMMARY, payload: payload };
};

const updateCampaignTargetGroups = (payload) => {
  return { type: types.CAMPAIGN_TARGET_GROUPS, payload: payload };
};

const updateCampaignFiles = (payload) => {
  return { type: types.CAMPAIGN_FILES, payload: payload };
};

const updateCampaignMedia = (payload) => {
  return { type: types.CAMPAIGN_MEDIA, payload: payload };
};

const updateCampaignTheatres = (payload) => ({ type: types.CAMPAIGN_THEATRES, payload });
const updateCampaignScreens = (payload) => ({ type: types.CAMPAIGN_SCREENS, payload });
const viewCampaignFiles = (files) => (dispatch) =>
  dispatch(updateCampaignFiles({ files, isOpen: true }));

const getCampaigns = (params = {}, filters = [], getList = getCampaignsApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CAMPAIGNS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateCampaigns(data));
      dispatch(actionCreator(types.IS_CAMPAIGNS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CAMPAIGNS_LOADING, false));
    }
  };
};

const getCampaign = (params) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getCampaignApi(params.campaignId);
      dispatch(updateCampaign(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const updateCampaignDetails = (data) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      await createOrUpdateCampaignApi(data);
      await showToast("Toast.updatedCampaignMaxPlaysPerScreen", true);
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const updateTargetGroupDetails = (data) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      await createOrUpdateTargetGroupApi(data);
      await showToast("Toast.updatedTargetGroupMaxPlaysPerScreen", true);
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const pauseOrResumeCampaigns = (ids, status, period, params, isSingleCampaign = false) => {
  const { req: ft } = createFilterRequest(
    ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  );
  const data = {
    ft,
    status: status,
    startDate: period.from,
    endDate: period.to,
  };
  return async (dispatch) => {
    try {
      await pauseOrResumeCampaignsApi(data);
      isSingleCampaign
        ? await dispatch(getCampaign({ campaignId: ids[0] }))
        : await dispatch(getCampaigns(params));
      await showToast("Toast.statusUpdatedSuccessfully", true);
    } catch (error) {
      isSingleCampaign
        ? await dispatch(getCampaign({ screenId: ids[0] }))
        : await dispatch(getCampaigns(params));
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};
const pauseOrResumeTargetGroups = (ids, status, period, params) => {
  const { req: ft } = createFilterRequest(
    ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  );
  const data = {
    ft,
    status: status,
    startDate: period.from,
    endDate: period.to,
  };
  return async (dispatch) => {
    try {
      await pauseOrResumeTargetGroupApi(data);
      await dispatch(getCampaignTargetGroups(params));
      await showToast("Toast.statusUpdatedSuccessfully", true);
    } catch (error) {
      await dispatch(getCampaignTargetGroups(params));
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};
const pauseOrResumeOneOrMoreMedia = (ids, status, period, params) => {
  const { req: ft } = createFilterRequest(
    ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  );
  const data = {
    ft,
    status: status,
    startDate: period.from,
    endDate: period.to,
  };
  return async (dispatch) => {
    try {
      await pauseOrResumeMediaApi(data);
      await dispatch(getCampaignMedia(params));
      await showToast("Toast.statusUpdatedSuccessfully", true);
    } catch (error) {
      await dispatch(getCampaignMedia(params));
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};

const cancel = (data, type) => {
  switch (type) {
    case constants.CAMPAIGN_TYPE.CAMPAIGN:
      return cancelCampaignsApi(data);
    case constants.CAMPAIGN_TYPE.TARGET_GROUP:
      return cancelTargetGroupsApi(data.campaignId, omit(data, ["campaignId"]));
    case constants.CAMPAIGN_TYPE.MEDIA:
      return cancelMediaApi(data.campaignId, omit(data, ["targetGroupId"]));
    case constants.CAMPAIGN_TYPE.SCREEN:
      return cancelScreensApi(data.campaignId, omit(data, ["campaignId"]));
    default:
      return;
  }
};
const fetchList = async (params, data, dispatch, type) => {
  switch (type) {
    case constants.CAMPAIGN_TYPE.CAMPAIGN:
      return await dispatch(getCampaigns(params));
    case constants.CAMPAIGN_TYPE.TARGET_GROUP:
      return await dispatch(getCampaignTargetGroups({ ...params, campaignId: data.campaignId }));
    case constants.CAMPAIGN_TYPE.MEDIA:
      return await dispatch(getCampaignMedia({ ...params, campaignId: data.campaignId }));
    case constants.CAMPAIGN_TYPE.SCREEN:
      return await dispatch(getCampaignTheatres(params));
    default:
      return;
  }
};

const fetchDetails = (data, type) => {
  switch (type) {
    case constants.CAMPAIGN_TYPE.CAMPAIGN:
      return getCampaign({ campaignId: data.ids[0] });
    default:
      return;
  }
};

const cancelCampaigns = (data, type, params, callback = () => {}, isSingleCampaign = false) => {
  const { req: ft } = createFilterRequest(
    data.ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  );
  const campaigns = { ft, ...data };
  return async (dispatch) => {
    try {
      await cancel(omit(campaigns, ["ids"]), type);
      isSingleCampaign
        ? fetchDetails(data, dispatch, type)
        : fetchList(params, data, dispatch, type);
      callback();
      await showToast("Toast.statusUpdatedSuccessfully", true);
    } catch (error) {
      isSingleCampaign
        ? fetchDetails(data, dispatch, type)
        : fetchList(params, data, dispatch, type);
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};

const getCampaignFiles = (campaignId) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CAMPAIGN_FILE_LOADING, true));
      const { data } = await getCampaignFilesApi(campaignId);
      dispatch(viewCampaignFiles(data));
      dispatch(actionCreator(types.IS_CAMPAIGN_FILE_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CAMPAIGN_FILE_LOADING, false));
    }
  };
};

const downloadCampaignFile = (campaignId, file) => {
  return async (dispatch) => {
    try {
      const { data } = await getCampaignFileUrlApi(campaignId, file.name, true);
      window.open(data.url, "_blank");
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
    }
  };
};

const getCampaignSummary = (campaignId) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getCampaignSummaryApi(campaignId);
      dispatch(updateCampaignSummary(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

const getCampaignTargetGroups = (
  params = {},
  filters = [],
  getList = getCampaignTargetGroupsApi
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc", campaignId } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getList(campaignId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateCampaignTargetGroups(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

const getCampaignMedia = (params = {}, filters = [], getList = getCampaignMediaApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc", campaignId } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getList(campaignId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateCampaignMedia(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

const getTheatresList = (id, params) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc" } = params;
  switch (params.targetType) {
    case constants.THEATRE_SCREENS_VIEW_TYPE.THEATRES:
      return getCampaignTheatresApi(id, ps, (page - 1) * ps, sort);
    case constants.THEATRE_SCREENS_VIEW_TYPE.SCREENS:
      return getCampaignScreensApi(id, ps, (page - 1) * ps, sort);
    default:
      return;
  }
};

const updateTheatresAndScreens = (params, data) => {
  switch (params.targetType) {
    case constants.THEATRE_SCREENS_VIEW_TYPE.THEATRES:
      return updateCampaignTheatres(data);
    case constants.THEATRE_SCREENS_VIEW_TYPE.SCREENS:
      return updateCampaignScreens(data);
    default:
      return;
  }
};

const getCampaignTheatres = (params = {}) => {
  const { id } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getTheatresList(id, params);
      dispatch(updateTheatresAndScreens(params, data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

export {
  getCampaigns,
  getCampaign,
  getCampaignSummary,
  getCampaignTargetGroups,
  getCampaignMedia,
  getCampaignTheatres,
  getCampaignFiles,
  updateCampaignFiles,
  viewCampaignFiles,
  downloadCampaignFile,
  pauseOrResumeCampaigns,
  pauseOrResumeTargetGroups,
  pauseOrResumeOneOrMoreMedia,
  cancelCampaigns,
  updateCampaignDetails,
  updateTargetGroupDetails,
};
