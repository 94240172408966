import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { createSelector } from "reselect";
import classNames from "classnames";
import { compact, isEmpty, uniqWith, isEqual } from "lodash";
import { bindDispatch } from "../../../../../utils";
import Breadcrumb from "../../../../common/BreadCrumb";
import PageLoadingWithTabs from "../../../../common/PageLoadingWithTabs";
import InfoBlockLoading from "../../../../common/InfoBlockLoading";
import styles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import pageStyles from "../../../../../styles/App.module.scss";
import CampaignTree from "../../CampaignStructure/CampaignTree";
import CampaignSteps from "../CampaignSteps";
import { BILLING_CYCLES, CAMPAIGN_STEPS, CAMPAIGN_STATUSES } from "../../../../../constants";
import { searchCountries } from "../../../../../api";
import { validateMandatoryCampaignFields, campaignStepChange } from "../../utils/campaignCreate";
import CampaignPageHeader from "../../common/CampaignPageHeader";
import EmptyState from "../EmptyState";
import BasicDetails from "./BasicDetails";
import ContactDetails from "./ContactDetails";
import BillingDetails from "./BillingDetails";
import campaignRouteHandler from "..";

const links = [
  { name: "PageHeader.campaigns", path: "/campaigns" },
  { name: "PageHeader.newCampaign" },
];

export class CampaignCreate extends Component {
  getInitialState = () => ({
    campaign: {
      name: "",
      advertiserId: null,
      billingCycle: {},
      countryIds: [],
      currencyId: "",
      currency: {},
      validity: {},
      countries: [],
      contacts: [],
      status: CAMPAIGN_STATUSES.DRAFT,
    },
    hasFormSaved: false,
    isFormEdited: false,
    isFetchingPreferences: false,
    isPageLoading: !this.props.isCampaignInvalid,
  });

  state = this.getInitialState();

  static propTypes = {
    isCampaignInvalid: PropTypes.bool,
  };

  static defaultProps = {
    isCampaignInvalid: false,
  };

  formContainer = React.createRef();

  componentDidMount = async () => {
    const {
      isCampaignInvalid,
      match: {
        params: { campaignId, action },
      },
      actions,
      history,
      campaignCreate: { campaign },
    } = this.props;
    if (isCampaignInvalid) return false;

    if (campaignId && isEmpty(action) && action !== "details") {
      history.replace(`/campaign-create/${campaignId}/details`);
    }
    await actions.updateActiveStep(CAMPAIGN_STEPS.CAMPAIGN);
    await actions.getBillingCycles();
    await actions.getCountries({ ps: -1 });

    this.setState({
      campaign: {
        ...this.state.campaign,
        ...campaign,
      },
      isPageLoading: false,
    });
  };

  componentDidUpdate(prevProps) {
    const {
      campaignCreate: { campaign, activeStep },
      history,
    } = this.props;
    if (
      campaign?.id !== prevProps.campaignCreate.campaign?.id ||
      activeStep !== prevProps.campaignCreate.activeStep
    ) {
      this.setState({
        campaign: {
          ...this.state.campaign,
          ...campaign,
        },
      });
    }

    if (campaign?.id && prevProps.campaignCreate?.campaign?.id !== campaign.id) {
      if (validateMandatoryCampaignFields(campaign)) {
        history.push(`/campaign-create/${campaign.id}/target-groups`);
      } else {
        history.push(`/campaign-create/${campaign.id}/details`);
      }
      this.setState({
        campaign: {
          ...this.state.campaign,
          ...campaign,
        },
      });
    }
  }

  // Sets state in campaign object on change of input
  onInputChange = (data, key) => {
    const { campaign } = this.state;
    if (key === "advertiserId") {
      campaign[key] = data.id;
      campaign.advertiserName = data.name;
    } else if (key === "countryIds") {
      if (!isEmpty(data)) {
        campaign[key] = uniqWith([...campaign[key], data], isEqual);
        campaign.countries = uniqWith([...campaign.countries, data], isEqual);
      } else {
        campaign[key] = [];
        campaign.countries = [];
      }
    } else if (key === "currencyId") {
      campaign[key] = data.id;
      campaign.currency = data;
    } else if (key === "billingCycleId") {
      campaign[key] = data.id;
      campaign.billingCycle = data;
    } else if (key === "validity") {
      const [fromDate, toDate] = data;
      const validity = {
        fromDate: fromDate
          ? moment(fromDate).format("YYYY-MM-DD")
          : campaign.validity?.fromDate ?? null,
        toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : campaign.validity?.toDate ?? null,
      };
      campaign[key] = validity;
    } else {
      campaign[key] = data;
    }
    this.setState({
      campaign,
      isFormEdited: true,
    });
  };

  handleCountryRemove = (index) => {
    const { campaign } = this.state;
    const filteredIds = campaign.countryIds.filter((ids, idIndex) => idIndex !== index);
    const filteredCountries = campaign.countries.filter((country, idIndex) => idIndex !== index);
    this.setState({
      campaign: {
        ...campaign,
        countryIds: filteredIds,
        countries: filteredCountries,
      },
    });
  };

  getBillingCycles = () => {
    const { campaign } = this.state;
    const { billingCycles } = this.props;
    if (billingCycles.isLoading) return [];
    const billingCycleList = billingCycles.data.data;
    const validBillingCycles =
      billingCycleList.filter((bc) => bc.code === BILLING_CYCLES.CAMPAIGN_COMPLETION) ?? [];
    if (billingCycleList.length > 0 && campaign.validity?.fromDate && campaign.validity?.toDate) {
      const campaignDuration = moment(campaign.validity.toDate).diff(
        campaign.validity.fromDate,
        "days"
      );
      if (campaignDuration > 7)
        validBillingCycles.push(billingCycleList.find((bc) => bc.code === BILLING_CYCLES.WEEKLY));
      if (campaignDuration > 14)
        validBillingCycles.push(
          billingCycleList.find((bc) => bc.code === BILLING_CYCLES.FORTNIGHTLY)
        );
      if (campaignDuration > 30)
        validBillingCycles.push(billingCycleList.find((bc) => bc.code === BILLING_CYCLES.MONTHLY));
    }

    return compact(validBillingCycles);
  };

  handleSaveCampaignAsDraft = () => {
    const { campaign } = this.state;
    if (campaign.name) {
      this.props.actions.createOrUpdateCampaign(campaign);
    }

    this.setState({
      hasFormSaved: true,
      isFormEdited: false,
    });
  };

  handleCampaignDelete = async () => {
    const {
      history,
      match: { params },
    } = this.props;
    await this.props.actions.deleteCampaign(params.campaignId);
    history.push(`/campaign-create`);
    this.setState({
      hasFormSaved: false,
      isFormEdited: false,
    });
  };

  handleChangeStep = async (preferredStep) => {
    const {
      match: { params },
      history,
      campaignCreate: { activeStep },
    } = this.props;
    const { campaign, isFormEdited } = this.state;

    if (preferredStep === activeStep + 1 && validateMandatoryCampaignFields(campaign)) {
      if (isFormEdited) this.props.actions.createOrUpdateCampaign(campaign);
      this.props.actions.updateActiveStep(preferredStep);
      this.props.history.push(`/campaign-create/${campaign.id}/target-groups`);
    } else {
      campaignStepChange(history, params, activeStep, preferredStep);
    }

    this.setState({
      hasFormSaved: true,
      isFormEdited: false,
    });
  };

  handleFormReset = () => {
    const {
      match: {
        params: { campaignId },
      },
      campaignCreate: { campaign },
    } = this.props;
    if (campaignId) {
      this.setState({
        campaign: campaign,
        advertiser: campaign.advertiser,
        validity: campaign.validity,
        country: campaign.countries,
        hasFormSaved: false,
        isFormEdited: false,
      });
    } else {
      this.setState({
        ...this.getInitialState(),
      });
    }
  };

  renderCampaignTree = () => {
    const { campaign, isPageLoading } = this.state;
    if (this.props.isCampaignInvalid) return null;
    return (
      <div className={styles.campaignTree}>
        <CampaignTree isNewCampaign isLoading={isPageLoading} campaignTreeData={campaign} />
      </div>
    );
  };

  renderFormContent = () => {
    const {
      isCampaignInvalid,
      userData,
      countries: { list: countries },
    } = this.props;
    const { campaign, hasFormSaved, isPageLoading } = this.state;
    const childProps = {
      campaign,
      onInputChange: this.onInputChange,
      disabled: !campaign.advertiserId,
      hasFormSaved: hasFormSaved,
    };

    if (isPageLoading) return <InfoBlockLoading />;
    if (isCampaignInvalid) return <EmptyState type="Campaign" />;

    return (
      <div className={classNames("col-12 clearfix", styles.formContainer)}>
        <BasicDetails
          {...childProps}
          companyPreferences={userData.company?.preferences}
          countryList={countries?.data ?? []}
          searchCountries={searchCountries}
          removeCountryItem={this.handleCountryRemove}
        />
        <ContactDetails {...childProps} />
        <BillingDetails {...childProps} billingCycles={this.getBillingCycles()} />
      </div>
    );
  };

  render() {
    const {
      history,
      campaignCreate: { isCampaignLoading, activeStep, campaignTargetGroups },
    } = this.props;
    const { campaign, hasFormSaved, isFormEdited } = this.state;

    if (isCampaignLoading) return <PageLoadingWithTabs />;
    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={links} />
        <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
          <div className={styles.campaignCreateProgressBar}>
            <CampaignPageHeader
              title="PageHeader.newCampaign"
              onSaveCampaignAsDraft={this.handleSaveCampaignAsDraft}
              onDiscardCampaign={this.handleCampaignDelete}
              isDiscardDisabled={!campaign.id}
            />
            <CampaignSteps
              activeStep={activeStep}
              validTillStep={campaignTargetGroups.data?.length > 0 ? activeStep + 1 : activeStep}
              hasFormSaved={hasFormSaved}
              disableReset={!isFormEdited}
              onChangeStep={this.handleChangeStep}
              onSaveDraft={this.handleSaveCampaignAsDraft}
              onReset={this.handleFormReset}
              isAdminUser={this.validateCreateCampaignAccess}
            />
          </div>
          <div className={classNames("col col-12", styles.pageContent)}>
            {this.renderFormContent()}
            {this.renderCampaignTree()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.campaignCreate,
  (state) => state.userData,
  (state) => state.billingCycles,
  (state) => state.countries,
  (campaignCreate, userData, billingCycles, countries) => ({
    campaignCreate,
    userData: userData.user,
    billingCycles,
    countries,
  })
);

export default connect(mapStateToProps, bindDispatch)(campaignRouteHandler(CampaignCreate));
