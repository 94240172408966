import { STATUS_ICON_COLORS, STATUS_ICON_TYPES } from "./status";

const THEATRE_SCREENS_VIEW_TYPE = {
  THEATRES: "theatres",
  SCREENS: "screens",
};

const CAMPAIGN_STATUSES = {
  DRAFT: "Draft",
  PROPOSED: "Proposed",
  PLANNED: "Planned",
  REJECTED: "Rejected",
  ACTIVE: "Active",
  PAUSED: "Paused",
  COMPLETED: "Completed",
  CONSUMED: "Consumed",
  CANCELLED: "Cancelled",
  EXPIRED: "Expired",
};

const CAMPAIGN_STATUS_LABELS = {
  DRAFT: "Draft",
  PROPOSED: "Proposed",
  PLANNED: "Planned",
  REJECTED: "Rejected",
  ACTIVE: "Active",
  PAUSED: "Paused",
  COMPLETED: "Completed",
  CONSUMED: "Consumed",
  CANCELLED: "Cancelled",
  EXPIRED: "Expired",
};

const CONTENT_AVAILABILITY_STATUS = [
  {
    id: true,
    displayName: "Yes",
  },
  {
    id: false,
    displayName: "No",
  },
];

const CAMPAIGN_STEPS = {
  CAMPAIGN: 1,
  TARGET_GROUP: 2,
  MEDIA: 3,
  REVIEW: 4,
  FINISH: 5,
};

const CAMPAIGN_ACTIONS = {
  PAUSE: "Pause",
  RESUME: "Resume",
  CANCEL: "Cancel",
  EDIT: "Edit",
  EDIT_VALIDITY: "EditValidity",
  EDIT_ADVERTISER: "EditAdvertiser",
  EDIT_CURRENCY: "EditCurrency",
};

const CAMPAIGN_TYPE = {
  CAMPAIGN: "campaign",
  TARGET_GROUP: "target_group",
  MEDIA: "media",
  SCREEN: "screen",
};

const CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST = {
  [CAMPAIGN_ACTIONS.PAUSE]: [
    CAMPAIGN_STATUSES.ACTIVE,
    CAMPAIGN_STATUSES.PLANNED,
    CAMPAIGN_STATUSES.CONSUMED,
  ],
  [CAMPAIGN_ACTIONS.RESUME]: [CAMPAIGN_STATUSES.PAUSED],
  [CAMPAIGN_ACTIONS.CANCEL]: [
    CAMPAIGN_STATUSES.PROPOSED,
    CAMPAIGN_STATUSES.ACTIVE,
    CAMPAIGN_STATUSES.PLANNED,
    CAMPAIGN_STATUSES.PAUSED,
  ],
  [CAMPAIGN_ACTIONS.EDIT]: [
    CAMPAIGN_STATUSES.DRAFT,
    CAMPAIGN_STATUSES.PROPOSED,
    CAMPAIGN_STATUSES.ACTIVE,
    CAMPAIGN_STATUSES.PLANNED,
    CAMPAIGN_STATUSES.PAUSED,
    CAMPAIGN_STATUSES.CONSUMED,
  ],
  [CAMPAIGN_ACTIONS.EDIT_VALIDITY]: [
    CAMPAIGN_STATUSES.DRAFT,
    CAMPAIGN_STATUSES.PROPOSED,
    CAMPAIGN_STATUSES.PLANNED,
  ],
  [CAMPAIGN_ACTIONS.EDIT_ADVERTISER]: [CAMPAIGN_ACTIONS.DRAFT],
  [CAMPAIGN_ACTIONS.EDIT_CURRENCY]: [CAMPAIGN_ACTIONS.DRAFT],
};

const CAMPAIGN_STATUS_LIST = [
  {
    displayName: CAMPAIGN_STATUS_LABELS.DRAFT,
    color: STATUS_ICON_COLORS.GREY,
    type: STATUS_ICON_TYPES.CIRCLE,
    id: CAMPAIGN_STATUSES.DRAFT,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.PROPOSED,
    id: CAMPAIGN_STATUSES.PROPOSED,
    color: STATUS_ICON_COLORS.LIGHT_BLUE,
    type: STATUS_ICON_TYPES.CIRCLE,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.PLANNED,
    id: CAMPAIGN_STATUSES.PLANNED,
    color: STATUS_ICON_COLORS.PURPLE,
    type: STATUS_ICON_TYPES.CIRCLE,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.REJECTED,
    id: CAMPAIGN_STATUSES.REJECTED,
    color: STATUS_ICON_COLORS.RED,
    type: STATUS_ICON_TYPES.CIRCLE,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.ACTIVE,
    id: CAMPAIGN_STATUSES.ACTIVE,
    color: STATUS_ICON_COLORS.GREEN,
    type: STATUS_ICON_TYPES.CIRCLE,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.PAUSED,
    id: CAMPAIGN_STATUSES.PAUSED,
    color: STATUS_ICON_COLORS.YELLOW,
    type: STATUS_ICON_TYPES.CIRCLE,
  },
  {
    id: CAMPAIGN_STATUSES.COMPLETED,
    displayName: CAMPAIGN_STATUS_LABELS.COMPLETED,
    color: STATUS_ICON_COLORS.LIGHT_GREY,
    type: STATUS_ICON_TYPES.CIRCLE,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.CONSUMED,
    id: CAMPAIGN_STATUSES.CONSUMED,
    color: STATUS_ICON_COLORS.LIGHT_GREY,
    type: STATUS_ICON_TYPES.CIRCLE,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.CANCELLED,
    id: CAMPAIGN_STATUSES.CANCELLED,
    color: STATUS_ICON_COLORS.RED,
    type: STATUS_ICON_TYPES.CIRCLE,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.EXPIRED,
    id: CAMPAIGN_STATUSES.EXPIRED,
    color: STATUS_ICON_COLORS.RED,
    type: STATUS_ICON_TYPES.CIRCLE,
  },
];

export {
  THEATRE_SCREENS_VIEW_TYPE,
  CAMPAIGN_STATUSES,
  CAMPAIGN_STATUS_LABELS,
  CAMPAIGN_STATUS_LIST,
  CONTENT_AVAILABILITY_STATUS,
  CAMPAIGN_ACTIONS,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  CAMPAIGN_TYPE,
  CAMPAIGN_STEPS,
};
