import React, { Component } from "react";
import { Button } from "@blueprintjs/core";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../styles/App.module.scss";
import PageHeader from "../../common/PageHeader";
import { SCOPES, TAG_TYPE } from "../../../constants";
import ButtonStyle from "../../../styles/Button.module.scss";
import Filters from "../../common/Filters/Filters";
import FilterChips from "../../common/Filters/FilterChips";

class Reports extends Component {
  state = {
    data: [
      {
        name: "Brands",
        dateRange: ["20/12/2020", "21/12/2020"],
        filters: [],
      },
    ],
  };

  onFilterChange = (index, filters) => {
    const { data } = this.state;
    data[index].filters = filters;
    this.setState(data);
  };

  render() {
    const { history } = this.props;
    const { data } = this.state;
    const columns = [
      {
        id: "name",
        Header: "Report",
        accessor: "name",
      },
      {
        id: "date",
        Header: "Date Range",
        accessor: "date",
      },
      {
        id: "filters",
        Header: "Filters",
        accessor: "filters",
        Cell: ({ original, index }) => (
          <>
            <Filters
              tagTypes={TAG_TYPE.CATEGORY}
              onFilterChange={(filters) => {
                this.onFilterChange(index, filters);
              }}
              selected={original.filters}
            />
            <FilterChips
              onFilterChange={(filters) => {
                this.onFilterChange(index, filters);
              }}
              selected={original.filters}
            ></FilterChips>
          </>
        ),
      },
      {
        id: "generate",
        hideIfOnlyColumn: true,
        resizable: false,
        sortable: false,
        Cell: <Button minimal className={ButtonStyle.saveButtonWithoutIcon} text="Generate" />,
        width: 150,
      },
    ];
    return (
      <div className={pageStyles.pageContainer}>
        <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
          <PageHeader
            name="Reports"
            buttons={[
              {
                text: "Generated Reports",
                scope: SCOPES.__SELF__,
                className: ButtonStyle.addButton,
                onClick: () => history.push("/reports/generated"),
              },
            ]}
          />
          <Table
            data={data}
            manual={false}
            defaultSorted={[{ id: "name", desc: false }]}
            columns={columns}
          />
        </div>
      </div>
    );
  }
}

export default Reports;
