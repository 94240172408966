import React from "react";
import PropTypes from "prop-types";
import styles from "../../styles/SummaryItem.module.scss";

const SummaryItem = ({ icon, title, body }) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.body}>{body}</div>
      </div>
    </div>
  );
};

SummaryItem.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
};

export default SummaryItem;
