import React from "react";
import { compact } from "lodash";
import { ActiveTableCellColumnFromArray } from "../../components/common/ActiveTableCell";

const _fullName = (value) => compact([value.firstName, value.lastName]).join(" ");

export const UserTableCell = ({ user, onIdSelect }) => {
  const userDetails = [
    { id: user.id, name: _fullName(user) },
    { id: user.id, name: user.email },
  ];
  return ActiveTableCellColumnFromArray(userDetails, onIdSelect);
};

const UserTableAccessor = (key, onIdSelect) => (d) =>
  d[key] && <UserTableCell user={d[key]} onIdSelect={onIdSelect} />;

export default UserTableAccessor;
