import React, { Component } from "react";
import PropTypes from "prop-types";
import { FORM_FIELD_TYPE, REASON_TYPES } from "../../../../../constants";
import RightPanel from "../../../../common/RightPanel";
import Form from "../../../../common/Form";
import { getAllReasonsByType } from "../../../../../api";

export default class RejectCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: null,
      notes: "",
      hasError: false,
    };
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
  };

  cancelRejection = () => {
    this.setState({ hasError: false, notes: "" });
    this.props.onClose();
  };

  reject = () => {
    const { notes, reason } = this.state;
    if (reason && notes !== "") {
      this.props.onReject(reason, notes);
      this.cancelRejection();
    } else {
      return this.setState({ hasError: true });
    }
  };

  inputOnChange = (value, accessor) => {
    this.setState({
      [accessor]: value,
    });
  };

  render = () => {
    const { isOpen } = this.props;
    const { reason, notes, hasError } = this.state;

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelRejection()}
        header="RightPanelHeader.reject"
        showFooter={true}
        primaryButtonProps={{
          text: "Button.reject",
          onClick: this.reject,
        }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelRejection }}
      >
        <Form
          config={[
            {
              type: FORM_FIELD_TYPE.API_SELECT,
              id: "reason",
              title: "Reason",
              label: "",
              placeholder: "Select Reason",
              fetchAction: () => getAllReasonsByType(REASON_TYPES.campaign),
              parseResults: (response) => {
                return response.data.map((item) => ({
                  id: item.id,
                  value: item.reason,
                }));
              },
              selected: (list) => list.filter((item) => item.id === reason),
              singleSelect: true,
              showLabelInButton: false,
              onSelect: ([value]) => {
                this.inputOnChange(value.id, "reason");
              },
              error: hasError && !reason,
              errorMessage: "Invalid Reason",
            },
            {
              id: "comments",
              type: FORM_FIELD_TYPE.TEXT_AREA,
              title: "Comments",
              placeholder: "Enter Comments",
              value: notes,
              onChange: (e) => this.inputOnChange(e.target.value, "notes"),
              error: hasError,
              errorMessage: "Invalid Comments",
            },
          ]}
        />
      </RightPanel>
    );
  };
}
