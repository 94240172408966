import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { bindDispatch } from "../../../../../utils";
import * as constants from "../../../../../constants";
import WithFetchList from "../../../../hoc/withFetchList";
import ContentTable from "../../../content/common/ContentTable";

class BrandContentList extends Component {
  state = {
    selectedColumns: ["In Use", "Campaign", "Played Count"],
  };
  componentDidMount = async () => {
    const {
      fetchData,
      match: { params },
    } = this.props;

    fetchData(null, false, [{ brandId: params.id }]);
  };

  render = () => {
    const {
      brands: { brandContent, isLoading },
    } = this.props;
    return (
      <ContentTable
        selectedColumns={this.state.selectedColumns}
        data={brandContent}
        isLoading={isLoading}
        {...this.props}
      />
    );
  };
}

const BrandContentTableWithFilters = WithFetchList("getBrandContent", {
  sort: [
    {
      id: "name",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(BrandContentList);

const mapStateToProps = createSelector(
  (state) => state.brands,
  (state) => state.userData,
  (brands, userData) => ({ brands, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(BrandContentTableWithFilters));
