import _ from "lodash";
import {
  fetchPlaylistPackTypes,
  getReasonsList,
  searchNotificationTemplates,
  searchContentTypes,
  searchSegmentTypes,
  searchCertificates,
  fetchRights,
  getCategoryLists,
  fetchBusinessTypes,
  fetchProductIdentificationNumberTypeList,
  searchCountries,
  fetchCampaignTargetTypes,
  fetchBillingCycles,
  getTimesOfDay,
  fetchAdvertiserTypes,
} from "../../api";
import { UPDATE_LIST } from "../types/list";

const updateList = (payload) => {
  return { type: UPDATE_LIST, payload: payload };
};

const getMetadata = async (apiCall) => {
  const { data } = await apiCall();
  const list = _.orderBy(data, ["updatedAt"], ["desc"]);
  return {
    count: list ? list.length : 0,
    updatedAt: list[0] ? list[0].updatedAt : "",
    updatedBy: list[0] ? list[0].updatedBy : "",
  };
};

const fetchMetadata = async (apiCall) => {
  const { data } = await apiCall(1, 0, "updatedAt:desc", []);
  return {
    count: data.totalCount,
    updatedAt: data.data && data.data[0] && data.data[0].updatedAt,
    updatedBy: data.data && data.data[0] && data.data[0].updatedBy,
  };
};

const fetchListDetails = (lists) => {
  return async (dispatch) => {
    try {
      if (lists.includes("rolesScopes"))
        dispatch(updateList({ rolesScopes: { count: "", updatedBy: "" } }));

      if (lists.includes("playlistPackTypes"))
        getMetadata(fetchPlaylistPackTypes).then((playlistPackTypes) =>
          dispatch(updateList({ playlistPackTypes }))
        );

      if (lists.includes("reasons"))
        fetchMetadata(getReasonsList).then((reasons) => dispatch(updateList({ reasons })));

      if (lists.includes("notificationTemplates"))
        fetchMetadata(searchNotificationTemplates).then((notificationTemplates) =>
          dispatch(updateList({ notificationTemplates }))
        );

      if (lists.includes("contentTypes"))
        fetchMetadata(searchContentTypes).then((contentTypes) =>
          dispatch(updateList({ contentTypes }))
        );

      if (lists.includes("segmentTypes"))
        fetchMetadata(searchSegmentTypes).then((segmentTypes) =>
          dispatch(updateList({ segmentTypes }))
        );

      if (lists.includes("ratings"))
        fetchMetadata(searchCertificates).then((ratings) => dispatch(updateList({ ratings })));

      if (lists.includes("rights"))
        getMetadata(fetchRights).then((rights) => dispatch(updateList({ rights })));

      if (lists.includes("categories"))
        fetchMetadata(getCategoryLists).then((categories) => dispatch(updateList({ categories })));

      if (lists.includes("businessTypes"))
        getMetadata(fetchBusinessTypes).then((businessTypes) =>
          dispatch(updateList({ businessTypes }))
        );

      if (lists.includes("advertiserTypes"))
        getMetadata(fetchAdvertiserTypes).then((advertiserTypes) =>
          dispatch(updateList({ advertiserTypes }))
        );

      if (lists.includes("productIdentificationNumberTypes"))
        fetchMetadata(
          fetchProductIdentificationNumberTypeList
        ).then((productIdentificationNumberTypes) =>
          dispatch(updateList({ productIdentificationNumberTypes }))
        );

      if (lists.includes("countries"))
        fetchMetadata(searchCountries).then((countries) => dispatch(updateList({ countries })));

      if (lists.includes("campaignTargetType"))
        getMetadata(fetchCampaignTargetTypes).then((campaignTargetType) =>
          dispatch(updateList({ campaignTargetType }))
        );

      if (lists.includes("timesOfDay"))
        getMetadata(getTimesOfDay).then((timesOfDay) => dispatch(updateList({ timesOfDay })));

      if (lists.includes("billingCycles"))
        getMetadata(fetchBillingCycles).then((billingCycles) =>
          dispatch(updateList({ billingCycles }))
        );
    } catch (error) {
      return error;
    }
  };
};
export { updateList, fetchListDetails };
