import React, { Component } from "react";
import { getDisplayName, checkScopeAuth, isSlateAdminCompanyUser } from "../../utils";
import { SCOPES } from "../../constants";

// HOC util to check scope and actions for a given user in any component.
// Returns null if the user does not have access, thereby removing the component.
function withScopeAuth(WrappedComponent) {
  class WithScopeAuth extends Component {
    getWrappedProps = () => {
      const { userData, scope, scopeAction, passUserData, onlyForSlateAdmin, ...rest } = this.props;
      return passUserData ? this.props : rest;
    };
    render() {
      const { userData, scope, scopeAction, onlyForSlateAdmin } = this.props;
      if (scope === SCOPES.__SELF__) return <WrappedComponent {...this.getWrappedProps()} />;
      if (!userData) return null;
      const isAdmin = isSlateAdminCompanyUser(userData);
      const isAllowed =
        (!onlyForSlateAdmin || isAdmin) && checkScopeAuth(userData, scope, scopeAction);
      if (!isAllowed) return null;
      return <WrappedComponent {...this.getWrappedProps()} />;
    }
  }
  WithScopeAuth.displayName = `WithScopeAuth(${getDisplayName(WrappedComponent)})`;
  return WithScopeAuth;
}

export default withScopeAuth;
