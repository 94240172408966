import { pick, groupBy, uniqBy } from "lodash";
import moment from "moment";
import {
  getSchedules,
  createOrUpdateSchedules,
  getUserScreens,
  createOrUpdateScheduleScreen,
  getScheduleScreens,
  getScheduleById,
} from "../../api";
import * as types from "../types/schedules";
import * as constants from "../../constants";
import { showToast } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateSchedulesList = (payload) => ({ type: types.SCHEDULES_LIST, payload });
const updateSchedule = (payload) => ({ type: types.SCHEDULE, payload });

const updateScheduleScreens = (payload) =>
  actionCreator(types.SCHEDULE_SCREENS, uniqBy(payload, "id"));

const updateScreenFilters = (payload) => actionCreator(types.SCHEDULE_SCREEN_FILTERS, payload);

const resetScheduleErrors = () => {
  return async (dispatch) => {
    dispatch(actionCreator(types.SCHEDULE_ERRORS, []));
    dispatch(
      actionCreator(types.ERROR_SCREEN_LIST, {
        data: [],
      })
    );
  };
};

const getSchedulesList = (params, filters = [], getList = getSchedules, isPartial = true) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc" } = params;
  return async (dispatch) => {
    try {
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, true))
        : dispatch(actionCreator(types.IS_LOADING, false));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateSchedulesList(data));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getScheduleScreenList = (params, filters = []) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc", scheduleId = "" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_SCREEN_LOADING, true));
      const { data } = await getScheduleScreens(scheduleId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateScheduleScreens(data?.data ?? []));
      dispatch(actionCreator(types.IS_SCREEN_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_SCREEN_LOADING, false));
    }
  };
};

const getSuggestedScheduleScreens = (
  params,
  filters = [],
  scheduleId,
  hasDataChanged = false,
  scheduleOrder
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc" } = params;
  return async (dispatch, getState) => {
    const { userData } = getState();
    const { scheduleScreens } = getState().schedules;
    dispatch(actionCreator(types.IS_SCREEN_LOADING, true));
    if (filters.length > 0) {
      try {
        const { data } = await getUserScreens(
          ps,
          (page - 1) * ps,
          sort,
          filters,
          userData?.user?.company.id
        );
        if (data?.data?.length === 0) showToast("No screen found with selected filters");
        const screenList = uniqBy([...(data?.data ?? []), ...scheduleScreens], "id");
        dispatch(updateScheduleScreens(screenList));
        if (hasDataChanged) {
          const data = {
            ...pick(scheduleOrder, [
              "id",
              "name",
              "scheduleNumber",
              "orderNumber",
              "subOrderNumber",
              "customerPurchaseOrderNumber",
              "cplId",
            ]),
            placement: {
              ...pick(scheduleOrder.placement, ["segmentId", "plays"]),
              numberOfPlays: undefined, // Remove numberOfPlays attribute
              position: scheduleOrder.placement.position
                ? Number.parseInt(scheduleOrder.placement.position)
                : 0,
            },
            validFrom: moment(scheduleOrder.validFrom).format(),
            validTo: moment(scheduleOrder.validTo).format(),
          };
          try {
            dispatch(actionCreator(types.IS_CREATE_SCHEDULES_LOADING, true));
            await createOrUpdateSchedules(data);
            showToast("Toast.scheduleUpdatedSuccessFully");
            dispatch(actionCreator(types.IS_CREATE_SCHEDULES_LOADING, false));
          } catch (error) {
            const errorResponse = error.response;
            if (errorResponse?.data?.code === 400 || errorResponse?.data?.code === 500) {
              showToast(errorResponse?.data?.message, false);
            }
            dispatch(actionCreator(types.IS_ERROR, error.message));
            dispatch(actionCreator(types.IS_CREATE_SCHEDULES_LOADING, false));
          }
        }
        if (scheduleId) {
          await dispatch(
            addScreensForScheduleOrder(
              scheduleId,
              data?.data.map((screen) => screen.id)
            )
          );
        }
        dispatch(actionCreator(types.IS_SCREEN_LOADING, false));
      } catch (error) {
        dispatch(actionCreator(types.IS_ERROR, error.message));
        dispatch(actionCreator(types.IS_SCREEN_LOADING, false));
      }
    } else {
      dispatch(updateScheduleScreens([]));
      dispatch(actionCreator(types.IS_SCREEN_LOADING, false));
    }
  };
};

const getScheduleDetailsById = (params, callback, getData = getScheduleById) => {
  const { scheduleId } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_SCHEDULE_LOADING, true));
      if (scheduleId) {
        const { data } = await getData(scheduleId);
        data.validity = {
          fromDate: moment(data.validFrom).utc().format("YYYY-MM-DD") ?? null,
          startTime: moment(data.validFrom).utc().format("HH:mm") ?? null,
          toDate: moment(data.validTo).utc().format("YYYY-MM-DD") ?? null,
          endTime: moment(data.validTo).utc().format("HH:mm") ?? null,
        };
        dispatch(updateSchedule(data));
      }
      dispatch(actionCreator(types.IS_SCHEDULE_LOADING, false));
    } catch (error) {
      const errorResponse = error.response;
      if (errorResponse?.data?.code === 404) {
        showToast("Toast.scheduleNotFound", false);
        callback();
      }
      if (errorResponse?.data?.code === 400) {
        showToast("Toast.invalidScheduleOrder", false);
        callback();
      }
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_SCHEDULE_LOADING, false));
    }
  };
};

const duplicateScheduleDetailsById = (scheduleId, getData = getScheduleById) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CREATE_SCHEDULES_LOADING, true));
      const { data } = await getData(scheduleId);
      const scheduleDetails = pick(data, ["cplId", "cplName", "scheduleNumber", "placement"]);
      if (moment(data.validFrom).isAfter(moment())) {
        scheduleDetails.validity = {
          fromDate: moment(data.validFrom).utc().format("YYYY-MM-DD") ?? null,
          toDate: moment(data.validTo).utc().format("YYYY-MM-DD") ?? null,
          startTime: moment(data.validFrom).utc().format("HH:mm") ?? null,
          endTime: moment(data.validTo).utc().format("HH:mm") ?? null,
        };
        scheduleDetails.validFrom = data.validFrom;
        scheduleDetails.validTo = data.validTo;
      }
      await dispatch(getScheduleScreenList({ scheduleId }));
      dispatch(updateSchedule(scheduleDetails));
      showToast("Toast.scheduleDuplicatedSuccessFully");
      dispatch(actionCreator(types.IS_CREATE_SCHEDULES_LOADING, false));
    } catch (error) {
      const errorResponse = error.response;
      if (errorResponse?.data?.code === 404) {
        showToast("Toast.scheduleNotFound", false);
      }
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CREATE_SCHEDULES_LOADING, false));
    }
  };
};

const getScheduleErrors = (errorResponse) => {
  const errorList = errorResponse?.data?.errors ?? [];
  return async (dispatch, getState) => {
    try {
      const { userData } = getState();
      const filteredErrors = errorList.filter((err) => err.errorCode !== "unmatched.id") ?? [];
      const errorScreenIds = filteredErrors.map((errorData) => errorData.screenId) ?? [];
      const filterList = errorScreenIds.map((id) => ({
        type: constants.TAG_TYPE.SCREEN,
        id,
      }));
      if (errorScreenIds.length > 0) {
        const { data } = await getUserScreens(
          100,
          0,
          "name:asc",
          filterList,
          userData?.user?.company.id
        );
        dispatch(actionCreator(types.SCHEDULE_ERRORS, groupBy(errorList, "errorCode")));
        dispatch(actionCreator(types.ERROR_SCREEN_LIST, data));
      }
    } catch (error) {
      const errorResponse = error.response;
      if (errorResponse?.data?.code === 400) {
        showToast(errorResponse?.data?.message, false);
      }
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CREATE_SCHEDULES_LOADING, false));
    }
  };
};

const updateScheduleOrderScreens = (scheduleId, screenIds = []) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_SCREEN_LOADING, true));
      const updateScreenData = await dispatch(
        addScreensForScheduleOrder(scheduleId, screenIds, null)
      );
      if (updateScreenData?.code === 200) {
        showToast("Toast.scheduleScreensUpdatedSuccessFully");
        await dispatch(getScheduleScreenList({ scheduleId }));
      }
      dispatch(actionCreator(types.IS_SCREEN_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_SCREEN_LOADING, false));
    }
  };
};

const addScreensForScheduleOrder = (
  scheduleId,
  screenIds = [],
  callback,
  saveScreens = createOrUpdateScheduleScreen
) => {
  return async (dispatch) => {
    try {
      const { data: scheduleScreens } = await saveScreens(scheduleId, {
        screenIds,
      });
      if (scheduleScreens?.code === 200 && callback) {
        callback(scheduleScreens, true);
      } else {
        return scheduleScreens;
      }
    } catch (error) {
      const errorResponse = error.response;
      if (callback) callback({ id: scheduleId });
      if (errorResponse?.data?.code === 404) {
        showToast("Toast.scheduleNotFound");
      } else if (errorResponse?.data?.code === 400) {
        await dispatch(getScheduleErrors(error.response));
      } else {
        showToast(errorResponse?.data?.message, false);
      }
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CREATE_SCHEDULES_LOADING, false));
      return errorResponse.data;
    }
  };
};

const createOrUpdateSchedule = (data, callback, saveData = createOrUpdateSchedules) => {
  const scheduleOrder = {
    ...data,
    screens: undefined, // Add screens in separate api call
    screenIds: undefined, // Remove screenIds attribute
    placement: {
      ...data.placement,
      numberOfPlays: undefined, // Remove numberOfPlays attribute
      position: data.placement.position ? Number.parseInt(data.placement.position) : 0,
    },
    validFrom: moment.utc(data.validFrom).format(),
    validTo: moment.utc(data.validTo).format(),
  };
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CREATE_SCHEDULES_LOADING, true));
      const { data: scheduleOrderData } = await saveData(scheduleOrder);
      if (!data?.id) {
        showToast("Toast.scheduleCreatedSuccessFully");
        await dispatch(addScreensForScheduleOrder(scheduleOrderData.id, data.screenIds, callback));
      }
      // During Update Schedule
      data?.id && showToast("Toast.scheduleUpdatedSuccessFully");
      dispatch(actionCreator(types.IS_CREATE_SCHEDULES_LOADING, false));
    } catch (error) {
      const errorResponse = error.response;
      if (errorResponse?.data?.code === 400) {
        showToast(errorResponse?.data?.message, false);
      }
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CREATE_SCHEDULES_LOADING, false));
    }
  };
};

const resetSchedule = () => {
  return async (dispatch) => {
    dispatch(actionCreator(types.RESET_SCHEDULE));
  };
};

export {
  getSchedulesList,
  createOrUpdateSchedule,
  addScreensForScheduleOrder,
  getScheduleScreenList,
  getScheduleDetailsById,
  resetSchedule,
  duplicateScheduleDetailsById,
  resetScheduleErrors,
  getSuggestedScheduleScreens,
  updateScreenFilters,
  updateScheduleScreens,
  updateScheduleOrderScreens,
};
