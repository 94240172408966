import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import classNames from "classnames";

import pageStyles from "../../../../../styles/App.module.scss";
import TabView from "../../../../common/Tabs";
import { bindDispatch } from "../../../../../utils";
import Breadcrumb from "../../../../common/BreadCrumb";
import styles from "../../../../../styles/CampaignCreate/TargetGroup.module.scss";
import campaignFormStyles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import PageLoadingWithTable from "../../../../common/PageLoadingWithTable";
import CampaignSteps from "../CampaignSteps";
import CampaignPageHeader from "../../common/CampaignPageHeader";
import { CAMPAIGN_STEPS } from "../../../../../constants";
import { campaignStepChange } from "../../utils/campaignCreate";
import TargetGroup from "./TargetGroup";
import campaignRouteHandler from "..";

const links = [
  { name: "PageHeader.campaigns", path: "/campaigns" },
  { name: "PageHeader.reviewTargetGroups" },
];

export class CampaignReview extends Component {
  state = {
    selectedTabId: null,
  };

  componentDidMount = async () => {
    const { actions } = this.props;
    await actions.updateActiveStep(CAMPAIGN_STEPS.REVIEW);
  };

  handleTabChange = (newTabId) =>
    this.setState({
      selectedTabId: newTabId,
    });

  handleChangeStep = (desiredStep) => {
    const {
      match: { params },
      history,
      campaignCreate: { activeStep },
    } = this.props;
    if (desiredStep === activeStep + 1 && params.campaignId) {
      history.push(`/campaign-create/${params.campaignId}/finish`);
    } else {
      campaignStepChange(history, params, activeStep, desiredStep);
    }

    this.setState({
      hasFormSaved: true,
    });
  };

  handleCampaignDelete = async () => {
    const {
      history,
      match: { params },
    } = this.props;
    await this.props.actions.deleteCampaign(params.campaignId);
    history.push(`/campaign-create`);
  };

  handleSaveCampaignDraft = () => {
    this.setState({
      hasFormSaved: true,
    });
  };

  render() {
    const { selectedTabId } = this.state;
    const {
      history,
      campaignCreate: { campaign, isTargetGroupLoading, campaignTargetGroups, activeStep },
    } = this.props;

    if (isTargetGroupLoading) return <PageLoadingWithTable />;

    return (
      <div className={classNames("col-12 clearfix", styles.pageWrapper)}>
        <Breadcrumb history={history} links={links} />
        <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
          <div className={campaignFormStyles.campaignCreateProgressBar}>
            <CampaignPageHeader
              title="PageHeader.reviewTargetGroups"
              onSaveCampaignAsDraft={this.handleSaveCampaignAsDraft}
              onDiscardCampaign={this.handleCampaignDelete}
              isDiscardDisabled={!campaign.id}
              hideDraftButton
            />
            <CampaignSteps
              activeStep={activeStep}
              validTillStep={campaignTargetGroups.data?.length > 0 ? activeStep + 1 : activeStep}
              disableSave={!campaign.id}
              disableReset={true}
              onChangeStep={this.handleChangeStep}
              onSaveDraft={this.handleSaveCampaignDraft}
            />
          </div>
          {campaignTargetGroups.data.length > 0 ? (
            <TabView
              id="campaignTabs"
              defaultSelectedTabId="targets"
              selectedTabId={selectedTabId || campaignTargetGroups[0]}
              onChange={this.handleTabChange}
              tabs={campaignTargetGroups.data.map((targetGroup) => ({
                id: targetGroup.id,
                title: targetGroup.name,
                panel: <TargetGroup targetGroupId={targetGroup.id} />,
              }))}
            />
          ) : (
            <div> No Target Groups Found! </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.campaignCreate,
  (state) => state.userData,
  (campaignCreate, userData) => ({
    campaignCreate,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(campaignRouteHandler(CampaignReview));
