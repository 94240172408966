import React, { Component } from "react";
import PropTypes from "prop-types";
import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { BaseButton, SIZE, BUTTON_TYPE } from "prefab";
import { compact, toLower, isEmpty, keys, values, uniq } from "lodash";
import * as PapaParse from "papaparse";
import RightPanel from "../../../common/RightPanel";
import FileUpload from "../../../common/FileUpload";
import { getUserScreenListByFilterId } from "../../../../api";
import { SCHEDULE_ERRORS } from "../../../../constants";
import { pluralize } from "../../../../utils";
import styles from "../../../../styles/Schedules/uploadCSV.module.scss";

const parseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
};

export default class UploadCSV extends Component {
  state = { uploadErrors: [], hasUploadError: false, isLoading: false, screenList: [] };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    header: PropTypes.string,
  };

  handleFormSave = () => {
    const { screenList } = this.state;
    this.props.updateSelectedScreens(screenList);
    this.handleFormCancel();
  };

  handleFormCancel = () => {
    const { errorScreenList } = this.props;
    const screenIds = errorScreenList?.data.map((error) => error.id);
    this.props.onClose(screenIds);
    this.setState({ screenList: [] });
  };

  getScreenListById = async (filterData, idType) => {
    const { userData } = this.props;
    try {
      this.setState({ isLoading: true });
      const { data } = await getUserScreenListByFilterId(userData.company.id, idType, {
        ids: filterData,
      });
      this.setState({
        screenList: data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ list: [], isLoading: false });
    }
  };

  validateAndParseScreenData = (data) => {
    const uploadErrors = [];

    if (!isEmpty(data)) {
      const csvData = PapaParse.parse(
        data?.content,
        Object.assign(parseOptions, {
          error: "Error",
          encoding: "UTF-8",
        })
      );

      if (csvData?.data.length > 0) {
        // ERP ID is currently available in CSV file
        const filterableColumn = "ERPID";

        if (csvData?.meta?.fields.find((column) => toLower(filterableColumn) === toLower(column))) {
          const filterIds = csvData?.data.map((screen) => screen[filterableColumn]);
          if (compact(filterIds).length > 0) {
            this.setState(
              {
                hasUploadError: uploadErrors.length > 0,
                uploadErrors,
                isLoading: true,
              },
              () => this.getScreenListById(filterIds, "erpId")
            );
            return;
          } else {
            uploadErrors.push(`${filterableColumn} data is empty`);
          }
        } else {
          uploadErrors.push(`${filterableColumn} column not Found`);
        }
      } else {
        uploadErrors.push("No Data Found");
      }
    }
    this.setState({
      hasUploadError: uploadErrors.length > 0,
      uploadErrors,
      isLoading: false,
    });
  };

  handleDelete = () => {
    const { errorScreenList, removeScheduleScreens } = this.props;
    const screenIds = errorScreenList?.data.map((error) => error.id);

    if (removeScheduleScreens) {
      removeScheduleScreens(screenIds);
    }
  };

  renderScreenErrors = (screenIds) => {
    const { errorScreenList } = this.props;

    if (screenIds?.length === 0 || errorScreenList.data.length === 0) return null;

    return screenIds.map((screenId, index) => {
      const screenData = errorScreenList?.data?.find((screen) => screen.id === screenId);
      return (
        <div className={styles.screen} key={index}>
          <div className={styles.name}>
            {screenData.name} {screenData?.theatreName ? `• ${screenData?.theatreName}` : ""}
          </div>
          <div className={styles.location}>
            {screenData?.cityName}
            {screenData?.countryName ? `• ${screenData?.countryName}` : ""}
          </div>
        </div>
      );
    });
  };

  getCSVDataStats = () => {
    const { screenList } = this.state;

    if (isEmpty(screenList)) return "";

    const screenCount = screenList?.totalCount;
    const theatreCount = screenList?.data?.map((screen) => screen?.theatreId);

    return `${screenCount} Screens of ${uniq(compact(theatreCount)).length} Theatres Found in CSV`;
  };

  render = () => {
    const { isOpen, scheduleErrors, errorScreenList } = this.props;
    const { isLoading, uploadErrors, hasUploadError } = this.state;
    const hasScheduleErrors = values(scheduleErrors)?.length > 0;
    const cancelActionProps = {
      text: "Button.cancel",
      onClick: this.handleFormCancel,
      disabled: isLoading || hasUploadError,
    };

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={this.handleFormCancel}
        header={
          hasScheduleErrors
            ? "RightPanelHeader.scheduleScreenErrors"
            : "RightPanelHeader.selectScreensByCSV"
        }
        showFooter={true}
        primaryButtonProps={[
          {
            text: "Button.selectScreens",
            onClick: this.handleFormSave,
            isHidden: hasScheduleErrors,
            disabled: isLoading || hasUploadError,
          },
        ]}
        secondaryButtonProps={hasScheduleErrors ? cancelActionProps : null}
      >
        {!hasScheduleErrors && (
          <FileUpload
            showRemove
            title="Button.selectScreenCsv"
            description="(or) Drag and Drop CSV"
            errorMessage="Please upload an CSV file"
            icon="DownloadIcon"
            onSelect={this.validateAndParseScreenData}
            acceptableFormats=".csv"
            mimeType="text/csv"
            isLoading={isLoading}
          />
        )}
        {uploadErrors.length === 0 && (
          <div className={classNames(styles.data, { [Classes.SKELETON]: isLoading })}>
            {this.getCSVDataStats()}
          </div>
        )}
        {uploadErrors.length > 0 && (
          <div className={styles.errorList}>
            {uploadErrors.map((error) => (
              <div className={styles.error}>{error}</div>
            ))}
          </div>
        )}
        {hasScheduleErrors && (
          <BaseButton
            className={styles.clearButton}
            iconName="RemoveFilledIcon"
            buttonText={errorScreenList.data.length > 1 ? "Remove All" : "Remove Screen"}
            buttonSize={SIZE.REGULAR}
            buttonType={BUTTON_TYPE.DESTRUCTIVE_SECONDARY}
            onClick={this.handleDelete}
          />
        )}
        {hasScheduleErrors &&
          keys(scheduleErrors).map((error, index) => (
            <div className={styles.errorList} key={index}>
              <div className={styles.errorType}>{`${SCHEDULE_ERRORS[error]} ${pluralize(
                scheduleErrors[error].length,
                "screen"
              )}`}</div>
              <div className={styles.screenErrors}>
                {this.renderScreenErrors(scheduleErrors[error]?.map((err) => err?.screenId))}
              </div>
            </div>
          ))}
      </RightPanel>
    );
  };
}
