import React, { Component } from "react";
import ClassNames from "classnames";
import { isEmpty, isNumber } from "lodash";

import { formatValidityPeriod } from "../../../../../utils";
import styles from "../../../../../styles/Campaigns/CampaignTree.module.scss";

const defaultCampaignData = [
  {
    id: "advertiserName",
    label: "Advertiser",
    value: "Select advertiser",
  },
  {
    id: "countries",
    label: "Country",
    value: "Select country",
  },
  {
    id: "validity",
    label: "Validity",
    value: "Select campaign validity",
  },
  {
    id: "maxPlaysPerScreen",
    label: "Max Play Per Screen",
    value: "-",
  },
];

export default class CampaignCard extends Component {
  state = {
    isExpanded: this.props.isExpanded,
  };

  static defaultProps = {
    isExpanded: true,
    showCollapse: false,
  };

  handleCardToggle = (isExpanded) => {
    if (this.props.onCardToggle) {
      this.props.onCardToggle(isExpanded);
    }

    this.setState({
      isExpanded: isExpanded,
    });
  };

  formatCampaignValues = (defaultValue, campaignData) => {
    const value = campaignData && campaignData[defaultValue.id];

    if (isEmpty(value) && !isNumber(value)) return defaultValue.value;

    if (defaultValue.id === "validity") {
      return formatValidityPeriod(value, "DD MMM YYYY");
    }

    if (defaultValue.id === "countries") {
      return value.map((c) => c.name).join(", ");
    }

    return value;
  };

  render() {
    const { campaignData, children } = this.props;
    return (
      <div className={ClassNames(styles.cardContainer, styles.campaignContainer)}>
        <div className={styles.cardItemWrapper}>
          {defaultCampaignData.map((itemDefaultData, index) => {
            return (
              <div key={index} className={styles.itemContainer}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>{itemDefaultData.label}</div>
                </div>
                <div className={styles.infoValue}>
                  {this.formatCampaignValues(itemDefaultData, campaignData)}
                </div>
              </div>
            );
          })}
        </div>
        {this.state.isExpanded && (
          <div className={`${styles.child} ${styles.campaign}`}>{children}</div>
        )}
      </div>
    );
  }
}
