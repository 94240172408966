import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { bindDispatch } from "../../../../../utils";
import WithFetchList from "../../../../hoc/withFetchList";
import { TheatresScreensList } from "../../common/TheatresScreensList";

class CampaignTheatresList extends Component {
  render = () => {
    const {
      history,
      match: { params },
      campaigns: { isTabLoading, campaign, campaignTheatres, campaignScreens },
    } = this.props;
    return (
      <div className="col-12 clearfix">
        <TheatresScreensList
          isTabLoading={isTabLoading}
          selectedSwitch={params.action}
          targetId={campaign.id}
          theatres={campaignTheatres}
          screens={campaignScreens}
          onSwitchChange={(text) =>
            history.push(`/campaigns/campaigns/${campaign.id}/theatres/${text}`)
          }
          {...this.props}
        />
      </div>
    );
  };
}
export const TheatresListWithFilters = WithFetchList("getCampaignTheatres", {
  sort: [
    {
      id: "endDate",
      desc: false,
    },
    {
      id: "status",
      desc: false,
    },
  ],
})(CampaignTheatresList);

const mapStateToProps = createSelector(
  (state) => state.campaigns,
  (state) => state.userData,
  (campaigns, userData) => ({ campaigns, userData: userData.user })
);

export default withRouter(connect(mapStateToProps, bindDispatch)(TheatresListWithFilters));
