import React from "react";
import PropTypes from "prop-types";
import { Icons } from "prefab";
import { getDuration } from "../../../../utils";
import TableInfoBlock from "../../../common/TableInfoBlock";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import styles from "../../../../styles/Campaigns/Campaigns.module.scss";

const { CalendarIcon, LocationIcon, TheatreIcon, PlayIcon, PremiumIcon } = Icons;

// Transposes properties of object with child's properties
// If object has a property with non-object value, they are ignored.
function transposeObject(data) {
  if (typeof data !== "object") return data;
  const out = {};
  Object.entries(data).forEach(([outer, item]) => {
    if (typeof item === "object") {
      Object.entries(item).forEach(([inner, value]) => {
        if (!out[inner]) out[inner] = {};
        out[inner][outer] = value;
      });
    }
  });
  return out;
}

const TITLES = {
  duration: { name: "Duration", icon: <CalendarIcon /> },
  days: { name: "Days", icon: <CalendarIcon /> },
  countStates: { name: "States", icon: <LocationIcon /> },
  countCities: { name: "Cities", icon: <LocationIcon /> },
  countTheatres: { name: "Theatres", icon: <TheatreIcon /> },
  countScreens: { name: "Screens", icon: <TheatreIcon /> },
  plays: { name: "Plays", icon: <PlayIcon /> },
  reach: { name: "Reach", icon: <PremiumIcon /> },
};

const Summary = ({ summary, isLoading }) => {
  if (isLoading) {
    return <PageLoadingWithTable />;
  }

  const data = Object.entries(transposeObject(summary)).map(([key, value]) => {
    if (key === "duration") {
      return {
        title: TITLES[key].name,
        icon: TITLES[key].icon,
        estimated: getDuration(value.estimated, "MMM DD, YYYY"),
        scheduled: getDuration(value.scheduled, "MMM DD, YYYY"),
        played: getDuration(value.played, "MMM DD, YYYY"),
        failed: getDuration(value.failed, "MMM DD, YYYY"),
      };
    }
    return {
      title: TITLES[key].name,
      icon: TITLES[key].icon,
      ...value,
    };
  });

  return (
    <TableInfoBlock
      data={data}
      columns={[
        {
          id: "title",
          Header: "",
          accessor: (d) => (
            <div className={styles.summaryTitle}>
              {d.icon}
              <span>{d.title}</span>
            </div>
          ),
          width: 192,
        },
        {
          id: "estimated",
          Header: "Estimated",
          accessor: (d) => d.estimated || "-",
        },
        {
          id: "scheduled",
          Header: "Scheduled",
          accessor: (d) => d.scheduled || "-",
        },
        {
          id: "played",
          Header: "Played",
          accessor: (d) => d.played || "-",
        },
        {
          id: "failed",
          Header: "Failed",
          accessor: (d) => d.failed || "-",
        },
      ]}
    />
  );
};

export default Summary;

Summary.propTypes = {
  summary: PropTypes.shape({
    id: PropTypes.string,
    estimated: PropTypes.object,
    scheduled: PropTypes.object,
    played: PropTypes.object,
    failed: PropTypes.object,
  }),
};
