import React, { Component } from "react";
import queryString from "query-string";
import classNames from "classnames";
import { ExpandTable } from "workbench";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import { modifyTableColumns, getBrandType } from "../../../../utils";
import { getTagByID } from "../../../../api";
import * as constants from "../../../../constants";
import FilterChips from "../../../common/Filters/FilterChips";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";
import pageStyles from "../../../../styles/App.module.scss";
import { GET_BRAND_ACTIONS } from "./brandConstants";

export default class BrandTable extends Component {
  componentDidMount = () => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    const searchParams = queryString.parse(history.location.search);
    if (params.action) {
      if (params.action === "new") {
        actions.addBrand(params);
      } else if (params.action && params.viewtabId) {
        actions[GET_BRAND_ACTIONS[params.tabId]](params, false);
      }
    } else if (searchParams.editBrandId) {
      this.redirectToBrandDetailsPage(searchParams);
    }
    this.props.actions.getBusinessTypesList();
    this.props.actions.getProductIdentificationNumberTypeList();
  };
  redirectToBrandDetailsPage = async (searchParams) => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    const { data } = await getTagByID(searchParams.editBrandId);
    const brandType = getBrandType(searchParams.editBrandId, data);
    if (brandType) {
      history.push(`/brands/${brandType}/${searchParams.editBrandId}/details/edit`);
      actions[GET_BRAND_ACTIONS[brandType]](params, true);
    }
  };
  render = () => {
    const {
      history,
      dataList,
      columns,
      selectedColumns,
      reorderedColumns,
      tagTypes,
      onColumnSelect,
      childIdentificationKey,
      addButtonProps,
      brands: { isLoading, isPartialLoading },
      filters,
      sort,
      ps,
      page,
      onQueryChange,
      isFilterLoading,
      onSortedChange,
      isViewOnly,
      isEdit,
      hiddenTagTypes = [],
      onRowClick,
      onChildRowClick,
    } = this.props;
    const query = queryString.parse(history.location.search);
    const addActionButton = isViewOnly || isEdit ? {} : addButtonProps;
    if (isLoading && !dataList.data)
      return (
        <PageLoadingWithTable
          showBreadcrumb={false}
          tableRowsCount={constants.DEFAULT_LOADING_ROW_COUNT}
        />
      );

    return (
      <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
        <TableControls
          searchBar
          columnFilter
          showRowSize
          pagination
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          data={dataList}
          query={query}
          ps={ps}
          page={page}
          tagTypes={tagTypes
            .filter((tag) => (hiddenTagTypes.includes(tag) ? "" : tag))
            .filter(Boolean)}
          onSearchFilterSelect={this.props.onFilterSelect}
          onFilterChange={this.props.onFilterChange}
          onColumnFilterSelect={onColumnSelect}
          onRowSizeChange={onQueryChange}
          filters={filters}
        />
        <FilterChips
          selected={filters}
          showResultsCount
          resultsCount={dataList.totalCount}
          onFilterChange={this.props.onFilterChange}
          loading={isLoading}
        />
        <ExpandTable
          data={dataList.data}
          loading={isPartialLoading || isFilterLoading}
          columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
          defaultPageSize={constants.DEFAULT_PAGE_SIZE}
          sorted={sort}
          selectable={false}
          childIdentificationKey={childIdentificationKey}
          onSortedChange={onSortedChange}
          LoadingComponent={
            <TableLoading
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          }
          onRowClick={onRowClick}
          onChildRowClick={onChildRowClick}
        />
        <FooterControls
          pagination
          data={dataList}
          query={query}
          ps={ps}
          page={page}
          onRowSizeChange={onQueryChange}
          {...addActionButton}
        />
      </div>
    );
  };
}
