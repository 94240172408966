import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import queryString from "query-string";
import { FormattedNumber } from "react-intl";
import { ExpandTable } from "workbench";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { Icons } from "prefab";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
  createdByColumn,
  createdAtColumn,
  formatDateTime,
  isSlateAdminCompanyUser,
  emptyDataMessage,
  getParams,
  checkScopeAuth,
} from "../../../../utils";
import * as constants from "../../../../constants";
import { getCampaign } from "../../../../api";
import ActionIcon from "../../../common/ActionIcon";
import FilterChips from "../../../common/Filters/FilterChips";
import WithFetchList from "../../../hoc/withFetchList";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import BulkActionButtons from "../../../common/BulkActionButtons";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";
import { ActiveTableCell } from "../../../common/ActiveTableCell";
import UserTableAccessor from "../../../common/UserTableAccessor";
import pageStyles from "../../../../styles/App.module.scss";
import {
  getStatus,
  getStatusIconLegend,
  getContentAvailabilityStatus,
  validateSelectedCampaignsDurations,
  isCampaignStatusChangesAvailable,
  validateCampaignsAction,
} from "../utils";
import PageHeader from "../../../common/PageHeader";
import HeaderButton from "../../../common/HeaderButtons";
import LocalizedButton from "../../../common/LocalizedButton";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import PauseOrResumeActions from "../bulkActions/PauseOrResumeActions";
import AddMaxPlaysPerScreen from "../actions/AddMaxPlaysPerScreen";
import CancelCampaigns from "../bulkActions/CancelCampaigns";
import styles from "../../../../styles/Campaigns/Campaigns.module.scss";

const { CancelIcon, PauseIcon, LogsIcon, PlayIcon, EditIcon } = Icons;
class CampaignsList extends Component {
  state = {
    selectedColumns: [
      "Campaign",
      "Created For",
      "Advertiser",
      "Start Date",
      "End Date",
      "Created By",
      "Status",
    ],
    selection: {
      parentSelection: [],
      childSelection: [],
    },
    currentCampaign: {},
    openedBulkAction: null,
    showAddMaxPlaysPerScreenPanel: false,
  };
  componentDidMount = () => {
    const {
      match: { params },
      fetchData,
      history,
    } = this.props;
    const query = queryString.parse(history.location.search);
    if (params.action === "addMaxPlaysPerScreen" && query.editCampaignId) {
      this.setState({
        showAddMaxPlaysPerScreenPanel: true,
      });
    }
    fetchData();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  onRowSelect = (selection) =>
    this.setState({
      selection,
    });

  handleCampaignAction = (action, props) => {
    this.setState({
      openedBulkAction: action,
      currentCampaign: props,
    });
  };

  handleCampaignClearAction = (props) => {
    const { ps, page, sort } = this.props;
    this.props.actions.pauseOrResumeCampaigns(
      [props.id],
      this.state.openedBulkAction,
      {
        from: "",
        to: "",
      },
      getParams({
        ps,
        page,
        sort,
      })
    );
  };

  handleCampaignCancelAction = (props) => {
    const {
      ps,
      page,
      sort,
      campaigns: { campaign },
    } = this.props;
    const { CAMPAIGN_TYPE } = constants;
    this.props.actions.cancelCampaigns(
      { ids: [props.id], fromDate: "", clearCancellation: true, campaignId: campaign.id },
      CAMPAIGN_TYPE.TARGET_GROUP,
      getParams({
        ps,
        page,
        sort,
      })
    );
  };

  renderIconColumn = (props, url, isChild = false) => {
    const { CAMPAIGN_ACTIONS, CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST } = constants;
    return (
      <ActionIcon
        iconProps={[
          {
            toolTip: "Tooltip.view",
            url: url,
            iconName: "ViewIcon",
          },
          {
            toolTip: "Tooltip.moreActions",
            iconName: "MoreVerticalIcon",
            dropdown: checkScopes(
              [
                {
                  text: "Edit",
                  url: `/campaign-create/${props.id}/details`,
                  icon: <EditIcon />,
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(
                      props.status
                    ),
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
                {
                  text: "Logs",
                  icon: <LogsIcon />,
                  scope: constants.SCOPES.LOGS,
                },
                {
                  text: "Pause",
                  onClick: () => this.handleCampaignAction(CAMPAIGN_ACTIONS.PAUSE, props),
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
                      props.status
                    ),
                  icon: <PauseIcon />,
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
                {
                  text: "Clear Pause",
                  onClick: () => this.handleCampaignClearAction(CAMPAIGN_ACTIONS.PAUSE, props),
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
                      props.status
                    ),
                  icon: <CancelIcon />,
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
                {
                  text: "Resume",
                  onClick: () =>
                    this.setState({
                      openedBulkAction: CAMPAIGN_ACTIONS.RESUME,
                      currentCampaign: props,
                    }),
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.RESUME].includes(
                      props.status
                    ),
                  icon: <PlayIcon />,
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
                {
                  text: "Clear Resume",
                  onClick: () => this.handleCampaignClearAction(CAMPAIGN_ACTIONS.RESUME, props),
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.RESUME].includes(
                      props.status
                    ),
                  icon: <CancelIcon />,
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
                {
                  text: "Clear Cancel",
                  onClick: () => this.handleCampaignCancelAction(props),
                  hideMenuItem: Boolean(
                    isChild ||
                      !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.CANCEL].includes(
                        props.status
                      )
                  ),
                  icon: <CancelIcon />,
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
                {
                  text: "Cancel",
                  onClick: () => this.handleCampaignAction(CAMPAIGN_ACTIONS.CANCEL, props),
                  hideMenuItem: Boolean(
                    isChild ||
                      !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.CANCEL].includes(
                        props.status
                      )
                  ),
                  icon: <CancelIcon />,
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
              ],
              this.props.userData
            ),
          },
        ]}
      />
    );
  };

  closeBulkAction = () => {
    this.setState({ openedBulkAction: null });
  };

  openBulkAction = (bulkAction) => {
    this.setState({ openedBulkAction: bulkAction });
  };

  handleAddMaxPlaysPerScreen = (id) => {
    const { history } = this.props;
    const search = queryString.stringify({
      ...queryString.parse(history.location.search),
      editCampaignId: id,
    });
    history.push(`/campaigns/addMaxPlaysPerScreen?${search}`);
    this.setState({
      showAddMaxPlaysPerScreenPanel: true,
    });
  };

  handleCloseAddMaxPlaysPerScreenPopup = () => {
    const { history } = this.props;
    const search = queryString.stringify({
      ...queryString.parse(history.location.search),
      editCampaignId: undefined,
    });
    history.replace(`/campaigns?${search}`);
    this.setState({
      showAddMaxPlaysPerScreenPanel: false,
    });
  };

  updateCampaignMaxPlaysPerScreen = (data) => {
    const { history } = this.props;
    this.props.actions.updateCampaignDetails(data);
    const search = queryString.stringify({
      ...queryString.parse(history.location.search),
      editCampaignId: undefined,
    });
    history.replace(`/campaigns?${search}`);
    this.setState({
      showAddMaxPlaysPerScreenPanel: false,
    });
  };

  renderMaxPlaysPerScreenValue = (props) => {
    const { userData } = this.props;
    const { SCOPES, SCOPE_ACTIONS, CAMPAIGN_STATUSES } = constants;
    const hasWriteScope = checkScopeAuth(userData, SCOPES.CAMPAIGNS, SCOPE_ACTIONS.WRITE);
    if (props.original.status === CAMPAIGN_STATUSES.CONSUMED && hasWriteScope) {
      return (
        <div className={styles.updateButtonWrapper}>
          <LocalizedButton
            id="edit"
            iconName="AddIcon"
            className={styles.updateButton}
            onClick={() => this.handleAddMaxPlaysPerScreen(props.original.id)}
            text="Button.update"
            scope={SCOPES.CAMPAIGNS}
            scopeAction={SCOPE_ACTIONS.WRITE}
            userData={userData}
          />
        </div>
      );
    }
    return <FormattedNumber value={props.value} />;
  };

  createNewCampaign = () => {
    const { history } = this.props;
    history.replace(`/campaign-create`);
  };

  render = () => {
    const {
      history,
      match: { params },
      campaigns: { campaigns, isCampaignsLoading },
      filters,
      userData,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;
    if (isCampaignsLoading && !campaigns.data) return <PageLoadingWithTable />;

    const {
      selectedColumns,
      reorderedColumns,
      selection,
      openedBulkAction,
      currentCampaign,
      showAddMaxPlaysPerScreenPanel,
    } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = campaigns.totalCount || 0;
    const { CAMPAIGN_ACTIONS, CAMPAIGN_TYPE } = constants;
    const selectedCampaignData =
      selection.parentSelection.length > 0 && isEmpty(currentCampaign)
        ? campaigns.data.filter((row) => selection.parentSelection.includes(row.id))
        : [currentCampaign];
    const isEdit = isCampaignStatusChangesAvailable(
      selectedCampaignData,
      isCampaignStatusChangesAvailable
    );
    const isPaused = validateCampaignsAction(campaigns, selection, CAMPAIGN_ACTIONS.PAUSE);
    const campaignHeader =
      isEdit && isPaused
        ? "RightPanelHeader.editPauseCampaign"
        : isEdit
        ? "RightPanelHeader.editResumeCampaign"
        : isPaused
        ? "RightPanelHeader.pauseCampaign"
        : "RightPanelHeader.resumeCampaign";
    const columns = [
      {
        id: "name",
        Header: "Campaign",
        accessor: "name",
        width: 200,
      },
      {
        id: "createdFor",
        Header: isSlateAdminCompanyUser(userData) ? "Created For" : "Advertiser",
        accessor: (d) =>
          ActiveTableCell(d.advertiserId, d.advertiserName, () =>
            this.props.onFilterIdSelect(d.advertiserId)
          ),
      },
      {
        id: "startDate",
        Header: "Start Date",
        accessor: (d) => formatDateTime(`${d.validity?.fromDate} ${d.validity?.startTime}`),
      },
      {
        id: "endDate",
        Header: "End Date",
        accessor: (d) => formatDateTime(`${d.validity?.toDate} ${d.validity?.endTime}`),
      },
      {
        id: "targetType",
        Header: "Campaign Target Type",
        accessor: (d) =>
          ActiveTableCell(d.campaignTargetTypeId, d.campaignTargetTypeName, () =>
            this.props.onFilterIdSelect(d.campaignTargetTypeId)
          ),
      },
      {
        id: "status",
        Header: "Status",
        accessor: (d) => getStatus(d, this.props.onFilterSelect),
        width: 150,
      },
      {
        id: "isContentAvailable",
        Header: "Content Availability",
        accessor: (d) => getContentAvailabilityStatus(d, this.props.onFilterSelect),
      },
      {
        id: "estimatedReach",
        Header: "Estimated Reach",
        accessor: "estimatedReach",
        Cell: (props) => <FormattedNumber value={props.value} />,
      },
      {
        id: "actualReach",
        Header: "Actual Reach",
        accessor: "actualReach",
        Cell: (props) => <FormattedNumber value={props.value} />,
      },
      {
        id: "id",
        Header: "ID",
        accessor: (d) => ActiveTableCell(d.id, d.code, () => this.props.onFilterIdSelect(d.id)),
        width: 80,
      },
      {
        id: "uuid",
        Header: "UUID",
        accessor: (d) => ActiveTableCell(d.id, d.id, () => this.props.onFilterIdSelect(d.id)),
        width: 100,
      },
      {
        id: "country",
        Header: "Country",
        accessor: (d) =>
          ActiveTableCell(d.countryId, d.countryName, () =>
            this.props.onFilterIdSelect(d.countryId)
          ),
        width: 100,
      },
      {
        id: "maxPlays",
        Header: "Max Plays",
        accessor: "maxPlays",
        Cell: (props) => <FormattedNumber value={props.value} />,
        width: 80,
      },
      {
        id: "maxPlaysPerScreen",
        Header: "Max Plays per Screen",
        accessor: "maxPlaysPerScreen",
        Cell: (props) => this.renderMaxPlaysPerScreenValue(props),
        width: 100,
      },
      {
        id: "pendingPlays",
        Header: "Pending",
        accessor: "pendingPlays",
        Cell: (props) => <FormattedNumber value={props.value} />,
        width: 80,
      },
      {
        id: "playedPlays",
        Header: "Played",
        accessor: "playedPlays",
        Cell: (props) => <FormattedNumber value={props.value} />,
        width: 80,
      },
      {
        id: "failedPlays",
        Header: "Failed",
        accessor: "failedPlays",
        Cell: (props) => <FormattedNumber value={props.value} />,
        width: 80,
      },
      {
        id: "approvedAt",
        Header: "Approved On",
        showTimeZone: true,
        accessor: "approvedAt",
        Cell: (props) => formatDateTime(props.value),
      },
      {
        id: "approvedBy",
        Header: "Approved By",
        accessor: UserTableAccessor("approvedBy", this.props.onFilterIdSelect),
      },
      {
        id: "paymentTerm",
        Header: "Payment Term",
        accessor: (d) =>
          d.paymentTermInDays
            ? `${d.paymentTermInDays} Day${d.paymentTermInDays !== 1 ? "s" : ""}`
            : null,
      },
      {
        id: "billingCycle",
        Header: "Billing Cycle",
        accessor: (d) =>
          ActiveTableCell(d.billingCycleId, d.billingCycleName, () =>
            this.props.onFilterIdSelect(d.billingCycleId)
          ),
      },
      createdAtColumn(),
      createdByColumn(this.props.onFilterIdSelect),
      lastUpdatedByColumn(this.props.onFilterIdSelect),
      lastUpdatedAtColumn(),

      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        width: 96,
        parentAccessor: (props) =>
          this.renderIconColumn(props, `/campaigns/campaigns/${props.id}/details`),
        childAccessor: (props) =>
          this.renderIconColumn(props, `/campaigns/target-groups/${props.id}`, true),
      },
    ];

    const addButtonProps = {
      addButton: true,
      buttonLabel: "Button.newCampaign",
      iconName: "AddIcon",
      addButtonScope: constants.SCOPES.CAMPAIGNS,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: this.createNewCampaign,
    };

    const selectionInfo = {
      count: selection.parentSelection.length,
      label: selection.parentSelection.length === 1 ? "Campaign" : "Campaigns",
    };

    return (
      <div>
        <PageHeader
          name="Campaigns"
          count={campaigns.totalCount}
          renderRightSideComponent={() => (
            <div className="flex align-center flex-wrap">
              <HeaderButton
                logsButton
                history={history}
                logsPath={`/logs/source/${AUDIT_LOG_SOURCE.CAMPAIGNS}`}
                {...addButtonProps}
                userData={userData}
              />
            </div>
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={pageStyles.statusGroup}>
            {getStatusIconLegend(
              campaigns.data,
              constants.TAG_TYPE.CAMPAIGN_STATUS,
              this.props.onFilterSelect
            )}
          </div>
          <BulkActionButtons
            isOpen={selection.parentSelection.length > 0}
            selection={[selectionInfo]}
            buttons={[
              {
                text: "Button.pauseCampaign",
                onClick: () => {
                  this.openBulkAction(CAMPAIGN_ACTIONS.PAUSE);
                },
                icon: "PauseIcon",
                isHidden: !validateCampaignsAction(campaigns, selection, CAMPAIGN_ACTIONS.PAUSE),
                checkScope: true,
                scope: constants.SCOPES.CAMPAIGNS,
                scopeAction: constants.SCOPE_ACTIONS.WRITE,
                userData,
              },
              {
                text: "Button.resumeCampaign",
                onClick: () => {
                  this.openBulkAction(CAMPAIGN_ACTIONS.RESUME);
                },
                icon: "PlayIcon",
                isHidden: !validateCampaignsAction(campaigns, selection, CAMPAIGN_ACTIONS.RESUME),
                checkScope: true,
                scope: constants.SCOPES.CAMPAIGNS,
                scopeAction: constants.SCOPE_ACTIONS.WRITE,
                userData,
              },
              {
                text: "Button.cancelCampaign",
                onClick: () => {
                  this.openBulkAction(CAMPAIGN_ACTIONS.CANCEL);
                },
                isIconRight: false,
                icon: "CancelIcon",
                isHidden: !validateCampaignsAction(campaigns, selection, CAMPAIGN_ACTIONS.CANCEL),
                checkScope: true,
                scope: constants.SCOPES.CAMPAIGNS,
                scopeAction: constants.SCOPE_ACTIONS.CANCEL,
                userData,
              },
            ]}
            onClose={() => {
              this.onRowSelect({
                parentSelection: [],
                childSelection: [],
              });
            }}
          />
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={campaigns}
              query={query}
              ps={ps}
              page={page}
              tagTypes={[
                constants.TAG_TYPE.CAMPAIGNS,
                constants.TAG_TYPE.CAMPAIGN_STATUS,
                constants.TAG_TYPE.UPDATED_AT,
                constants.TAG_TYPE.CREATED_AT,
              ]}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={resultsCount}
              onFilterChange={this.props.onFilterChange}
            />
            <ExpandTable
              data={campaigns.data}
              loading={isCampaignsLoading || isFilterLoading}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              defaultPageSize={constants.DEFAULT_PAGE_SIZE}
              sorted={sort}
              onSortedChange={onSortedChange}
              selectable={true}
              selection={selection}
              onSelect={this.onRowSelect}
              showChildSelect={false}
              childIdentificationKey="targetGroups"
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
              emptyDataMessage={emptyDataMessage(
                `EmptyDataMessage.noCampaignFound`,
                `EmptyDataMessage.noCampaignFoundBody`
              )}
              onRowClick={({ original }) =>
                history.push(`/campaigns/campaigns/${original.id}/details`)
              }
              onChildRowClick={({ original }) =>
                history.push(`/campaigns/target-groups/${original.id}`)
              }
            />
            <FooterControls
              pagination
              data={campaigns}
              query={query}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
        {(openedBulkAction === CAMPAIGN_ACTIONS.RESUME ||
          openedBulkAction === CAMPAIGN_ACTIONS.PAUSE) && (
          <PauseOrResumeActions
            header={campaignHeader}
            selectionInfo={
              selection.parentSelection.length > 0 && isEmpty(currentCampaign)
                ? selectionInfo
                : null
            }
            history={history}
            selected={selectedCampaignData}
            isPaused={isPaused}
            isEdit={isEdit}
            showEdit={validateSelectedCampaignsDurations(selectedCampaignData)}
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            onPauseOrResumeAction={(period) => {
              this.props.actions.pauseOrResumeCampaigns(
                selectedCampaignData.map((campaign) => campaign.id),
                openedBulkAction,
                period,
                getParams({
                  ps,
                  page,
                  sort,
                })
              );
            }}
          />
        )}
        {openedBulkAction === CAMPAIGN_ACTIONS.CANCEL && (
          <CancelCampaigns
            selectionInfo={
              selection.parentSelection.length > 0 && isEmpty(currentCampaign)
                ? selectionInfo
                : null
            }
            header="RightPanelHeader.cancelCampaign"
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            cancelCampaigns={(data) => {
              this.props.actions.cancelCampaigns(
                { ...data, ids: selectedCampaignData.map((campaign) => campaign.id) },
                CAMPAIGN_TYPE.CAMPAIGN,
                getParams({
                  ps,
                  page,
                  sort,
                }),
                this.closeBulkAction
              );
            }}
          />
        )}
        {query?.editCampaignId && params.action === "addMaxPlaysPerScreen" && (
          <AddMaxPlaysPerScreen
            fetchActionId={query?.editCampaignId}
            fetchAction={getCampaign}
            isOpen={showAddMaxPlaysPerScreenPanel}
            onClose={this.handleCloseAddMaxPlaysPerScreenPopup}
            onSave={this.updateCampaignMaxPlaysPerScreen}
          />
        )}
      </div>
    );
  };
}

export const CampaignsListWithFilters = WithFetchList("getCampaigns", {
  sort: [{ id: "status", desc: false }],
})(CampaignsList);

const mapStateToProps = createSelector(
  (state) => state.campaigns,
  (state) => state.userData,
  (campaigns, userData) => ({ campaigns, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(CampaignsListWithFilters));
