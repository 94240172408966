import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch } from "../../../../utils";
import * as constants from "../../../../constants";
import ActionIcon from "../../../common/ActionIcon";
import StatusIcon from "../../../common/StatusIcon";
import WithFetchList from "../../../hoc/withFetchList";
import ScreenTable from "../../../tables/Screen";
import { locationColumn } from "../../../../utils";
import { ActiveTableCell } from "../../../common/ActiveTableCell";

class MovieScreens extends Component {
  state = {};

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    this.props.fetchData(null, true, [{ movieId: params.movieId }]);
  };

  getMovieStatusList = (data) => {
    const dataStatus = data.map((item) => item.movieStatus);
    return constants.MOVIE_STATUS_LIST.map((status) => ({
      name: status.displayName,
      count: dataStatus.filter((d) => d === status.id).length,
      type: constants.STATUS_ICON_TYPES.MOVIES,
      tagType: constants.TAG_TYPE.MOVIE_STATUS,
      color: status.color,
      id: status.id,
    }));
  };

  renderMovieStatus = ({ movieStatus }) => {
    return (
      <div className="flex align-center" onClick={() => this.props.onFilterIdSelect(movieStatus)}>
        <StatusIcon
          color={constants.MOVIE_STATUS_LIST.find((item) => item.id === movieStatus).color}
          type={constants.STATUS_ICON_TYPES.MOVIES}
        />
        &nbsp;
        {movieStatus}
      </div>
    );
  };

  renderScreenWithTheatre = (d) => {
    return (
      <>
        <span>{d.name} · </span>
        {ActiveTableCell(d.theatre.id, d.theatre.name, () =>
          this.props.onFilterIdSelect(d.theatre.id)
        )}
      </>
    );
  };

  getScreenColumns = () => {
    return [
      {
        id: "theatreName",
        Header: "Screen · Theatre",
        accessor: (d) => this.renderScreenWithTheatre(d),
      },
      {
        id: "chainName",
        Header: "Chain",
        accessor: (d) =>
          ActiveTableCell(d.chain.id, d.chain.name, () => this.props.onFilterIdSelect(d.chain.id)),
      },
      locationColumn(this.props.onFilterIdSelect),
      {
        id: "movieStatus",
        Header: "Movie Status",
        accessor: (d) => this.renderMovieStatus(d),
      },

      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        Cell: (props) => (
          <ActionIcon
            iconProps={[
              {
                toolTip: "Tooltip.view",
                url: `/screens/${props.original.id}`,
                iconName: "ViewIcon",
              },
            ]}
          />
        ),
      },
    ];
  };

  render = () => {
    const {
      movies: { movieScreens, isPartialLoading },
      isFilterLoading,
    } = this.props;

    return (
      <ScreenTable
        {...this.props}
        withToggleSwitch
        screenList={movieScreens}
        isLoading={isPartialLoading || isFilterLoading}
        selectedColumns={["Movie Status"]}
        columns={this.getScreenColumns()}
        statusList={this.getMovieStatusList(movieScreens?.data)}
        tagTypes={[
          constants.TAG_TYPE.SCREEN,
          constants.TAG_TYPE.THEATRE,
          constants.TAG_TYPE.CHAIN,
          constants.TAG_TYPE.LOCATION,
        ]}
      />
    );
  };
}

export const MovieScreensWithFilters = WithFetchList("getMovieScreens", {
  sort: [
    {
      id: "theatreName",
      desc: false,
    },
  ],
})(MovieScreens);

const mapStateToProps = createSelector(
  (state) => state.movies,
  (movies) => ({ movies })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(MovieScreensWithFilters));
