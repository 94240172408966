import { map } from "lodash";
import {
  getAdminTheatres,
  getUserTheatres,
  getTheatre as getTheatreApi,
  getTheatreScreens as getTheatreScreensApi,
  getTheatrePlaylist as getTheatrePlaylistApi,
  getTheatreRights as getTheatreRightsApi,
  getTheatreRateCards as getTheatreRateCardsApi,
  getTheatreRight,
  createOrUpdateTheatreRight,
  fetchRights,
  getCompaniesList,
  updateScreenStatus,
} from "../../api";
import * as types from "../types/theatres";
import * as constants from "../../constants";

import { showToast, createFilterRequest, getScreenStatusList } from "../../utils";

const actionCreator = (type, payload) => ({ type, payload });
const updateTheatres = (payload) => {
  return { type: types.THEATRES, payload: payload };
};

const updateTheatre = (payload) => {
  return { type: types.THEATRE, payload: payload };
};

const updateTheatreScreens = (payload) => {
  return { type: types.THEATRE_SCREENS, payload: payload };
};

const updateTheatrePlaylist = (payload) => {
  return { type: types.THEATRE_PLAYLIST, payload: payload };
};

const updateTheatreRights = (payload) => {
  return { type: types.THEATRE_RIGHTS, payload: payload };
};

const updateTheatreRateCards = (payload) => {
  return { type: types.THEATRE_RATE_CARDS, payload: payload };
};

const getAdminTheatresList = (
  params,
  filters = [],
  getList = getAdminTheatres,
  isPartial = true
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "categoryName:asc" } = params;
  return async (dispatch) => {
    try {
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, true))
        : dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateTheatres(data));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getUserTheatresList = (params, filters = [], getList = getUserTheatres, isPartial = true) => {
  const {
    page = 1,
    ps = constants.DEFAULT_PAGE_SIZE,
    sort = "categoryName:asc",
    companyId,
  } = params;
  return async (dispatch) => {
    try {
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, true))
        : dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters, companyId);
      dispatch(updateTheatres(data));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getTheatre = (params) => {
  return async (dispatch) => {
    dispatch(actionCreator(types.IS_THEATRE_VIEW_LOADING, true));
    dispatch(actionCreator(types.IS_THEATRE_SCREENS_LOADING, true));
    try {
      const { data } = await getTheatreApi(params.theatreId);
      dispatch(updateTheatre(data));
      if (data?.screens?.length > 0) {
        map(data.screens, (screen, index) => {
          if (screen?.companyScreenStatus && screen.companyScreenStatus.length > 0) {
            const [screenStatus] = screen.companyScreenStatus;
            data.screens[index].screenSettings = [
              {
                screenStatus: screenStatus,
                screenStatusList: getScreenStatusList(screenStatus),
              },
            ];
          }
        });
      }
      dispatch(updateTheatreScreens(data?.screens ?? []));
      dispatch(actionCreator(types.IS_THEATRE_VIEW_LOADING, false));
      dispatch(actionCreator(types.IS_THEATRE_SCREENS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_THEATRE_VIEW_LOADING, false));
      dispatch(actionCreator(types.IS_THEATRE_SCREENS_LOADING, false));
    }
  };
};

const getTheatrePlaylist = (params, filters = [], getList = getTheatrePlaylistApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "updatedAt:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_THEATRE_SCREENS_LOADING, true));
      const { data } = await getList(params.theatreId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateTheatrePlaylist(data));
      dispatch(actionCreator(types.IS_THEATRE_SCREENS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_THEATRE_SCREENS_LOADING, false));
    }
  };
};

const getTheatreRights = (params, filters = [], getList = getTheatreRightsApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "categoryName:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_THEATRE_RIGHTS_LOADING, true));
      const { data } = await getList(params.theatreId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateTheatreRights(data));
      dispatch(actionCreator(types.IS_THEATRE_RIGHTS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_THEATRE_RIGHTS_LOADING, false));
    }
  };
};

const getTheatreRightsCount = (params, getList = getTheatreRightsApi) => {
  return async (dispatch) => {
    const { data } = await getList(params.theatreId, 0, "categoryName:asc", []);
    dispatch(updateTheatreRights(data));
  };
};

const createRight = () => {
  return async (dispatch) => {
    const { data: rights } = await fetchRights(0, 0, [], {});
    const {
      data: { data: companies },
    } = await getCompaniesList();
    const { data: screens } = await getTheatreScreensApi(0, 0, [], {});

    dispatch(
      actionCreator(types.THEATRE_RIGHTS_EDIT, {
        rights: rights,
        screens: screens,
        companies: companies,
        isOpen: true,
        isEdit: false,
        right: {
          screen: {
            id: "",
            name: "",
          },
          company: {
            id: "",
            name: "",
          },
          right: {
            id: "",
            name: "",
          },
          playlist: {
            id: "",
            name: "",
          },
          playlistPack: {
            id: "",
            name: "",
          },
          segment: {
            id: "",
            name: "",
          },
          status: "",
          validity: {
            fromDate: null,
            toDate: null,
            startTime: null,
            endTime: null,
          },
        },
      })
    );
  };
};

const editRight = (right) => {
  return async (dispatch) => {
    const { data: rights } = await fetchRights(0, 0, [], {});
    const {
      data: { data: companies },
    } = await getCompaniesList();
    const { data: screens } = await getTheatreScreensApi(0, 0, [], {});

    dispatch(
      actionCreator(types.THEATRE_RIGHTS_EDIT, {
        rights: rights,
        screens: screens,
        companies: companies,
        isOpen: true,
        isEdit: true,
        right: right,
      })
    );
  };
};

const editRightById = (rightId, get = getTheatreRight) => {
  return async (dispatch) => {
    try {
      const { data } = await get(rightId);
      dispatch(editRight(data));
      dispatch(getTheatreRights());
    } catch (error) {
      dispatch(actionCreator({ type: types.IS_THEATRE_RIGHTS_LOADING, payload: false }));
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

const cancelEditRight = () => (dispatch) =>
  dispatch(actionCreator(types.THEATRE_RIGHTS_EDIT, { isOpen: false, right: {} }));

const saveRight = (
  params,
  filters,
  right,
  save = createOrUpdateTheatreRight,
  getList = getTheatreRightsApi
) => {
  const { page = 0, ps = 0, sort = "categoryName:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_THEATRE_RIGHTS_LOADING, true));
      // TODO: Format `right` like in api contract here [https://app.zenhub.com/workspaces/qube-slate-5b62c505eb87c774495f5301/issues/realimage/qube-slate/224]
      await save(right);
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateTheatreRights(data));
      showToast(right ? "Toast.rightsUpdated" : "Toast.rightsAdded");
      dispatch(actionCreator(types.IS_THEATRE_RIGHTS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_THEATRE_RIGHTS_LOADING, false));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateTheatreRights(data));
      showToast(error.message, false);
      return error;
    }
  };
};

const getTheatreRateCards = (params, filters = [], getList = getTheatreRateCardsApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "categoryName:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_THEATRE_RATE_CARDS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateTheatreRateCards(data));
      dispatch(actionCreator(types.IS_THEATRE_RATE_CARDS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_THEATRE_RATE_CARDS_LOADING, false));
    }
  };
};

const updateTheatreScreensStatus = (ids, setting, params) => {
  const { req: ft } = createFilterRequest(
    ids.map((id) => ({ id: id, type: constants.TAG_TYPE.SCREEN }))
  );
  const reqPayload = {
    ft: ft,
    ...setting,
  };
  return async (dispatch) => {
    try {
      await updateScreenStatus(params.companyId, reqPayload);
      await dispatch(getTheatre(params));
      await showToast(
        setting.status === "Active" ? "Activated Successfully" : "Deactivated Successfully",
        true
      );
    } catch (error) {
      await dispatch(getTheatre(params));
      await showToast(error.message, false);
      return error;
    }
  };
};

export {
  getAdminTheatresList,
  getUserTheatresList,
  getTheatre,
  getTheatrePlaylist,
  updateTheatreScreensStatus,
  getTheatreRights,
  getTheatreRightsCount,
  createRight,
  editRight,
  editRightById,
  saveRight,
  cancelEditRight,
  getTheatreRateCards,
};
