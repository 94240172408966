import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Icons } from "prefab";
import TabView from "../../../../common/Tabs";
import LocalizedButton from "../../../../common/LocalizedButton";
import styles from "../../../../../styles/CampaignCreate/TargetGroup.module.scss";

import CriteriaList from "../../common/CriteriaList";
import { CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST, CAMPAIGN_ACTIONS } from "../../../../../constants";
import EmptyPage from "../../../../common/EmptyPage";
import LocationBasedInput from "./LocationBasedInput";
import TheatreBasedInput from "./TheatreBasedInput";
import TargetGroupTheatres from "./TargetGroupTheatres";
import BasicDetails from "./BasicDetails";

const { AddIcon, LocationIcon, TheatreIcon } = Icons;

export default class CreateTargetGroup extends Component {
  static propTypes = {
    campaign: PropTypes.object,
  };

  static defaultProps = {
    campaign: {
      countries: [],
    },
    errors: [],
  };

  state = {
    selectedTabId: "targets",
    activeTargetType: "location",
    criteria: {
      inclusions: [],
      exclusions: [],
    },
    includedProvinces: [],
    errors: [],
  };

  componentDidMount() {
    const { targetGroup, campaign, errors } = this.props;
    let targetGroupCriteria = this.props.criteria;
    if (!isEmpty(targetGroup) && !isEmpty(targetGroup?.criteria))
      targetGroupCriteria = targetGroup.criteria;
    this.setState({
      criteria: targetGroupCriteria,
      errors,
      countries: campaign?.countries ?? [],
    });
  }

  componentDidUpdate(prevProps) {
    const { criteria, errors } = this.props;
    if ((!isEmpty(criteria) && prevProps.criteria !== criteria) || prevProps.errors !== errors) {
      this.setState({ criteria, errors });
    }
  }

  handleTabChange = (newTabId) =>
    this.setState({
      selectedTabId: newTabId,
    });

  updateCurrentTargetingType = (currentTargetType) =>
    this.setState({
      activeTargetType: currentTargetType,
      errors: [],
    });

  validateTheatreCriteriaType = (targetGroupType) => {
    const { criteria } = this.state;
    const criteriaList = {
      inclusions: criteria?.inclusions?.filter((item) => item.targetGroupType === targetGroupType),
      exclusions: criteria?.exclusions?.filter((item) => item.targetGroupType === targetGroupType),
    };

    return !isEmpty(criteriaList.inclusions) || !isEmpty(criteriaList.exclusions);
  };

  handleRemoveCriteriaItem = (params) => {
    this.props.onCriteriaListUpdate(...params);
  };

  handleFormReset = () => {
    const { targetGroupEdit } = this.props;
    let targetGroupCriteria = this.state.criteria;

    if (!isEmpty(targetGroupEdit)) targetGroupCriteria = targetGroupEdit.criteria;
    this.setState({
      selectedTabId: "targets",
      activeTargetType: "location",
      criteria: targetGroupCriteria,
      includedProvinces: [],
      errors: [],
    });
  };

  renderTargetCriteriaTypes = () => {
    const { criteria, activeTargetType, countries, errors } = this.state;
    const { onCriteriaListUpdate } = this.props;

    return (
      <div className={styles.targetTypesContainer}>
        <div className={styles.title}>Choose Targeting Type</div>
        <div className={styles.buttonContainer}>
          <LocalizedButton
            id="location"
            onClick={() => this.updateCurrentTargetingType("location")}
            text="Button.location"
            className={classNames(styles.button, {
              [styles.active]: activeTargetType === "location",
            })}
          />
          <LocalizedButton
            id="theatre"
            icon={!this.validateTheatreCriteriaType("theatre") && <AddIcon />}
            onClick={() => this.updateCurrentTargetingType("theatre")}
            text="Button.theatre"
            className={classNames(styles.button, {
              [styles.active]: activeTargetType === "theatre",
            })}
          />
        </div>
        <div className={styles.contentWrapper}>
          {activeTargetType === "location" ? (
            <LocationBasedInput
              countries={countries}
              updateCriteriaList={(data, criteriaListType) =>
                onCriteriaListUpdate(data, criteriaListType, "location")
              }
            />
          ) : (
            <TheatreBasedInput
              updateCriteriaList={(data, criteriaListType) =>
                onCriteriaListUpdate(data, criteriaListType, "theatre")
              }
            />
          )}
          <div className={styles.errorList}>
            {errors.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>
          <CriteriaList
            targetGroupType="theatre"
            criteria={criteria}
            errors={errors}
            headerIcon={<TheatreIcon />}
            headerName={"Theatre"}
            onRemoveCriteriaItem={(data) =>
              this.props.onCriteriaListUpdate(data, "", "theatre", true)
            }
          />
          <CriteriaList
            targetGroupType="location"
            criteria={criteria}
            errors={errors}
            countries={countries}
            headerIcon={<LocationIcon />}
            headerName={"Location"}
            onRemoveCriteriaItem={(data) =>
              this.props.onCriteriaListUpdate(data, "", "location", true)
            }
          />
        </div>
      </div>
    );
  };

  onExclusion = (id, type) =>
    this.setState({
      criteria: {
        inclusions: this.state.criteria.inclusions,
        exclusions: [...this.state.criteria.exclusions, { id, type }],
      },
    });

  render() {
    const { selectedTabId, criteria } = this.state;
    const { hasFormSaved, onInputChange, targetGroups, campaign, targetGroup } = this.props;
    if (!CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(targetGroup.status))
      return (
        <EmptyPage
          heading="EmptyState.targetGroupNotEditableHeading"
          body="EmptyState.targetGroupNotEditableMessage"
          isHomeLinkVisible={false}
        />
      );
    return (
      <div className={styles.formContainer}>
        <BasicDetails
          campaign={campaign}
          targetGroups={targetGroups}
          targetGroup={targetGroup}
          hasFormSaved={hasFormSaved}
          onInputChange={onInputChange}
        />
        <div className={styles.formContent}>
          <div className={styles.tabContainer}>
            <TabView
              id="campaignTabs"
              defaultSelectedTabId="targets"
              selectedTabId={selectedTabId}
              onChange={this.handleTabChange}
              tabs={[
                {
                  id: "targets",
                  title: "Targets",
                  panel: this.renderTargetCriteriaTypes(),
                },
                {
                  id: "theatres",
                  title: "Theatres/Screens",
                  panel: <TargetGroupTheatres criteria={criteria} onExclusion={this.onExclusion} />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
