import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import classNames from "classnames";
import { TablePagination } from "workbench";
import { BaseButton } from "prefab";
import * as constants from "../../constants";
import styles from "../../styles/TableControls.module.scss";
import TagSearch from "./Filters/TagSearch";
import Filters from "./Filters/Filters";
import Selector from "./Selector";
import ColumnSelector from "./ColumnSelector";

class TableControls extends Component {
  static propTypes = {
    searchBar: PropTypes.bool,
    columnFilter: PropTypes.bool,
    showRowSize: PropTypes.bool,
    pagination: PropTypes.bool,
    isVariableSizeInput: PropTypes.bool,
    filters: PropTypes.array,
    controls: PropTypes.array,
    summary: PropTypes.array,
    tagTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  };

  static defaultProps = {
    searchBar: false,
    idSelection: false,
    columnFilter: false,
    showRowSize: false,
    pagination: false,
    isVariableSizeInput: true,
    columns: [],
    filters: [],
    tagTypes: [],
    onIdSelection: () => {},
    onColumnFilterSelect: () => {},
    onSearchFilterSelect: () => {},
  };

  state = {
    isInputFocussed: false,
    width: null,
    isOpen: false,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  resizeInputWidth = (isInputFocussed) => {
    if (this.state.width > 767) {
      this.setState({
        isInputFocussed,
      });
    }
  };

  cancelEdit = () => {
    this.setState({
      isOpen: false,
    });
  };

  onClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  onHandleApply = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleRightPanel() {
    this.setState({
      isOpen: true,
    });
  }

  onSearchFilterSelect = (...args) => {
    this.props.onSearchFilterSelect(...args);
    if (args?.length > 0) {
      const [tag] = args;
      ReactGA.event({ category: "filter", action: "search", label: tag.type });
    }
  };

  render() {
    const {
      searchBar,
      idSelection,
      columnFilter,
      showRowSize,
      pagination,
      filters = [],
      data = [],
      ps,
      page,
      onIdSelection,
      onFilterChange,
      onRowSizeChange,
      tagTypes,
      summary,
      controls,
      isVariableSizeInput,
    } = this.props;

    return (
      <div className={styles.tableControls}>
        <div className={styles.section}></div>
        {this.state.isOpen && columnFilter && (
          <ColumnSelector
            selectedColumns={this.props.selectedColumns}
            reorderedColumns={this.props.reorderedColumns}
            isOpen={this.state.isOpen}
            columns={this.props.columns}
            onClose={this.onClose}
            cancelEdit={this.cancelEdit}
            onHandleApply={this.onHandleApply}
            onItemSelect={this.onItemSelect}
            onColumnFilterSelect={this.props.onColumnFilterSelect}
          />
        )}

        <div
          className={classNames("flex clearfix col-12", styles.container, {
            [styles.filtersActive]: filters.length > 0,
            [styles.searchInputActive]: this.state.isInputFocussed,
          })}
        >
          <div className={styles.expandContainer}>
            {searchBar && (
              <div className={`flex ${styles.searchContainer}`}>
                <div
                  className={classNames(styles.searchBar, {
                    [styles.focusedSearchInput]: this.state.isInputFocussed,
                  })}
                >
                  <TagSearch
                    tagTypes={tagTypes}
                    onSelect={this.onSearchFilterSelect}
                    onFocus={() => {
                      isVariableSizeInput && this.resizeInputWidth(true);
                    }}
                    onBlur={() => {
                      isVariableSizeInput && this.resizeInputWidth(false);
                    }}
                  />
                </div>
                <div className={styles.filter}>
                  <Filters tagTypes={tagTypes} onFilterChange={onFilterChange} selected={filters} />
                </div>
              </div>
            )}
            {!this.state.isInputFocussed && (
              <div className={`flex flex-wrap ${styles.secondaryControls}`}>
                {summary &&
                  summary.map((s, i) => (
                    <div key={i} className={styles.controls}>
                      {s}
                    </div>
                  ))}
                {idSelection && (
                  <Selector
                    label="Button.selectUsingId"
                    onSelect={onIdSelection}
                    singleSelect
                    noSelectedIcon
                    list={["Movie", "Screen", "Theatre"]}
                    placeHolder="Button.selectUsingId"
                  />
                )}
              </div>
            )}
          </div>
          {!this.state.isInputFocussed && (
            <div className={`flex ${styles.controlContainer}`}>
              {showRowSize && (
                <div className={styles.controls}>
                  <Selector
                    id="selectRow"
                    label="Show"
                    showLabelInButton={false}
                    singleSelect
                    value={ps}
                    selectedList={[ps]}
                    onSelect={(v) => onRowSizeChange("ps", v)}
                    list={constants.PAGE_SIZE_OPTIONS}
                    placeHolder="Show"
                    usePortal={false}
                  />
                </div>
              )}
              {pagination && (
                <div className={styles.controls}>
                  <TablePagination
                    totalRecords={data.totalCount || 0}
                    pageSize={ps}
                    pageNeighbours={1}
                    onPageChange={(v) => onRowSizeChange("page", v)}
                    currentPage={page}
                  />
                </div>
              )}
              {columnFilter && (
                <>
                  <BaseButton
                    iconName="SettingsIcon"
                    onClick={() => {
                      this.handleRightPanel();
                    }}
                  />
                  <div className={styles.controls}></div>
                </>
              )}
              {controls &&
                controls.map((c, i) => (
                  <div
                    key={i}
                    className={classNames({ [styles.controls]: i < controls.length - 1 })}
                  >
                    {c}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default TableControls;
