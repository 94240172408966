import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Classes } from "@blueprintjs/core";
import { sum, includes, compact, isEmpty, values } from "lodash";
import queryString from "query-string";
import { Table } from "workbench";
import { modifyTableColumns, getParams } from "../../utils";
import * as constants from "../../constants";
import pageStyles from "../../styles/App.module.scss";
import FilterChips from "../common/Filters/FilterChips";
import TableControls from "../common/TableControls";
import BulkActionButtons from "../common/BulkActionButtons";
import TableLoading from "../common/TableLoading";
import FooterControls from "../common/FooterControls";
import StatusIconLegend from "../common/StatusIconLegend";
import SwitchButtonWithText from "../common/SwitchButtonWithText";
import ActivateOrDeactivateScreens from "../pages/inventory/TheatresScreens/actions/ActivateOrDeactivateScreens";
import EditSchedulingCutoffTimes from "../pages/inventory/TheatresScreens/bulkActions/EditSchedulingCutoffTimes";
import EditSchedulingTime from "../pages/inventory/TheatresScreens/actions/EditSchedulingTime";

export default class ScreenTable extends Component {
  state = {
    selectedColumns: ["Screen · Theatre", "Chain", "Location"],
    selectedScreens: [],
    openedAction: null,
    currentScreen: null,
  };

  static propTypes = {
    screenList: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selectedColumns: PropTypes.array,
    columns: PropTypes.array,
    bulkActions: PropTypes.array,
    statusList: PropTypes.array,
    tagTypes: PropTypes.array,
    showScheduleActions: PropTypes.bool,
    showScreenStatusActions: PropTypes.bool,
    withToggleSwitch: PropTypes.bool,
    isSelectable: PropTypes.bool,
    sortable: PropTypes.bool,
    showPagination: PropTypes.bool,
    searchBar: PropTypes.bool,
    showRowSize: PropTypes.bool,
    columnFilter: PropTypes.bool,
    withStatusLegend: PropTypes.bool,
    isFilterable: PropTypes.bool,
    isFilterLoading: PropTypes.bool,
    isPartialLoading: PropTypes.bool,
  };

  static defaultProps = {
    selectedColumns: [],
    columns: [],
    bulkActions: [],
    statusList: [],
    tagTypes: [constants.TAG_TYPE.SCREEN],
    showScheduleActions: false,
    showScreenStatusActions: false,
    withToggleSwitch: false,
    isSelectable: false,
    sortable: true,
    showPagination: true,
    searchBar: true,
    showRowSize: true,
    columnFilter: true,
    isFilterable: true,
    withStatusLegend: true,
    isFilterLoading: false,
    isPartialLoading: false,
  };

  componentDidMount() {
    const { selectedColumns } = this.props;

    this.setState({
      selectedColumns: [...this.state.selectedColumns, ...selectedColumns],
    });
  }

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  onRowSelect = (selectedScreens) => this.setState({ selectedScreens });

  openScreenAction = (action, currentScreen = null) => {
    this.setState({ openedAction: action, currentScreen: currentScreen });
  };

  closeScreenAction = () => {
    this.setState({ openedAction: null, currentScreen: null });
  };

  validateSelectedScreensHaveSameStatus = (status) => {
    const { selectedScreens } = this.state;
    const { screenList } = this.props;
    if (selectedScreens.length === 0) return false;
    const filteredScreens = screenList?.data?.filter((screen) => {
      if (!screen?.screenSettings) return false;
      const [{ screenStatus, screenStatusList = [] }] = screen?.screenSettings;
      const futureScreenStatus = screenStatusList?.find((s) => !s.isCurrent) ?? null;
      const currentScreenStatus = isEmpty(futureScreenStatus) ? screenStatus : futureScreenStatus;
      return includes(selectedScreens, screen.id) && currentScreenStatus.screenStatus === status;
    });

    return filteredScreens.length === selectedScreens.length;
  };

  onSaveSchedulingCutOffTimes = (data) => {
    const {
      actions,
      userData,
      history,
      match: { params },
      filters,
    } = this.props;

    actions.saveScreensScheduleCutoffTimes(
      userData.company.id,
      this.state.selectedScreens,
      data,
      params,
      filters,
      true,
      () => {
        history.push(`/screens`);
        this.closeScreenAction();
      }
    );
  };

  onSaveBulkSchedulingTime = (data) => {
    const {
      actions,
      userData,
      history,
      match: { params },
      filters,
    } = this.props;

    actions.saveScreensScheduleTimings(
      userData.company.id,
      this.state.selectedScreens,
      data,
      { ...params, companyId: userData.company.id },
      filters,
      false,
      () => {
        history.push(`/screens`);
        this.closeScreenAction();
      }
    );
  };

  renderStatusIconLegend = () => {
    const { statusList, isPartialLoading } = this.props;
    if (isPartialLoading) {
      return (
        <div
          className={classNames("flex flex-auto flex-wrap", isPartialLoading && Classes.SKELETON)}
        />
      );
    }

    if (sum(statusList.map((status) => status.count)) === 0) return null;

    return (
      <div className={classNames("flex flex-auto flex-wrap")}>
        <StatusIconLegend statusInfo={statusList} onFilterSelect={this.props.onFilterSelect} />
      </div>
    );
  };

  render = () => {
    const {
      match: { params },
      history,
      screenList,
      isFilterLoading,
      isPartialLoading,
      isUpdateScreenStatusLoading,
      filters,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      userData,
      columns,
      bulkActions,
      showScreenStatusActions,
      showScheduleActions,
      tagTypes,
      onToggleSwitch,
      withToggleSwitch,
      isSelectable,
      sortable,
      showPagination,
      withStatusLegend,
      searchBar,
      showRowSize,
      updateScreenStatus,
      isFilterable,
      style,
      columnFilter,
    } = this.props;

    const {
      selectedColumns,
      reorderedColumns,
      selectedScreens,
      currentScreen,
      openedAction,
    } = this.state;
    const {
      SCREEN_STATUSES: { ACTIVE, INACTIVE, ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE },
    } = constants;
    const query = queryString.parse(history.location.search);
    const resultsCount = screenList.totalCount || 0;
    const selectedScreenData =
      selectedScreens?.length > 0
        ? screenList?.data.filter((row) => selectedScreens.includes(row.id))
        : compact([currentScreen]);
    const selectionInfo = [
      {
        count: selectedScreenData.length,
        label: selectedScreenData.length === 1 ? "Screen" : "Screens",
      },
    ];
    const isAllActive = this.validateSelectedScreensHaveSameStatus(ACTIVE);
    const isAllInactive = this.validateSelectedScreensHaveSameStatus(INACTIVE);
    const isAllActiveInFuture = this.validateSelectedScreensHaveSameStatus(ACTIVE_IN_FUTURE);
    const isAllInactiveInFuture = this.validateSelectedScreensHaveSameStatus(INACTIVE_IN_FUTURE);
    return (
      <div>
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <div
              className={classNames(
                "flex flex-auto flex-wrap",
                pageStyles.statusGroup,
                isPartialLoading && pageStyles.statusGroupLoading
              )}
            >
              {withStatusLegend && this.renderStatusIconLegend()}
              {withToggleSwitch && (
                <div className="flex align-center justify-end flex-wrap">
                  <SwitchButtonWithText
                    buttons={[
                      {
                        isActive: params.tabId === "screens",
                        text: "Button.screens",
                        onClick: () => onToggleSwitch("screens"),
                      },
                      {
                        isActive: params.tabId === "theatres",
                        text: "Button.theatres",
                        onClick: () => onToggleSwitch("theatres"),
                      },
                    ]}
                  />
                </div>
              )}
            </div>
            <BulkActionButtons
              isOpen={selectedScreens.length > 0}
              selection={selectionInfo}
              buttons={[
                {
                  text: isAllInactive ? "Button.activate" : isAllActive ? "Button.deactivate" : "",
                  onClick: () =>
                    this.openScreenAction(
                      isAllInactive
                        ? constants.SCREEN_STATUSES.INACTIVE
                        : constants.SCREEN_STATUSES.ACTIVE
                    ),
                  icon: isAllInactive ? "PlayIcon" : "PauseIcon",
                  isHidden: !showScreenStatusActions || (!isAllInactive && !isAllActive),
                  checkScope: true,
                  scope: constants.SCOPES.INVENTORY,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                  userData,
                },
                {
                  text: isAllInactiveInFuture
                    ? "Button.editDeactivation"
                    : isAllActiveInFuture
                    ? "Button.editActivation"
                    : "",
                  onClick: () =>
                    this.openScreenAction(
                      isAllInactiveInFuture
                        ? constants.SCREEN_STATUSES.INACTIVE_IN_FUTURE
                        : constants.SCREEN_STATUSES.ACTIVE_IN_FUTURE
                    ),
                  icon: "EditIcon",
                  isHidden:
                    !showScreenStatusActions || (!isAllInactiveInFuture && !isAllActiveInFuture),
                  checkScope: true,
                  scope: constants.SCOPES.INVENTORY,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                  userData,
                },
                {
                  text: "Button.updateCutoffTimes",
                  onClick: () => this.openScreenAction("schedulingCutOffTimes"),
                  icon: "AddFilledIcon",
                  checkScope: true,
                  isHidden: !showScheduleActions,
                  scope: constants.SCOPES.INVENTORY,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                  userData,
                },
                {
                  text: "Button.updateSchedulingTime",
                  onClick: () => this.openScreenAction("schedulingTime"),
                  icon: "AddFilledIcon",
                  checkScope: true,
                  isHidden: !showScheduleActions,
                  scope: constants.SCOPES.INVENTORY,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                  userData,
                },
                ...bulkActions,
              ]}
              onClose={() => this.onRowSelect([])}
            />
            <TableControls
              searchBar={searchBar}
              columnFilter={columnFilter}
              showRowSize={showRowSize}
              pagination={showPagination}
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={screenList}
              query={query}
              ps={ps}
              page={page}
              tagTypes={tagTypes}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            {isFilterable && (
              <FilterChips
                selected={filters}
                showResultsCount
                resultsCount={resultsCount}
                onFilterChange={this.props.onFilterChange}
              />
            )}
            <Table
              style={style}
              data={screenList.data}
              loading={isPartialLoading || isFilterLoading}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              defaultPageSize={constants.DEFAULT_PAGE_SIZE}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={
                <TableLoading
                  style={style}
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
              selectable={isSelectable}
              selection={selectedScreens}
              sortable={sortable}
              selectedClassNames="selectedParent"
              selectedBackground="rgba(121, 126, 209, 0.2)"
              onSelect={(selection) => this.onRowSelect(selection)}
              onRowClick={({ original }) => history.push(`/screens/${original.id}/details`)}
            />
            <FooterControls
              pagination={showPagination}
              data={screenList}
              query={query}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
        {showScreenStatusActions && values(constants.SCREEN_STATUSES).includes(openedAction) && (
          <ActivateOrDeactivateScreens
            isLoading={isUpdateScreenStatusLoading}
            selectionInfo={selectionInfo}
            screenInfo={currentScreen}
            isBulkAction
            selectedScreens={selectedScreenData}
            activationType={openedAction}
            isOpen={values(constants.SCREEN_STATUSES).includes(openedAction)}
            onClose={this.closeScreenAction}
            onActivateOrDeactivate={(data, callback) => {
              updateScreenStatus(
                selectedScreenData.map((screen) => screen.id),
                data,
                getParams({
                  ps,
                  page,
                  sort,
                  companyId: userData.company.id,
                  ...params,
                }),
                filters,
                false,
                callback
              );
            }}
          />
        )}
        {showScheduleActions && openedAction === "schedulingCutOffTimes" && (
          <EditSchedulingCutoffTimes
            selectionInfo={selectionInfo}
            selected={selectedScreenData}
            isOpen={openedAction === "schedulingCutOffTimes"}
            onClose={this.closeScreenAction}
            onSave={this.onSaveSchedulingCutOffTimes}
          />
        )}
        {showScheduleActions && openedAction === "schedulingTime" && (
          <EditSchedulingTime
            selectionInfo={selectionInfo}
            isBulkAction
            selectedScreens={selectedScreenData}
            isOpen={openedAction === "schedulingTime"}
            onClose={this.closeScreenAction}
            onSave={this.onSaveBulkSchedulingTime}
          />
        )}
      </div>
    );
  };
}
