import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isEmpty } from "lodash";
import { BUTTON_TYPE } from "prefab";
import { FORM_FIELD_TYPE } from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";
import BulkActionButtonsStyles from "../../../../styles/BulkActionButtons.module.scss";
import styles from "../../../../styles/Campaigns/PauseOrResumeActions.module.scss";

export default class CancelCampaigns extends Component {
  state = {
    fromDate: this.props.fromDate,
  };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    cancelCampaigns: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fromDate: null,
    selectionInfo: null,
  };

  componentWillUnmount = () => {
    this.cancelAction();
  };

  cancelAction = () => {
    this.setState({
      fromDate: null,
    });
    this.props.onClose();
  };

  save = () => {
    const { fromDate } = this.state;
    const data = moment(fromDate, "YYYY-MM-DD").isValid()
      ? { fromDate, clearCancellation: false }
      : {};
    this.props.cancelCampaigns(data);
  };

  clear = () => {
    this.props.cancelCampaigns({ fromDate: "", clearCancellation: true });
  };

  render = () => {
    const { isOpen, header, selectionInfo, actionType } = this.props;
    const { fromDate } = this.state;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelAction()}
        header={header}
        showFooter={true}
        primaryButtonProps={[
          {
            text: "Button.cancel",
            buttonType: BUTTON_TYPE.PRIMARY,
            onClick: this.save,
          },
          {
            text: "Button.clear",
            buttonType: BUTTON_TYPE.PRIMARY,
            onClick: this.clear,
            className: styles.clear,
          },
        ]}
        secondaryButtonProps={{ text: "Button.close", onClick: this.cancelAction }}
      >
        {!isEmpty(selectionInfo) && (
          <div className={BulkActionButtonsStyles.selectionInfoContainer}>
            <div className={BulkActionButtonsStyles.selectionInfo}>
              <span>{selectionInfo.count}</span> {selectionInfo.label} Selected
            </div>
          </div>
        )}
        <Form
          config={[
            {
              type: FORM_FIELD_TYPE.DATE,
              title: `${actionType} From`,
              invalidDateMessage: "Invalid From Date",
              onChange: (v) => {
                this.setState({ fromDate: moment(v).format("YYYY-MM-DD") });
              },
              value: fromDate ? new Date(fromDate) : null,
              placeholder: "MM/DD/YYYY",
              minDate: new Date(),
            },
          ]}
        />
        <div className={styles.campaignActionInfo}>
          <span>
            All schedules on the from day may not be cancelled entirely. Any applicable cancellation
            fees will be charged on cancellation
          </span>
        </div>
      </RightPanel>
    );
  };
}
