import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { ICON_POS } from "prefab";
import PageLoadingWithTabs from "../../../common/PageLoadingWithTabs";
import { bindDispatch, isSlateAdminCompanyUser } from "../../../../utils";
import PageHeader from "../../../common/PageHeader";
import HeaderButton from "../../../common/HeaderButtons";
import ButtonStyles from "../../../../styles/Button.module.scss";
import LocalizedButton from "../../../common/LocalizedButton";
import Breadcrumb from "../../../common/BreadCrumb";
import TabView from "../../../common/Tabs";
import { QUBE_WIRE_CINEMAS_BASE_URL } from "../../../../config";
import { SCOPES, SCOPE_ACTIONS } from "../../../../constants";
import theatreDetails from "./tabs/theatre/details";
import TheatreScreens from "./tabs/theatre/screens";
import TheatreRights from "./tabs/theatre/rights";
// import TheatreRateCards from "./tabs/theatre/rateCards";
// import TheatrePlaylist from "./tabs/theatre/playlistTemplates";

const _LINKS = [{ name: "PageHeader.theatres", path: "/theatres" }];
export class TheatreView extends Component {
  componentDidMount = async () => {
    await this.getTheatre();
  };

  getTheatre = () => {
    const {
      match: { params },
      actions,
    } = this.props;
    actions.getTheatre(params);
    actions.getTheatreRightsCount(params);
  };

  render = () => {
    const {
      history,
      theatres: { theatre, isTheatreViewLoading, theatreRights },
      match: { params },
      userData,
    } = this.props;
    const isAdminApp = isSlateAdminCompanyUser(userData);

    if (isTheatreViewLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={3} />;
    }

    return (
      <div className="col-12 clearfix">
        <div className="col-12 clearfix">
          <Breadcrumb
            isLoading={isTheatreViewLoading}
            history={history}
            links={[..._LINKS, { name: `${theatre.name} · ${theatre.chain.name}` }]}
          />
        </div>
        <PageHeader
          name={`${theatre.name} · ${theatre.chain.name}`}
          isLoading={isTheatreViewLoading}
          renderRightSideComponent={() => (
            <>
              <LocalizedButton
                scope={SCOPES.INVENTORY}
                scopeAction={SCOPE_ACTIONS.WRITE}
                userData={userData}
                text="Button.edit"
                className={ButtonStyles.buttonSpacing}
                iconName="ExportIcon"
                iconPos={ICON_POS.RIGHT}
                onClick={() => {
                  window.open(`${QUBE_WIRE_CINEMAS_BASE_URL}theatres/${theatre.id}/edit`, "_blank");
                }}
              />

              <HeaderButton
                userData={userData}
                logsButton
                history={history}
                logsPath={`/logs/ref/${theatre.id}`}
              />
            </>
          )}
        />
        <div>
          <TabView
            id="navbar"
            selectedTabId={params.tabId}
            tabs={[
              {
                id: "details",
                title: "Details",
                goto: `/theatres/${theatre.id}/details`,
                panel: theatreDetails(theatre),
              },
              {
                id: "screens",
                title: "Screens",
                count: theatre.screens.length,
                goto: `/theatres/${theatre.id}/screens`,
                panel: <TheatreScreens isAdminApp={isAdminApp} />,
              },
              // {
              //   id: "playlistTemplates",
              //   title: "Playlist Templates",
              //   count: theatre.screens.length,
              //   goto: `/theatres/${theatre.id}/playlistTemplates`,
              //   panel: <TheatrePlaylist />,
              // },
              {
                id: "rights",
                title: "Rights",
                count: theatreRights?.totalCount ?? 0,
                goto: `/theatres/${theatre.id}/rights`,
                panel: <TheatreRights />,
              },
              // {
              //   id: "rateCards",
              //   title: "Rate Cards",
              //   //TODO: Fetch and show actual count, once the api is available
              //   count: 3,
              //   goto: `/theatres/${theatre.id}/rateCards`,
              //   panel: <TheatreRateCards />,
              // },
            ]}
          />
        </div>
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.theatres,
  (state) => state.userData,
  (theatres, userData) => ({ theatres, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(TheatreView);
