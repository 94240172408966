import { isEmpty } from "lodash";

import { CAMPAIGN_STEPS } from "../../../../constants";

const campaignStepChange = (history, params, activeStep, stepToProceed) => {
  if (activeStep < stepToProceed || !params.campaignId) return null;

  const { campaignId } = params;
  switch (stepToProceed) {
    case CAMPAIGN_STEPS.CAMPAIGN:
      history.push(`/campaign-create/${campaignId}/details`);
      break;
    case CAMPAIGN_STEPS.TARGET_GROUP:
      history.push(`/campaign-create/${campaignId}/target-groups/list`);
      break;
    case CAMPAIGN_STEPS.MEDIA:
      history.push(`/campaign-create/${campaignId}/media/list`);
      break;
    case CAMPAIGN_STEPS.REVIEW:
      history.push(`/campaign-create/${campaignId}/review`);
      break;
    case CAMPAIGN_STEPS.FINISH:
      history.push(`/campaign-create/${campaignId}/finish`);
      break;
    default:
      break;
  }
};

const getDateRange = (validity) => {
  if (!isEmpty(validity)) {
    const { fromDate, toDate } = validity;
    return [fromDate ? new Date(fromDate) : null, toDate ? new Date(toDate) : null];
  }
  return [];
};

const getRestrictedDateLimit = (validity, campaignValidity) => {
  const validityData = isEmpty(validity) ? campaignValidity : validity;
  if (!isEmpty(validityData)) {
    const { fromDate, toDate } = validityData;

    return {
      minDate: fromDate ? new Date(fromDate) : undefined,
      maxDate: toDate ? new Date(toDate) : undefined,
    };
  }

  return { minDate: undefined, maxDate: undefined };
};

const validateMandatoryCampaignFields = (campaign) => {
  let isValid = false;

  if (campaign?.name) {
    isValid = Boolean(
      campaign.advertiserId &&
        campaign.maxPlaysPerScreen > 0 &&
        !isEmpty(campaign.currencyId) &&
        !isEmpty(campaign.countryIds) &&
        campaign.validity?.fromDate &&
        campaign.validity?.toDate
    );
  }

  if (isValid) {
    isValid = Boolean(campaign?.billerName && campaign?.billingAddress && campaign?.billingCycleId);
  }

  if (isValid && campaign.isAdvanceAmountRequired) {
    isValid = campaign?.advanceAmountInFractionalUnit > 0;
  }

  return isValid;
};

const validateMandatoryTargetGroupFields = (targetGroup, actionType) => {
  let isValid = false;

  if (actionType === "edit") {
    return targetGroup?.id;
  } else {
    isValid = true;
  }

  if (targetGroup.name && !isEmpty(targetGroup.validity)) {
    isValid = Boolean(
      targetGroup.maxPlaysPerScreen > 0 &&
        targetGroup.validity?.fromDate &&
        targetGroup.validity?.toDate
    );
  }

  return isValid;
};

const validateMandatoryMediaFields = (media, actionType) => {
  let isValid = false;

  if (actionType === "edit") {
    return media?.id;
  } else {
    isValid = true;
  }

  if (media.name && !isEmpty(media.validity)) {
    isValid = Boolean(media.validity?.fromDate && media.validity?.toDate);
  }

  return isValid;
};

export {
  campaignStepChange,
  validateMandatoryCampaignFields,
  validateMandatoryTargetGroupFields,
  validateMandatoryMediaFields,
  getDateRange,
  getRestrictedDateLimit,
};
