import React from "react";
import classnames from "classnames";
import styles from "../../styles/ActiveTableCell.module.scss";
import * as constants from "../../constants";

const onClickTableCell = (e, onClick) => {
  e.stopPropagation();
  onClick();
};

const ActiveTableCell = (id, value, onClick) => (
  <span className={styles.highlight} onClick={(e) => onClickTableCell(e, onClick)} key={id}>
    {value}
  </span>
);

// Creates a single HORIZONTAL ROW of values separated by comma
const ActiveTableCellRowFromArray = (
  list,
  onClick,
  separator = constants.SPECIAL_CHARACTERS.COMMA
) =>
  (list ?? []).map((item, index) => {
    return ActiveTableCell(
      item.id,
      `${item.name} ${index + 1 === list.length ? "" : separator}`,
      () => onClick(item.id)
    );
  });

// Creates a VERTICAL LIST of cells
const ActiveTableCellColumnFromArray = (list, onClick, withEllipsize = true) =>
  (list ?? []).map((item, index) => {
    return (
      <div className={classnames({ "text-ellipsize": withEllipsize })} key={index}>
        {onClick && ActiveTableCell(item.id, item.name, () => onClick(item.id))}
        {!onClick && item.name}
      </div>
    );
  });

// Creates a VERTICAL LIST of cells with each HORIZONTAL row values
const ActiveTableCellColumnWithRowFromArray = (list, onClick, separator) =>
  (list ?? []).map((item, index) => {
    return (
      <div className="text-ellipsize" key={index}>
        {ActiveTableCellRowFromArray(item, onClick, separator)}
      </div>
    );
  });

export {
  ActiveTableCell,
  ActiveTableCellRowFromArray,
  ActiveTableCellColumnFromArray,
  ActiveTableCellColumnWithRowFromArray,
};
