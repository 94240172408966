import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { FormattedNumber } from "react-intl";
import { Table } from "workbench";
import { includes, isEmpty } from "lodash";
import classNames from "classnames";
import { Icons } from "prefab";
import {
  modifyTableColumns,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
  createdByColumn,
  createdAtColumn,
  formatDateTime,
  getParams,
} from "../../../../utils";
import {
  getStatus,
  getStatusIconLegend,
  validateSelectedCampaignsDurations,
  isCampaignStatusChangesAvailable,
  validateCampaignsAction,
} from "../utils";
import {
  CAMPAIGN_ACTIONS,
  SCOPE_ACTIONS,
  SCOPES,
  DEFAULT_ACTION_ICON_COLUMN,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  TAG_TYPE,
  DEFAULT_PAGE_SIZE,
  CAMPAIGN_TYPE,
} from "../../../../constants";
import pageStyles from "../../../../styles/App.module.scss";
import ActionIcon from "../../../common/ActionIcon";
import FilterChips from "../../../common/Filters/FilterChips";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import BulkActionButtons from "../../../common/BulkActionButtons";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";
import { ActiveTableCell } from "../../../common/ActiveTableCell";
import PauseOrResumeActions from "../bulkActions/PauseOrResumeActions";
import CancelCampaignMedia from "../bulkActions/CancelCampaigns";

const { RemoveIcon, LogsIcon, PlayIcon, PauseIcon, EditIcon } = Icons;
class MediaList extends Component {
  state = {
    selectedColumns: ["Media", "Target Group", "Start Date", "End Date", "Status"],
    selection: [],
    openedBulkAction: null,
    currentCampaign: {},
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  onRowSelect = (selection) =>
    this.setState({
      selection,
    });

  validateMedaiAction = (actionType) => {
    const { selection } = this.state;
    const {
      data: { media },
    } = this.props;
    const selectedMedia = media.data
      ? media.data.filter(
          (media) =>
            selection.includes(media.id) &&
            CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[actionType].includes(media.status)
        )
      : [];
    return selectedMedia.length === selection.length;
  };

  closeBulkAction = () => {
    this.setState({ openedBulkAction: null });
  };

  openBulkAction = (bulkAction) => {
    this.setState({ openedBulkAction: bulkAction });
  };

  handleCampaignAction = (action, props) => {
    this.setState({
      openedBulkAction: action,
      currentCampaign: props,
    });
  };

  handleCampaignCancelAction = (props) => {
    const {
      ps,
      page,
      sort,
      data: { targetGroupId },
    } = this.props;
    this.props.actions.cancelCampaigns(
      { ids: [props.id], fromDate: "", clearCancellation: true, campaignId: targetGroupId },
      CAMPAIGN_TYPE.TARGET_GROUP,
      getParams({
        ps,
        page,
        sort,
      })
    );
  };

  handleCampaignClearAction = (props) => {
    const { ps, page, sort } = this.props;
    this.props.actions.pauseOrResumeOneOrMoreMedia(
      [props.id],
      this.state.openedBulkAction,
      {
        from: "",
        to: "",
      },
      getParams({
        ps,
        page,
        sort,
      })
    );
  };

  render = () => {
    const {
      history,
      data: { media, isLoading, targetGroupId },
      hiddenColumns,
      filters,
      userData,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;
    if (isLoading) return <PageLoadingWithTable />;
    const {
      selectedColumns,
      reorderedColumns,
      selection,
      openedBulkAction,
      currentCampaign,
    } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = media.totalCount || 0;
    const selectionInfo = {
      count: selection.length,
      label: selection.length === 1 ? "Medaia" : "Medias",
    };
    const selectedMedia =
      selection.length > 0 && isEmpty(currentCampaign)
        ? media.data?.filter((row) => selection.includes(row.id))
        : [currentCampaign];
    const isEdit = isCampaignStatusChangesAvailable(selectedMedia, openedBulkAction);
    const isPaused = validateCampaignsAction(
      media,
      { parentSelection: selection },
      CAMPAIGN_ACTIONS.PAUSE
    );
    const mediaHeader =
      isEdit && isPaused
        ? "RightPanelHeader.editPauseMedia"
        : isEdit
        ? "RightPanelHeader.editResumeMedia"
        : isPaused
        ? "RightPanelHeader.pauseMedia"
        : "RightPanelHeader.resumeMedia";

    const columns = checkScopes(
      [
        {
          id: "name",
          Header: "Media",
          accessor: "name",
          width: 200,
        },
        {
          id: "targetGroup",
          Header: "Target Group",
          accessor: (d) =>
            ActiveTableCell(d.targetGroupId, d.targetGroupName, () =>
              this.props.onFilterIdSelect(d.targetGroupId)
            ),
        },
        createdByColumn(this.props.onFilterIdSelect),
        {
          id: "startDate",
          Header: "Start Date",
          accessor: (d) => formatDateTime(`${d.validity.fromDate} ${d.validity.startTime}`),
        },
        {
          id: "endDate",
          Header: "End Date",
          accessor: (d) => formatDateTime(`${d.validity.toDate} ${d.validity.endTime}`),
        },
        {
          id: "status",
          Header: "Status",
          accessor: (d) => getStatus(d, this.props.onFilterSelect),
          width: 150,
        },
        {
          id: "estimatedReach",
          Header: "Estimated Reach",
          accessor: "estimatedReach",
          Cell: (props) => <FormattedNumber value={props.value} />,
        },
        {
          id: "actualReach",
          Header: "Actual Reach",
          accessor: "actualReach",
          Cell: (props) => <FormattedNumber value={props.value} />,
        },
        {
          id: "id",
          Header: "ID",
          accessor: (d) => ActiveTableCell(d.id, d.code, () => this.props.onFilterIdSelect(d.id)),
          width: 80,
        },
        {
          id: "uuid",
          Header: "UUID",
          accessor: (d) => ActiveTableCell(d.id, d.id, () => this.props.onFilterIdSelect(d.id)),
          width: 100,
          onlyInAdmin: true,
        },
        {
          id: "country",
          Header: "Country",
          accessor: (d) =>
            ActiveTableCell(d.countryId, d.countryName, () =>
              this.props.onFilterIdSelect(d.countryId)
            ),
          width: 100,
        },
        {
          id: "maxPlays",
          Header: "Max Plays",
          accessor: "maxPlays",
          Cell: (props) => <FormattedNumber value={props.value} />,
          width: 80,
        },
        {
          id: "maxPlaysPerScreen",
          Header: "Max Plays per Screen",
          accessor: "maxPlaysPerScreen",
          Cell: (props) => <FormattedNumber value={props.value} />,
          width: 80,
        },
        {
          id: "pendingPlays",
          Header: "Pending",
          accessor: "pendingPlays",
          Cell: (props) => <FormattedNumber value={props.value} />,
          width: 80,
        },
        {
          id: "playedPlays",
          Header: "Played",
          accessor: "playedPlays",
          Cell: (props) => <FormattedNumber value={props.value} />,
          width: 80,
        },
        {
          id: "failedPlays",
          Header: "Failed",
          accessor: "failedPlays",
          Cell: (props) => <FormattedNumber value={props.value} />,
          width: 80,
        },
        createdAtColumn("Created On"),
        lastUpdatedByColumn(this.props.onFilterIdSelect),
        lastUpdatedAtColumn(),

        {
          ...DEFAULT_ACTION_ICON_COLUMN,
          width: 96,
          Cell: (props) => (
            <ActionIcon
              iconProps={checkScopes([
                {
                  toolTip: "Tooltip.view",
                  url: `/campaigns/media/${props.original.id}`,
                  iconName: "ViewIcon",
                },
                {
                  toolTip: "Tooltip.moreActions",
                  iconName: "MoreVerticalIcon",
                  dropdown: checkScopes(
                    [
                      {
                        text: "Edit",
                        onClick: () =>
                          this.props.history.push(
                            `/campaign-create/${props.campaignId}/media/${props.id}`
                          ),
                        icon: <EditIcon />,
                        hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                          CAMPAIGN_ACTIONS.EDIT
                        ].includes(props.status),
                      },
                      {
                        text: "Logs",
                        onClick: () => {},
                        icon: <LogsIcon />,
                        scope: SCOPES.LOGS,
                      },
                      {
                        text: "Pause",
                        onClick: () => this.handleCampaignAction(CAMPAIGN_ACTIONS.PAUSE, props),
                        hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                          CAMPAIGN_ACTIONS.PAUSE
                        ].includes(props.status),
                        icon: <PauseIcon />,
                      },
                      {
                        text: "Clear Pause",
                        onClick: () =>
                          this.handleCampaignClearAction(CAMPAIGN_ACTIONS.PAUSE, props),
                        hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                          CAMPAIGN_ACTIONS.PAUSE
                        ].includes(props.status),
                        icon: <RemoveIcon />,
                      },
                      {
                        text: "Resume",
                        onClick: () =>
                          this.setState({
                            openedBulkAction: CAMPAIGN_ACTIONS.RESUME,
                            currentCampaign: props,
                          }),
                        hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                          CAMPAIGN_ACTIONS.RESUME
                        ].includes(props.status),
                        icon: <PlayIcon />,
                      },
                      {
                        text: "Clear Resume",
                        onClick: () =>
                          this.handleCampaignClearAction(CAMPAIGN_ACTIONS.RESUME, props),
                        hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                          CAMPAIGN_ACTIONS.RESUME
                        ].includes(props.status),
                        icon: <RemoveIcon />,
                      },
                      {
                        text: "Cancel",
                        onClick: () => this.handleCampaignAction(CAMPAIGN_ACTIONS.CANCEL, props),
                        hideMenuItem: Boolean(
                          CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.CANCEL].includes(
                            props.status
                          )
                        ),
                        icon: <RemoveIcon />,
                      },
                      {
                        text: "Clear Cancel",
                        onClick: () =>
                          this.handleCampaignCancelAction(CAMPAIGN_ACTIONS.CANCEL, props),
                        hideMenuItem: Boolean(
                          CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.CANCEL].includes(
                            props.status
                          )
                        ),
                        icon: <RemoveIcon />,
                      },
                    ],
                    userData
                  ),
                },
              ])}
            />
          ),
        },
      ],
      userData
    ).filter((column) => !includes(hiddenColumns, column.Header));

    return (
      <div className={classNames("col-12 clearfix", pageStyles.viewPageContainer)}>
        <div className={pageStyles.statusGroup}>
          {getStatusIconLegend(media.data, TAG_TYPE.CAMPAIGN_STATUS, this.props.onFilterSelect)}
        </div>
        <BulkActionButtons
          isOpen={selection.length > 0}
          selection={[selectionInfo]}
          buttons={[
            {
              text: "Button.pauseMedia",
              onClick: () => {
                this.openBulkAction(CAMPAIGN_ACTIONS.PAUSE);
              },
              icon: "PauseIcon",
              isHidden: !validateCampaignsAction(
                media,
                { parentSelection: selection },
                CAMPAIGN_ACTIONS.PAUSE
              ),
              checkScope: true,
              scope: SCOPES.CAMPAIGNS,
              scopeAction: SCOPE_ACTIONS.WRITE,
              userData,
            },
            {
              text: "Button.resumeMedia",
              onClick: () => {
                this.openBulkAction(CAMPAIGN_ACTIONS.RESUME);
              },
              icon: "PlayIcon",
              isHidden: !validateCampaignsAction(
                media,
                { parentSelection: selection },
                CAMPAIGN_ACTIONS.RESUME
              ),
              checkScope: true,
              scope: SCOPES.CAMPAIGNS,
              scopeAction: SCOPE_ACTIONS.WRITE,
              userData,
            },
            {
              text: "Button.cancelMedia",
              onClick: () => {
                this.openBulkAction(CAMPAIGN_ACTIONS.CANCEL);
              },
              icon: "CancelIcon",
              isHidden: !this.validateMedaiAction(CAMPAIGN_ACTIONS.CANCEL),
              checkScope: true,
              scope: SCOPES.CAMPAIGNS,
              scopeAction: SCOPE_ACTIONS.CANCEL,
              userData,
            },
          ]}
          onClose={() => {
            this.onRowSelect([]);
          }}
        />
        <TableControls
          searchBar
          columnFilter
          showRowSize
          pagination
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          data={media}
          query={query}
          ps={ps}
          page={page}
          tagTypes={[TAG_TYPE.TARGET_GROUP, TAG_TYPE.CAMPAIGN_STATUS]}
          onSearchFilterSelect={this.props.onFilterSelect}
          onFilterChange={this.props.onFilterChange}
          onColumnFilterSelect={this.onColumnSelect}
          onRowSizeChange={onQueryChange}
          filters={filters}
        />
        <FilterChips
          selected={filters}
          showResultsCount
          resultsCount={resultsCount}
          onFilterChange={this.props.onFilterChange}
        />

        <Table
          data={media.data}
          loading={isLoading || isFilterLoading}
          columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          sorted={sort}
          onSortedChange={onSortedChange}
          selectable={true}
          selection={selection}
          onSelect={this.onRowSelect}
          LoadingComponent={
            <TableLoading
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          }
          onRowClick={({ original }) => history.push(`/campaigns/media/${original.id}`)}
        />
        <FooterControls
          pagination
          data={media}
          query={query}
          ps={ps}
          page={page}
          onRowSizeChange={onQueryChange}
        />
        {(openedBulkAction === CAMPAIGN_ACTIONS.RESUME ||
          openedBulkAction === CAMPAIGN_ACTIONS.PAUSE) && (
          <PauseOrResumeActions
            header={mediaHeader}
            selectionInfo={selection.length > 0 && isEmpty(currentCampaign) ? selectionInfo : null}
            history={history}
            selected={selectedMedia}
            isPaused={isPaused}
            isEdit={isEdit}
            showEdit={validateSelectedCampaignsDurations(selectedMedia)}
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            onPauseOrResumeAction={(period) => {
              this.props.actions.pauseOrResumeOneOrMoreMedia(
                selectedMedia.map((media) => media.id),
                openedBulkAction,
                period,
                getParams({
                  ps,
                  page,
                  sort,
                })
              );
            }}
          />
        )}
        {openedBulkAction === CAMPAIGN_ACTIONS.CANCEL && (
          <CancelCampaignMedia
            selectionInfo={selection.length > 0 && isEmpty(currentCampaign) ? selectionInfo : null}
            header="RightPanelHeader.cancelMedia"
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            cancelCampaigns={(data) => {
              this.props.actions.cancelCampaigns(
                {
                  ...data,
                  ...{
                    ids: selectedMedia.map((media) => media.id),
                    campaignId: targetGroupId,
                  },
                },
                CAMPAIGN_TYPE.MEDIA,
                getParams({
                  ps,
                  page,
                  sort,
                }),
                this.closeBulkAction
              );
            }}
          />
        )}
      </div>
    );
  };
}

MediaList.propTypes = {
  data: PropTypes.shape({
    media: PropTypes.object,
    isLoading: PropTypes.bool,
  }),
  history: PropTypes.object,
  filters: PropTypes.array,
  userData: PropTypes.object,
  sort: PropTypes.array,
  ps: PropTypes.number,
  page: PropTypes.number,
  onSortedChange: PropTypes.func,
  onQueryChange: PropTypes.func,
  onFilterSelect: PropTypes.func,
  onFilterChange: PropTypes.func,
  onFilterIdSelect: PropTypes.func,
};

export default MediaList;
