import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import classNames from "classnames";
import { Icons } from "prefab";
import {
  CAMPAIGN_STATUS_LIST,
  CAMPAIGN_ACTIONS,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  SCOPES,
  SCOPE_ACTIONS,
  CAMPAIGN_STATUSES,
} from "../../../../constants";
import PageLoadingWithTabs from "../../../common/PageLoadingWithTabs";
import { bindDispatch, getCampaignColorClassName } from "../../../../utils";
import { getCurrentActionPeriod } from "../utils";
import { getCampaignTree as getCampaignTreeApi } from "../../../../api";
import styles from "../../../../styles/Campaigns/Campaigns.module.scss";
import ButtonStyle from "../../../../styles/Button.module.scss";
import pageStyles from "../../../../styles/App.module.scss";
import PageHeader from "../../../common/PageHeader";
import Breadcrumb from "../../../common/BreadCrumb";
import CancelCampaigns from "../bulkActions/CancelCampaigns";
import TabView from "../../../common/Tabs";
import CampaignTree from "../CampaignStructure/CampaignTree";
import PauseOrResumeActions from "../../campaigns/bulkActions/PauseOrResumeActions";
import TargetGroupSummary from "./tabs/TargetGroupSummary";
import targetGroupDetails from "./tabs/TargetGroupDetails";
import TargetGroupMedia from "./tabs/TargetGroupMedia";
import TargetGroupTheatres from "./tabs/TargetGroupTheatres";

const { AdGroupIcon, CancelIcon, PauseIcon, PlayIcon, EditIcon } = Icons;
const _LINKS = [{ name: "PageHeader.campaigns", path: "/campaigns/campaigns" }];
export class TargetGroupView extends Component {
  state = {
    isCampaignStructureLoading: true,
    campaignStructure: {},
    isCampaignStructureError: false,
    openedBulkAction: null,
  };

  componentDidMount = async () => {
    await this.getTargetGroup();
    await this.fetchCampaignTreeData();
  };

  getTargetGroup = () => {
    const {
      match: { params },
      actions,
    } = this.props;
    actions.getTargetGroup(params);
  };

  fetchCampaignTreeData = () => {
    const {
      match: { params },
    } = this.props;
    getCampaignTreeApi(params.targetGroupId, "targetGroup")
      .then((campaignRes) =>
        this.setState({
          isCampaignStructureLoading: false,
          campaignStructure: campaignRes,
        })
      )
      .catch((err) => {
        this.setState({
          isCampaignStructureLoading: false,
          isCampaignStructureError: true,
          campaignStructure: null,
        });
      });
  };

  getStatusDetails = (status) => {
    return CAMPAIGN_STATUS_LIST.find((item) => item.id === status);
  };

  renderTabWithCampaignStructure = (tabPanel) => {
    const { isCampaignStructureLoading, campaignStructure, isCampaignStructureError } = this.state;

    if (isCampaignStructureError) return tabPanel;

    return (
      <div className={classNames(pageStyles.pageContainer, styles.tabContentWrapper)}>
        <div className={styles.tabPanel}>{tabPanel}</div>
        <CampaignTree isLoading={isCampaignStructureLoading} campaignTreeData={campaignStructure} />
      </div>
    );
  };

  openBulkAction = (bulkAction) => {
    this.setState({ openedBulkAction: bulkAction });
  };

  closeBulkAction = () => {
    this.setState({ openedBulkAction: null });
  };

  render = () => {
    const {
      history,
      targetGroups: { targetGroup, isLoading },
      match: { params },
      userData,
    } = this.props;
    const { openedBulkAction } = this.state;
    if (isLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={2} />;
    }
    const statusDetails = this.getStatusDetails(targetGroup.status);
    const isPaused = CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
      targetGroup.status
    );
    const isEdit = targetGroup.statusChanges?.length > 0;
    const targetGroupHeader =
      isEdit && isPaused
        ? "RightPanelHeader.editPauseTargetGroup"
        : isEdit
        ? "RightPanelHeader.editResumeTargetGroup"
        : isPaused
        ? "RightPanelHeader.pauseTargetGroup"
        : "RightPanelHeader.resumeTargetGroup";
    return (
      <div className="col-12 clearfix">
        <div className="col-12 clearfix">
          <Breadcrumb
            isLoading={isLoading}
            history={history}
            links={[
              ..._LINKS,
              {
                name: `${targetGroup.campaignName}`,
                path: `/campaigns/campaigns/${targetGroup.campaignId}/details`,
              },
              { name: `${targetGroup.name}` },
            ]}
          />
        </div>
        <div className={classNames("col-12 clearfix")}>
          <PageHeader
            name={`${targetGroup.name}`}
            isLoading={isLoading}
            headerIcon={<AdGroupIcon />}
            customBadge={
              statusDetails && (
                <div
                  className={`${styles.statusBadge} ${getCampaignColorClassName(
                    statusDetails.color
                  )}`}
                >
                  {statusDetails.displayName}
                </div>
              )
            }
            buttons={[
              {
                text: "Button.edit",
                onClick: () =>
                  history.push(
                    `/campaign-create/${targetGroup.campaignId}/target-groups/${targetGroup.id}`
                  ),
                className: `${styles.cancel} ${ButtonStyle.addButton}`,
                isIconRight: false,
                icon: <EditIcon />,
                isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(
                  targetGroup.status
                ),
                checkScope: true,
                scope: SCOPES.CAMPAIGNS,
                scopeAction: SCOPE_ACTIONS.WRITE,
                userData,
              },
              {
                text: "Button.pause",
                onClick: () => this.openBulkAction(CAMPAIGN_ACTIONS.PAUSE),
                className: `${ButtonStyle.addButton} ${styles.pauseButton}`,
                minimal: true,
                icon: <PauseIcon />,
                isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
                  targetGroup.status
                ),
                checkScope: true,
                scope: SCOPES.CAMPAIGNS,
                scopeAction: SCOPE_ACTIONS.WRITE,
                userData,
              },
              {
                text: "Button.resume",
                onClick: () => this.openBulkAction(CAMPAIGN_ACTIONS.RESUME),
                className: ButtonStyle.addButton,
                icon: <PlayIcon />,
                isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.RESUME].includes(
                  targetGroup.status
                ),
                checkScope: true,
                scope: SCOPES.CAMPAIGNS,
                scopeAction: SCOPE_ACTIONS.WRITE,
                userData,
              },
              {
                text: "Button.cancel",
                onClick: () => this.openBulkAction(CAMPAIGN_ACTIONS.CANCEL),
                className: ` ${styles.cancel} ${ButtonStyle.addButton}`,
                isIconRight: false,
                icon: <CancelIcon />,
                isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.CANCEL].includes(
                  targetGroup.status
                ),
                checkScope: true,
                scope: SCOPES.CAMPAIGNS,
                scopeAction: SCOPE_ACTIONS.CANCEL,
                userData,
              },
            ]}
          />
          <div className={styles.tabsContainer}>
            <TabView
              id="targetGroupTabs"
              selectedTabId={params.tabId}
              tabs={[
                {
                  id: "summary",
                  title: "Summary",
                  goto: `/campaigns/target-groups/${targetGroup.id}/summary`,
                  panel: this.renderTabWithCampaignStructure(
                    <TargetGroupSummary targetGroupId={targetGroup.id} />
                  ),
                },
                {
                  id: "details",
                  title: "Details",
                  goto: `/campaigns/target-groups/${targetGroup.id}/details`,
                  panel: this.renderTabWithCampaignStructure(
                    targetGroupDetails(targetGroup, userData)
                  ),
                },
                {
                  id: "media",
                  title: "Media",
                  goto: `/campaigns/target-groups/${targetGroup.id}/media`,
                  panel: <TargetGroupMedia />,
                },
                {
                  id: "theatres",
                  title: "Theatres",
                  goto: `/campaigns/target-groups/${targetGroup.id}/theatres/theatres`,
                  panel: <TargetGroupTheatres />,
                },
              ]}
            />
          </div>
        </div>
        {(openedBulkAction === CAMPAIGN_ACTIONS.RESUME ||
          openedBulkAction === CAMPAIGN_ACTIONS.PAUSE) && (
          <PauseOrResumeActions
            period={getCurrentActionPeriod(targetGroup, openedBulkAction)}
            header={targetGroupHeader}
            type={openedBulkAction}
            isPaused={isPaused}
            isEdit={isEdit}
            showEdit={targetGroup.status !== CAMPAIGN_STATUSES.CONSUMED}
            selected={[targetGroup]}
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            history={history}
            onPauseOrResumeAction={(period) => {
              this.props.actions.pauseOrResumeTargetGroup({
                ...period,
                ...{
                  ids: [targetGroup.id],
                  targetGroupId: targetGroup.id,
                  campaignId: targetGroup.campaignId,
                },
                status: openedBulkAction,
              });
            }}
          />
        )}
        {openedBulkAction === CAMPAIGN_ACTIONS.CANCEL && (
          <CancelCampaigns
            selectionInfo={[]}
            header="RightPanelHeader.cancelTargetGroup"
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            cancelCampaigns={(data) => {
              this.props.actions.cancelTargetGroup(
                {
                  ...data,
                  ...{
                    ids: [targetGroup.id],
                    targetGroupId: targetGroup.id,
                    campaignId: targetGroup.campaignId,
                  },
                },
                this.closeBulkAction
              );
            }}
          />
        )}
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.targetGroups,
  (state) => state.userData,
  (targetGroups, userData) => ({ targetGroups, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(TargetGroupView);
