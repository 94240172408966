import { searchSegmentTypes, saveSegmentType, getSegmentTypeById } from "../../api";

import * as types from "../types/segmentTypes";
import { showToast } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateSegmentTypes = (payload) => {
  return { type: types.DATA, payload: payload };
};

const getSegmentTypes = (
  ps = 0,
  offset = 0,
  sort = "rank",
  reqPay = {},
  search = searchSegmentTypes
) => {
  return async (dispatch) => {
    dispatch(actionCreator(types.IS_LOADING, true));
    try {
      const { data } = await search(ps, offset, sort, reqPay);
      dispatch(updateSegmentTypes(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_LOADING, false));
      dispatch(actionCreator(types.IS_ERROR, error));
    }
  };
};

const editSegmentType = (segmentType) => (dispatch) =>
  dispatch(actionCreator(types.EDIT, { segmentType, isOpen: true }));

const addSegmentType = () => (dispatch) =>
  dispatch(
    actionCreator(types.EDIT, {
      isOpen: true,
      segmentType: { code: "", name: "", rank: "", color: "" },
    })
  );

const cancelEditSegmentType = () => (dispatch) =>
  dispatch(actionCreator(types.EDIT, { isOpen: false, segmentType: {} }));

const editSegmentTypeById = (segmentTypeId, get = getSegmentTypeById) => {
  return async (dispatch) => {
    try {
      const { data } = await get(segmentTypeId);
      dispatch(editSegmentType(data));
      dispatch(getSegmentTypes());
    } catch (error) {
      dispatch(actionCreator(types.IS_LOADING, false));
      dispatch(actionCreator(types.IS_ERROR, error.message));
    }
  };
};

const createOrUpdateSegmentType = (
  segmentType,
  save = saveSegmentType,
  getUpdatedSegmentList = getSegmentTypes
) => {
  return async (dispatch) => {
    try {
      await save(segmentType);
      await dispatch(getUpdatedSegmentList());
      showToast(segmentType.id ? "Toast.segmentTypeUpdated" : "Toast.segmentTypeAdded");
    } catch (error) {
      showToast(error.message, false);
      await dispatch(getUpdatedSegmentList());
      return error;
    }
  };
};

export {
  updateSegmentTypes,
  getSegmentTypes,
  createOrUpdateSegmentType,
  addSegmentType,
  editSegmentType,
  editSegmentTypeById,
  cancelEditSegmentType,
};
