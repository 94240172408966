const IS_LOADING = "inventory/screens/IS_LOADING";
const IS_PARTIAL_LOADING = "inventory/screens/IS_PARTIAL_LOADING";
const IS_SCREEN_RIGHTS_LOADING = "inventory/screens/SCREEN/RIGHTS/IS_LOADING";
const IS_SCREEN_RATE_CARDS_LOADING = "inventory/screens/SCREEN/RATE_CARDS/IS_LOADING";
const IS_SCREEN_SETTINGS_LOADING = "inventory/screens/SCREEN/IS_SCREEN_SETTINGS_LOADING";
const IS_SAVE_SCHEDULE_TIMINGS_LOADING =
  "inventory/screens/SCREEN/IS_SAVE_SCHEDULE_TIMINGS_LOADING";
const IS_UPDATE_SCREEN_STATUS_LOADING = "inventory/screens/SCREEN/IS_UPDATE_SCREEN_STATUS_LOADING";
const IS_ERROR = "inventory/screens/IS_ERROR";
const SCREENS = "inventory/screens/SCREENS";
const SCREEN = "inventory/screens/SCREEN";
const SCREEN_RIGHTS = "inventory/screens/SCREEN/RIGHTS";
const SCREEN_RIGHTS_EDIT = "inventory/screens/SCREEN/RIGHTS/EDIT";
const SCREEN_RATE_CARDS = "inventory/screens/SCREEN/RATE_CARDS";
const SCREEN_PLAYLIST = "inventory/screens/SCREEN/PLAYLIST";
const ASSIGN_PLAYLIST = "inventory/screens/SCREEN/ASSIGN_PLAYLIST";
const SCREEN_SETTINGS = "inventory/screens/SCREEN/SCREEN_SETTINGS";
const SCREEN_SETTINGS_EDIT = "inventory/screens/SCREEN/SCREEN_SETTINGS_EDIT";
const SCREEN_SETTINGS_EDIT_CANCEL = "inventory/screens/SCREEN/SCREEN_SETTINGS_EDIT_CANCEL";

export {
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_ERROR,
  SCREEN,
  SCREENS,
  SCREEN_RIGHTS,
  IS_SCREEN_RIGHTS_LOADING,
  SCREEN_RIGHTS_EDIT,
  IS_SCREEN_RATE_CARDS_LOADING,
  IS_SAVE_SCHEDULE_TIMINGS_LOADING,
  IS_UPDATE_SCREEN_STATUS_LOADING,
  SCREEN_RATE_CARDS,
  SCREEN_PLAYLIST,
  ASSIGN_PLAYLIST,
  IS_SCREEN_SETTINGS_LOADING,
  SCREEN_SETTINGS,
  SCREEN_SETTINGS_EDIT,
  SCREEN_SETTINGS_EDIT_CANCEL,
};
