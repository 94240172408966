import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { isEmpty } from "lodash";
import queryString from "query-string";
import moment from "moment";
import classNames from "classnames";
import {
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  CAMPAIGN_ACTIONS,
  CAMPAIGN_STATUSES,
} from "../../../../../constants";
import EmptyPage from "../../../../common/EmptyPage";
import pageStyles from "../../../../../styles/App.module.scss";
import CampaignTree from "../../CampaignStructure/CampaignTree";
import { bindDispatch } from "../../../../../utils";
import { validateMandatoryMediaFields, campaignStepChange } from "../../utils/campaignCreate";
import { CAMPAIGN_STEPS } from "../../../../../constants";
import Breadcrumb from "../../../../common/BreadCrumb";
import CampaignPageHeader from "../../common/CampaignPageHeader";
import PageLoadingWithTabs from "../../../../common/PageLoadingWithTabs";
import InfoBlockLoading from "../../../../common/InfoBlockLoading";
import CampaignSteps from "../CampaignSteps";
import { getCampaignTree as getCampaignTreeApi } from "../../../../../api";
import campaignFormStyles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import styles from "../../../../../styles/CampaignCreate/TargetGroup.module.scss";
import MediaDetails from "../../common/pages/MediaDetails";
import EmptyState from "../EmptyState";
import BasicDetails from "./BasicDetails";
import PlacementOptions from "./PlacementOptions";
import campaignRouteHandler from "..";

const links = [
  { name: "PageHeader.campaigns", path: "/campaigns" },
  { name: "PageHeader.newMedia" },
];

export class Media extends Component {
  static propTypes = {
    isCampaignInvalid: PropTypes.bool,
  };

  static defaultProps = {
    mediaList: null,
    isCampaignInvalid: false,
  };

  getInitialState = () => ({
    media: {
      name: "",
      targetGroup: {},
      validity: [],
      contentId: "",
      contentTypeId: "",
      durationInSeconds: 0,
      brand: {},
      categories: [],
      categoryIds: [],
      placements: [
        {
          playsPerDay: [],
        },
      ],
      status: CAMPAIGN_STATUSES.DRAFT,
    },
    campaignStructure: {},
    isCampaignStructureLoading: true,
    isCampaignStructureError: false,
    isValidMedia: true,
    errors: [],
    isPageLoading: !this.props.isCampaignInvalid,
  });

  state = this.getInitialState();

  componentDidMount = async () => {
    const {
      isCampaignInvalid,
      match: {
        params: { action },
      },
      campaignCreate: { campaignMedia },
      actions,
      history,
    } = this.props;
    let mediaData = this.state.media;
    const query = queryString.parse(history.location.search);
    await actions.updateActiveStep(CAMPAIGN_STEPS.MEDIA);
    if (isCampaignInvalid) return false;

    await actions.getTimesOfDayList(this.props.match.params);
    await actions.getCategories(this.props.match.params);
    if (action === "create") {
      if (!query?.id) {
        await actions.updateNewCampaignMedia(mediaData);
        await actions.updateMediaAction("create");
      } else {
        await actions.getMediaById(query?.id, "duplicate");
        if (isEmpty(campaignMedia)) {
          this.setState({
            isValidMedia: false,
            isPageLoading: false,
          });
        } else {
          mediaData = campaignMedia;
        }
      }
    } else if (action === "list") {
      await actions.updateMediaAction("list");
    } else {
      await actions.getMediaById(action, "edit");
      if (isEmpty(campaignMedia)) {
        this.setState({
          isValidMedia: false,
          isPageLoading: false,
        });
      } else {
        mediaData = campaignMedia;
      }
    }
    await this.fetchCampaignTreeData();

    this.setState({
      media: mediaData,
      isPageLoading: false,
    });
  };

  componentDidUpdate(prevProps) {
    const {
      match: { params },
      campaignCreate: { mediaAction, campaignMediaList, campaignMedia },
      actions,
      history,
    } = this.props;
    if (
      prevProps.campaignCreate.campaignTargetGroups.data.length !== campaignMediaList.data.length
    ) {
      if (campaignMediaList.data.length === 0 && mediaAction === "list") {
        history.push(`/campaign-create/${params.campaignId}/media/create`);
        actions.updateMediaAction("create");
      }
    }

    if (prevProps.campaignCreate.mediaAction !== mediaAction) {
      if (mediaAction === "list") {
        history.push(`/campaign-create/${params.campaignId}/media/list`);
      }

      if (mediaAction === "edit" || mediaAction === "duplicate") {
        if (mediaAction === "edit" && campaignMedia.id) {
          history.push(`/campaign-create/${params.campaignId}/media/${campaignMedia.id}`);
        }
        this.setState({
          media: campaignMedia,
        });
      }
    }
  }

  fetchCampaignTreeData = () => {
    const {
      match: { params },
    } = this.props;

    getCampaignTreeApi(params.campaignId)
      .then((campaignRes) =>
        this.setState({
          isCampaignStructureLoading: false,
          campaignStructure: campaignRes,
        })
      )
      .catch((err) => {
        this.setState({
          isCampaignStructureLoading: false,
          isCampaignStructureError: true,
          campaignStructure: null,
        });
      });
  };

  onInputChange = (data, key, isDuplicate) => {
    const { media } = this.state;
    let { nameDuplicate } = this.state;
    if (key === "name") {
      media[key] = data;
      nameDuplicate = isDuplicate;
    } else if (key === "targetGroup") {
      media[key] = data;
      media.targetGroupId = data.id;
      media.validity = data.validity;
    } else if (key === "validity") {
      const [fromDate, toDate] = data;
      const validity = {
        fromDate: fromDate
          ? moment(fromDate).format("YYYY-MM-DD")
          : media.validity?.fromDate ?? null,
        toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : media.validity?.toDate ?? null,
      };
      media[key] = validity;
    } else if (key === "brandId") {
      media.brand = data;
      media[key] = data?.id;
    } else if (key === "categoryIds") {
      media.categories.push(data);
      media[key].push(data?.categoryId);
    } else {
      media[key] = data;
    }
    this.setState({
      media,
      nameDuplicate,
      isFormEdited: true,
    });
  };

  handleCampaignDelete = async () => {
    const {
      history,
      match: { params },
    } = this.props;
    await this.props.actions.deleteCampaign(params.campaignId);
    history.push(`/campaign-create`);
    this.setState({
      hasFormSaved: false,
      isFormEdited: false,
    });
  };

  handleAddPlacement = () => {
    const { media } = this.state;
    media.placements.push({
      playsPerDay: [],
    });
    this.setState({
      media: media,
    });
  };

  handleCampaignMediaDelete = (mediaId) => {
    const {
      actions,
      match: { params },
    } = this.props;
    actions.deleteMedia(params, mediaId);
  };

  handleCampaignMediaCreate = () => {
    const {
      match: { params },
      history,
    } = this.props;
    history.push(`/campaign-create/${params.campaignId}/media/create`);
  };

  handleCampaignMediaEdit = (mediaId) => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;
    if (params.campaignId) {
      actions.getMediaById(mediaId, "edit");
      history.push(`/campaign-create/${params.campaignId}/media/${mediaId}`);
    }
  };

  handleCreateMediaDuplicate = (mediaId) => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;

    actions.getMediaById(mediaId, "duplicate");
    history.push(`/campaign-create/${params.campaignId}/media/create?id=${mediaId}`);
  };

  handleFormReset = () => {
    const {
      match: {
        params: { action },
      },
      campaignCreate: { campaignMedia },
    } = this.props;
    if (action !== "list" && action !== "create") {
      this.setState({
        media: campaignMedia,
        hasFormSaved: false,
        isFormEdited: false,
      });
    } else {
      this.setState({
        ...this.getInitialState(),
        isPageLoading: false,
      });
    }
  };

  handleSaveMediaAsDraft = async () => {
    const { media } = this.state;
    const {
      match: { params },
      actions,
    } = this.props;

    if (media.name && media.targetGroupId) {
      media.campaignId = params.campaignId;
      await actions.createOrUpdateMedia(params.campaignId, media.targetGroupId, media, "edit");
    }

    this.setState({
      hasFormSaved: true,
      isFormEdited: false,
    });
  };

  handleChangeStep = (desiredStep) => {
    const {
      match: { params },
      history,
      campaignCreate: { activeStep, mediaAction },
      actions,
    } = this.props;
    const { media } = this.state;
    if (desiredStep === activeStep + 1 && params.campaignId) {
      if (params.action !== "list" && validateMandatoryMediaFields(media, mediaAction)) {
        media.campaignId = params.campaignId;
        actions.createOrUpdateMedia(params.campaignId, media.targetGroupId, media, "list");
      } else {
        history.push(`/campaign-create/${params.campaignId}/review`);
      }
    } else {
      campaignStepChange(history, params, activeStep, desiredStep);
    }

    this.setState({
      hasFormSaved: true,
      isFormEdited: false,
    });
  };

  // TODO: Update URL and action once flow is completed
  handleFormSave = (data) => {
    const { actions } = this.props;
    actions.addTargetGroupMedia(data);
    actions.updateMediaEdit({}, false);
  };

  renderCampaignTree = () => {
    const { isCampaignStructureLoading, isValidMedia, media } = this.state;
    const {
      campaignCreate: { campaign, mediaAction },
    } = this.props;

    if (this.props.isCampaignInvalid || !isValidMedia || mediaAction === "list") return null;

    return (
      <div className={styles.campaignTree}>
        <CampaignTree
          isNewCampaign
          isLoading={isCampaignStructureLoading}
          campaignTreeData={{
            ...campaign,
            targetGroups: [
              {
                ...media.targetGroup,
                media: [media] || [{}],
              },
            ],
          }}
        />
      </div>
    );
  };

  renderMediaContent = () => {
    const {
      isCampaignInvalid,
      timesOfDay: { data: timesOfDay },
      campaignCreate: {
        isMediaLoading,
        campaignMediaList,
        campaignTargetGroups,
        mediaAction,
        campaign,
        isMediaDetailsLoading,
      },
      categories: {
        categories: { data: categoryList },
      },
      actions,
      match: { params },
    } = this.props;
    const { media, isPageLoading, hasFormSaved, isValidMedia } = this.state;
    if (isCampaignInvalid || !isValidMedia)
      return <EmptyState type={isValidMedia ? "campaign" : "media"} />;

    if (isPageLoading) return <InfoBlockLoading />;

    if (campaignMediaList.data?.length > 0 && mediaAction === "list") {
      return (
        <MediaDetails
          params={params}
          isLoading={isMediaLoading}
          withCampaignTree
          isDetailsLoading={isMediaDetailsLoading}
          timesOfDay={timesOfDay}
          mediaList={campaignMediaList.data}
          getMediaDetails={actions.getMediaById}
          onEdit={this.handleCampaignMediaEdit}
          onDuplicate={this.handleCreateMediaDuplicate}
          onDelete={this.handleCampaignMediaDelete}
          onMediaCreate={this.handleCampaignMediaCreate}
        />
      );
    }
    if (!CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(media.status))
      return (
        <EmptyPage
          heading="EmptyState.mediaNotEditableHeading"
          body="EmptyState.mediaNotEditableMessage"
          isHomeLinkVisible={false}
        />
      );
    return (
      <div className={styles.formContainer}>
        <div className={styles.formContent}>
          <BasicDetails
            campaign={campaign}
            hasFormSaved={hasFormSaved}
            categoryList={categoryList}
            mediaList={campaignMediaList.data}
            media={media}
            categorySearch={actions.categorySearch}
            onInputChange={this.onInputChange}
            targetGroups={campaignTargetGroups?.data}
          />
          <PlacementOptions
            timesOfDay={timesOfDay}
            hasFormSaved={hasFormSaved}
            media={media}
            onInputChange={this.onInputChange}
            addNewPlacement={this.handleAddPlacement}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      history,
      campaignCreate: { campaign, isMediaLoading, activeStep, campaignMediaList },
    } = this.props;
    const { hasFormSaved } = this.state;

    if (isMediaLoading) return <PageLoadingWithTabs />;

    return (
      <div className={`col-12 clearfix ${styles.pageWrapper}`}>
        <div className={styles.container}>
          <Breadcrumb history={history} links={links} />
          <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
            <div className={campaignFormStyles.campaignCreateProgressBar}>
              <CampaignPageHeader
                title="PageHeader.newMedia"
                onSaveCampaignAsDraft={this.handleSaveMediaAsDraft}
                onDiscardCampaign={this.handleCampaignDelete}
                isDiscardDisabled={!campaign.id}
              />
              <CampaignSteps
                activeStep={activeStep}
                validTillStep={campaignMediaList.data.length > 0 ? activeStep + 2 : activeStep}
                hasFormSaved={hasFormSaved}
                onChangeStep={this.handleChangeStep}
                onSaveDraft={this.handleSaveMediaAsDraft}
                onDiscard={this.handleFormReset}
                isAdminUser={this.validateCreateCampaignAccess}
              />
            </div>
            <div className={classNames("col col-12", campaignFormStyles.pageContent)}>
              {this.renderMediaContent()}
              {this.renderCampaignTree()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.userData,
  (state) => state.campaignCreate,
  (state) => state.timesOfDay,
  (state) => state.categories,
  (userData, campaignCreate, timesOfDay, categories) => ({
    userData: userData.user,
    campaignCreate,
    timesOfDay,
    categories,
  })
);

export default connect(mapStateToProps, bindDispatch)(campaignRouteHandler(Media));
