import React, { Component } from "react";
import classNames from "classnames";
import { isEmpty, groupBy } from "lodash";
import { Icons } from "prefab";

import InfoBlock from "../../../common/InfoBlock";
import { SCOPE_ACTIONS, SCOPES, THEATRE_CRITERIA_TYPES } from "../../../../constants";
import styles from "../../../../styles/CampaignCreate/CriteriaList.module.scss";

const {
  EditIcon,
  AddIcon,
  RemoveIcon,
  CancelFilledIcon,
  AddRoundedIcon,
  CancelRoundedIcon,
} = Icons;

export default class CriteriaList extends Component {
  state = {
    isEdit: false,
    criteriaList: {},
  };

  static defaultProps = {
    withEdit: true,
    countries: [],
  };
  componentDidMount() {
    this.setState({
      criteriaList: this.props.criteria,
    });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.criteria !== this.props.criteria) {
      this.setState({
        criteriaList: this.props.criteria,
      });
    }
  };

  handleEditCriteria = () => {
    this.setState({
      isEdit: true,
    });
  };

  handleCancelChanges = () => {
    this.setState({
      isEdit: false,
      criteriaList: this.props.criteria,
    });
  };

  handleSaveChanges = () => {
    const { criteriaList } = this.state;
    if (this.props.onRemoveCriteriaItem) {
      this.props.onRemoveCriteriaItem(criteriaList);
    }
    this.setState({
      isEdit: false,
    });
  };

  handleRemoveCriteriaItem = (id, listType) => {
    const { criteriaList } = this.state;

    this.setState({
      criteriaList: {
        ...criteriaList,
        [listType]: criteriaList[listType].filter((item) => item.id !== id),
      },
    });
  };

  getRightSideButtons = () => {
    if (isEmpty(this.state.criteriaList)) return [];

    if (this.state.isEdit) {
      return [
        {
          id: "cancel",
          scope: SCOPES.CAMPAIGNS,
          scopeAction: SCOPE_ACTIONS.WRITE,
          text: "Button.cancel",
          onClick: this.handleCancelChanges,
          className: styles.cancelButton,
        },
        {
          id: "save",
          scope: SCOPES.CAMPAIGNS,
          scopeAction: SCOPE_ACTIONS.WRITE,
          text: "Button.done",
          onClick: this.handleSaveChanges,
          icon: <AddIcon />,
          className: styles.saveButton,
        },
      ];
    }

    return [
      {
        id: "edit",
        scope: SCOPES.CAMPAIGNS,
        scopeAction: SCOPE_ACTIONS.WRITE,
        text: "Button.edit",
        onClick: this.handleEditCriteria,
        icon: <EditIcon />,
        className: styles.editButton,
      },
    ];
  };

  validateCriteriaData = () => {
    const { criteria, targetGroupType } = this.props;

    return (
      isEmpty(criteria?.inclusions?.filter((data) => data.targetGroupType === targetGroupType)) &&
      isEmpty(criteria?.exclusions?.filter((data) => data.targetGroupType === targetGroupType))
    );
  };

  renderCriteriaName = (data) => {
    const { TICKET_PRICE, SEATING_CAPACITY, AUDITORIUM_DIMENSIONS } = THEATRE_CRITERIA_TYPES;
    if (data.targetGroupType === "theatre" && data) {
      if ([TICKET_PRICE, SEATING_CAPACITY].includes(data.type)) {
        return `${data.rangeFrom} to ${data.rangeTo}`;
      } else if (data.type === AUDITORIUM_DIMENSIONS) {
        const { rangeFrom: minLength, rangeTo: maxLength } = data.auditoriumLength;
        const { rangeFrom: minWidth, rangeTo: maxWidth } = data.auditoriumWidth;
        const { rangeFrom: minHeight, rangeTo: maxHeight } = data.auditoriumHeight;
        return `${minLength || 0} x ${minWidth || 0} x ${minHeight || 0} To ${
          maxLength || "max"
        } x ${maxWidth || "max"} x ${maxHeight || "max"}`;
      }
    }

    return data.displayName ? data.displayName : data.name;
  };

  renderItemList = (criteria, icon, criteriaListType, index) => {
    const { isEdit } = this.state;
    const iconClassName = classNames(styles.icon, { [styles.editIcon]: isEdit });

    return (
      <div className={styles.itemContent} key={index || criteria.id}>
        <div
          className={iconClassName}
          onClick={
            isEdit ? () => this.handleRemoveCriteriaItem(criteria.id, criteriaListType) : null
          }
        >
          {isEdit ? <CancelFilledIcon width={16} height={16} /> : icon}
        </div>
        <div className={styles.displayName}>{this.renderCriteriaName(criteria)}</div>
      </div>
    );
  };

  renderListOfCriteriaItems = (filteredList, groupedData, icon, listType) => {
    const { targetGroupType } = this.props;
    if (targetGroupType === "location") {
      if (filteredList.length > 0) {
        return filteredList.map((data, index) => this.renderItemList(data, icon, listType, index));
      }
      return <span>Nil</span>;
    }

    if (isEmpty(groupedData)) return <span>Nil</span>;

    return Object.keys(groupedData).map((criteria, index) => (
      <div key={index}>
        <div className={styles.itemHeader}>{groupedData[criteria][0].typeDisplayName}</div>
        <div className={styles.itemWrapper}>
          {groupedData[criteria].map((item) => this.renderItemList(item, icon, listType, index))}
        </div>
      </div>
    ));
  };

  renderInclusionList = () => {
    const { criteriaList } = this.state;
    const { targetGroupType } = this.props;
    if (isEmpty(criteriaList?.inclusions)) return null;

    const filteredList = criteriaList.inclusions.filter(
      (data) => data.targetGroupType === targetGroupType
    );
    const groupedInclusions = groupBy(filteredList, "type");

    return (
      <>
        <div className={styles.header}>
          <div className={styles.icon}>
            <AddIcon />
          </div>
          <div className={styles.title}>Inclusions</div>
        </div>
        <div className={styles.items}>
          {this.renderListOfCriteriaItems(
            filteredList,
            groupedInclusions,
            <AddRoundedIcon />,
            "inclusions"
          )}
        </div>
      </>
    );
  };

  renderExclusionList = () => {
    const { criteriaList } = this.state;
    const { targetGroupType } = this.props;

    if (isEmpty(criteriaList?.exclusions)) return null;

    const filteredList = criteriaList.exclusions.filter(
      (data) => data.targetGroupType === targetGroupType
    );
    const groupedExclusions = groupBy(filteredList, "type");

    return (
      <>
        <div className={styles.header}>
          <div className={classNames(styles.icon, styles.exclusion)}>
            <RemoveIcon />
          </div>
          <div className={styles.title}>Exclusions</div>
        </div>
        <div className={styles.items}>
          {this.renderListOfCriteriaItems(
            filteredList,
            groupedExclusions,
            <CancelRoundedIcon />,
            "exclusions"
          )}
        </div>
      </>
    );
  };

  render() {
    const { countries, criteria, headerName, headerIcon, withEdit, targetGroupType } = this.props;

    if (this.validateCriteriaData() && countries.length === 0) return null;

    return (
      <div className={styles.container}>
        <InfoBlock
          header={headerName}
          headerIcon={headerIcon}
          rightSideButtons={criteria && withEdit ? this.getRightSideButtons() : []}
          rightSideContainerClass={styles.actions}
        >
          {!isEmpty(countries) && targetGroupType === "location" && (
            <div className={styles.countries}>
              <div className={styles.title}>Country :</div>
              <div className={styles.list}>
                {countries.map((country) => country.name).join(", ")}
              </div>
            </div>
          )}
          <div className={styles.criteriaWrapper}>
            <div className={styles.criteriaItem}>{this.renderInclusionList()}</div>
            <div className={styles.criteriaItem}>{this.renderExclusionList()}</div>
          </div>
        </InfoBlock>
      </div>
    );
  }
}
