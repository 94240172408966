import * as types from "../types/reports";

const initialState = {
  isLoading: true,
  isError: null,
  adminGenerated: {},
  userGenerated: {},
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.ADMIN_GENERATED:
      return { ...state, adminGenerated: payload };
    case types.USER_GENERATED:
      return { ...state, userGenerated: payload };
    default:
      return state;
  }
}
