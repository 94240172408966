import React from "react";
import ReactDOM from "react-dom";
import { Classes } from "@blueprintjs/core";
import { BaseButton } from "prefab";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { compact, flatten } from "lodash";
import { BUTTON_TYPE } from "prefab";
import styles from "../../styles/RightPanel.module.scss";
import { intlKeyPropType } from "../../utils";
import ButtonStyles from "../../styles/Button.module.scss";
import LocalizedButton from "./LocalizedButton";
import RightPaneLoading from "./RightPaneFormLoading";

const portalRoot = document.getElementById("right-panel-portal");

export default class RightPanel extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.escFunction = this.escFunction.bind(this);
  }

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    showFooter: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    header: intlKeyPropType.isRequired,
    isLoading: PropTypes.bool,
    hideOverlay: PropTypes.bool,
  };

  static defaultProps = {
    isOpen: false,
    showFooter: false,
    isLoading: false,
    hideOverlay: false,
  };

  componentDidMount() {
    portalRoot.appendChild(this.el);
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    portalRoot.removeChild(this.el);
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.onClose();
    }
  }

  renderPrimaryButtons(buttonProps) {
    const buttons = flatten(compact([buttonProps]));
    return buttons.map(
      ({ buttonType = BUTTON_TYPE.PRIMARY, isHidden, loading, ...primaryButtonProp }, index) => {
        if (isHidden) return null;
        return (
          <LocalizedButton
            key={index}
            className={classNames(
              `flex add`,
              ButtonStyles.buttonSpacing,
              [Classes.SKELETON] && loading
            )}
            buttonType={buttonType}
            onClick={primaryButtonProp.onClick}
            text={primaryButtonProp.text}
            disabled={primaryButtonProp.disabled}
          />
        );
      }
    );
  }

  renderFooter() {
    const { isLoading, primaryButtonProps, secondaryButtonProps } = this.props;
    return (
      <div className={classNames(`flex flex-auto`, { [Classes.SKELETON]: isLoading })}>
        {this.renderPrimaryButtons(primaryButtonProps)}
        {secondaryButtonProps && (
          <LocalizedButton
            className={classNames("flex")}
            onClick={secondaryButtonProps.onClick}
            buttonType={BUTTON_TYPE.SECONDARY}
            text={secondaryButtonProps.text}
            disabled={secondaryButtonProps.disabled}
            loading={secondaryButtonProps.loading}
          />
        )}
      </div>
    );
  }

  renderPanelView() {
    const {
      isOpen,
      children,
      onClose,
      header,
      showFooter,
      isLoading,
      hideOverlay,
      className,
    } = this.props;
    if (isOpen) {
      return (
        <div>
          {!hideOverlay && <div className={styles.overlay}></div>}
          <div className={styles.container}>
            <div className={styles.header}>
              {header && (
                <FormattedMessage id={header} defaultMessage={header}>
                  {([header]) => (
                    <div
                      className={classNames("flex text-ellipsize", {
                        [Classes.SKELETON]: isLoading,
                      })}
                      title={header}
                    >
                      {header}
                    </div>
                  )}
                </FormattedMessage>
              )}

              <BaseButton
                iconName="CancelIcon"
                buttonType={BUTTON_TYPE.SECONDARY}
                onClick={onClose}
              />
            </div>
            <div
              className={classNames(className, styles.wrapper, {
                [styles.wrapperWithFooter]: showFooter,
              })}
            >
              {isLoading ? <RightPaneLoading /> : children}
            </div>
            {showFooter && <div className={styles.footer}>{this.renderFooter()}</div>}
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return ReactDOM.createPortal(this.renderPanelView(), this.el);
  }
}
