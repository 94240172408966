import {
  searchCountries as searchCountriesApi,
  getActiveCountriesCount,
  saveCountryCertificate,
  getCertificationsByCountry,
  getCertificationById,
  fetchTaxById,
  fetchCountry,
  fetchCountryTaxes,
  saveTax,
  syncCountriesWithQubeCinemas,
} from "../../api";
import * as types from "../types/countries";
import * as constants from "../../constants";

import { showToast } from "../../utils";

const updateCountries = (payload) => {
  return { type: types.DATA, payload: payload };
};

const actionCreator = (action) => {
  return action;
};

const updateCountryCertifications = (payload) => {
  return { type: types.CERTIFICATIONS, payload: payload };
};

const updateCountry = (payload) => {
  return { type: types.COUNTRY, payload: payload };
};

const updateActiveCountries = (payload) => ({ type: types.ACTIVE_COUNTRY, payload });

const updateTax = (payload) => {
  return {
    type: types.TAX,
    payload: payload,
  };
};

const updateCertification = (payload) => {
  return {
    type: types.CERTIFICATION,
    payload: payload,
  };
};

const addTax = () => (dispatch) =>
  dispatch(
    updateTax({
      isOpen: true,
      tax: { code: "", taxPercentage: 0, name: "", description: "", validFrom: "" },
    })
  );

const editTax = (data) => (dispatch) => dispatch(updateTax({ tax: data, isOpen: true }));

const cancelTaxEdit = () => (dispatch) => dispatch(updateTax({ tax: {}, isOpen: false }));

const editTaxById = (countryId, taxId, get = fetchTaxById) => {
  return async (dispatch) => {
    try {
      const { data } = await get(countryId, taxId);
      dispatch(editTax(data));
    } catch (error) {
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error.message }));
    }
  };
};

const addCertification = () => (dispatch) =>
  dispatch(
    updateCertification({
      isOpen: true,
      certification: {
        certificate: {},
        hasEditError: false,
      },
    })
  );

const editCertification = (data) => (dispatch) =>
  dispatch(updateCertification({ certification: data, isOpen: true }));

const cancelCertificationEdit = () => (dispatch) =>
  dispatch(updateCertification({ certification: {}, isOpen: false }));

const editCertificationById = (countryId, contentTypeId, get = getCertificationById) => {
  return async (dispatch) => {
    try {
      const { data } = await get(countryId, contentTypeId);
      dispatch(editCertification(data));
    } catch (error) {
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error.message }));
    }
  };
};

const getActiveCountries = () => async (dispatch) => {
  const { data } = await getActiveCountriesCount();
  dispatch(updateActiveCountries(data));
};

const getCountries = (
  params = {},
  filters = [],
  listCountries = searchCountriesApi,
  isPartial = true
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc" } = params;
  return async (dispatch) => {
    isPartial
      ? dispatch(actionCreator({ type: types.IS_PARTIAL_LOADING, payload: true }))
      : dispatch(actionCreator({ type: types.IS_LOADING, payload: true }));
    try {
      const { data } = await listCountries(ps, (page - 1) * ps, sort, filters);
      dispatch(updateCountries(data));
      isPartial
        ? dispatch(actionCreator({ type: types.IS_PARTIAL_LOADING, payload: false }))
        : dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
    } catch (error) {
      isPartial
        ? dispatch(actionCreator({ type: types.IS_PARTIAL_LOADING, payload: false }))
        : dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));

      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

const fetchCountryCertificate = (countryId, fetch = getCertificationsByCountry) => {
  return async (dispatch) => {
    dispatch(actionCreator({ type: types.IS_LOADING, payload: true }));
    try {
      const { data } = await fetch(countryId);
      dispatch(updateCountryCertifications(data));
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
    } catch (error) {
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

const createOrUpdateCertificate = (
  countryId,
  certificate,
  callback,
  save = saveCountryCertificate,
  fetch = getCertificationsByCountry
) => {
  return async (dispatch) => {
    dispatch(actionCreator({ type: types.IS_LOADING, payload: true }));
    try {
      await save(countryId, certificate);
      const { data } = await fetch(countryId);
      dispatch(actionCreator({ type: types.CERTIFICATIONS, payload: data }));
      showToast(
        certificate.updatedAt
          ? "Toast.certificationUpdatedSuccess"
          : "Toast.certificationCreatedSuccess",
        true
      );
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
      callback();
    } catch (error) {
      showToast(error.response.data.message, false);
      callback();
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

const createOrUpdateTax = (countryId, tax, callback, save = saveTax, get = fetchCountryTaxes) => {
  return async (dispatch) => {
    dispatch(actionCreator({ type: types.IS_LOADING, payload: true }));
    try {
      await save(countryId, tax);
      const { data } = await get(countryId);
      dispatch(actionCreator({ type: types.TAXES, payload: data }));
      showToast(tax.id ? "Toast.taxUpdatedSuccess" : "Toast.taxCreatedSuccess", true);
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
      callback();
    } catch (error) {
      showToast(error.response.data.message, false);
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

const getCountry = (countryId, get = fetchCountry) => {
  return async (dispatch) => {
    dispatch(actionCreator({ type: types.IS_LOADING, payload: true }));
    try {
      const { data } = await get(countryId);
      dispatch(updateCountry(data));
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
    } catch (error) {
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

const getCountryTaxes = (countryId, get = fetchCountryTaxes) => {
  return async (dispatch) => {
    dispatch(actionCreator({ type: types.IS_LOADING, payload: true }));
    try {
      const { data } = await get(countryId);
      dispatch(actionCreator({ type: types.TAXES, payload: data }));
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
    } catch (error) {
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

const syncCountries = (callback, sync = syncCountriesWithQubeCinemas) => {
  return async () => {
    try {
      const { data } = await sync();
      showToast(data.message, true);
      callback();
    } catch (error) {
      showToast(error.response.data.message, false);
      callback();
    }
  };
};

export {
  updateCountries,
  getCountries,
  getActiveCountries,
  fetchCountryCertificate,
  createOrUpdateCertificate,
  updateCountryCertifications,
  getCountry,
  getCountryTaxes,
  createOrUpdateTax,
  syncCountries,
  addTax,
  editTaxById,
  cancelTaxEdit,
  addCertification,
  editCertification,
  editCertificationById,
  cancelCertificationEdit,
};
