import * as types from "../types/theatres";

const initialState = {
  isLoading: false,
  isPartialLoading: false,
  isTheatreScreensLoading: false,
  isTheatreViewLoading: true,
  isTheatreRightsLoading: false,
  isTheatreRateCardsLoading: false,
  isError: null,
  theatres: {
    data: [],
  },
  theatre: {},
  theatreScreens: [],
  theatrePlaylist: {
    data: [],
  },
  theatreRights: {
    data: [],
  },
  theatreRightsEdit: {
    rights: [],
    screens: [],
    companies: [],
    isOpen: false,
    right: {},
  },
  theatreRateCards: {
    data: [],
  },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case types.IS_THEATRE_SCREENS_LOADING:
      return { ...state, isTheatreScreensLoading: payload };
    case types.IS_THEATRE_VIEW_LOADING:
      return { ...state, isTheatreViewLoading: payload };
    case types.IS_THEATRE_RIGHTS_LOADING:
      return { ...state, isTheatreRightsLoading: payload };
    case types.IS_THEATRE_RATE_CARDS_LOADING:
      return { ...state, isTheatreRateCardsLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.THEATRES:
      return { ...state, theatres: payload };
    case types.THEATRE:
      return { ...state, theatre: payload };
    case types.THEATRE_SCREENS:
      return { ...state, theatreScreens: payload };
    case types.THEATRE_PLAYLIST:
      return { ...state, theatrePlaylist: payload };
    case types.THEATRE_RIGHTS:
      return { ...state, theatreRights: payload };
    case types.THEATRE_RIGHTS_EDIT:
      return { ...state, theatreRightsEdit: payload };
    case types.THEATRE_RATE_CARDS:
      return { ...state, theatreRateCards: payload };
    default:
      return state;
  }
}
