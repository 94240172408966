import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import classNames from "classnames";
import { Icons } from "prefab";
import {
  CAMPAIGN_STATUS_LIST,
  CAMPAIGN_ACTIONS,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  SCOPES,
  SCOPE_ACTIONS,
  CAMPAIGN_STATUSES,
} from "../../../../constants";
import PageLoadingWithTabs from "../../../common/PageLoadingWithTabs";
import { bindDispatch, getCampaignColorClassName } from "../../../../utils";
import { getCurrentActionPeriod } from "../utils";
import { getCampaignTree as getCampaignTreeApi } from "../../../../api";
import styles from "../../../../styles/Campaigns/Campaigns.module.scss";
import ButtonStyle from "../../../../styles/Button.module.scss";
import pageStyles from "../../../../styles/App.module.scss";
import CancelMedia from "../bulkActions/CancelCampaigns";
import PageHeader from "../../../common/PageHeader";
import Breadcrumb from "../../../common/BreadCrumb";
import TabView from "../../../common/Tabs";
import CampaignTree from "../CampaignStructure/CampaignTree";
import PauseOrResumeActions from "../../campaigns/bulkActions/PauseOrResumeActions";
import MediaSummary from "./tabs/MediaSummary";
import mediaDetails from "./tabs/mediaDetails";
import MediaTheatres from "./tabs/MediaTheatres";

const { ContentIcon, CancelIcon, PauseIcon, PlayIcon, EditIcon } = Icons;
const _LINKS = [{ name: "PageHeader.campaigns", path: "/campaigns/campaigns" }];
export class MediaView extends Component {
  state = {
    isCampaignStructureLoading: true,
    campaignStructure: {},
    isCampaignStructureError: false,
    openedBulkAction: null,
  };

  componentDidMount = async () => {
    await this.getMediaDetails();
    await this.fetchCampaignTreeData();
  };

  getMediaDetails = () => {
    const {
      match: { params },
      actions,
    } = this.props;
    actions.getMediaDetails(params);
    actions.getTimesOfDayList();
  };

  fetchCampaignTreeData = () => {
    const {
      match: { params },
    } = this.props;
    getCampaignTreeApi(params.mediaId, "media")
      .then((campaignRes) =>
        this.setState({
          isCampaignStructureLoading: false,
          campaignStructure: campaignRes,
        })
      )
      .catch((err) => {
        this.setState({
          isCampaignStructureLoading: false,
          isCampaignStructureError: true,
          campaignStructure: null,
        });
      });
  };

  getStatusDetails = (status) => {
    return CAMPAIGN_STATUS_LIST.find((item) => item.id === status);
  };

  renderTabWithCampaignStructure = (tabPanel) => {
    const { isCampaignStructureLoading, campaignStructure, isCampaignStructureError } = this.state;

    if (isCampaignStructureError) return tabPanel;

    return (
      <div className={classNames(pageStyles.pageContainer, styles.tabContentWrapper)}>
        <div className={styles.tabPanel}>{tabPanel}</div>
        <CampaignTree isLoading={isCampaignStructureLoading} campaignTreeData={campaignStructure} />
      </div>
    );
  };

  openBulkAction = (bulkAction) => {
    this.setState({ openedBulkAction: bulkAction });
  };

  closeBulkAction = () => {
    this.setState({ openedBulkAction: null });
  };
  render = () => {
    const {
      history,
      media: { media, isLoading },
      timesOfDay: { data: timesOfDay },
      match: { params },
      userData,
    } = this.props;
    const { openedBulkAction } = this.state;
    if (isLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={2} />;
    }

    const statusDetails = this.getStatusDetails(media.status);
    const isPaused = CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
      media.status
    );
    const isEdit = media.statusChanges?.length > 0;
    const mediaHeader =
      isEdit && isPaused
        ? "RightPanelHeader.editPauseMedia"
        : isEdit
        ? "RightPanelHeader.editResumeMedia"
        : isPaused
        ? "RightPanelHeader.pauseMedia"
        : "RightPanelHeader.resumeMedia";

    return (
      <div className="col-12 clearfix">
        <div className="col-12 clearfix">
          <Breadcrumb
            isLoading={isLoading}
            history={history}
            links={[
              ..._LINKS,
              {
                name: `${media.campaignName}`,
                path: `/campaigns/campaigns/${media.campaignId}/details`,
              },
              {
                name: `${media.targetGroupName}`,
                path: `/campaigns/target-groups/${media.targetGroupId}`,
              },
              { name: `${media.name}` },
            ]}
          />
        </div>
        <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
          <PageHeader
            name={`${media.name}`}
            headerIcon={<ContentIcon />}
            customBadge={
              statusDetails && (
                <div
                  className={`${styles.statusBadge} ${getCampaignColorClassName(
                    statusDetails.color
                  )}`}
                >
                  {statusDetails.displayName}
                </div>
              )
            }
            buttons={[
              {
                text: "Button.edit",
                onClick: () =>
                  history.push(`/campaign-create/${media.campaignId}/media/${media.id}`),
                className: `${styles.cancel} ${ButtonStyle.addButton}`,
                isIconRight: false,
                icon: <EditIcon />,
                isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(
                  media.status
                ),
                checkScope: true,
                scope: SCOPES.CAMPAIGNS,
                scopeAction: SCOPE_ACTIONS.WRITE,
                userData,
              },
              {
                text: "Button.pause",
                onClick: () => this.openBulkAction(CAMPAIGN_ACTIONS.PAUSE),
                className: `${ButtonStyle.addButton} ${styles.pauseButton}`,
                minimal: true,
                icon: <PauseIcon />,
                isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
                  media.status
                ),
                checkScope: true,
                scope: SCOPES.CAMPAIGNS,
                scopeAction: SCOPE_ACTIONS.WRITE,
                userData,
              },
              {
                text: "Button.resume",
                onClick: () => this.openBulkAction(CAMPAIGN_ACTIONS.RESUME),
                className: ButtonStyle.addButton,
                icon: <PlayIcon />,
                isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.RESUME].includes(
                  media.status
                ),
                checkScope: true,
                scope: SCOPES.CAMPAIGNS,
                scopeAction: SCOPE_ACTIONS.WRITE,
                userData,
              },
              {
                text: "Button.cancel",
                onClick: () => this.openBulkAction(CAMPAIGN_ACTIONS.CANCEL),
                className: ` ${styles.cancel} ${ButtonStyle.addButton}`,
                isIconRight: false,
                icon: <CancelIcon />,
                isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.CANCEL].includes(
                  media.status
                ),
                checkScope: true,
                scope: SCOPES.CAMPAIGNS,
                scopeAction: SCOPE_ACTIONS.CANCEL,
                userData,
              },
            ]}
            isLoading={isLoading}
          />
          <div className={styles.tabsContainer}>
            <TabView
              id="targetGroupTabs"
              selectedTabId={params.tabId}
              defaultSelectedTabId="details"
              tabs={[
                {
                  id: "summary",
                  title: "Summary",
                  goto: `/campaigns/media/${media.id}/summary`,
                  panel: this.renderTabWithCampaignStructure(<MediaSummary mediaId={media.id} />),
                },
                {
                  id: "details",
                  title: "Details",
                  goto: `/campaigns/media/${media.id}/details`,
                  panel: this.renderTabWithCampaignStructure(
                    mediaDetails(media, timesOfDay, userData)
                  ),
                },
                {
                  id: "theatres",
                  title: "Theatres",
                  goto: `/campaigns/media/${media.id}/theatres/theatres`,
                  panel: <MediaTheatres />,
                },
              ]}
            />
          </div>
        </div>
        {(openedBulkAction === CAMPAIGN_ACTIONS.RESUME ||
          openedBulkAction === CAMPAIGN_ACTIONS.PAUSE) && (
          <PauseOrResumeActions
            period={getCurrentActionPeriod(media, openedBulkAction)}
            header={mediaHeader}
            type={openedBulkAction}
            isPaused={isPaused}
            isEdit={isEdit}
            showEdit={media.status !== CAMPAIGN_STATUSES.CONSUMED}
            selected={[media]}
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            history={history}
            onPauseOrResumeAction={(period) => {
              this.props.actions.pauseOrResumeMedia({
                ...period,
                ...{
                  ids: [media.id],
                  mediaId: media.id,
                  campaignId: media.campaignId,
                },
                status: openedBulkAction,
              });
            }}
          />
        )}
        {openedBulkAction === CAMPAIGN_ACTIONS.CANCEL && (
          <CancelMedia
            selectionInfo={[]}
            header="RightPanelHeader.cancelMedia"
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            cancelCampaigns={(data) => {
              this.props.actions.cancelMedia(
                {
                  ...data,
                  ...{
                    ids: [media.id],
                    mediaId: media.id,
                  },
                },
                this.closeBulkAction
              );
            }}
          />
        )}
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.media,
  (state) => state.timesOfDay,
  (state) => state.userData,
  (media, timesOfDay, userData) => ({ media, timesOfDay, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(MediaView);
