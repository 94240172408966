import React, { Component } from "react";
import queryString from "query-string";
import moment from "moment";
import { Table } from "workbench";
import { Icons } from "prefab";
import { modifyTableColumns, checkScopes, locationColumn, getParams } from "../../../../utils";
import checkScopeAuth from "../../../../utils/checkScopeAuth";
import {
  CAMPAIGN_TYPE,
  CAMPAIGN_ACTIONS,
  CAMPAIGN_STATUSES,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  DEFAULT_ACTION_ICON_COLUMN,
  TAG_TYPE,
  SCOPE_ACTIONS,
  SCOPES,
  DEFAULT_PAGE_SIZE,
} from "../../../../constants";
import { getStatus } from "../utils";
import ActionIcon from "../../../common/ActionIcon";
import FilterChips from "../../../common/Filters/FilterChips";
import BulkActionButtons from "../../../common/BulkActionButtons";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";
import { ActiveTableCell } from "../../../common/ActiveTableCell";
import CancelCampaignTargetGroup from "../bulkActions/CancelCampaigns";
import PauseOrResumeActions from "../bulkActions/PauseOrResumeActions";
import ActionDetails from "../actions/ActionDetails";
import styles from "../../../../styles/Campaigns/Screens.module.scss";

const { PlayIcon, PauseIcon } = Icons;

class ScreensList extends Component {
  state = {
    selectedColumns: [
      "Screen · Theatre",
      "Chain",
      "Location",
      "Actual Reach",
      "Estimated Reach",
      "Priority",
      "Status",
    ],
    selection: [],
    currentScreen: {},
    openedBulkAction: null,
    isActionDetailOpen: false,
  };

  componentDidMount = () => {
    const {
      match: { params },
      targetId,
    } = this.props;
    this.props.fetchData(null, false, [{ id: targetId, targetType: params.action }]);
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  onRowSelect = (selection) => this.setState({ selection });

  closeBulkAction = () => {
    this.setState({ openedBulkAction: null });
  };

  handleActionDetailsPane = () => {
    this.setState({ isActionDetailOpen: true });
  };

  closeActionDetails = () => {
    this.setState({ isActionDetailOpen: false });
  };

  openBulkAction = (bulkAction) => {
    this.setState({ openedBulkAction: bulkAction });
  };
  validateScreensAction = (actionType) => {
    const { selection } = this.state;
    const { screens } = this.props;
    const selectedScreens = screens.data
      ? screens?.data.filter(
          (screen) =>
            selection.includes(screen.id) &&
            CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[actionType].includes(screen.status)
        )
      : [];
    return selectedScreens?.length === selection.length;
  };

  handleCampaignScreenAction = (action, props) => {
    this.setState({
      openedBulkAction: action,
      currentScreen: props,
    });
  };
  getCurrentActionPeriod = () => {
    const {
      campaigns: { campaign },
    } = this.props;

    if (campaign.statusChanges.length === 0)
      return {
        from: new Date(),
      };

    return campaign.statusChanges.find(
      (item) =>
        item.status === this.state.openedBulkAction &&
        moment(item.toDate, "YYYY-MM-DD").isAfter(moment.now())
    );
  };

  renderIconColumn = (props) => {
    return (
      <ActionIcon
        iconProps={[
          {
            id: "info",
            toolTip: "Tooltip.info",
            iconName: "InfoFilledIcon",
            toolTipClass: styles.infoIconTooltip,
            onClick: () => this.handleActionDetailsPane(),
          },
          {
            toolTip: "Tooltip.moreActions",
            iconName: "MoreVerticalIcon",
            dropdown: checkScopes(
              [
                {
                  text: "Pause",
                  onClick: () => this.handleCampaignScreenAction(CAMPAIGN_ACTIONS.PAUSE, props),
                  hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                    CAMPAIGN_ACTIONS.PAUSE
                  ].includes(props.status),
                  icon: <PauseIcon />,
                  scope: SCOPES.CAMPAIGNS,
                  scopeAction: SCOPE_ACTIONS.WRITE,
                },
                {
                  text: "Resume",
                  onClick: () =>
                    this.setState({
                      openedBulkAction: CAMPAIGN_ACTIONS.RESUME,
                      currentScreen: props,
                    }),
                  hideMenuItem: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                    CAMPAIGN_ACTIONS.RESUME
                  ].includes(props.status),
                  icon: <PlayIcon />,
                  scope: SCOPES.CAMPAIGNS,
                  scopeAction: SCOPE_ACTIONS.WRITE,
                },
              ],
              this.props.userData
            ),
          },
        ]}
      />
    );
  };

  render = () => {
    const {
      history,
      isTabLoading,
      screens,
      match: { params },
      campaigns: { campaign },
      filters,
      targetId,
      userData,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;
    const {
      selectedColumns,
      reorderedColumns,
      selection,
      openedBulkAction,
      currentScreen,
      isActionDetailOpen,
    } = this.state;

    const query = queryString.parse(history.location.search);
    const data = screens.data || [];
    const selectionInfo = {
      count: selection.length,
      label: selection.length === 1 ? "Screen" : "Screens",
    };
    const selectedScreenData =
      selection.length > 0
        ? screens?.data.filter((row) => selection.includes(row.id))
        : [currentScreen];
    const isPaused = this.validateScreensAction(CAMPAIGN_ACTIONS.PAUSE);
    const isEdit = campaign.statusChanges?.find((action) => action.status === openedBulkAction);
    const screenActionHeader =
      isEdit && isPaused
        ? "RightPanelHeader.editPauseScreen"
        : isEdit
        ? "RightPanelHeader.editResumeScreen"
        : isPaused
        ? "RightPanelHeader.pauseScreen"
        : "RightPanelHeader.resumeScreen";
    const columns = checkScopes(
      [
        {
          id: "name",
          Header: "Screen · Theatre",
          accessor: (d) => (
            <>
              {d.name} ·
              {ActiveTableCell(d.theatreId, d.theatreName, () =>
                this.props.onFilterIdSelect(d.theatreId)
              )}
            </>
          ),
        },
        {
          id: "chainName",
          Header: "Chain",
          accessor: (d) =>
            ActiveTableCell(d.chainId, d.chainName, () => this.props.onFilterIdSelect(d.chainId)),
        },
        locationColumn(this.props.onFilterIdSelect),
        {
          id: "startDate",
          Header: "Start Date",
          accessor: (d) => moment(d.statusChanges.fromDate).format("MMM DD, YYYY"),
        },
        {
          id: "endDate",
          Header: "End Date",
          accessor: (d) => moment(d.statusChanges.toDate).format("MMM DD, YYYY"),
        },

        {
          id: "reach",
          Header: "Max Plays Per Screen",
        },
        {
          id: "reach",
          Header: "Max Plays for the Campaign",
          accessor: "-",
        },
        {
          id: "pendingPlays",
          Header: "Pending Plays",
          accessor: "pendingPlays",
        },
        {
          id: "playedPlays",
          Header: "Played",
          accessor: "playedPlays",
        },
        {
          id: "failedPlays",
          Header: "Failed",
          accessor: "failedPlays",
        },
        {
          id: "actualReach",
          Header: "Actual Reach",
          accessor: "actualReach",
        },
        {
          id: "estimatedReach",
          Header: "Estimated Reach",
          accessor: "estimatedReach",
        },
        {
          id: "status",
          Header: "Status",
          accessor: (d) =>
            d.status &&
            ActiveTableCell(d.statusTag, getStatus(d), () =>
              this.props.onFilterIdSelect(d.statusTag)
            ),
        },
        {
          ...DEFAULT_ACTION_ICON_COLUMN,
          width: 96,
          scope: SCOPES.CAMPAIGNS,
          scopeAction: SCOPE_ACTIONS.READ,
          accessor: (props) => this.renderIconColumn(props),
        },
      ],
      userData
    );
    return (
      <div className="col-12 clearfix">
        <BulkActionButtons
          isOpen={selection.length > 0}
          selection={[selectionInfo]}
          buttons={[
            {
              text: "Button.pauseScreens",
              onClick: () => {
                this.openBulkAction(CAMPAIGN_ACTIONS.PAUSE);
              },
              icon: "PauseIcon",
              isHidden: !this.validateScreensAction(CAMPAIGN_ACTIONS.PAUSE),
              checkScope: true,
              scope: SCOPES.CAMPAIGNS,
              scopeAction: SCOPE_ACTIONS.WRITE,
              userData,
            },
            {
              text: "Button.resumeScreens",
              onClick: () => {
                this.openBulkAction(CAMPAIGN_ACTIONS.RESUME);
              },
              icon: "PlayIcon",
              isHidden: !this.validateScreensAction(CAMPAIGN_ACTIONS.RESUME),
              checkScope: true,
              scope: SCOPES.CAMPAIGNS,
              scopeAction: SCOPE_ACTIONS.WRITE,
              userData,
            },
            {
              text: "Button.cancelScreens",
              onClick: () => {
                this.openBulkAction(CAMPAIGN_ACTIONS.CANCEL);
              },
              icon: "CancelIcon",
              isHidden: !this.validateScreensAction(CAMPAIGN_ACTIONS.CANCEL),
              checkScope: true,
              scope: SCOPES.CAMPAIGNS,
              scopeAction: SCOPE_ACTIONS.CANCEL,
              userData,
            },
          ]}
          onClose={() => {
            this.onRowSelect({
              parentSelection: [],
              childSelection: [],
            });
          }}
        />
        <TableControls
          searchBar
          columnFilter
          showRowSize
          pagination
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          data={data}
          query={query}
          ps={ps}
          page={page}
          tagTypes={[TAG_TYPE.SCREEN, TAG_TYPE.SCREEN_STATUS]}
          onSearchFilterSelect={this.props.onFilterSelect}
          onFilterChange={this.props.onFilterChange}
          onColumnFilterSelect={this.onColumnSelect}
          onRowSizeChange={onQueryChange}
          filters={filters}
        />
        <FilterChips
          selected={filters}
          showResultsCount
          resultsCount={screens.totalCount}
          onFilterChange={this.props.onFilterChange}
        />
        <Table
          data={data}
          loading={isTabLoading || isFilterLoading}
          columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          sorted={sort}
          onSortedChange={onSortedChange}
          LoadingComponent={
            <TableLoading
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          }
          selectable={checkScopeAuth(userData, SCOPES.CAMPAIGNS, SCOPE_ACTIONS.WRITE)}
          selection={selection}
          selectedClassNames="selectedParent"
          selectedBackground="rgba(121, 126, 209, 0.2)"
          onSelect={(selection) => this.onRowSelect(selection)}
        />
        <FooterControls
          pagination
          data={data}
          query={query}
          ps={ps}
          page={page}
          onRowSizeChange={onQueryChange}
        />
        {openedBulkAction === CAMPAIGN_ACTIONS.CANCEL && (
          <CancelCampaignTargetGroup
            selectionInfo={selection.length > 0 ? selectionInfo : null}
            header="RightPanelHeader.cancelScreens"
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            cancelCampaigns={(data) => {
              this.props.actions.cancelCampaigns(
                {
                  ...data,
                  ...{
                    ids: selectedScreenData.map((campaign) => campaign.id),
                    campaignId: campaign.id,
                  },
                },
                CAMPAIGN_TYPE.SCREEN,
                getParams({
                  ps,
                  page,
                  sort,
                  ...{ id: targetId, targetType: params.action },
                }),
                this.closeBulkAction
              );
            }}
          />
        )}
        {(openedBulkAction === CAMPAIGN_ACTIONS.RESUME ||
          openedBulkAction === CAMPAIGN_ACTIONS.PAUSE) && (
          <PauseOrResumeActions
            header={screenActionHeader}
            period={this.getCurrentActionPeriod()}
            type={openedBulkAction}
            isPaused={isPaused}
            showEdit={campaign.status !== CAMPAIGN_STATUSES.CONSUMED}
            selected={selectedScreenData}
            isEdit={isEdit}
            isOpen={Boolean(openedBulkAction)}
            validity={campaign.validity}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            history={history}
            onPauseOrResumeAction={(period) => {
              this.props.actions.pauseOrResumeScreens(
                [currentScreen.id],
                campaign.id,
                openedBulkAction,
                period,
                null,
                true
              );
            }}
          />
        )}
        <ActionDetails
          isOpen={isActionDetailOpen}
          header="RightPanelHeader.screenPauseDetails"
          actionType={CAMPAIGN_STATUSES.PAUSED}
          onClose={this.closeActionDetails}
          statusChanges={campaign.statusChanges}
        />
      </div>
    );
  };
}

export default ScreensList;
