import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import classNames from "classnames";
import TabView from "../../../../common/Tabs";
import { bindDispatch } from "../../../../../utils";
import Breadcrumb from "../../../../common/BreadCrumb";
import Popup from "../../../../common/Popup";
import styles from "../../../../../styles/CampaignCreate/Proposal.module.scss";
import campaignFormStyles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import pageStyles from "../../../../../styles/App.module.scss";
import PageLoadingWithTable from "../../../../common/PageLoadingWithTable";
import CampaignSteps from "../CampaignSteps";
import CampaignDetails from "../../common/pages/CampaignDetails";
import MediaDetails from "../../common/pages/MediaDetails";
import { getCampaignTree as getCampaignTreeApi } from "../../../../../api";
import CampaignPageHeader from "../../common/CampaignPageHeader";
import { CAMPAIGN_STEPS } from "../../../../../constants";
import CampaignTree from "../../CampaignStructure/CampaignTree";
import TargetGroupDetails from "../../common/pages/TargetGroupDetails";
import CampaignTheatresList from "../../Campaigns/tabs/CampaignTheatres";
import { campaignStepChange } from "../../utils/campaignCreate";
import campaignRouteHandler from "..";

const links = [
  { name: "PageHeader.campaigns", path: "/campaigns" },
  { name: "PageHeader.sendProposal" },
];

export class CampaignProposal extends Component {
  state = {
    isCampaignStructureLoading: true,
    campaignStructure: {},
    isCampaignStructureError: false,
  };

  componentDidMount = async () => {
    const { actions } = this.props;
    await actions.updateActiveStep(CAMPAIGN_STEPS.FINISH);
    await this.fetchCampaignTreeData();
  };

  fetchCampaignTreeData = () => {
    const {
      match: { params },
    } = this.props;

    getCampaignTreeApi(params.campaignId)
      .then((campaignRes) =>
        this.setState({
          isCampaignStructureLoading: false,
          campaignStructure: campaignRes,
        })
      )
      .catch((err) => {
        this.setState({
          isCampaignStructureLoading: false,
          isCampaignStructureError: true,
          campaignStructure: null,
        });
      });
  };

  validateCreateCampaignAccess = () => {
    const { userData } = this.props;

    return userData.roles.includes("ADMIN") || userData.roles.includes("APPROVER");
  };

  handleTabChange = (newTabId) => {
    const {
      match: {
        params: { campaignId },
      },
      actions,
    } = this.props;

    if (newTabId === "targetGroup") {
      actions.getNewCampaignTargetGroups({
        campaignId,
      });
    } else if (newTabId === "media") {
      actions.getNewCampaignMediaList({
        campaignId,
      });
    }

    actions.getNewCampaignDetails({
      campaignId,
    });
    this.setState({
      selectedTabId: newTabId,
    });
  };

  handleSendProposal = () => {
    this.setState({
      isPopupOpen: true,
      isProposal: true,
    });
  };
  handleCreateCampaign = () => {
    this.setState({
      isPopupOpen: true,
      isProposal: false,
    });
  };

  onDiscardChanges = () => {
    this.setState({
      isPopupOpen: false,
    });
  };

  handleConfirmCreate = () => {
    if (this.state.isProposal) {
      // TODO: handle api call for proposal with data
    }
    // TODO: handle api call for campaign submission with data
    this.setState({
      isPopupOpen: false,
    });
  };

  handleMediaEdit = (id) => {
    const {
      match: { params },
      history,
    } = this.props;
    history.push(`/campaign/${params.campaignId}/media/id`);
  };

  handleTargetGroupEdit = (id) => {
    const {
      match: { params },
      history,
    } = this.props;
    history.push(`/campaign/${params.campaignId}/target-groups/id`);
  };

  handleCampaignDelete = async () => {
    const {
      history,
      match: { params },
    } = this.props;
    await this.props.actions.deleteCampaign(params.campaignId);
    history.push(`/campaign-create`);
  };

  handleChangeStep = (desiredStep) => {
    const {
      match: { params },
      history,
      campaignCreate: { activeStep },
      actions,
    } = this.props;
    if (desiredStep === activeStep + 1 && params.campaignId) {
      actions.updateActiveStep(desiredStep);
    } else {
      campaignStepChange(history, params, activeStep, desiredStep);
    }

    this.setState({
      hasFormSaved: true,
    });
  };

  renderCampaignTree = () => {
    const { isCampaignStructureLoading, campaignStructure, isCampaignStructureError } = this.state;

    if (this.props.isCampaignInvalid || isCampaignStructureError) return null;
    return (
      <div className={styles.campaignTree}>
        <CampaignTree
          isNewCampaign
          isLoading={isCampaignStructureLoading}
          campaignTreeData={campaignStructure}
        />
      </div>
    );
  };

  render() {
    const { selectedTabId } = this.state;
    const {
      history,
      actions,
      userData,
      match: { params },
      timesOfDay: { data: timesOfDay },
      campaignCreate: {
        isTabLoading,
        campaign,
        campaignTargetGroups,
        campaignMediaList,
        campaignSummary,
        activeStep,
        isMediaLoading,
        isTargetGroupLoading,
        isMediaDetailsLoading,
        isTargetGroupDetailsLoading,
      },
    } = this.props;
    const { isPopupOpen } = this.state;

    if (isTabLoading) return <PageLoadingWithTable />;

    return (
      <div className={classNames("col-12 clearfix", styles.pageWrapper)}>
        <Breadcrumb history={history} links={links} />
        <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
          <div className={campaignFormStyles.campaignCreateProgressBar}>
            <CampaignPageHeader
              title="PageHeader.sendProposal"
              onSaveCampaignAsDraft={this.handleSaveCampaignAsDraft}
              onDiscardCampaign={this.handleCampaignDelete}
              isDiscardDisabled={!campaign.id}
              hideDraftButton
            />
            <CampaignSteps
              activeStep={activeStep}
              validTillStep={activeStep}
              disableSave={false}
              disableReset={true}
              onChangeStep={this.handleChangeStep}
              onSendProposal={this.handleSendProposal}
              onCreateCampaign={this.handleCreateCampaign}
              isAdminUser={this.validateCreateCampaignAccess}
            />
          </div>
          <div className={styles.tabPanel}>
            <TabView
              id="campaignTabs"
              selectedTabId={selectedTabId}
              defaultSelectedTabId="details"
              onChange={this.handleTabChange}
              tabs={[
                {
                  id: "campaign",
                  title: "Campaign Details",
                  panel: (
                    <CampaignDetails
                      isLoading={isTabLoading}
                      campaign={campaign}
                      campaignSummary={campaignSummary}
                      params={params}
                      userData={userData}
                      showEstimates
                    />
                  ),
                },
                {
                  id: "targetGroup",
                  title: "Target Group",
                  panel: (
                    <TargetGroupDetails
                      showEstimates
                      params={params}
                      isLoading={isTargetGroupLoading}
                      isDetailsLoading={isTargetGroupDetailsLoading}
                      targetGroups={campaignTargetGroups?.data}
                      showDuplicate={false}
                      showDelete={false}
                      onEdit={this.handleTargetGroupEdit}
                      getTargetGroupDetails={actions.getTargetGroupById}
                    />
                  ),
                },
                {
                  id: "media",
                  title: "Media",
                  panel: (
                    <MediaDetails
                      params={params}
                      isLoading={isMediaLoading}
                      isDetailsLoading={isMediaDetailsLoading}
                      showEstimates
                      mediaList={campaignMediaList?.data}
                      timesOfDay={timesOfDay}
                      getMediaDetails={actions.getMediaById}
                      showDuplicate={false}
                      showDelete={false}
                      onEdit={this.handleMediaEdit}
                    />
                  ),
                },
                {
                  id: "theatres",
                  title: "Theatres",
                  panel: <CampaignTheatresList />,
                },
              ]}
            />
            {this.renderCampaignTree()}
          </div>
        </div>
        <Popup
          isOpen={isPopupOpen}
          onClose={this.onDiscardChanges}
          onConfirm={this.handleConfirmCreate}
          title="Popup.campaignTitle"
          body="Popup.campaignBody"
          confirmButtonText="Popup.campaignConfirmation"
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.campaignCreate,
  (state) => state.userData,
  (state) => state.timesOfDay,
  (campaignCreate, userData, timesOfDay) => ({
    campaignCreate,
    userData: userData.user,
    timesOfDay: timesOfDay,
  })
);

export default connect(mapStateToProps, bindDispatch)(campaignRouteHandler(CampaignProposal));
