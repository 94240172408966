import {
  getCampaign as getCampaignApi,
  getCampaignTargetGroups as getCampaignTargetGroupsApi,
  getCampaignMedia as getCampaignMediaApi,
  getTargetGroup as getTargetGroupApi,
  getMedia as getMediaApi,
  getTheatresByCriteria,
  createOrUpdateCampaign as createOrUpdateCampaignApi,
  createOrUpdateTargetGroup as createOrUpdateTargetGroupApi,
  createOrUpdateMedia as createOrUpdateMediaApi,
  deleteCampaign as deleteCampaignApi,
  deleteTargetGroup as deleteTargetGroupApi,
  deleteMedia as deleteMediaApi,
} from "../../api";
import * as types from "../types/campaignCreate";
import * as constants from "../../constants";
import { showToast } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

// Update Action: Update Campaign Details
const updateActiveStep = (payload) => {
  return { type: types.CAMPAIGN_STEPS, payload };
};

// Update Action: Update Campaign Details
const updateNewCampaignDetails = (payload) => {
  return { type: types.NEW_CAMPAIGN_DETAILS, payload: payload };
};

// Update Action: Update Campaign Target Group List
const updateNewCampaignTargetGroups = (payload) => {
  return { type: types.NEW_CAMPAIGN_TARGET_GROUPS, payload: payload };
};

// Update Action: Update Campaign Media List
const updateCampaignMediaList = (payload) => {
  return { type: types.NEW_CAMPAIGN_MEDIA_LIST, payload: payload };
};

// Update Action: Update Target group action ["list", "create", "edit", "duplicate"]
const updateTargetGroupAction = (payload) => {
  return { type: types.TARGET_GROUP_ACTION, payload };
};

// Update Action: Update Media action ["list", "create", "edit", "duplicate"]
const updateMediaAction = (payload) => {
  return { type: types.MEDIA_ACTION, payload };
};

// Update Action: Update Media action ["list", "create", "edit", "duplicate"]
const resetCampaignCreateError = (payload = null) => {
  return { type: types.IS_ERROR, payload };
};

// Update Action: Update Campaign Target Group
const updateNewCampaignTargetGroup = (targetGroup, type = "edit") => {
  return async (dispatch) => {
    const targetGroupData = targetGroup;
    if (type === "duplicate") {
      delete targetGroupData.id;
      targetGroupData.name = "";
    }
    dispatch(actionCreator(types.NEW_CAMPAIGN_TARGET_GROUP, targetGroupData));
    dispatch(updateTargetGroupAction(type));
  };
};

// Update Action: Update Campaign Media
const updateNewCampaignMedia = (media, type = "edit") => {
  return async (dispatch) => {
    const mediaData = media;
    if (type === "duplicate") {
      delete mediaData.id;
      mediaData.name = "";
    }
    dispatch(actionCreator(types.NEW_CAMPAIGN_MEDIA, mediaData));
    dispatch(updateMediaAction(type));
  };
};

const updateTheatresAndScreens = (payload) => {
  return { type: types.CRITERIA_THEATRES, payload };
};

// Create new campaign or update
const createOrUpdateCampaign = (campaignData, saveData = createOrUpdateCampaignApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, true));
      const { data } = await saveData(campaignData);
      if (campaignData.id) {
        showToast("Toast.campaignUpdatedSuccessfully");
      } else {
        showToast("Toast.campaignCreatedSuccessfully");
      }
      dispatch(updateNewCampaignDetails(data));
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, false));
    }
  };
};

// Create new target group or update
const createOrUpdateTargetGroup = (
  campaignId,
  tgData,
  actionType,
  saveData = createOrUpdateTargetGroupApi
) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, true));
      const { data } = await saveData(campaignId, tgData);
      if (tgData.id) {
        showToast("Toast.targetGroupUpdatedSuccessfully");
      } else {
        showToast("Toast.targetGroupCreatedSuccessfully");
      }
      await dispatch(getNewCampaignTargetGroups({ campaignId }));
      await dispatch(updateNewCampaignTargetGroup(data, actionType));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    }
  };
};

// Create new Media or Update
const createOrUpdateMedia = (
  campaignId,
  targetGroupId,
  mediaData,
  actionType,
  saveData = createOrUpdateMediaApi
) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_MEDIA_LOADING, true));
      const { data } = await saveData(campaignId, targetGroupId, mediaData);
      if (mediaData.id) {
        showToast("Toast.mediaUpdatedSuccessfully");
      } else {
        showToast("Toast.mediaCreatedSuccessfully");
      }
      await dispatch(getNewCampaignMediaList({ campaignId, targetGroupId }));
      await dispatch(updateNewCampaignMedia(data, actionType));
      dispatch(actionCreator(types.IS_MEDIA_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_MEDIA_LOADING, false));
    }
  };
};

// Get campaign details
const getNewCampaignDetails = (params = {}, fetchData = getCampaignApi) => {
  const { campaignId } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, true));
      let campaignData = {};
      if (campaignId) {
        const { data } = await fetchData(campaignId);
        campaignData = data;
      }
      dispatch(updateNewCampaignDetails(campaignData));
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, false));
    }
  };
};

// Get Campaign Target Group List
const getNewCampaignTargetGroups = (
  params = {},
  filters = [],
  getList = getCampaignTargetGroupsApi
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc", campaignId } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, true));
      const { data } = await getList(campaignId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateNewCampaignTargetGroups(data));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    }
  };
};

// Get New Campaign Media List
const getNewCampaignMediaList = (params = {}, filters = [], getList = getCampaignMediaApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc", campaignId } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_MEDIA_LOADING, true));
      const { data } = await getList(campaignId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateCampaignMediaList(data));
      dispatch(actionCreator(types.IS_MEDIA_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_MEDIA_LOADING, false));
    }
  };
};

// TODO: Update once api is completed
const deleteCampaign = (id, removeData = deleteCampaignApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, true));
      await removeData(id);
      showToast("Toast.campaignDeletedSuccessfully");
      dispatch(updateNewCampaignDetails(null));
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, false));
    }
  };
};

// TODO: Update once api is completed
const deleteTargetGroup = (params, id, removeData = deleteTargetGroupApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, true));
      await removeData(id);
      showToast("Toast.targetGroupDeletedSuccessfully");
      await dispatch(getNewCampaignTargetGroups(params));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    }
  };
};

// TODO: Update once api is completed
const deleteMedia = (params, id, removeData = deleteMediaApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_MEDIA_LOADING, true));
      await removeData(id);
      showToast("Toast.mediaDeletedSuccessfully");
      await dispatch(getNewCampaignMediaList(params));
      dispatch(actionCreator(types.IS_MEDIA_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_MEDIA_LOADING, false));
    }
  };
};

// Get Target Group by Id
const getTargetGroupById = (targetGroupId, actionType, getData = getTargetGroupApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TARGET_GROUP_DETAILS_LOADING, true));
      const { data: targetGroup } = await getData(targetGroupId);
      dispatch(updateNewCampaignTargetGroup(targetGroup, actionType));
      dispatch(actionCreator(types.IS_TARGET_GROUP_DETAILS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TARGET_GROUP_DETAILS_LOADING, false));
    }
  };
};

// Get Media by Id
const getMediaById = (id, actionType, getData = getMediaApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_MEDIA_DETAILS_LOADING, true));
      const { data: media } = await getData(id);
      dispatch(updateNewCampaignMedia(media, actionType));
      dispatch(actionCreator(types.IS_MEDIA_DETAILS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_MEDIA_DETAILS_LOADING, false));
    }
  };
};

// TODO: Update once api is completed
const getTargetGroupTheatresByCriteria = (params) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getTheatresByCriteria(10, 0, "name:asc", []);
      dispatch(updateTheatresAndScreens(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

export {
  getNewCampaignTargetGroups,
  getTargetGroupById,
  updateNewCampaignTargetGroup,
  updateTargetGroupAction,
  deleteTargetGroup,
  deleteMedia,
  deleteCampaign,
  getTargetGroupTheatresByCriteria,
  updateMediaAction,
  updateNewCampaignMedia,
  getMediaById,
  getNewCampaignMediaList,
  getNewCampaignDetails,
  createOrUpdateCampaign,
  createOrUpdateTargetGroup,
  createOrUpdateMedia,
  updateActiveStep,
  resetCampaignCreateError,
};
