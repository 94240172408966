import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import { ExpandTable } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../../styles/App.module.scss";
import { bindDispatch, modifyTableColumns, lastUpdatedAtColumn } from "../../../../utils";
import * as constants from "../../../../constants";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import PageHeader from "../../../common/PageHeader";
import FilterChips from "../../../common/Filters/FilterChips";
import ActionIcon from "../../../common/ActionIcon";
import WithFetchList from "../../../hoc/withFetchList";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";

class MoviesList extends Component {
  state = {
    selectedColumns: [
      "Movie Name",
      "Language",
      "Country · Certification",
      "Release Dates",
      "Week Number",
      "Last Updated On",
    ],
  };
  componentDidMount = () => {
    this.props.fetchData();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  formatReleaseDate = (releaseDate) => {
    const parsedDate = moment(releaseDate, "YYYY-MM-DD");
    return parsedDate.isValid() ? parsedDate.format("MMM DD, YYYY") : "";
  };

  render = () => {
    const {
      history,
      movies: { movies, isLoading, isPartialLoading },
      filters,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable />;

    const { selectedColumns, reorderedColumns } = this.state;
    const resultsCount = movies ? movies.totalCount : 0;

    const columns = [
      {
        id: "name",
        Header: "Movie Name",
        accessor: "name",
      },
      {
        id: "language",
        Header: "Language",
        accessor: "language",
      },
      {
        id: "certifications",
        Header: "Country · Certification",
        sortable: false,
        accessor: "certifications",
        parentAccessor: (d) => (d.certifications ? d.certifications.length : 0),
        childAccessor: (d) => `${d.country || ""} ${d.certification ? `· ${d.certification}` : ""}`,
      },
      {
        id: "releaseDate",
        Header: "Release Dates",
        accessor: "releaseDate",
        parentAccessor: (d) =>
          d.earliestDate ? this.formatReleaseDate(d.earliestDate.releaseDate) : "",
        childAccessor: (d) => this.formatReleaseDate(d.releaseDate),
      },
      {
        id: "weekNumber",
        Header: "Week Number",
        accessor: "releaseDate",
        sortable: false,
        parentAccessor: (d) => d?.earliestDate?.weekNumber ?? "",
        childAccessor: (d) => d.weekNumber || "",
      },
      lastUpdatedAtColumn(),
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        parentCell: (props) => (
          <ActionIcon
            iconProps={[
              {
                toolTip: "Tooltip.view",
                url: `/movies/${props.original.id}`,
                iconName: "ViewIcon",
              },
            ]}
          />
        ),
      },
    ];

    return (
      <div className="col-12 clearfix">
        <PageHeader
          name="PageHeader.movies"
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          count={movies.totalCount}
          renderRightSideComponent={() => (
            <div className="flex align-center flex-wrap">
              <div className="flex" />
            </div>
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={movies}
              ps={ps}
              page={page}
              tagTypes={[constants.TAG_TYPE.MOVIE, constants.TAG_TYPE.UPDATED_AT]}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={resultsCount}
              onFilterChange={this.props.onFilterChange}
            />
            <ExpandTable
              data={movies.data}
              loading={isPartialLoading || isFilterLoading}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              defaultPageSize={constants.DEFAULT_PAGE_SIZE}
              sorted={sort}
              onSortedChange={onSortedChange}
              selectable={false}
              childIdentificationKey="certificatesAndReleaseDates"
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
              onRowClick={({ original }) => history.push(`/movies/${original.id}`)}
            />
            <FooterControls
              pagination
              data={movies}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
      </div>
    );
  };
}

export const MoviesListWithFilters = WithFetchList("getMoviesList", {
  sort: [{ id: "name", desc: false }],
})(MoviesList);

const mapStateToProps = createSelector(
  (state) => state.movies,
  (movies) => ({ movies })
);

export default connect(mapStateToProps, bindDispatch)(MoviesListWithFilters);
