import { formatDateTime } from "..";

const dateColumn = (accessor, Header) => ({
  id: accessor,
  Header,
  showTimeZone: true,
  accessor,
  Cell: (prop) => formatDateTime(prop.value),
});

export default dateColumn;
