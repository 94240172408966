import * as types from "../types/screens";

const initialState = {
  isLoading: false,
  isPartialLoading: false,
  isScreenRightsLoading: false,
  isScreenRateCardsLoading: false,
  isScreenSettingsLoading: false,
  isSaveScheduleTimingsLoading: false,
  isUpdateScreenStatusLoading: false,
  isError: null,
  screens: {
    data: [],
  },
  screen: {},
  screenRights: {
    data: [],
  },
  screenPlaylist: {
    data: [],
  },
  assignPlaylist: { isOpen: false, playlist: [] },
  screenRightsEdit: {
    rights: [],
    companies: [],
    isOpen: false,
    right: {},
  },
  screenRateCards: {
    data: [],
  },
  initialScreen: {
    companyId: "",
    filterBy: {},
    playlistTemplateId: "",
    isDefault: false,
    validity: {
      fromDate: new Date(),
      toDate: new Date(),
      fromTime: "",
      toTime: "",
      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  screenSettings: [],
  screenSettingsEdit: {
    isOpen: false,
    setting: {
      schedulingCutOffTimeExceptions: null,
      schedulingCutOffTimeInSeconds: 0,
      schedulingTime: null,
    },
    isEdit: false,
  },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case types.IS_SCREEN_RIGHTS_LOADING:
      return { ...state, isScreenRightsLoading: payload };
    case types.IS_SCREEN_RATE_CARDS_LOADING:
      return { ...state, isScreenRateCardsLoading: payload };
    case types.IS_SCREEN_SETTINGS_LOADING:
      return { ...state, isScreenSettingsLoading: payload };
    case types.IS_SAVE_SCHEDULE_TIMINGS_LOADING:
      return { ...state, isSaveScheduleTimingsLoading: payload };
    case types.IS_UPDATE_SCREEN_STATUS_LOADING:
      return { ...state, isUpdateScreenStatusLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.SCREENS:
      return { ...state, screens: payload };
    case types.SCREEN:
      return { ...state, screen: payload };
    case types.SCREEN_PLAYLIST:
      return { ...state, screenPlaylist: payload };
    case types.ASSIGN_PLAYLIST:
      return { ...state, assignPlaylist: payload };
    case types.SCREEN_RIGHTS:
      return { ...state, screenRights: payload };
    case types.SCREEN_RIGHTS_EDIT:
      return { ...state, screenRightsEdit: payload };
    case types.SCREEN_RATE_CARDS:
      return { ...state, screenRateCards: payload };
    case types.SCREEN_SETTINGS:
      return { ...state, screenSettings: payload, isScreenSettingsLoading: false };
    case types.SCREEN_SETTINGS_EDIT:
    case types.SCREEN_SETTINGS_EDIT_CANCEL:
      return { ...state, screenSettingsEdit: payload };
    default:
      return state;
  }
}
