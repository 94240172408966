import React from "react";
import moment from "moment";
import { lowerCase } from "lodash";
import { Icons } from "prefab";
import { CAMPAIGN_STATUS_LIST, CONTENT_AVAILABILITY_STATUS, TAG_TYPE } from "../../../../constants";
import StatusIcon from "../../../common/StatusIcon";
import StatusIconLegend from "../../../common/StatusIconLegend";
import InfoBlock from "../../../common/InfoBlock";
import { formatValidityPeriod, sortByEarliestDate, formatDateTime } from "../../../../utils";
import styles from "../../../../styles/Campaigns/Campaigns.module.scss";
import activeCellStyle from "../../../../styles/ActiveTableCell.module.scss";
import getContactDetails from "./getContactDetails";
import getBillingInfo from "./getBillingInfo";
import getSummaryBlock from "./getSummaryBlock";
import MediaPlacements from "./MediaPlacements";
import validateSelectedCampaignsDurations from "./validateSelectedCampaignsDurations";
import isCampaignStatusChangesAvailable from "./isCampaignStatusChangesAvailable";
import getCurrentActionPeriod from "./getCurrentActionPeriod";
import validateCampaignsAction from "./validateCampaignsAction";

const { TheatreIcon, LocationIcon, DurationIcon, AdGroupIcon } = Icons;

const getStatus = (d, onFilterSelect = null) => {
  const [statusDetails] = CAMPAIGN_STATUS_LIST.filter((status) => status.displayName === d.status);
  if (!statusDetails) return "";
  return (
    <div
      className={`flex align-center cursor ${activeCellStyle.highlight}`}
      onClick={(e) => {
        e.stopPropagation();
        onFilterSelect &&
          onFilterSelect({
            id: statusDetails.id,
            displayName: statusDetails.displayName,
            type: TAG_TYPE.CAMPAIGN_STATUS,
          });
      }}
    >
      <StatusIcon color={statusDetails.color} type={statusDetails.type} />
      <span>{statusDetails.displayName}</span>
    </div>
  );
};

const getContentAvailabilityStatus = (d, onFilterSelect = null) => {
  const availabilityStatus = CONTENT_AVAILABILITY_STATUS.find(
    (status) => status.id === d.isContentAvailable
  );
  if (!availabilityStatus) return "";
  return (
    <div
      className={`flex align-center cursor ${activeCellStyle.highlight}`}
      onClick={() => {
        onFilterSelect &&
          onFilterSelect({
            id: availabilityStatus.id,
            displayName: availabilityStatus.displayName,
            type: TAG_TYPE.CONTENT_AVAILABILITY,
          });
      }}
    >
      <span>{availabilityStatus.displayName}</span>
    </div>
  );
};
const getStatusIconLegend = (data, tagType, onFilterSelect) => {
  if (!data) {
    return null;
  }
  const statusInfo = CAMPAIGN_STATUS_LIST.map((status) => ({
    name: status.displayName,
    id: status.id,
    count: data && data.filter((row) => row.status === status.id).length,
    type: status.type,
    color: status.color,
    tagType,
  }));

  return <StatusIconLegend statusInfo={statusInfo} onFilterSelect={onFilterSelect} />;
};

const getValidity = (data, format = "MMM DD, YYYY HH:mm", hideStatusChanges = false) => {
  if (!data.validity) return "";

  const currentValidity = formatValidityPeriod(data.validity, format);

  const statusChanges = data.statusChanges
    ? data.statusChanges.map((change) =>
        [
          change.status,
          "from",
          [
            moment(change.fromDate).format("MMM DD, YYYY"),
            moment(change.toDate).format("MMM DD, YYYY"),
          ].join(" to "),
        ].join(" ")
      )
    : [];

  return (
    <>
      <div>{currentValidity}</div>
      {!hideStatusChanges &&
        statusChanges.map((statusChange, key) => (
          <div key={key} className={styles.secondaryValue}>
            {statusChange}
          </div>
        ))}
    </>
  );
};

const targetGroupIcons = (type) => {
  switch (lowerCase(type)) {
    case "location":
      return <LocationIcon />;
    case "theatre":
      return <TheatreIcon />;
    case "movie":
      return <AdGroupIcon />;
    case "time":
      return <DurationIcon />;
    default:
      return <TheatreIcon />;
  }
};

const getName = (data, isAdminApp) => {
  return (
    <>
      <div>{`${data.name} (${data.code})`}</div>
      {isAdminApp && <div className={styles.secondaryValue}>{data.id}</div>}
    </>
  );
};

const getCampaignBasicDetails = (campaign, isAdminApp = false, header = "Campaign Details") => (
  <InfoBlock
    header={header}
    config={[
      {
        title: "Campaign Name & ID",
        value: getName(campaign, isAdminApp),
        size: {
          lg: 6,
        },
      },

      {
        title: "Validity",
        value: getValidity(campaign),
        size: {
          lg: 6,
        },
      },

      {
        title: "Country",
        value: campaign.countryName,
        size: {
          lg: 3,
          md: 6,
          sm: 6,
          xs: 6,
        },
      },
      {
        title: "Currency",
        value: campaign.currencyName,
        size: {
          lg: 3,
          md: 6,
          sm: 6,
          xs: 6,
        },
      },
      {
        title: "Created by",
        value:
          campaign.createdBy &&
          `${campaign.createdBy.firstName} ${campaign.createdBy.lastName} (${campaign.createdBy.id})`,
        size: {
          lg: 3,
          md: 6,
          sm: 6,
          xs: 6,
        },
      },
      {
        title: isAdminApp ? "Created for" : "Advertiser",
        value: `${campaign.advertiserName}${isAdminApp ? ` (${campaign.advertiserId})` : ""}`,
        size: {
          lg: 3,
          md: 6,
          sm: 6,
          xs: 6,
        },
      },
    ].filter((col) => col.name !== "Created by" || isAdminApp)}
  />
);

const getRequestedChanges = (requestedChanges, header = "Requested Changes") => {
  if (!requestedChanges) {
    return null;
  }
  const [changes] = sortByEarliestDate(requestedChanges, "requestedAt", "MMM DD, YYYY HH:mm");
  return (
    <InfoBlock
      header={header}
      config={[
        {
          title: "Requested By",
          value: [
            changes.requestedBy.firstName,
            changes.requestedBy.lastName,
            changes.requestedBy.email ? `(${changes.requestedBy.email})` : null,
          ].join(" "),
          size: {
            lg: 6,
          },
        },
        {
          title: "Requested On",
          value: formatDateTime(changes.requestedAt),
          size: {
            lg: 6,
          },
        },
        {
          title: "Notes",
          value: changes.notes,
          size: {
            lg: 6,
          },
        },
      ]}
    />
  );
};

const getApprovalDetails = (campaign, header = "Approval Details") =>
  campaign.approvedBy && (
    <InfoBlock
      header={header}
      config={[
        {
          title: "Approved By",
          value: [
            campaign.approvedBy.firstName,
            campaign.approvedBy.lastName,
            campaign.approvedBy.email ? `(${campaign.approvedBy.email})` : null,
          ].join(" "),
          size: {
            lg: 6,
          },
        },
        {
          title: "Approved On",
          value: formatDateTime(campaign.approvedAt),
          size: {
            lg: 6,
          },
        },
        {
          title: "Notes",
          value: campaign.approvalNotes,
          size: {
            lg: 6,
          },
        },
      ]}
    />
  );

const getRejectionDetails = (campaign, header = "Rejection Details") =>
  campaign.rejectionReasonId && (
    <InfoBlock
      header={header}
      config={[
        {
          title: "Rejected By",
          value: [
            campaign.rejectedBy.firstName,
            campaign.rejectedBy.lastName,
            campaign.rejectedBy.email ? `(${campaign.rejectedBy.email})` : null,
          ].join(" "),
          size: {
            lg: 6,
          },
        },
        {
          title: "Rejected On",
          value: formatDateTime(campaign.rejectedBy),
          size: {
            lg: 6,
          },
        },
        {
          title: "Reason",
          value: campaign.rejectionReasonName,
          size: {
            lg: 6,
          },
        },
        {
          title: "Notes",
          value: campaign.rejectionNotes,
          size: {
            lg: 6,
          },
        },
      ]}
    />
  );

export {
  getStatus,
  getStatusIconLegend,
  getContentAvailabilityStatus,
  getValidity,
  targetGroupIcons,
  getName,
  getContactDetails,
  getBillingInfo,
  getRequestedChanges,
  getApprovalDetails,
  getCampaignBasicDetails,
  getSummaryBlock,
  getRejectionDetails,
  MediaPlacements,
  validateSelectedCampaignsDurations,
  isCampaignStatusChangesAvailable,
  getCurrentActionPeriod,
  validateCampaignsAction,
};
