import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { BaseButton, SIZE, BUTTON_TYPE } from "prefab";
import { DateInput, TimePrecision } from "@blueprintjs/datetime";

import { parseDate } from "../../utils/DatePickerUtil";
import styles from "../../styles/CustomDateRangePicker.module.scss";
import InputStyles from "../../styles/TextInput.module.scss";
import FormStyles from "../../styles/Form.module.scss";

export default class CustomDateRangePicker extends Component {
  state = {
    focusDate: moment().toDate(),
  };

  renderClearIcon = (type) => {
    if (!this.props.showClearIcon) return null;

    return (
      <BaseButton
        className={InputStyles.clearSelection}
        iconName="CancelRoundedIcon"
        buttonSize={SIZE.REGULAR}
        buttonType={BUTTON_TYPE.SECONDARY}
        onClick={() => this.props.handleClearDateSelection(type)}
      />
    );
  };

  render() {
    const { className, title, separator, startDateProps, endDateProps } = this.props;

    return (
      <div className={classNames(styles.dateRangePicker, className)}>
        <div className={FormStyles.title}>{title}</div>
        <div className={classNames("flex", styles.dateContainer)}>
          <div className={classNames("col-6", styles.startDate)}>
            <DateInput
              className={classNames(InputStyles.inputField, {
                [styles.error]: startDateProps.error && isEmpty(startDateProps.value),
                [styles.disabled]: startDateProps.disabled,
              })}
              dayPickerProps={{ className: styles.daypicker }}
              initialMonth={startDateProps.initialMonth}
              disabled={startDateProps.disabled}
              formatDate={startDateProps.formatDate}
              parseDate={parseDate}
              timePrecision={startDateProps.timePrecision}
              defaultValue={startDateProps.defaultValue}
              timePickerProps={startDateProps.timePickerProps}
              closeOnSelection={startDateProps.closeOnSelection}
              popoverProps={{ usePortal: false }}
              onChange={startDateProps.onChange}
              placeholder={startDateProps.placeholder}
              value={startDateProps.value ? startDateProps.value : null}
              minDate={startDateProps.minDate || new Date("1986-01-01")}
              maxDate={startDateProps.maxDate || new Date("2053-01-01")}
              rightElement={
                startDateProps.value &&
                !startDateProps.disabled &&
                this.renderClearIcon("validFrom")
              }
            />
            {startDateProps.error && isEmpty(startDateProps.value) && (
              <div className={FormStyles.errorMessage}>{startDateProps.errorMessage}</div>
            )}
          </div>
          <div className={styles.separator}>{separator}</div>
          <div className={classNames("col-6", styles.endDate)}>
            <DateInput
              className={classNames(InputStyles.inputField, {
                [styles.error]: endDateProps.error && isEmpty(endDateProps.value),
                [styles.disabled]: endDateProps.disabled,
              })}
              dayPickerProps={{ className: styles.daypicker }}
              initialMonth={endDateProps.initialMonth}
              disabled={endDateProps.disabled || !startDateProps.value}
              formatDate={endDateProps.formatDate}
              parseDate={parseDate}
              timePrecision={endDateProps.timePrecision}
              defaultValue={endDateProps.defaultValue}
              timePickerProps={endDateProps.timePickerProps}
              closeOnSelection={endDateProps.closeOnSelection}
              popoverProps={{ usePortal: false }}
              onChange={endDateProps.onChange}
              placeholder={endDateProps.placeholder}
              value={endDateProps.value ? endDateProps.value : null}
              minDate={endDateProps.minDate || new Date("1986-01-01")}
              maxDate={endDateProps.maxDate || new Date("2053-01-01")}
              rightElement={
                endDateProps.value &&
                startDateProps.value &&
                !endDateProps.disabled &&
                this.renderClearIcon("validTo")
              }
            />
            {endDateProps.error && isEmpty(endDateProps.value) && (
              <div className={FormStyles.errorMessage}>{endDateProps.errorMessage}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const DateProps = {
  formatDate: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  defaultDate: PropTypes.instanceOf(Date),
  value: PropTypes.instanceOf(Date),
  initialMonth: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  timePrecision: PropTypes.oneOf([
    TimePrecision.SECOND,
    TimePrecision.MINUTE,
    TimePrecision.MILLISECOND,
  ]),
  timePickerProps: PropTypes.shape({
    precision: PropTypes.oneOf([
      TimePrecision.SECOND,
      TimePrecision.MINUTE,
      TimePrecision.MILLISECOND,
    ]),
    disabled: PropTypes.bool,
    defaultValue: PropTypes.instanceOf(Date),
  }),
  disabled: PropTypes.bool,
  showClearIcon: PropTypes.bool,
  error: PropTypes.bool,
  closeOnSelection: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
};

CustomDateRangePicker.propTypes = {
  handleClearDateSelection: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  startDateProps: PropTypes.shape({ ...DateProps }),
  endDateProps: PropTypes.shape({ ...DateProps }),
  separator: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]),
};

CustomDateRangePicker.defaultProps = {
  className: null,
  title: "Date Range",
  separator: "To",
};
