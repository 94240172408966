import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import classNames from "classnames";
import { BUTTON_TYPE, Icons } from "prefab";
import {
  CAMPAIGN_STATUS_LIST,
  STATUS_ICON_COLORS,
  SCOPES,
  SCOPE_ACTIONS,
} from "../../../../constants";
import PageLoadingWithTabs from "../../../common/PageLoadingWithTabs";
import { bindDispatch, getCampaignColorClassName } from "../../../../utils";
import { getCampaignTree as getCampaignTreeApi } from "../../../../api";
import styles from "../../../../styles/Campaigns/Campaigns.module.scss";
import PageHeader from "../../../common/PageHeader";
import Breadcrumb from "../../../common/BreadCrumb";
import TabView from "../../../common/Tabs";
import * as constants from "../../../../constants";
import CampaignTree from "../CampaignStructure/CampaignTree";
import ButtonStyle from "../../../../styles/Button.module.scss";
import pageStyles from "../../../../styles/App.module.scss";
import PauseOrResumeActions from "../bulkActions/PauseOrResumeActions";
import CancelCampaigns from "../bulkActions/CancelCampaigns";
import ViewFiles from "../common/Files";
import LocalizedButton from "../../../common/LocalizedButton";
import CampaignDetails from "../common/pages/CampaignDetails";
import CampaignSummary from "./tabs/CampaignSummary";
import CampaignTargetGroups from "./tabs/CampaignTargetGroups";
import CampaignMedia from "./tabs/CampaignMedia";
import CampaignTheatres from "./tabs/CampaignTheatres";

const { CampaignIcon } = Icons;
const _LINKS = [{ name: "PageHeader.campaigns", path: "/campaigns/campaigns" }];

export class CampaignView extends Component {
  state = {
    isCampaignStructureLoading: true,
    campaignStructure: {},
    isCampaignStructureError: false,
    openedBulkAction: null,
  };

  componentDidMount = async () => {
    await this.getCampaign();
    await this.fetchCampaignTreeData();
  };

  fetchCampaignTreeData = () => {
    const {
      match: { params },
    } = this.props;

    getCampaignTreeApi(params.campaignId)
      .then((campaignRes) =>
        this.setState({
          isCampaignStructureLoading: false,
          campaignStructure: campaignRes,
        })
      )
      .catch((err) => {
        this.setState({
          isCampaignStructureLoading: false,
          isCampaignStructureError: true,
          campaignStructure: null,
        });
      });
  };

  getCampaign = () => {
    const {
      match: { params },
      actions,
    } = this.props;
    if (params.action === "files") {
      actions.getCampaignFiles(params.campaignId);
    }
    actions.getCampaign(params);
  };

  getStatusDetails = (status) => {
    return CAMPAIGN_STATUS_LIST.find((item) => item.id === status);
  };

  closeFile = () => {
    const {
      history,
      actions,
      match: { params },
    } = this.props;
    history.push(`/campaigns/campaigns/${params.campaignId}/${params.tabId}`);
    actions.updateCampaignFiles({ isOpen: false, files: [] });
  };

  viewCampaignFiles = () => {
    const {
      history,
      actions,
      match: { params },
    } = this.props;
    history.push(`/campaigns/campaigns/${params.campaignId}/${params.tabId}/files`);
    actions.viewCampaignFiles([]);
    actions.getCampaignFiles(params.campaignId);
  };

  renderTabWithCampaignStructure = (tabPanel) => {
    const { isCampaignStructureLoading, campaignStructure, isCampaignStructureError } = this.state;

    if (isCampaignStructureError) return tabPanel;

    return (
      <div className={classNames(pageStyles.pageContainer, styles.tabContentWrapper)}>
        <div className={styles.tabPanel}>{tabPanel}</div>
        <CampaignTree isLoading={isCampaignStructureLoading} campaignTreeData={campaignStructure} />
      </div>
    );
  };

  getCurrentActionPeriod = () => {
    const {
      campaigns: { campaign },
    } = this.props;

    if (campaign.statusChanges.length === 0)
      return {
        from: new Date(),
      };

    return campaign.statusChanges.find(
      (item) =>
        item.status === this.state.openedBulkAction &&
        moment(item.toDate, "YYYY-MM-DD").isAfter(moment.now())
    );
  };

  openBulkAction = (bulkAction) => {
    this.setState({ openedBulkAction: bulkAction });
  };

  closeBulkAction = () => {
    this.setState({ openedBulkAction: null });
  };

  render = () => {
    const {
      actions,
      history,
      campaigns: { campaign, isLoading, isCampaignFileLoading, campaignFiles },
      match: { params },
      userData,
    } = this.props;
    const { openedBulkAction } = this.state;
    const {
      CAMPAIGN_ACTIONS,
      CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
      CAMPAIGN_STATUSES,
      CAMPAIGN_TYPE,
    } = constants;

    if (isLoading) {
      return <PageLoadingWithTabs />;
    }

    const statusDetails = this.getStatusDetails(campaign.status);
    const contenAvailabilityStatus = campaign.isContentAvailable;
    return (
      <div className="col-12 clearfix">
        <div className="col-12 clearfix">
          <Breadcrumb
            isLoading={isLoading}
            history={history}
            links={[..._LINKS, { name: `${campaign.name}` }]}
          />
        </div>
        <PageHeader
          name={`${campaign.name}`}
          headerIcon={<CampaignIcon />}
          customBadge={
            statusDetails && (
              <div
                className={`${styles.statusBadge} ${getCampaignColorClassName(
                  statusDetails.color
                )}`}
              >
                {statusDetails.displayName}
              </div>
            )
          }
          buttons={[
            {
              text: "Button.edit",
              onClick: () => history.push(`/campaign-create/${campaign.id}/details`),
              className: ButtonStyle.buttonSpacing,
              iconName: "EditIcon",
              isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(
                campaign.status
              ),
              checkScope: true,
              scope: constants.SCOPES.CAMPAIGNS,
              scopeAction: constants.SCOPE_ACTIONS.WRITE,
              userData,
            },
            {
              text: "Button.cancel",
              onClick: () => this.openBulkAction(CAMPAIGN_ACTIONS.CANCEL),
              className: ButtonStyle.buttonSpacing,
              iconName: "CancelIcon",
              isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.CANCEL].includes(
                campaign.status
              ),
              checkScope: true,
              scope: constants.SCOPES.CAMPAIGNS,
              scopeAction: constants.SCOPE_ACTIONS.CANCEL,
              userData,
            },
            {
              text: "Button.pause",
              onClick: () => this.openBulkAction(CAMPAIGN_ACTIONS.PAUSE),
              className: `${ButtonStyle.buttonSpacing} `,
              iconName: "PauseIcon",
              isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
                campaign.status
              ),
              checkScope: true,
              scope: constants.SCOPES.CAMPAIGNS,
              scopeAction: constants.SCOPE_ACTIONS.WRITE,
              userData,
            },
            {
              text: "Button.resume",
              onClick: () => this.openBulkAction(CAMPAIGN_ACTIONS.RESUME),
              className: ButtonStyle.buttonSpacing,
              iconName: "PlayIcon",
              isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.RESUME].includes(
                campaign.status
              ),
              checkScope: true,
              scope: constants.SCOPES.CAMPAIGNS,
              scopeAction: constants.SCOPE_ACTIONS.WRITE,
              userData,
            },
          ]}
          renderRightSideComponent={() => (
            <>
              <LocalizedButton
                scope={SCOPES.CAMPAIGNS}
                scopeAction={SCOPE_ACTIONS.READ}
                userData={userData}
                text="Button.viewFiles"
                buttonType={BUTTON_TYPE.PRIMARY}
                iconName="ViewIcon"
                className="editButton"
                onClick={this.viewCampaignFiles}
              />
              <div
                className={`${styles.statusBadge} ${getCampaignColorClassName(
                  contenAvailabilityStatus ? STATUS_ICON_COLORS.GREEN : STATUS_ICON_COLORS.RED
                )}`}
              >
                {contenAvailabilityStatus ? "Content Available" : "Content Unavailable"}
              </div>
            </>
          )}
          isLoading={isLoading}
        />
        <div className={`${styles.tabsContainer}`}>
          <TabView
            id="campaignTabs"
            selectedTabId={params.tabId}
            defaultSelectedTabId="details"
            tabs={[
              {
                id: "summary",
                title: "Summary",
                goto: `/campaigns/campaigns/${campaign.id}/summary`,
                panel: this.renderTabWithCampaignStructure(
                  <CampaignSummary campaignId={campaign.id} />
                ),
              },
              {
                id: "details",
                title: "Details",
                goto: `/campaigns/campaigns/${campaign.id}/details`,
                panel: this.renderTabWithCampaignStructure(
                  <CampaignDetails
                    campaign={campaign}
                    userData={userData}
                    params={params}
                    showCampaignActions
                  />
                ),
              },
              {
                id: "target_groups",
                title: "Target Group",
                goto: `/campaigns/campaigns/${campaign.id}/target_groups`,
                panel: <CampaignTargetGroups />,
              },
              {
                id: "media",
                title: "Media",
                goto: `/campaigns/campaigns/${campaign.id}/media`,
                panel: <CampaignMedia />,
              },
              {
                id: "theatres",
                title: "Theatres",
                goto: `/campaigns/campaigns/${campaign.id}/theatres/theatres`,
                panel: <CampaignTheatres />,
              },
            ]}
          />
        </div>
        {(openedBulkAction === CAMPAIGN_ACTIONS.RESUME ||
          openedBulkAction === CAMPAIGN_ACTIONS.PAUSE) && (
          <PauseOrResumeActions
            period={this.getCurrentActionPeriod()}
            type={openedBulkAction}
            isPaused={CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
              campaign.status
            )}
            showEdit={campaign.status !== CAMPAIGN_STATUSES.CONSUMED}
            selected={[campaign]}
            isEdit={campaign.statusChanges?.length > 0}
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            history={history}
            onPauseOrResumeAction={(period) => {
              this.props.actions.pauseOrResumeCampaigns(
                userData.company.id,
                [campaign.id],
                openedBulkAction,
                period,
                null,
                true
              );
            }}
          />
        )}
        {openedBulkAction === CAMPAIGN_ACTIONS.CANCEL && (
          <CancelCampaigns
            selectionInfo={[]}
            header="RightPanelHeader.cancelCampaign"
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            cancelCampaigns={(data) => {
              this.props.actions.cancelCampaigns(
                { ...data, ids: [campaign.id] },
                CAMPAIGN_TYPE.CAMPAIGN,
                null,
                this.closeBulkAction,
                true
              );
            }}
          />
        )}
        <ViewFiles
          campaignId={campaign.id}
          isLoading={isCampaignFileLoading}
          isOpen={campaignFiles.isOpen}
          files={campaignFiles.files}
          onClose={this.closeFile}
          downloadFile={actions.downloadCampaignFile}
        />
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.campaigns,
  (state) => state.userData,
  (campaigns, userData) => ({ campaigns, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(CampaignView);
