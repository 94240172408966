import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { FORM_FIELD_TYPE } from "../../../constants";
import Form from "../../common/Form";
import { formatDateTime } from "../../../utils";
import styles from "../../../styles/FilterSection.module.scss";

class DateRangeFilterSection extends Component {
  static propTypes = {
    tagType: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
      .isRequired,
    selected: PropTypes.array,
    onFilterChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: [],
  };

  onChange = (value, key) => {
    const { tagType, onFilterChange, selected } = this.props;
    const [selectedDate] = selected;
    const dates = { ...selectedDate, [key]: value };
    let displayName = "";
    if (dates.from) displayName += formatDateTime(dates.from, "MMM DD, YYYY");
    if (dates.from && dates.to) displayName += ` - `;
    if (dates.to) displayName += formatDateTime(dates.to, "MMM DD, YYYY");
    const tag = { ...dates, id: tagType, type: tagType, displayName };
    onFilterChange(tag, dates.from || dates.to);
    if (dates.from || dates.to)
      ReactGA.event({ category: "filter", action: "date", label: tagType });
  };

  render() {
    const { selected } = this.props;
    const [selectedDate = {}] = selected;
    const startDate = selectedDate?.from ? new Date(selectedDate?.from) : new Date();
    return (
      <Form
        config={[
          {
            type: FORM_FIELD_TYPE.DATE,
            title: "From",
            onChange: (v) => this.onChange(v, "from"),
            value: selectedDate.from || null,
            placeholder: "MMM DD, YYYY",
          },
          {
            type: FORM_FIELD_TYPE.DATE,
            title: "To",
            onChange: (v) => this.onChange(v, "to"),
            value: selectedDate.to || null,
            disabled: !selectedDate.from,
            containerClassName: styles.toDate,
            placeholder: "MMM DD, YYYY",
            minDate: startDate,
            initialMonth: startDate,
            defaultValue: startDate,
          },
        ]}
      ></Form>
    );
  }
}

export default DateRangeFilterSection;
