import { TAG_TYPE, BRANDS_TYPE } from "../../../../constants";

const DEFAULT_BRANDS_PATH = {
  PARENT_BRANDS: BRANDS_TYPE.PARENT_BRANDS,
  INDIVIDUAL_BRANDS: BRANDS_TYPE.INDIVIDUAL_BRANDS,
  PRODUCTS: BRANDS_TYPE.PRODUCTS,
  VARIANTS: BRANDS_TYPE.VARIANTS,
};

const BRAND_TAG = {
  PARENT_BRANDS: TAG_TYPE.PARENT_BRAND,
  INDIVIDUAL_BRANDS: TAG_TYPE.INDIVIDUAL_BRAND,
  PRODUCTS: TAG_TYPE.PRODUCT,
  VARIANTS: TAG_TYPE.VARIANT,
};

const BRAND_TAG_TYPE = {
  [BRAND_TAG.PARENT_BRANDS]: DEFAULT_BRANDS_PATH.PARENT_BRANDS,
  [BRAND_TAG.INDIVIDUAL_BRANDS]: DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS,
  [BRAND_TAG.PRODUCTS]: DEFAULT_BRANDS_PATH.PRODUCTS,
  [BRAND_TAG.VARIANTS]: DEFAULT_BRANDS_PATH.VARIANTS,
};

const GET_BRAND_ACTIONS = {
  [DEFAULT_BRANDS_PATH.PARENT_BRANDS]: "getParentBrandById",
  [DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS]: "getIndividualBrandById",
  [DEFAULT_BRANDS_PATH.PRODUCTS]: "getProductById",
  [DEFAULT_BRANDS_PATH.VARIANTS]: "getVariantById",
};

const ADD_BRAND_TITLE = {
  [DEFAULT_BRANDS_PATH.PARENT_BRANDS]: "addParentBrand",
  [DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS]: "addIndividualBrand",
  [DEFAULT_BRANDS_PATH.PRODUCTS]: "addProduct",
  [DEFAULT_BRANDS_PATH.VARIANTS]: "addVariant",
};

const UPDATE_BRAND_TITLE = {
  [DEFAULT_BRANDS_PATH.PARENT_BRANDS]: "updateParentBrand",
  [DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS]: "updateIndividualBrand",
  [DEFAULT_BRANDS_PATH.PRODUCTS]: "updateProduct",
  [DEFAULT_BRANDS_PATH.VARIANTS]: "updateVariant",
};
const BRAND_TITLE = {
  [DEFAULT_BRANDS_PATH.PARENT_BRANDS]: "Parent Brands",
  [DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS]: "Individual Brands",
  [DEFAULT_BRANDS_PATH.PRODUCTS]: "Products",
  [DEFAULT_BRANDS_PATH.VARIANTS]: "Variants",
};

const DEFAULT_BRAND_KEY = {
  [DEFAULT_BRANDS_PATH.PARENT_BRANDS]: {
    id: "",
    name: "",
    businessType: "",
    advertiserIds: [],
    categoryIds: [],
    categories: [],
    advertisers: [],
  },
  [DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS]: {
    id: "",
    name: "",
    parentBrandId: "",
    advertiserIds: [],
    categoryIds: [],
    categories: [],
    advertisers: [],
  },
  [DEFAULT_BRANDS_PATH.PRODUCTS]: {
    id: "",
    name: "",
    productIdentificationNumber: "",
    productIdentificationNumberTypeId: "",
    individualBrandId: "",
    parentBrandId: "",
    advertiserIds: [],
    categoryIds: [],
    categories: [],
    advertisers: [],
  },
  [DEFAULT_BRANDS_PATH.VARIANTS]: {
    id: "",
    name: "",
    parentBrandId: "",
    individualBrandId: "",
    productId: "",
    variantIdentificationNumberTypeId: "",
    variantIdentificationNumber: "",
  },
};

const BRAND_TAB_IDS = ["details", "individual-brands", "products", "variants", "content"];

const ERROR_MESSAGE = {
  name: "Enter Valid Name",
  parentBrandName: "Select Parent Brand",
  enterIndividualBrandName: "Enter Individual Brand Name",
  individualBrandName: "Select Individual Brand",
  productName: "Select Product Name",
  businessType: "Select Parent Brand Type",
  productIdentificationNumber: "Enter Product Code",
  variantIdentificationNumber: "Enter Variant Code",
};

const BRAND_LIST_COMMON_TAG_TYPES = [
  TAG_TYPE.BUSINESS_TYPE,
  TAG_TYPE.ADVERTISER,
  TAG_TYPE.ALL_CATEGORIES,
  TAG_TYPE.UPDATED_AT,
  TAG_TYPE.CREATED_AT,
];

export {
  GET_BRAND_ACTIONS,
  BRAND_TAG,
  BRAND_TAG_TYPE,
  ADD_BRAND_TITLE,
  UPDATE_BRAND_TITLE,
  DEFAULT_BRAND_KEY,
  BRAND_TAB_IDS,
  DEFAULT_BRANDS_PATH,
  BRAND_TITLE,
  ERROR_MESSAGE,
  BRAND_LIST_COMMON_TAG_TYPES,
};
