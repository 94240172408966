import React, { Component } from "react";
import moment from "moment";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Intent } from "@blueprintjs/core";
import { isEmpty } from "lodash";
import { IconNames } from "@blueprintjs/icons";
import { SearchIcon } from "../../../../common/SearchIcon";
import RemoveIcon from "../../../../common/RemoveIcon";
import InfoBlock from "../../../../common/InfoBlock";
import Popup from "../../../../common/Popup";
import Form from "../../../../common/Form";
import {
  FORM_FIELD_TYPE,
  CAMPAIGN_STATUSES,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  CAMPAIGN_ACTIONS,
} from "../../../../../constants";
import {
  searchAdvertisers,
  searchCurrencies,
  getCompanyAdvertiserPreferences,
} from "../../../../../api";
import { CustomMenuItem } from "../../../../common";
import { getDateRange } from "../../utils/campaignCreate";
import styles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";

export default class BasicDetails extends Component {
  state = {
    isFetchingPreferences: false,
    shouldSetCurrencyData: null,
  };

  static propTypes = {
    campaign: PropTypes.object.isRequired,
    hasFormSaved: PropTypes.bool,
  };

  static defaultProps = {
    hasFormSaved: false,
  };

  // Separate method since we want to set state after preferences are fetched
  onAdvertiserSelect = async (advertiser) => {
    const { onInputChange, companyPreferences } = this.props;
    this.setState({ isFetchingPreferences: true });
    const { data: companyAdvertiserPreferences } = await getCompanyAdvertiserPreferences(
      advertiser.id
    );
    const currency =
      companyAdvertiserPreferences.campaignDefaultCurrency ||
      companyPreferences.campaignDefaultCurrency;
    const billingCycle =
      companyAdvertiserPreferences.campaignDefaultBillingCycle ||
      companyPreferences.campaignDefaultBillingCycle;
    this.setState({ isFetchingPreferences: false });
    if (billingCycle) onInputChange(billingCycle, "billingCycle");
    if (currency) onInputChange(currency, "currency");
    onInputChange(advertiser, "advertiserId");
  };

  // Separate method since we want to confirm before changing currency
  onCurrencySelect = (data, isConfirmed = false) => {
    const { onInputChange, campaign } = this.props;
    if (isConfirmed || isEmpty(campaign?.currencyId) || campaign.currencyId === data.id) {
      onInputChange(data, "currencyId");
      if (isConfirmed) this.setState({ shouldSetCurrencyData: null });
    } else this.setState({ shouldSetCurrencyData: data });
  };

  renderCountryMenuItem = (data, { modifiers, handleClick }) => (
    <CustomMenuItem
      key={data.id}
      active={modifiers.active}
      icon={
        this.props.campaign?.countryIds?.find((c) => c?.id === data.id)
          ? IconNames.TICK
          : IconNames.BLANK
      }
      onClick={handleClick}
      text={data.name}
      shouldDismissPopover={false}
    />
  );

  render() {
    const { shouldSetCurrencyData } = this.state;
    const {
      campaign,
      onInputChange,
      hasFormSaved,
      countryList,
      removeCountryItem,
      searchCountries,
    } = this.props;
    const showError = campaign.id && campaign.name && hasFormSaved;

    return (
      <>
        <InfoBlock header="Campaign Details" childClassName={styles.childWrapper}>
          <div
            className={classNames(
              "flex flex-wrap clearfix",
              styles.formContainer,
              styles.basicDetails
            )}
          >
            <Form
              isFormGroup={false}
              config={[
                {
                  id: "name",
                  label: "Campaign Name",
                  type: FORM_FIELD_TYPE.INPUT,
                  className: "col-11",
                  size: {
                    lg: 6,
                    md: 6,
                  },
                  placeholder: "Enter Name",
                  value: campaign.name || "",
                  errorMessage: "Please Enter Campaign Name",
                  error: hasFormSaved && !campaign.name,
                  onChange: (e) => onInputChange(e.target.value, "name"),
                },
                {
                  id: "advertiserId",
                  label: "Advertisers",
                  type: FORM_FIELD_TYPE.API_SEARCH,
                  className: "col-12",
                  size: {
                    lg: 6,
                    md: 6,
                  },
                  placeholder: "Search Advertisers",
                  value: campaign.advertiserName,
                  query: campaign.advertiserName,
                  errorMessage: "Please Select Advertiser",
                  error: showError && !campaign.advertiserId,
                  fetchAction: (text) => searchAdvertisers(text),
                  parseResults: (response) => response,
                  onSelect: (data) => this.onAdvertiserSelect(data),
                  isLabel: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                    CAMPAIGN_ACTIONS.EDIT_ADVERTISER
                  ].includes(campaign.status),
                },
                {
                  type: FORM_FIELD_TYPE.DATE_RANGE_PICKER,
                  formatDate: (date) => moment(date).format("DD/MM/YYYY"),
                  title: "Validity",
                  containerClassName: "col-11",
                  size: {
                    lg: 6,
                    md: 6,
                  },
                  errorMessage: "Please Select Validity",
                  error: showError && isEmpty(campaign?.validity),
                  handleDateChange: (dateRange) => onInputChange(dateRange, "validity"),
                  dateRange: getDateRange(campaign?.validity),
                  isLabel: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                    CAMPAIGN_ACTIONS.EDIT_VALIDITY
                  ].includes(campaign.status),
                },
                {
                  id: "maxPlaysPerScreen",
                  title: "Max Plays Per Screen",
                  type: FORM_FIELD_TYPE.NUMERIC_INPUT,
                  size: {
                    lg: 4,
                    md: 4,
                  },
                  placeholder: "Enter Plays Per Screen",
                  value: campaign?.maxPlaysPerScreen,
                  errorMessage: "Please Enter Max Plays Per Screen",
                  error: showError && !campaign?.maxPlaysPerScreen,
                  onValueChange: (value) => onInputChange(value, "maxPlaysPerScreen"),
                },
                {
                  id: "currency",
                  label: "Currency",
                  title: "Currency",
                  type: FORM_FIELD_TYPE.API_SEARCH,
                  size: {
                    lg: 6,
                    md: 6,
                    sm: 6,
                  },
                  className: "col col-11",
                  placeholder: "Search currency",
                  onSelect: this.onCurrencySelect,
                  query: campaign?.currency?.name ?? "",
                  searchIcon: "right",
                  value: campaign.currencyName,
                  showSubText: false,
                  parseResults: (data) => {
                    return data?.data.map((item) => {
                      return {
                        id: item.id,
                        name: `${item.country} - ${item.currency}`,
                        tag: item.type,
                        item: item,
                      };
                    });
                  },
                  errorMessage: "Please Select Currency",
                  error: showError && isEmpty(campaign?.currencyId),
                  fetchAction: (text) => searchCurrencies(text),
                  isLabel: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                    CAMPAIGN_ACTIONS.EDIT_CURRENCY
                  ].includes(campaign.status),
                },
                {
                  id: "countries", // TODO: Need to remove countries and move it to location targeting
                  label: "Country",
                  type: FORM_FIELD_TYPE.MULTI_SELECT,
                  size: {
                    lg: 6,
                    md: 6,
                    sm: 6,
                  },
                  className: `col col-11 ${styles.countrySelector}`,
                  isHidden: isEmpty(campaign?.currencyId),
                  items: countryList ?? [],
                  onItemSelect: (data) => onInputChange(data, "countryIds"),
                  itemRenderer: this.renderCountryMenuItem,
                  resetOnSelect: true,
                  tagRenderer: (data) => data.name,
                  tagInputProps: {
                    placeholder: "Search Countries",
                    tagProps: { intent: Intent.NONE, minimal: true },
                    onRemove: (data, index) => removeCountryItem(index),
                    rightElement:
                      campaign?.countryIds?.length > 0 ? (
                        <RemoveIcon onClick={() => onInputChange(null, "countryIds")} />
                      ) : (
                        <SearchIcon />
                      ),
                  },
                  onQueryChange: searchCountries,
                  selectedItems: campaign?.countries ?? [],
                  value: campaign?.countries?.map((c) => c.name).join(", ") ?? "",
                  errorMessage: "Please Select Country",
                  error: showError && isEmpty(campaign?.countryIds),
                  isLabel: campaign.status !== CAMPAIGN_STATUSES.DRAFT,
                },
              ]}
            />
          </div>
        </InfoBlock>
        <Popup
          isOpen={!!shouldSetCurrencyData}
          onClose={() => this.setState({ shouldSetCurrencyData: null })}
          onConfirm={() => {
            this.onCurrencySelect(shouldSetCurrencyData, true);
          }}
          title="Popup.title"
          body="Popup.campaignCurrencyChangeConfirmation"
        />
      </>
    );
  }
}
