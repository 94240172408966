import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tag } from "@blueprintjs/core";
import { differenceBy } from "lodash";
import classNames from "classnames";
import { getTagTypeDisplayName, getTagDisplayName } from "../../../utils";
import styles from "../../../styles/FilterChips.module.scss";

class FilterChips extends Component {
  static propTypes = {
    showResultsCount: PropTypes.bool,
    resultsCount: PropTypes.number,
    selected: PropTypes.array.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    showResultsCount: false,
    loading: false,
  };

  onRemove = (filters) => {
    const newSelected = differenceBy(this.props.selected, filters, "id");
    this.props.onFilterChange(newSelected);
  };

  renderFilters = () => {
    const { selected } = this.props;
    return selected.map((filter) => (
      <Tag
        key={filter.id}
        className={classNames(styles.primary, styles.chip)}
        minimal
        onRemove={() => this.onRemove([filter])}
      >
        {getTagTypeDisplayName(filter.type)}: {getTagDisplayName(filter)}
      </Tag>
    ));
  };

  renderResultsCount = () => {
    const { showResultsCount, resultsCount } = this.props;
    if (!showResultsCount) return null;
    let r;
    if (resultsCount === 0) r = "No results for";
    else if (resultsCount === 1) r = `Showing ${resultsCount} result for`;
    else r = `Showing ${resultsCount} results for`;
    return <span className={styles.resultsCount}>{r}</span>;
  };

  render() {
    const { selected, loading } = this.props;
    if (!selected || selected.length === 0 || loading) {
      return null;
    }
    return (
      <div className={styles.filterChips}>
        {this.renderResultsCount()}
        {this.renderFilters()}
        <Tag
          key="CLEAR-ALL"
          minimal
          className={classNames(styles.danger, styles.chip)}
          interactive
          onClick={() => this.onRemove(selected)}
        >
          Clear
        </Tag>
      </div>
    );
  }
}

export default FilterChips;
