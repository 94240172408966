import { mergeWith } from "lodash";
import * as types from "../types/campaignApprovals";

const initialState = {
  isLoading: true,
  isError: null,
  campaignApprovals: {},
  campaignApproval: {},
  action: null,
  isTabLoading: true,
  isMediaDetailsLoading: true,
  isTargetGroupDetailsLoading: true,
  campaignApprovalMedia: {
    data: [],
  },
  campaignApprovalTargetGroups: {
    data: [],
  },
  rejectedProposals: {
    data: [],
  },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.CAMPAIGN_APPROVALS:
      return { ...state, campaignApprovals: payload };
    case types.REJECTED_PROPOSALS:
      return { ...state, rejectedProposals: payload };
    case types.CAMPAIGN_APPROVAL:
      return { ...state, campaignApproval: payload };
    case types.ACTION:
      return { ...state, action: payload };
    case types.IS_TAB_LOADING:
      return { ...state, isTabLoading: payload };
    case types.CAMPAIGN_APPROVAL_MEDIA:
      return { ...state, campaignApprovalMedia: payload };
    case types.CAMPAIGN_APPROVAL_MEDIA_DETAILS_LOADING:
      return { ...state, isMediaDetailsLoading: payload };
    case types.CAMPAIGN_APPROVAL_TARGET_GROUP_DETAILS_LOADING:
      return { ...state, isTargetGroupDetailsLoading: payload };
    case types.CAMPAIGN_APPROVAL_MEDIA_DETAILS:
      return {
        ...state,
        campaignApprovalMedia: {
          ...state.campaignApprovalMedia,
          data: mergeWith(state.campaignApprovalMedia.data, [payload], (x, y) => {
            if (x.id === y.id) {
              return { ...x, ...y };
            }
          }),
        },
      };
    case types.CAMPAIGN_APPROVAL_TARGET_GROUPS:
      return { ...state, campaignApprovalTargetGroups: payload };
    case types.CAMPAIGN_APPROVAL_TARGET_GROUP_DETAILS:
      return {
        ...state,
        campaignApprovalTargetGroups: {
          ...state.campaignApprovalTargetGroups,
          data: mergeWith(state.campaignApprovalTargetGroups.data, [payload], (x, y) => {
            if (x.id === y.id) {
              return { ...x, ...y };
            }
          }),
        },
      };
    default:
      return state;
  }
}
