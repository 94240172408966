import React, { Component } from "react";
import PropTypes from "prop-types";
import { Classes, Button, FormGroup, InputGroup } from "@blueprintjs/core";
import classNames from "classnames";
import { DEFAULT_LOADING_ROW_COUNT } from "../../constants";
import tableControls from "../../styles/TableControls.module.scss";
import tableControlsStyles from "../../styles/TableControls.module.scss";
import pageHeaderStyles from "../../styles/PageHeader.module.scss";
import BreadcrumbLoading from "./BreadcrumbLoading";
import TableLoading from "./TableLoading";

export default class PageLoadingWithTable extends Component {
  static propTypes = {
    showBreadcrumb: PropTypes.bool,
    tableRowsCount: PropTypes.number,
  };

  static defaultProps = {
    showBreadcrumb: false,
    tableRowsCount: DEFAULT_LOADING_ROW_COUNT,
  };

  render = () => {
    const { showBreadcrumb, tableRowsCount } = this.props;
    const columns = [
      {
        id: "col1",
        Header: (
          <span className={Classes.SKELETON}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
        ),
        accessor: "col1",
        sortable: false,
        resizable: false,
      },
      {
        id: "col2",
        Header: (
          <span className={Classes.SKELETON}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
        ),
        accessor: "col2",
        sortable: false,
        resizable: false,
      },
      {
        id: "col3",
        Header: (
          <span className={Classes.SKELETON}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
        ),
        accessor: "col3",
        sortable: false,
        resizable: false,
      },
      {
        id: "col4",
        Header: (
          <span className={Classes.SKELETON}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
        ),
        accessor: "col4",
        sortable: false,
        resizable: false,
      },
    ];

    return (
      <div className="col-12 clearfix">
        {showBreadcrumb && <BreadcrumbLoading />}
        <div>
          <div className={`flex flex-wrap col-12 clearfix ${pageHeaderStyles.pageHeader}`}>
            <div className="flex flex-auto flex-wrap">
              <div className={`flex ${pageHeaderStyles.header}`}>
                <div
                  className={classNames(
                    "flex h2 mb2",
                    pageHeaderStyles.headerText,
                    Classes.SKELETON
                  )}
                >
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                </div>
              </div>
            </div>
            <div className={`flex ${pageHeaderStyles.headerButtonContainer}`}>
              <div className="flex-auto" />
              <div className="flex">
                <Button
                  className={Classes.SKELETON}
                  text="&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="p2">
            <div
              className={classNames("flex flex-wrap col-12 clearfix", tableControls.tableControls)}
            >
              <div className={classNames("flex", tableControlsStyles.searchContainer)}>
                <div className={tableControlsStyles.searchBar}>
                  <FormGroup>
                    <InputGroup className={Classes.SKELETON} />
                  </FormGroup>
                </div>
              </div>

              <div className="flex-auto" />
              <div className="flex flex-wrap p1">
                <div className={tableControlsStyles.controls}>
                  <Button className={Classes.SKELETON} text="&emsp;" />
                </div>
                <div className={tableControlsStyles.controls}>
                  <Button
                    className={Classes.SKELETON}
                    text="&emsp;"
                    style={{ marginRight: "0.5rem" }}
                  />
                </div>
              </div>
            </div>
            <TableLoading columns={columns} rowsCount={tableRowsCount} />
          </div>
        </div>
      </div>
    );
  };
}
