import { omit } from "lodash";
import {
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_CREATE_UPDATE_LOADING,
  IS_ERROR,
  PARENT_BRANDS,
  INDIVIDUAL_BRANDS,
  PRODUCTS,
  VARIANTS,
  BRAND_CONTENT,
  BRAND,
  EDIT_BRAND,
  SEARCH,
  CATEGORY_SEARCH,
  BRAND_TAG_TYPE,
  ADVERTISER_SEARCH,
} from "../types/brands";
import { DEFAULT_BRAND_KEY } from "../../components/pages/catalogue/brands/brandConstants";
import { getCategoryId } from "../../utils";
import * as constants from "../../constants";
import {
  getParentBrands,
  getIndividualBrands,
  getProducts,
  getVariants,
  brandContent,
  getParentBrandById as getParentBrand,
  getIndividualBrandById as getIndividualBrand,
  getProductById as getProduct,
  getVariantById as getVariant,
  searchDuplicateParentBrands,
  searchDuplicateIndividualBrands,
  searchDuplicateProducts,
  searchDuplicateVariants,
  categorySearchWithText,
  searchAdvertisers as searchAdvertisersApi,
  getTagByID,
  saveParentBrand,
  saveIndividualBrand,
  saveProduct,
  saveVariant,
} from "../../api";
import { showToast } from "../../utils";

const updateParentBrandList = (payload) => ({ type: PARENT_BRANDS, payload });
const updateIndividualBrandList = (payload) => ({ type: INDIVIDUAL_BRANDS, payload });
const updateProductList = (payload) => ({ type: PRODUCTS, payload });
const updateVariantList = (payload) => ({ type: VARIANTS, payload });
const updateEditBrand = (payload) => ({ type: EDIT_BRAND, payload });
const updateBrand = (payload) => ({ type: BRAND, payload: payload });
const handleError = (payload) => ({ type: IS_ERROR, payload });
const handleLoading = (payload) => ({ type: IS_LOADING, payload });
const handleCreateUpdateLoading = (payload) => ({
  type: IS_CREATE_UPDATE_LOADING,
  payload,
});
const handlePartialLoading = (payload) => ({ type: IS_PARTIAL_LOADING, payload });
const updateBrandType = (payload) => ({ type: BRAND_TAG_TYPE, payload });
const updateBrandContent = (payload) => ({ type: BRAND_CONTENT, payload });

const addBrand = (params, defaultSelectedField = {}) => (dispatch) =>
  dispatch(
    updateEditBrand({
      isOpen: true,
      brand: { ...DEFAULT_BRAND_KEY[params.tabId], ...defaultSelectedField },
    })
  );

const editBrand = (brand) => (dispatch) =>
  dispatch(updateEditBrand({ brand: { ...brand, type: brand.businessType }, isOpen: true }));

const cancelEditBrand = () => (dispatch) => dispatch(updateEditBrand({ isOpen: false, brand: {} }));

const getParentBrandList = (
  params = {},
  filters = [],
  fetchParentBrand = getParentBrands,
  isPartial = true
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc" } = params;
  return async (dispatch) => {
    isPartial ? dispatch(handlePartialLoading(true)) : dispatch(handleLoading(true));
    try {
      if (params.parentTagId) {
        const { data } = await getTagByID(params.parentTagId);
        filters = [...filters, data];
      }
      const { data } = await fetchParentBrand(ps, (page - 1) * ps, sort, filters);
      dispatch(updateParentBrandList(data));
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
    } catch (error) {
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const getIndividualBrandList = (
  params = {},
  filters = [],
  fetchIndividualBrand = getIndividualBrands,
  isPartial = true
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc" } = params;

  return async (dispatch) => {
    isPartial ? dispatch(handlePartialLoading(true)) : dispatch(handleLoading(true));
    try {
      if (params.parentTagId) {
        const { data } = await getTagByID(params.parentTagId);
        filters = [...filters, data];
      }
      const { data } = await fetchIndividualBrand(ps, (page - 1) * ps, sort, filters);
      dispatch(updateIndividualBrandList(data));
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
    } catch (error) {
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const getProductList = (
  params = {},
  filters = [],
  fetchProducts = getProducts,
  isPartial = true
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc" } = params;
  return async (dispatch) => {
    isPartial ? dispatch(handlePartialLoading(true)) : dispatch(handleLoading(true));
    try {
      if (params.parentTagId) {
        const { data } = await getTagByID(params.parentTagId);
        filters = [...filters, data];
      }
      const { data } = await fetchProducts(ps, (page - 1) * ps, sort, filters);
      dispatch(updateProductList(data));
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
    } catch (error) {
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const getVariantList = (
  params = {},
  filters = [],
  fetchVariants = getVariants,
  isPartial = true
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc" } = params;
  return async (dispatch) => {
    isPartial ? dispatch(handlePartialLoading(true)) : dispatch(handleLoading(true));
    if (params.parentTagId) {
      const { data } = await getTagByID(params.parentTagId);
      filters = [...filters, data];
    }
    try {
      const { data } = await fetchVariants(ps, (page - 1) * ps, sort, filters);
      dispatch(updateVariantList(data));
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
    } catch (error) {
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const getParentBrandById = (params = {}, isEdit = false, fetch = getParentBrand) => {
  return async (dispatch) => {
    isEdit ? dispatch(handleCreateUpdateLoading(true)) : dispatch(handleLoading(true));
    try {
      const { data } = await fetch(params.id);
      dispatch(isEdit ? editBrand(data) : updateBrand(data));
      isEdit ? dispatch(handleCreateUpdateLoading(false)) : dispatch(handleLoading(false));
    } catch (error) {
      isEdit ? dispatch(handleCreateUpdateLoading(false)) : dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const getIndividualBrandById = (params = {}, isEdit = false, fetch = getIndividualBrand) => {
  return async (dispatch) => {
    isEdit ? dispatch(handleCreateUpdateLoading(true)) : dispatch(handleLoading(true));
    try {
      const { data } = await fetch(params.id);
      dispatch(isEdit ? editBrand(data) : updateBrand(data));
      isEdit ? dispatch(handleCreateUpdateLoading(false)) : dispatch(handleLoading(false));
    } catch (error) {
      isEdit ? dispatch(handleCreateUpdateLoading(false)) : dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const getProductById = (params = {}, isEdit = false, fetch = getProduct) => {
  return async (dispatch) => {
    isEdit ? dispatch(handleCreateUpdateLoading(true)) : dispatch(handleLoading(true));
    try {
      const { data } = await fetch(params.id);
      dispatch(isEdit ? editBrand(data) : updateBrand(data));
      isEdit ? dispatch(handleCreateUpdateLoading(false)) : dispatch(handleLoading(false));
    } catch (error) {
      isEdit ? dispatch(handleCreateUpdateLoading(false)) : dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const getVariantById = (params = {}, isEdit = false, fetch = getVariant) => {
  return async (dispatch) => {
    isEdit ? dispatch(handleCreateUpdateLoading(true)) : dispatch(handleLoading(true));
    try {
      const { data } = await fetch(params.id);
      dispatch(isEdit ? editBrand(data) : updateBrand(data));
      isEdit ? dispatch(handleCreateUpdateLoading(false)) : dispatch(handleLoading(false));
    } catch (error) {
      isEdit ? dispatch(handleCreateUpdateLoading(false)) : dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const saveOrUpdateBrand = async (brand, params) => {
  if (params.editBrandId) {
    const { data } = await getTagByID(params.editBrandId);
    params = { ...params, tabId: constants.BRAND_TAG_TYPE[data.type] };
  }
  switch (params.tabId) {
    case constants.BRANDS_TYPE.PARENT_BRANDS:
      return saveParentBrand(brand);
    case constants.BRANDS_TYPE.INDIVIDUAL_BRANDS:
      return saveIndividualBrand(brand);
    case constants.BRANDS_TYPE.PRODUCTS:
      return saveProduct(brand);
    case constants.BRANDS_TYPE.VARIANTS:
      return saveVariant(brand);
    default:
      return;
  }
};

const searchDuplicateBrands = async (params, query) => {
  if (params.editBrandId) {
    const { data } = await getTagByID(params.editBrandId);
    params = { ...params, tabId: constants.BRAND_TAG_TYPE[data.type] };
  }
  switch (params.tabId) {
    case constants.BRANDS_TYPE.PARENT_BRANDS:
      return searchDuplicateParentBrands(query);
    case constants.BRANDS_TYPE.INDIVIDUAL_BRANDS:
      return searchDuplicateIndividualBrands(query);
    case constants.BRANDS_TYPE.PRODUCTS:
      return searchDuplicateProducts(query);
    case constants.BRANDS_TYPE.VARIANTS:
      return searchDuplicateVariants(query);
    default:
      return;
  }
};

const createOrUpdateBrand = (brand, params, cancelEdit, fetchDataList) => {
  return async (dispatch) => {
    dispatch(handleCreateUpdateLoading(true));
    try {
      const modifiedBrand = {
        ...brand,
        categoryIds: brand.categories
          ? brand.categories.map((c) => getCategoryId(c)).filter((c) => c)
          : [],
        advertiserIds: brand.advertisers ? brand.advertisers.map((a) => a.id) : [],
      };
      await saveOrUpdateBrand(omit(modifiedBrand, ["advertisers", "categories"]), params);
      cancelEdit();
      fetchDataList();
      showToast(modifiedBrand.id ? "Toast.brandUpdatedSuccess" : "Toast.brandCreatedSuccess", true);
      dispatch(handleCreateUpdateLoading(false));
    } catch (error) {
      if (error.response) {
        showToast(error.response.data.message, false);
        dispatch(handleError(error.response.data.message));
      }
      dispatch(handleCreateUpdateLoading(false));
    }
  };
};

const checkDuplication = (brand, params, hasDuplication, cancelEdit, fetchDataList) => {
  return async (dispatch) => {
    try {
      dispatch(handleCreateUpdateLoading(true));
      const { data } = await searchDuplicateBrands(params, brand.name);
      const duplicateData = data.data ? data.data.filter((d) => d.id !== brand.id) : [];
      if (duplicateData.length === 0) {
        dispatch(createOrUpdateBrand(brand, params, () => cancelEdit(), fetchDataList));
        return;
      }
      hasDuplication(duplicateData || []);
      dispatch(handleCreateUpdateLoading(false));
    } catch (error) {
      dispatch(handleError(error.message));
      dispatch(handleCreateUpdateLoading(false));
    }
  };
};

const handleSearch = (payload) => {
  return { type: SEARCH, payload: payload };
};

const handleSearchCategory = (payload) => {
  return { type: CATEGORY_SEARCH, payload: payload };
};

const updateAdvertiserSearch = (payload) => ({ type: ADVERTISER_SEARCH, payload: payload });

const categorySearch = (text, search = categorySearchWithText) => {
  return async (dispatch) => {
    try {
      const { data } = await search(text);
      dispatch(handleSearchCategory(data));
    } catch (error) {
      dispatch(handleError(error.message));
    }
  };
};

const searchAdvertisers = (text, search = searchAdvertisersApi) => {
  return async (dispatch) => {
    try {
      const { data } = await search(text);
      dispatch(updateAdvertiserSearch(data));
    } catch (error) {
      dispatch(handleError(error.message));
    }
  };
};

const getBrandType = (tagId) => {
  return async (dispatch) => {
    try {
      const { data } = await getTagByID(tagId);
      dispatch(updateBrandType(data));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const getBrandContent = (params, filters = [], getList = brandContent, isPartial = true) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "updatedAt:asc" } = params;
  return async (dispatch) => {
    try {
      isPartial ? dispatch(handlePartialLoading(true)) : dispatch(handleLoading(true));
      const { data } = await getList(params.brandId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateBrandContent(data));
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleError(error.message));
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
    }
  };
};

export {
  addBrand,
  updateEditBrand,
  editBrand,
  updateBrand,
  createOrUpdateBrand,
  cancelEditBrand,
  updateParentBrandList,
  updateIndividualBrandList,
  updateProductList,
  updateVariantList,
  getParentBrandList,
  getIndividualBrandList,
  getProductList,
  getVariantList,
  getParentBrandById,
  getIndividualBrandById,
  getProductById,
  getVariantById,
  checkDuplication,
  categorySearch,
  handleSearch,
  getBrandType,
  searchAdvertisers,
  getBrandContent,
};
