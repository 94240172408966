import { map } from "lodash";
import {
  getAdminScreens,
  getUserScreens,
  getScreen as getScreenApi,
  getScreenRights as getScreenRightsApi,
  getScreenPlaylist as getScreenPlaylistApi,
  pauseOrResumeScreens as pauseOrResumeScreensApi,
  getScreenRight,
  createOrUpdateScreenRight,
  createOrUpdateScreenScheduleTimings,
  createOrUpdateSettings,
  fetchRights,
  getCompaniesList,
  getScreenRateCards as getScreenRateCardsApi,
  assignPlaylistTemplate,
  getScreenSettings as getScreenSettingsApi,
  updateScreenStatus as updateScreenStatusApi,
} from "../../api";
import * as types from "../types/screens";
import * as constants from "../../constants";
import { createFilterRequest, getScreenStatusList } from "../../utils";

import { showToast } from "../../utils";

const actionCreator = (type, payload) => ({ type, payload });
const updateScreens = (payload) => ({ type: types.SCREENS, payload });
const updateScreen = (payload) => ({ type: types.SCREEN, payload });
const updateScreenPlaylist = (payload) => ({ type: types.SCREEN_PLAYLIST, payload });
const updateAssignPlaylist = (payload) => ({ type: types.ASSIGN_PLAYLIST, payload });
const updateScreenRights = (payload) => ({ type: types.SCREEN_RIGHTS, payload });
const updateScreenRateCards = (payload) => ({ type: types.SCREEN_RATE_CARDS, payload });
const updateScreenSettings = (payload) => ({ type: types.SCREEN_SETTINGS, payload });

const getAdminScreensList = (params, filters = [], getList = getAdminScreens, isPartial = true) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "categoryName:asc" } = params;
  return async (dispatch) => {
    try {
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, true))
        : dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateScreens(data));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getUserScreensList = (params, filters = [], getList = getUserScreens, isPartial = true) => {
  const {
    page = 1,
    ps = constants.DEFAULT_PAGE_SIZE,
    sort = "categoryName:asc",
    companyId,
  } = params;
  return async (dispatch) => {
    try {
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, true))
        : dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters, companyId);
      if (data?.data.length > 0) {
        map(data.data, (screen) => {
          let screenStatusList = [];
          if (screen?.screenSettings.length > 0) {
            screenStatusList = getScreenStatusList(screen.screenSettings[0].screenStatus);
            screen.screenSettings[0].screenStatusList = screenStatusList;
          } else {
            screen.screenSettings = [{ screenStatusList: [], screenStatus: { screenStatus: "" } }];
          }
        });
      }

      dispatch(updateScreens(data));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getScreen = (params) => {
  return async (dispatch) => {
    dispatch(actionCreator(types.IS_LOADING, true));
    try {
      const { data } = await getScreenApi(params.screenId);
      dispatch(updateScreen(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const resetScreenData = () => {
  return async (dispatch) => {
    dispatch(actionCreator(types.IS_LOADING, true));
    try {
      dispatch(updateScreen(null));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getScreenPlaylist = (params, filters = [], getList = getScreenPlaylistApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "updatedAt:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getList(params.screenId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateScreenPlaylist(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getScreenRights = (params, filters = [], getList = getScreenRightsApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "categoryName:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_SCREEN_RIGHTS_LOADING, true));
      const { data } = await getList(params.screenId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateScreenRights(data));
      dispatch(actionCreator(types.IS_SCREEN_RIGHTS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_SCREEN_RIGHTS_LOADING, false));
    }
  };
};

const getScreenRightsCount = (params, getList = getScreenRightsApi) => {
  return async (dispatch) => {
    const { data } = await getList(params.screenId, 0, "validity:asc", []);
    dispatch(updateScreenRights(data));
  };
};

const assignNewPlaylistTemplate = (playlistTemplate) => (dispatch) => {
  dispatch(updateAssignPlaylist({ isOpen: true, playlist: playlistTemplate }));
};
const editPlaylistTemplate = (playlistTemplate) => (dispatch) => {
  dispatch(updateAssignPlaylist({ isOpen: true, playlist: playlistTemplate }));
};
const assignTheatreScreenPlaylist = (
  playlist,
  params,
  cancelEdit,
  save = assignPlaylistTemplate
) => {
  return async (dispatch) => {
    dispatch(actionCreator(types.IS_LOADING, true));
    try {
      await save(playlist);
      showToast("Toast.assignedPlaylistSuccess", true);
      dispatch(getScreenPlaylist(params));
      cancelEdit();
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      showToast(error.message, false);
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const pauseOrResumeScreens = (ids, campaignId, status, period, params, isSingleScreen = false) => {
  const { req: ft } = createFilterRequest(
    ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  );
  const data = {
    ft,
    status: status,
    startDate: period.from,
    endDate: period.to,
  };
  return async (dispatch) => {
    try {
      await pauseOrResumeScreensApi(campaignId, data);
      isSingleScreen
        ? await dispatch(getScreen({ screenId: ids[0] }))
        : await dispatch(getUserScreensList(params));
      await showToast("Toast.statusUpdatedSuccessfully", true);
    } catch (error) {
      isSingleScreen
        ? await dispatch(getScreen({ screenId: ids[0] }))
        : await dispatch(getUserScreensList(params));
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};

const createScreenRight = () => {
  return async (dispatch) => {
    const { data: rights } = await fetchRights(0, 0, [], {});
    const {
      data: { data: companies },
    } = await getCompaniesList();
    dispatch(
      actionCreator(types.SCREEN_RIGHTS_EDIT, {
        rights: rights,
        companies: companies,
        isOpen: true,
        isEdit: false,
        right: {
          screen: {
            id: "",
            name: "",
          },
          company: {
            id: "",
            name: "",
          },
          right: {
            id: "",
            name: "",
          },
          playlist: {
            id: "",
            name: "",
          },
          playlistPack: {
            id: "",
            name: "",
          },
          segment: {
            id: "",
            name: "",
          },
          status: "",
          validity: {
            fromDate: null,
            toDate: null,
            startTime: null,
            endTime: null,
          },
        },
      })
    );
  };
};

const editScreenRight = (right) => {
  return async (dispatch) => {
    const { data: rights } = await fetchRights(0, 0, [], {});
    const {
      data: { data: companies },
    } = await getCompaniesList();

    dispatch(
      actionCreator(types.SCREEN_RIGHTS_EDIT, {
        rights: rights,
        companies: companies,
        isOpen: true,
        isEdit: true,
        right: right,
      })
    );
  };
};

const editScreenRightById = (rightId, get = getScreenRight) => {
  return async (dispatch) => {
    try {
      const { data } = await get(rightId);
      dispatch(editScreenRight(data));
      dispatch(getScreenRights());
    } catch (error) {
      dispatch(actionCreator({ type: types.IS_SCREEN_RIGHTS_LOADING, payload: false }));
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

const cancelEditScreenRight = () => (dispatch) =>
  dispatch(actionCreator(types.SCREEN_RIGHTS_EDIT, { isOpen: false, right: {} }));

const saveScreenRight = (
  params,
  filters,
  right,
  save = createOrUpdateScreenRight,
  getList = getScreenRightsApi
) => {
  const { page = 0, ps = 0, sort = "categoryName:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_SCREEN_RIGHTS_LOADING, true));
      // TODO: Format `right` like in api contract here [https://app.zenhub.com/workspaces/qube-slate-5b62c505eb87c774495f5301/issues/realimage/qube-slate/224]
      await save(right);
      const data = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateScreenRights(data));
      showToast(right ? "Toast.rightsUpdated" : "Toast.rightsAdded");
      dispatch(actionCreator(types.IS_SCREEN_RIGHTS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_SCREEN_RIGHTS_LOADING, false));
      const data = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateScreenRights(data));
      showToast(error.message, false);
      return error;
    }
  };
};

const getScreenRateCards = (params, filters = [], getList = getScreenRateCardsApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "categoryName:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_SCREEN_RATE_CARDS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateScreenRateCards(data));
      dispatch(actionCreator(types.IS_SCREEN_RATE_CARDS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_SCREEN_RATE_CARDS_LOADING, false));
    }
  };
};

const getScreenSettings = (params, get = getScreenSettingsApi, isAdminApp = false) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_SCREEN_SETTINGS_LOADING, true));
      const data = await get(params.screenId);
      if (data?.status === 200 && data?.data?.length > 0) {
        const screenStatusList = getScreenStatusList(data.data[0].screenStatus);
        data.data[0].screenStatusList = screenStatusList;
        dispatch(updateScreenSettings(data.data));
      }
      dispatch(actionCreator(types.IS_SCREEN_SETTINGS_LOADING, false));
    } catch (error) {
      const errorResponse = error.response;
      if (errorResponse?.data?.code === 404) {
        // TODO: Update with company user preferences once its introduced
        // dispatch(updateScreenSettings([userData?.user?.company?.preferences]));
        const screenSettingsData = [];
        if (!isAdminApp) {
          screenSettingsData.push({
            schedulingCutOffTimeExceptions: null,
            schedulingCutOffTimeInSeconds: 0,
            schedulingTime: null,
            screenStatus: { screenStatus: "", startDate: "", endDate: "" },
            screenStatusList: [{ isCurrent: true, screenStatus: "", startDate: "", endDate: "" }],
          });
        }
        dispatch(updateScreenSettings(screenSettingsData));
        dispatch(actionCreator(types.IS_SCREEN_SETTINGS_LOADING, false));
        dispatch(actionCreator(types.IS_ERROR, error.message));
      }
    }
  };
};

const editScreenSettingsById = (params, getData = getScreenSettingsApi) => {
  return async (dispatch) => {
    try {
      const { data: setting } = await getData(params.screenId);
      dispatch(
        actionCreator(types.SCREEN_SETTINGS_EDIT, {
          isOpen: true,
          isEdit: true,
          setting: setting[0],
        })
      );
    } catch (error) {
      const errorResponse = error.response;
      if (errorResponse?.data?.code === 404) {
        // TODO: Update with company user preferences once its introduced
        // dispatch(updateScreenSettings([userData?.user?.company?.preferences]));
        dispatch(
          actionCreator(types.SCREEN_SETTINGS_EDIT, {
            isOpen: true,
            isEdit: true,
            setting: {
              schedulingCutOffTimeExceptions: null,
              schedulingCutOffTimeInSeconds: 0,
              schedulingTime: null,
            },
          })
        );
        dispatch(actionCreator(types.IS_ERROR, error.message));
      }
    }
  };
};

const cancelSettingEdit = () => (dispatch) =>
  dispatch(
    actionCreator(types.SCREEN_SETTINGS_EDIT_CANCEL, { isOpen: false, isEdit: false, setting: {} })
  );

const saveScreensScheduleTimings = (
  companyId,
  screenIds,
  setting,
  params,
  filters,
  isSingleScreen = false,
  callback,
  saveData = createOrUpdateScreenScheduleTimings
) => {
  const { req: filterTree } = createFilterRequest(
    screenIds.map((id) => ({ id: id, type: constants.TAG_TYPE.SCREEN }))
  );
  const reqPayload = {
    ft: filterTree,
    ...setting,
  };

  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_SAVE_SCHEDULE_TIMINGS_LOADING, true));
      await saveData(companyId, reqPayload);
      showToast("Toast.settingsUpdatedSuccessfully");
      callback();
      isSingleScreen
        ? await dispatch(getScreenSettings({ screenId: screenIds[0] }))
        : await dispatch(
            params.companyId
              ? getUserScreensList(params, filters)
              : getAdminScreensList(params, filters)
          );
      dispatch(actionCreator(types.IS_SAVE_SCHEDULE_TIMINGS_LOADING, false));
    } catch (error) {
      const { response } = error;
      if (response.status === 400 || response.status === 500) {
        showToast(response?.data?.message ?? error.message, false);
      }
      callback();
      isSingleScreen
        ? await dispatch(getScreenSettings({ screenId: screenIds[0] }))
        : await dispatch(
            params.companyId
              ? getUserScreensList(params, filters)
              : getAdminScreensList(params, filters)
          );
      dispatch(actionCreator(types.IS_SAVE_SCHEDULE_TIMINGS_LOADING, false));
      return error;
    }
  };
};

const saveScreensScheduleCutoffTimes = (
  companyId,
  screenIds,
  setting,
  params,
  filters,
  isSingleScreen = false,
  callback,
  saveData = createOrUpdateSettings
) => {
  const { req: filterTree } = createFilterRequest(
    screenIds.map((id) => ({ id: id, type: constants.TAG_TYPE.SCREEN }))
  );
  const reqPayload = {
    ft: filterTree,
    ...setting,
  };

  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_SCREEN_SETTINGS_LOADING, true));
      await saveData(companyId, reqPayload);
      isSingleScreen
        ? await dispatch(getScreenSettings({ screenId: screenIds[0] }))
        : await dispatch(
            params.companyId
              ? getUserScreensList(params, filters)
              : getAdminScreensList(params, filters)
          );
      showToast("Toast.settingsUpdatedSuccessfully");
      dispatch(actionCreator(types.IS_SCREEN_SETTINGS_LOADING, false));
      if (callback) callback();
    } catch (error) {
      const { response } = error;
      if (response.status === 400 || response.status === 500) {
        showToast(response?.data?.message ?? error.message, false);
      }
      isSingleScreen
        ? await dispatch(getScreenSettings({ screenId: screenIds[0] }))
        : await dispatch(
            params.companyId ? getUserScreensList(params) : getAdminScreensList(params)
          );
      callback();
      dispatch(actionCreator(types.IS_SCREEN_SETTINGS_LOADING, false));

      return error;
    }
  };
};

const updateScreenStatus = (
  screenIds,
  setting,
  params,
  filters,
  isSingleScreen = false,
  callback,
  saveData = updateScreenStatusApi
) => {
  const { req: filterTree } = createFilterRequest(
    screenIds.map((id) => ({ id: id, type: constants.TAG_TYPE.SCREEN }))
  );
  const reqPayload = {
    ft: filterTree,
    ...setting,
  };

  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_UPDATE_SCREEN_STATUS_LOADING, true));
      const { data } = await saveData(params.companyId, reqPayload);
      dispatch(actionCreator(types.IS_UPDATE_SCREEN_STATUS_LOADING, false));
      const errorScreens = data?.filter(
        (screen) => screen.activationOrDeactivationStatus === "failed"
      );
      if (errorScreens?.length > 0) {
        const errorCount = {
          count: errorScreens.length === 1 ? "Screen" : `${errorScreens.length} Screens`,
        };
        showToast("Toast.statusUpdateFailed", false, constants.DEFAULT_TOAST_TIMEOUT, errorCount);
      } else {
        showToast("Toast.settingsUpdatedSuccessfully");
      }
      if (callback) callback();
      isSingleScreen
        ? await dispatch(getScreenSettings({ screenId: screenIds[0] }))
        : await dispatch(
            params.companyId
              ? getUserScreensList(params, filters)
              : getAdminScreensList(params, filters)
          );
    } catch (error) {
      const { response } = error;
      dispatch(actionCreator(types.IS_UPDATE_SCREEN_STATUS_LOADING, false));
      if (response?.status === 400 || response?.status === 500) {
        showToast(response?.data?.message ?? error.message, false);
      }
      if (callback) callback();
      isSingleScreen
        ? await dispatch(getScreenSettings({ screenId: screenIds[0] }))
        : await dispatch(
            params.companyId
              ? getUserScreensList(params, filters)
              : getAdminScreensList(params, filters)
          );
      return error;
    }
  };
};

export {
  getAdminScreensList,
  getUserScreensList,
  getScreen,
  resetScreenData,
  getScreenRights,
  getScreenRightsCount,
  editScreenRight,
  editScreenRightById,
  pauseOrResumeScreens,
  getScreenPlaylist,
  saveScreenRight,
  createScreenRight,
  cancelEditScreenRight,
  getScreenRateCards,
  assignTheatreScreenPlaylist,
  assignNewPlaylistTemplate,
  editPlaylistTemplate,
  updateAssignPlaylist,
  getScreenSettings,
  editScreenSettingsById,
  cancelSettingEdit,
  saveScreensScheduleTimings,
  saveScreensScheduleCutoffTimes,
  updateScreenStatus,
};
