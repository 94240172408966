import React, { createRef, Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icons } from "prefab";

import styles from "../../styles/FileUpload.module.scss";
import LocalizedButton from "./LocalizedButton";

const { CancelRoundedIcon } = Icons;

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.fileImport = createRef();
    this.state = {
      fileList: [],
      error: "",
      isDragging: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUploadError !== prevProps.isUploadError) {
      this.setState({
        fileList: [],
      });
    }
  }

  handleFileDragOver = (event, isDragging) => {
    event.preventDefault();

    this.setState({
      isDragging,
    });
  };

  readFileAndTrigger = (file) =>
    new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve({ name: file.name, content: fileReader.result });
      };
      fileReader.readAsText(file);
    });

  validateAndReadFileContent = (files) => {
    const { mimeType, errorMessage, multiple } = this.props;
    const fileList = Array.prototype.slice.call(files);

    if (fileList.findIndex((file) => mimeType !== file.type) === -1) {
      Promise.all(fileList.map((file) => this.readFileAndTrigger(file))).then((result) => {
        this.props.onSelect(multiple ? result : result[0]);
        this.setState({ fileList: result });
      });
    } else {
      this.setState({ error: errorMessage });
    }
  };

  handleRemoveFile = (fileIndex) => {
    const { fileList } = this.state;
    const filteredFiles = fileList.filter((f, index) => index !== fileIndex);
    this.props.onSelect(filteredFiles);
    this.setState({
      fileList: filteredFiles,
    });
  };

  handleFileDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      this.validateAndReadFileContent(e.dataTransfer.files);
    } else {
      return;
    }
  };

  renderUploadedFile = () => {
    const { fileList } = this.state;
    const { showRemove } = this.props;
    if (fileList.length === 0) return null;

    return fileList.map((fileContent, index) => {
      return (
        <div
          key={index}
          className={styles.fileContainer}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => e.preventDefault()}
        >
          <div className={styles.fileDetails}>
            {this.props.name ? (
              <div className={styles.name}>{this.props.name}</div>
            ) : (
              <div className={styles.fileName}>{fileContent?.name ? fileContent.name : ""}</div>
            )}
            {showRemove && (
              <div className={styles.remove} onClick={() => this.handleRemoveFile(index)}>
                <CancelRoundedIcon />
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  renderFormContent = () => {
    const { title, icon, acceptableFormats, name, description, multiple, isLoading } = this.props;
    const { fileList, showRemove, isDragging } = this.state;

    if (isLoading) {
      return (
        <div className={classNames(styles.fileContainer, styles.loadingContainer)}>
          <div className={styles.loader} />
          <span>Uploading</span>
        </div>
      );
    } else if (fileList.length === 0 && !name) {
      return (
        <div
          className={classNames(styles.fileUpload, isDragging && styles.active)}
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={(event) => this.handleFileDragOver(event, true)}
          onDragLeave={(event) => this.handleFileDragOver(event, false)}
          onDrop={this.handleFileDrop}
        >
          <LocalizedButton
            text={title}
            iconName={icon}
            onClick={() => this.fileImport.current.click()}
          />
          <input
            multiple={multiple}
            type="file"
            accept={acceptableFormats}
            style={{ display: "none" }}
            ref={this.fileImport}
            onChange={(event) => this.validateAndReadFileContent(event?.target?.files)}
          />
          <div className={styles.dragDropOption} onClick={() => this.fileImport.current.click()}>
            {description}
          </div>
        </div>
      );
    } else {
      if (fileList.length === 0) return null;
      return fileList.map((fileContent, index) => {
        return (
          <div
            key={index}
            className={styles.fileContainer}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => e.preventDefault()}
          >
            <div className={styles.fileDetails}>
              {this.props.name ? (
                <div className={styles.name}>{this.props.name}</div>
              ) : (
                <div className={styles.fileName}>{fileContent?.name ? fileContent.name : ""}</div>
              )}
              {showRemove && (
                <div className={styles.remove} onClick={() => this.handleRemoveFile(index)}>
                  <CancelRoundedIcon />
                </div>
              )}
            </div>
          </div>
        );
      });
    }
  };

  render() {
    const { error } = this.state;
    return (
      <div>
        {this.renderFormContent()}
        {error && <div className={styles.errorMessage}>{error}</div>}
      </div>
    );
  }
}

FileUpload.propTypes = {
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  acceptableFormats: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  fileTypes: PropTypes.string,
  errorMessage: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  multiple: PropTypes.bool,
  showRemove: PropTypes.bool,
};

FileUpload.defaultProps = {
  description: "Drag and Drop",
  icon: "UploadIcon",
  errorMessage: "Please upload an valid file",
  multiple: false,
  showRemove: false,
};

export default FileUpload;
