import React from "react";
import { BaseButton } from "prefab";
import classNames from "classnames";
import { BUTTON_TYPE, SIZE, ICON_POS } from "prefab";
import LocalizedButton from "../common/LocalizedButton";
import BulkActionButtonsStyles from "../../styles/BulkActionButtons.module.scss";

export default function BulkActionButtons({
  buttons = [],
  selection = [],
  isOpen = false,
  onClose = () => {},
}) {
  if (!isOpen) return null;
  return (
    <div className={BulkActionButtonsStyles.actionButtonsContainer}>
      <div className={BulkActionButtonsStyles.selectionInfoContainer}>
        <div className="flex align-center justify-start">
          {selection.map(({ count, label }, index) => (
            <div className={BulkActionButtonsStyles.selectionInfo} key={index}>
              <span>{count}</span> {label} {index + 1 === selection.length ? "Selected" : null}
            </div>
          ))}
        </div>
        <BaseButton
          iconName="CancelIcon"
          buttonType={BUTTON_TYPE.SECONDARY}
          buttonSize={SIZE.SMALL}
          onClick={onClose}
        />
      </div>
      <div
        className={classNames(
          "flex align-center justify-start",
          BulkActionButtonsStyles.buttonList
        )}
      >
        {buttons.map(
          (
            {
              text = "",
              onClick,
              icon,
              className = "",
              disabled = false,
              checkScope = false,
              scope,
              scopeAction,
              userData,
              isIconRight = false,
              isHidden,
            },
            index
          ) => {
            const withScopeProps = checkScope
              ? {
                  scope,
                  scopeAction,
                  userData,
                }
              : null;
            return (
              !isHidden && (
                <LocalizedButton
                  className={className}
                  buttonType={BUTTON_TYPE.SECONDARY}
                  iconName={icon}
                  iconPos={isIconRight ? ICON_POS.RIGHT : ICON_POS.LEFT}
                  onClick={onClick}
                  text={text}
                  key={index}
                  disabled={disabled}
                  {...withScopeProps}
                />
              )
            );
          }
        )}
      </div>
    </div>
  );
}
