import React, { Component } from "react";
import moment from "moment";
import classNames from "classnames";
import { isEmpty, sum, keys, map, values } from "lodash";
import { Icons } from "prefab";
import Form from "../../../../common/Form";
import SecondaryTable from "../../../../common/SecondaryTable";
import InfoBlock from "../../../../common/InfoBlock";
import styles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import { DAY_OF_WEEK, FORM_FIELD_TYPE } from "../../../../../constants";
import { searchSegmentTypes, fetchPlaylistPackTypes } from "../../../../../api";

const { AddIcon, AddFilledIcon, RemoveIcon } = Icons;

export class MediaPlacement extends Component {
  state = {
    showAdvanced: false,
    placementTable: [],
  };

  componentDidMount() {
    this.setState({
      placementTable: this.generatePlayData(),
    });
  }

  generatePlayData = (modifiedPlacement = null) => {
    const { timesOfDay } = this.props;
    const placement = modifiedPlacement ?? this.props.placement;
    return timesOfDay.map((item) => {
      const currentPlay = placement?.playsPerDay.find((p) => p.timeOfDayId === item.id);
      const plays = {};
      DAY_OF_WEEK.map(
        ({ value }) =>
          (plays[value] = currentPlay?.dayOfWeek === value ? currentPlay?.numberOfPlays : 0)
      );
      return {
        plays: plays,
        timeOfDayId: item.id,
      };
    });
  };

  onChange = (value, key) => {
    const { index, media, onInputChange, placement } = this.props;
    const modifiedPlacement = { ...placement, [key]: value };
    const numberOfPlays = sum(modifiedPlacement?.playsPerDay.map((p) => p.numberOfPlays));
    modifiedPlacement.numberOfPlays = numberOfPlays;
    const modifiedPlacements = media.placements.map((p, i) =>
      i === index ? modifiedPlacement : p
    );
    onInputChange(modifiedPlacements, "placements");
  };

  filterPlacementData = (placementsData) => {
    const filteredData = [];
    map(placementsData, (item) => {
      const dayList = keys(item.plays).filter((day) => item.plays[day] > 0);
      dayList.map((day) =>
        filteredData.push({
          timeOfDayId: item.timeOfDayId,
          dayOfWeek: parseInt(day),
          numberOfPlays: item.plays[day],
        })
      );
    });

    return filteredData;
  };

  modifyPlay = (timeOfDayId, day, count) => {
    let { placementTable } = this.state;
    placementTable = map(placementTable, (item) => {
      if (item.timeOfDayId === timeOfDayId) item.plays[day] += count;
      return item;
    });
    const playsPerDay = this.filterPlacementData(placementTable);
    this.onChange(playsPerDay, "playsPerDay");
    this.setState({
      placementTable,
    });
  };

  modifyPlaysByRowOrColumn = (data, count, type) => {
    let { placementTable } = this.state;

    placementTable = map(placementTable, (item) => {
      if (type === "day") {
        item.plays[data] += count;
      } else if (item.timeOfDayId === data && type === "timeOfDay") {
        keys(item.plays).map((day) => (item.plays[day] += count));
      }
      return item;
    });
    const playsPerDay = this.filterPlacementData(placementTable);
    this.onChange(playsPerDay, "playsPerDay");
    this.setState({
      placementTable,
    });
  };

  renderHeader = (day, dayValue) => {
    const { placementTable } = this.state;
    const playsByDay = placementTable.map((item) => item.plays[dayValue]);
    return (
      <div className={styles.placementHeader}>
        <span
          className={classNames({ [styles.decrementDisabled]: playsByDay.includes(0) })}
          onClick={() => this.modifyPlaysByRowOrColumn(dayValue, -1, "day")}
        >
          <RemoveIcon width={16} height={16} />
        </span>
        <div>{day}</div>
        <span onClick={() => this.modifyPlaysByRowOrColumn(dayValue, 1, "day")}>
          <AddFilledIcon width={16} height={16} />
        </span>
      </div>
    );
  };

  renderPlay = (d, dayValue) => {
    return (
      <div className={styles.placementContent}>
        <span
          className={classNames({ [styles.decrementDisabled]: d.plays[dayValue] === 0 })}
          onClick={() => this.modifyPlay(d.timeOfDayId, dayValue, -1)}
        >
          <RemoveIcon width={16} height={16} />
        </span>
        <div> {d.plays[dayValue] || 0} </div>
        <span onClick={() => this.modifyPlay(d.timeOfDayId, dayValue, 1)}>
          <AddFilledIcon width={16} height={16} />
        </span>
      </div>
    );
  };

  render() {
    const { timesOfDay = [], onInputChange, placement, hasFormSaved } = this.props;
    const { showAdvanced, placementTable } = this.state;
    return (
      <>
        <Form
          isFormGroup={false}
          config={[
            {
              id: "numberOfPlays",
              title: "Number of Plays",
              type: FORM_FIELD_TYPE.NUMERIC_INPUT,
              size: {
                lg: 2,
                md: 2,
              },
              placeholder: "Enter Plays Per Screen",
              value: placement?.numberOfPlays,
              disabled: showAdvanced,
              errorMessage: "Please Enter Plays Per Screen",
              error: hasFormSaved && !placement?.numberOfPlays,
              onValueChange: (value) => onInputChange(value, "numberOfPlays"),
            },
            {
              type: FORM_FIELD_TYPE.API_SELECT,
              id: "segmentTypeId",
              label: "Segment Type",
              placeholder: "Select Segment Type",
              size: {
                lg: 3,
                md: 3,
              },
              fetchAction: () => searchSegmentTypes(-1, 0, "name"),
              parseResults: (response) =>
                response.data.map((item) => ({
                  id: item.id,
                  value: item.name,
                })),
              selected: (list) => list.filter((item) => item.id === placement?.segmentTypeId),
              singleSelect: true,
              showLabelInButton: false,
              errorMessage: "Please Select Segment Type",
              error: hasFormSaved && !placement?.segmentTypeId,
              onSelect: ([data]) => this.onChange(data.id, "segmentTypeId"),
            },
            {
              type: FORM_FIELD_TYPE.API_SELECT,
              id: "playlistPackTypeId",
              label: "Playlist Pack Type",
              placeholder: "Select Playlist Pack Type",
              fetchAction: () => fetchPlaylistPackTypes(),
              size: {
                lg: 4,
                md: 4,
              },
              parseResults: (response) =>
                response.map((item) => ({
                  id: item.id,
                  value: item.name,
                })),
              selected: (list) => list.filter((item) => item.id === placement?.playlistPackTypeId),
              singleSelect: true,
              showLabelInButton: false,
              errorMessage: "Please Select Playlist Pack Type",
              error: hasFormSaved && !placement?.segmentTypeId,
              onSelect: ([data]) => this.onChange(data.id, "playlistPackTypeId"),
            },
            {
              isLabel: true,
              label: showAdvanced ? "Hide Advanced Option" : "Advanced Options",
              size: {
                lg: 3,
                md: 3,
              },
              containerClassName: styles.placementOption,
              onClick: () => this.setState({ showAdvanced: !showAdvanced }),
            },
          ]}
        />
        {showAdvanced && (
          <SecondaryTable
            data={placementTable}
            bodyColumnClass={styles.tableColumn}
            rowHeader={styles.headerColumn}
            columns={[
              {
                id: "timeOfDay",
                Header: "",
                accessor: (d) => {
                  const timeOfDay = timesOfDay.find((day) => day.id === d.timeOfDayId);
                  const playsByTime = placementTable.find(
                    (item) => item.timeOfDayId === timeOfDay.id
                  );
                  if (!timeOfDay) return "";
                  return (
                    <div className={styles.timeOfDayHeader}>
                      <span
                        className={classNames({
                          [styles.decrementDisabled]: values(playsByTime.plays).includes(0),
                        })}
                        onClick={() => this.modifyPlaysByRowOrColumn(timeOfDay.id, -1, "timeOfDay")}
                      >
                        <RemoveIcon width={16} height={16} />
                      </span>
                      <div className={styles.timeOfDayContent}>
                        <div className={styles.placementsTimeName}>{timeOfDay.name}</div>
                        <div className={styles.placementsTimeRange}>
                          {moment(timeOfDay.startTime, "HH:mm:ss").format("hh:mm a")}-
                          {moment(timeOfDay.endTime, "HH:mm:ss").format("hh:mm a")}
                        </div>
                      </div>
                      <span
                        onClick={() => this.modifyPlaysByRowOrColumn(timeOfDay.id, 1, "timeOfDay")}
                      >
                        <AddFilledIcon width={16} height={16} />
                      </span>
                    </div>
                  );
                },
                width: 160,
              },
              ...DAY_OF_WEEK.map((dayOfWeek) => ({
                id: dayOfWeek.value,
                Header: this.renderHeader(dayOfWeek.label, dayOfWeek.value),
                accessor: (d) => this.renderPlay(d, dayOfWeek.value),
              })),
            ]}
          />
        )}
      </>
    );
  }
}

const validateAddPlacement = (data) =>
  data.filter((item) => {
    return (
      !isEmpty(item?.playlistPackTypeId) &&
      !isEmpty(item?.segmentTypeId) &&
      item?.playsPerDay.filter((item) => isEmpty(item.timeOfDayId)).length === 0
    );
  }).length === data.length;

const PlacementOptions = (props) => {
  return (
    <InfoBlock header="Number of Plays & Placement" childClassName={styles.childWrapper}>
      <div
        className={classNames(
          "flex flex-wrap clearfix",
          styles.formContainer,
          styles.basicDetails,
          styles.placementsWrapper
        )}
      >
        {props.media.placements.map((placement, index) => (
          <MediaPlacement {...props} key={index} index={index} placement={placement} />
        ))}

        <div
          className={classNames(styles.addItem, {
            [styles.disabled]: !validateAddPlacement(props.media.placements),
          })}
          onClick={() => props.addNewPlacement()}
        >
          <AddIcon />
          <span>Add Another Placement</span>
        </div>
      </div>
    </InfoBlock>
  );
};

export default PlacementOptions;
