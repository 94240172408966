import React, { Component } from "react";
import { isEmpty } from "lodash";

import styles from "../../../../styles/Campaigns/CampaignTree.module.scss";
import InfoBlock from "../../../common/InfoBlock";
import CardSkeletonLoader from "./cards/CardSkeletonLoader";
import CampaignCard from "./cards/CampaignCard";
import TargetGroupCard from "./cards/TargetGroupCard";
import MediaCard from "./cards/MediaCard";

export default class CampaignTree extends Component {
  state = {
    isExpanded: true,
  };

  static defaultProps = {
    isNewCampaign: false,
  };

  handleCardToggle = (status) => {
    this.setState({
      isExpanded: status,
    });
  };

  renderTargetGroups = () => {
    const { campaignTreeData } = this.props;
    const { isExpanded } = this.state;
    const targetGroupList = campaignTreeData?.targetGroups ?? [];
    return targetGroupList.map((targetGroup, index) => {
      const mediaList = targetGroup?.media ?? [];

      return (
        <TargetGroupCard
          key={index}
          title={targetGroup.name || "New Target Group"}
          targetGroupData={targetGroup}
          isExpanded={isExpanded}
        >
          {!isEmpty(mediaList) &&
            mediaList.map((media, index) => (
              <MediaCard key={index} mediaData={media} title={media.name || "New Media"} />
            ))}
        </TargetGroupCard>
      );
    });
  };

  render() {
    const { campaignTreeData, isLoading } = this.props;

    if (isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <CardSkeletonLoader />
          <CardSkeletonLoader />
          <CardSkeletonLoader />
          <CardSkeletonLoader />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <InfoBlock header="Campaign Summary" className={""}>
          <CampaignCard
            campaignData={campaignTreeData}
            onCardToggle={(status) => this.handleCardToggle(status)}
          >
            {this.renderTargetGroups()}
          </CampaignCard>
        </InfoBlock>
      </div>
    );
  }
}
