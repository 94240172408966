import React from "react";
import moment from "moment";
import { values, groupBy, sumBy } from "lodash";
import InfoBlock from "../../../common/InfoBlock";
import SecondaryTable from "../../../common/SecondaryTable";
import { DAY_OF_WEEK } from "../../../../constants";
import styles from "../../../../styles/Campaigns/Campaigns.module.scss";

const MediaPlacements = ({ media, timesOfDay, header = "Placement & Number Of Plays" }) => (
  <InfoBlock
    header={header}
    children={
      <div className={styles.placementsWrapper}>
        {media.placements.map((placement, index) => {
          const modifiedPlaysPerDay = placement.playsPerDay.map((play) => ({
            [DAY_OF_WEEK[play.dayOfWeek].label]: play.numberOfPlays,
            timeOfDayId: play.timeOfDayId,
          }));
          const plays = values(groupBy(modifiedPlaysPerDay, "timeOfDayId")).map((play) => {
            return play.reduce((merge, value) => {
              return {
                ...merge,
                ...value,
              };
            }, {});
          });

          if (modifiedPlaysPerDay.length === 0) return <div key="no-data">No Data</div>;
          return (
            <InfoBlock
              key={index}
              header={`Placement ${index + 1}`}
              isSubHeader
              cardType="secondary"
              config={[
                {
                  title: "Number of Plays Per Week",
                  value: sumBy(placement.playsPerDay, "numberOfPlays"),
                  size: 3,
                },
                {
                  title: "Playlist Pack",
                  value: placement.playlistPackName,
                  size: 3,
                },
                {
                  title: "Segment Type",
                  value: placement.segmentTypeName,
                  size: 3,
                },
                {
                  title: "Position",
                  value: placement.position || "Any",
                  size: 3,
                },
                {
                  title: "",
                  size: {
                    lg: 12,
                  },
                  value: (
                    <SecondaryTable
                      data={plays}
                      columns={[
                        {
                          id: "timeOfDay",
                          Header: "",
                          accessor: (d) => {
                            const timeOfDay = timesOfDay.find((day) => day.id === d.timeOfDayId);
                            return timeOfDay ? (
                              <>
                                <div className={styles.placementsTimeName}>{timeOfDay.name}</div>
                                <div className={styles.placementsTimeRange}>
                                  {moment(timeOfDay.startTime, "HH:mm:ss").format("hh:mm a")}-
                                  {moment(timeOfDay.endTime, "HH:mm:ss").format("hh:mm a")}
                                </div>
                              </>
                            ) : (
                              ""
                            );
                          },
                          width: 128,
                        },
                        {
                          id: "mon",
                          Header: "Mon",
                          accessor: (d) => d.Mon || 0,
                        },
                        {
                          id: "tue",
                          Header: "Tue",
                          accessor: (d) => d.Tue || 0,
                        },
                        {
                          id: "wed",
                          Header: "Wed",
                          accessor: (d) => d.Wed || 0,
                        },
                        {
                          id: "thu",
                          Header: "Thu",
                          accessor: (d) => d.Thu || 0,
                        },
                        {
                          id: "fri",
                          Header: "Fri",
                          accessor: (d) => d.Fri || 0,
                        },
                        {
                          id: "sat",
                          Header: "Sat",
                          accessor: (d) => d.Sat || 0,
                        },
                        {
                          id: "sun",
                          Header: "Sun",
                          accessor: (d) => d.Sun || 0,
                        },
                      ]}
                    />
                  ),
                },
              ]}
            />
          );
        })}
      </div>
    }
  />
);

export default MediaPlacements;
