import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { map, isEmpty } from "lodash";
import moment from "moment";
import queryString from "query-string";
import classNames from "classnames";

import pageStyles from "../../../../../styles/App.module.scss";
import { bindDispatch } from "../../../../../utils";
import Breadcrumb from "../../../../common/BreadCrumb";
import { THEATRE_CRITERIA_MENU_LIST, CAMPAIGN_STATUSES } from "../../../../../constants";
import PageLoadingWithTabs from "../../../../common/PageLoadingWithTabs";
import InfoBlockLoading from "../../../../common/InfoBlockLoading";
import CampaignSteps from "../CampaignSteps";
import campaignFormStyles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import { validateLocationCriteria, validateTheatreCriteria } from "../../../../../utils";
import styles from "../../../../../styles/CampaignCreate/TargetGroup.module.scss";
import CampaignTree from "../../CampaignStructure/CampaignTree";
import CampaignPageHeader from "../../common/CampaignPageHeader";
import TargetGroupList from "../../common/pages/TargetGroupDetails";
import { CAMPAIGN_STEPS } from "../../../../../constants";
import { validateMandatoryTargetGroupFields, campaignStepChange } from "../../utils/campaignCreate";
import EmptyState from "../EmptyState";
import CreateTargetGroup from "./CreateTargetGroup";
import campaignRouteHandler from "..";

const links = [
  { name: "PageHeader.campaigns", path: "/campaigns" },
  { name: "PageHeader.newTargetGroup" },
];

export class TargetGroup extends Component {
  getInitialState = () => ({
    targetGroup: {
      name: "",
      validity: {},
      criteria: {
        inclusions: [],
        exclusions: [],
      },
      status: CAMPAIGN_STATUSES.DRAFT,
    },
    isEdit: false,
    hasFormSaved: false,
    isFormEdited: false,
    isFetchingPreferences: false,
    isValidTargetGroup: true,
    isPageLoading: !this.props.isCampaignInvalid,
  });

  state = this.getInitialState();

  static propTypes = {
    isCampaignInvalid: PropTypes.bool,
  };

  static defaultProps = {
    isCampaignInvalid: false,
  };

  static defaultProps = {
    campaign: null,
  };

  componentDidMount = async () => {
    const {
      match: {
        params: { action },
      },
      campaignCreate: { campaign, campaignTargetGroup },
      actions,
      history,
      isCampaignInvalid,
    } = this.props;
    let targetGroupData = this.state.targetGroup;
    const query = queryString.parse(history.location.search);
    await actions.updateActiveStep(CAMPAIGN_STEPS.TARGET_GROUP);
    if (isCampaignInvalid) return false;

    if (action === "create") {
      if (query?.id) {
        await actions.getTargetGroupById(query?.id, "duplicate");
        if (isEmpty(campaignTargetGroup)) {
          this.setState({
            isValidTargetGroup: false,
            isPageLoading: false,
          });
        } else {
          targetGroupData = campaignTargetGroup;
        }
      } else {
        targetGroupData = {
          ...targetGroupData,
          validity: campaign.validity,
          maxPlaysPerScreen: campaign.maxPlaysPerScreen,
        };
        await actions.updateNewCampaignTargetGroup(targetGroupData, "create");
      }
    } else if (action === "list") {
      await actions.updateTargetGroupAction(action);
    } else {
      await actions.getTargetGroupById(action, "edit");
      if (isEmpty(campaignTargetGroup)) {
        this.setState({
          isValidTargetGroup: false,
          isPageLoading: false,
        });
      } else {
        targetGroupData = campaignTargetGroup;
      }
    }

    this.setState({
      targetGroup: targetGroupData,
      isPageLoading: false,
    });
  };

  componentDidUpdate(prevProps) {
    const {
      match: { params },
      campaignCreate: { targetGroupAction, campaignTargetGroups, campaignTargetGroup },
      actions,
      history,
    } = this.props;
    if (
      campaignTargetGroups?.data?.length === 0 &&
      prevProps.campaignCreate.campaignTargetGroups?.data?.length !==
        campaignTargetGroups?.data?.length
    ) {
      if (campaignTargetGroups.data?.length === 0 && targetGroupAction === "list") {
        history.push(`/campaign-create/${params.campaignId}/target-groups/create`);
        actions.updateTargetGroupAction("create");
      }
    }

    if (prevProps.campaignCreate.targetGroupAction !== targetGroupAction) {
      if (targetGroupAction === "list") {
        history.push(`/campaign-create/${params.campaignId}/target-groups/list`);
      }

      if (targetGroupAction === "edit" || targetGroupAction === "duplicate") {
        if (!params.id && targetGroupAction === "edit")
          history.push(
            `/campaign-create/${params.campaignId}/target-groups/${campaignTargetGroup.id}`
          );
        this.setState({
          targetGroup: campaignTargetGroup,
        });
      }
    }
  }

  onInputChange = (data, key) => {
    const { targetGroup } = this.state;
    if (key === "validity") {
      const [fromDate, toDate] = data;
      const validity = {
        fromDate: fromDate
          ? moment(fromDate).format("YYYY-MM-DD")
          : targetGroup.validity?.fromDate ?? null,
        toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : targetGroup.validity?.toDate ?? null,
      };
      targetGroup[key] = validity;
    } else {
      targetGroup[key] = data;
    }
    this.setState({
      targetGroup: targetGroup,
      isFormEdited: true,
    });
  };

  updateDefaultValues = (data) => {
    const { accessors } = THEATRE_CRITERIA_MENU_LIST.find((item) => item.id === data.type);
    const criteriaValues = {};
    map(accessors, (key) => {
      criteriaValues[key] = {
        rangeFrom: data[key].rangeFrom || 0,
        rangeTo: data[key].rangeTo || Infinity,
      };
    });

    return {
      ...data,
      ...criteriaValues,
    };
  };

  updateCriteriaList = (data, listType, targetType, isRemoved = false) => {
    const {
      targetGroup: { criteria },
    } = this.state;
    const criteriaList = {
      ...data,
      targetGroupType: targetType,
    };
    const errors = [];

    if (isRemoved) {
      this.setState({
        targetGroup: {
          ...this.state.targetGroup,
          criteria: data,
        },
        errors,
      });

      return false;
    }

    if (targetType === "location") {
      const locationCriteriaErrors = validateLocationCriteria(criteria, criteriaList, listType);
      if (locationCriteriaErrors.length === 0) criteria[listType].push(criteriaList);
      errors.push(...locationCriteriaErrors);
    } else if (targetType === "theatre") {
      const theatreCriteriaErrors = validateTheatreCriteria(criteria, criteriaList, listType);
      if (theatreCriteriaErrors.length === 0)
        criteria[listType].push(this.updateDefaultValues(criteriaList));
      errors.push(...theatreCriteriaErrors);
    }

    this.setState({
      targetGroup: {
        ...this.state.targetGroup,
        criteria: criteria,
      },
      errors,
    });
  };

  // TODO: Update URL once flow is completed
  handleTargetGroupDelete = (targetGroupId) => {
    const {
      actions,
      match: { params },
    } = this.props;
    actions.deleteTargetGroup(params, targetGroupId);
  };

  handleCampaignDelete = async () => {
    const {
      history,
      match: { params },
    } = this.props;
    await this.props.actions.deleteCampaign(params.campaignId);
    history.push(`/campaign-create`);
    this.setState({
      hasFormSaved: false,
      isFormEdited: false,
    });
  };

  handleTargetGroupCreate = async () => {
    const {
      actions,
      match: { params },
      history,
    } = this.props;
    history.push(`/campaign-create/${params.campaignId}/target-groups/create`);
    await actions.updateTargetGroupAction("create");
  };

  handleTargetGroupEdit = (targetGroupId) => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;
    if (params.campaignId) {
      actions.getTargetGroupById(targetGroupId, "edit");
      history.push(`/campaign-create/${params.campaignId}/target-groups/${targetGroupId}`);
    }
  };

  handleTargetGroupDuplicate = (targetGroupId) => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;

    actions.getTargetGroupById(targetGroupId, "duplicate");
    history.push(`/campaign-create/${params.campaignId}/target-groups/create?id=${targetGroupId}`);
  };

  handleFormReset = () => {
    const {
      match: {
        params: { action },
      },
      campaignCreate: { campaignTargetGroup },
    } = this.props;
    if (action !== "list" && action !== "create") {
      this.setState({
        targetGroup: campaignTargetGroup,
        hasFormSaved: false,
        isFormEdited: false,
      });
    } else {
      this.setState({
        ...this.getInitialState(),
        isPageLoading: false,
      });
    }
  };

  handleSaveTargetGroupAsDraft = async () => {
    const { targetGroup } = this.state;
    const {
      match: { params },
      actions,
    } = this.props;

    if (targetGroup.name) {
      targetGroup.campaignId = params.campaignId;
      await actions.createOrUpdateTargetGroup(params.campaignId, targetGroup, "edit");
    }

    this.setState({
      hasFormSaved: true,
      isFormEdited: false,
    });
  };

  handleChangeStep = (preferredStep) => {
    const {
      match: { params },
      history,
      campaignCreate: { activeStep, targetGroupAction },
      actions,
    } = this.props;
    const { targetGroup } = this.state;
    if (targetGroupAction === "list") {
      history.push(`/campaign-create/${params.campaignId}/media`);
      return false;
    }

    if (preferredStep === activeStep + 1 && params.campaignId) {
      if (
        params.action !== "list" &&
        validateMandatoryTargetGroupFields(targetGroup, targetGroupAction)
      ) {
        targetGroup.campaignId = params.campaignId;
        actions.createOrUpdateTargetGroup(params.campaignId, targetGroup, "list");
      } else {
        history.push(`/campaign-create/${params.campaignId}/media`);
      }
    } else {
      campaignStepChange(history, params, activeStep, preferredStep);
    }

    this.setState({
      hasFormSaved: true,
      isFormEdited: false,
    });
  };

  renderCampaignTree = () => {
    const {
      isCampaignInvalid,
      campaignCreate: { campaign, targetGroupAction, campaignTargetGroups },
    } = this.props;
    const { targetGroup, isValidTargetGroup } = this.state;
    if (isCampaignInvalid || !isValidTargetGroup) return null;

    return (
      <div className={styles.campaignTree}>
        <CampaignTree
          isNewCampaign
          isLoading={false}
          campaignTreeData={{
            ...campaign,
            targetGroups: targetGroupAction === "list" ? campaignTargetGroups?.data : [targetGroup],
          }}
        />
      </div>
    );
  };

  renderTargetGroupSection = () => {
    const {
      isCampaignInvalid,
      actions,
      campaignCreate: {
        campaign,
        campaignTargetGroups,
        targetGroupAction,
        isTargetGroupLoading,
        isTargetGroupDetailsLoading,
      },
    } = this.props;
    const { targetGroup, hasFormSaved, errors, isPageLoading, isValidTargetGroup } = this.state;
    if (isCampaignInvalid || !isValidTargetGroup)
      return <EmptyState type={isValidTargetGroup ? "campaign" : "TargetGroup"} />;

    if (isPageLoading) return <InfoBlockLoading />;

    if (targetGroupAction === "list") {
      return (
        <TargetGroupList
          targetGroups={campaignTargetGroups?.data}
          onEdit={this.handleTargetGroupEdit}
          onDuplicate={this.handleTargetGroupDuplicate}
          onDelete={this.handleTargetGroupDelete}
          showAdd
          isLoading={isTargetGroupLoading}
          isDetailsLoading={isTargetGroupDetailsLoading}
          countries={campaign.countries}
          getTargetGroupDetails={actions.getTargetGroupById}
          onTargetGroupCreate={this.handleTargetGroupCreate}
        />
      );
    } else {
      return (
        <CreateTargetGroup
          campaign={campaign}
          targetGroups={campaignTargetGroups.data}
          hasFormSaved={hasFormSaved}
          targetGroup={targetGroup}
          onInputChange={this.onInputChange}
          criteria={targetGroup.criteria}
          errors={errors}
          onCriteriaListUpdate={this.updateCriteriaList}
        />
      );
    }
  };

  render() {
    const {
      history,
      campaignCreate: { isTargetGroupLoading, campaign, activeStep, campaignMediaList },
    } = this.props;
    const { hasFormSaved, isFormEdited } = this.state;

    if (isTargetGroupLoading) return <PageLoadingWithTabs />;

    return (
      <div className={`col-12 clearfix ${styles.pageWrapper}`}>
        <div className={styles.container}>
          <Breadcrumb history={history} links={links} />
          <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
            <div className={campaignFormStyles.campaignCreateProgressBar}>
              <CampaignPageHeader
                title="PageHeader.newTargetGroup"
                onSaveCampaignAsDraft={this.handleSaveTargetGroupAsDraft}
                onDiscardCampaign={this.handleCampaignDelete}
                isDiscardDisabled={!campaign.id}
              />
              <CampaignSteps
                activeStep={activeStep}
                validTillStep={campaignMediaList.data?.length > 0 ? activeStep + 1 : activeStep}
                hasFormSaved={hasFormSaved}
                disableSave={!campaign.id}
                disableReset={!isFormEdited}
                onChangeStep={this.handleChangeStep}
                onReset={this.handleFormReset}
                isAdminUser={this.validateCreateCampaignAccess}
              />
            </div>
            <div className={classNames("col col-12", campaignFormStyles.pageContent)}>
              {this.renderTargetGroupSection()}
              {this.renderCampaignTree()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.userData,
  (state) => state.campaignCreate,
  (userData, campaignCreate) => ({
    userData: userData.user,
    campaignCreate: campaignCreate,
  })
);

export default connect(mapStateToProps, bindDispatch)(campaignRouteHandler(TargetGroup));
