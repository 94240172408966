import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import { Classes } from "@blueprintjs/core";
import { compact, sum } from "lodash";
import classNames from "classnames";
import moment from "moment";
import ButtonStyles from "../../../styles/Button.module.scss";
import pageStyles from "../../../styles/App.module.scss";
import {
  bindDispatch,
  modifyTableColumns,
  lastUpdatedAtColumn,
  formatValidityPeriod,
  lastUpdatedByColumn,
  checkScopes,
} from "../../../utils";
import PageHeader from "../../common/PageHeader";
import LocalizedButton from "../../common/LocalizedButton";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import TableLoading from "../../common/TableLoading";
import FilterChips from "../../common/Filters/FilterChips";
import WithFetchList from "../../hoc/withFetchList";
import TableControls from "../../common/TableControls";
import FooterControls from "../../common/FooterControls";
import ActionIcon from "../../common/ActionIcon";
import StatusIcon from "../../common/StatusIcon";
import StatusIconLegend from "../../common/StatusIconLegend";
import HeaderButtons from "../../common/HeaderButtons";
import {
  TAG_TYPE,
  DEFAULT_ACTION_ICON_COLUMN,
  SCOPES,
  SCOPE_ACTIONS,
  STATIC_FILTER_TAG_LIST,
  STATUS_ICON_TYPES,
  STATUS_ICON_COLORS,
  SCHEDULE_STATUSES,
  SCHEDULE_MISSING_DATA,
} from "../../../constants";
import { ActiveTableCell } from "../../common/ActiveTableCell";
import { AUDIT_LOG_SOURCE } from "../logs/logSource";
import styles from "../../../styles/Schedules/scheduleList.module.scss";

const { CREATED, DELIVERED, EXPIRED, FAILED } = SCHEDULE_STATUSES;

class SchedulesList extends Component {
  state = {
    selectedColumns: [
      "Schedule Name",
      "Validity",
      "Order Number",
      "Schedule Number",
      "Last Updated On",
      "Last Updated By",
    ],
  };

  componentDidMount() {
    this.props.fetchData();
  }

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  editScheduleDetails = (schedule) => {
    const { actions, history } = this.props;
    actions.getScheduleDetailsById(schedule?.id);
    history.push(`/schedules/${schedule?.id}/edit`);
  };

  validateMissingDataFilter = () => {
    const {
      filters,
      schedules: { schedulesList },
    } = this.props;
    const { SCHEDULE_MISSING_PO_NUMBER } = TAG_TYPE;
    const missingData = compact(
      schedulesList?.data.map(
        (schedule) =>
          schedule.advertiserTypeName === "Government" && !schedule?.customerPurchaseOrderNumber
      )
    );

    if (filters.length === 0) return missingData.length > 0;
    return (
      filters.filter((f) => f.type === SCHEDULE_MISSING_PO_NUMBER).length > 0 ||
      missingData.length > 0
    );
  };

  getScheduleStatusLegends = (schedules) => {
    const { SCHEDULE_STATUS } = TAG_TYPE;
    if (schedules.length === 0) return [];
    const statusList = compact(schedules.map((schedule) => schedule?.status ?? ""));
    const getStatusDisplayName = (status) =>
      STATIC_FILTER_TAG_LIST[SCHEDULE_STATUS].find((d) => d.id === status).displayName;
    const missingData = compact(
      schedules.map(
        (schedule) =>
          schedule.advertiserTypeName === "Government" && !schedule?.customerPurchaseOrderNumber
      )
    );

    const statusLegends = [
      {
        id: CREATED,
        name: getStatusDisplayName(CREATED),
        count: statusList.filter((d) => d === CREATED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.BLUE,
        tagType: TAG_TYPE.SCHEDULE_STATUS,
      },
      {
        id: DELIVERED,
        name: getStatusDisplayName(DELIVERED),
        count: statusList.filter((d) => d === DELIVERED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.GREEN,
        tagType: TAG_TYPE.SCHEDULE_STATUS,
      },
      {
        id: EXPIRED,
        name: getStatusDisplayName(EXPIRED),
        count: statusList.filter((d) => d === EXPIRED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.GREY,
        tagType: TAG_TYPE.SCHEDULE_STATUS,
      },
      {
        id: FAILED,
        name: getStatusDisplayName(FAILED),
        count: statusList.filter((d) => d === FAILED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.RED,
        tagType: TAG_TYPE.SCHEDULE_STATUS,
      },
    ];

    // Only push if data is present to avoid unwanted separator
    if (this.validateMissingDataFilter()) {
      statusLegends.push({
        id: SCHEDULE_MISSING_DATA.id,
        name: SCHEDULE_MISSING_DATA.displayName,
        count: missingData.length,
        type: STATUS_ICON_TYPES.LOGS,
        color: STATUS_ICON_COLORS.RED,
        tagType: TAG_TYPE.SCHEDULE_MISSING_PO_NUMBER,
      });
    }

    return statusLegends;
  };

  renderStatusIconLegend = () => {
    const {
      schedules: { schedulesList, isPartialLoading },
    } = this.props;
    const statusList = this.getScheduleStatusLegends(schedulesList?.data ?? []);

    if (isPartialLoading) {
      return (
        <div
          className={classNames("flex flex-auto flex-wrap", isPartialLoading && Classes.SKELETON)}
        />
      );
    }

    if (sum(statusList.map((status) => status.count)) === 0) return null;

    return (
      <div className={classNames("flex flex-auto flex-wrap align-center")}>
        <StatusIconLegend statusInfo={statusList} onFilterSelect={this.props.onFilterSelect} />
      </div>
    );
  };

  renderStatusIcon = (cellProps) => {
    if (!cellProps?.original?.status) return null;

    const {
      schedules: { schedulesList },
    } = this.props;
    const statusLegends = this.getScheduleStatusLegends(schedulesList.data ?? []);
    const currentStatusLegend = statusLegends.find(
      (status) => status.id === cellProps?.original?.status
    );

    return (
      <div
        className={classNames("flex align-center", styles.status)}
        onClick={(event) => {
          event.stopPropagation();
          this.props.onFilterSelect({
            id: cellProps?.original?.status,
            displayName: STATIC_FILTER_TAG_LIST[TAG_TYPE.SCHEDULE_STATUS].find(
              (d) => d.id === cellProps?.original?.status
            ).displayName,
            type: TAG_TYPE.SCHEDULE_STATUS,
          });
        }}
      >
        <StatusIcon color={currentStatusLegend.color} type={currentStatusLegend.type} />
      </div>
    );
  };

  getMenuList = (cellProps) => {
    if (!cellProps?.original?.status || cellProps?.original?.status === SCHEDULE_STATUSES.EXPIRED)
      return [];

    return [
      {
        toolTip: "Tooltip.update",
        onClick: () => {
          this.editScheduleDetails(cellProps.original);
        },
        iconName: "EditIcon",
      },
    ];
  };

  render() {
    const {
      schedules: { schedulesList, isLoading, isPartialLoading },
      filters,
      onFilterIdSelect,
      onFilterChange,
      onFilterSelect,
      ps,
      page,
      sort,
      onQueryChange,
      isFilterLoading,
      onSortedChange,
      userData,
      history,
    } = this.props;
    const { selectedColumns, reorderedColumns } = this.state;

    const columns = checkScopes(
      [
        {
          id: "name",
          Header: "Schedule Name",
          accessor: "name",
        },
        {
          id: "validFrom",
          Header: "Validity",
          width: 280,
          accessor: (d) => {
            const validity = {
              fromDate: moment(d?.validFrom).utc().format("YYYY-MM-DD") ?? null,
              startTime: moment(d?.validFrom).utc().format("HH:mm") ?? null,
              toDate: moment(d?.validTo).utc().format("YYYY-MM-DD") ?? null,
              endTime: moment(d?.validTo).utc().format("HH:mm") ?? null,
            };
            return formatValidityPeriod(validity, "MMM DD, YYYY HH:mm");
          },
        },
        {
          id: "orderNumber",
          Header: "Order Number",
          accessor: "orderNumber",
          sortable: false,
        },
        {
          id: "subOrderNumber",
          Header: "Sub Order Number",
          accessor: "subOrderNumber",
          sortable: false,
        },
        {
          id: "scheduleNumber",
          Header: "Schedule Number",
          accessor: "scheduleNumber",
          sortable: false,
        },
        {
          id: "customerPurchaseOrderNumber",
          Header: "Customer Purchase Order Number",
          accessor: "customerPurchaseOrderNumber",
          sortable: false,
        },
        {
          id: "cplName",
          Header: "Composition",
          accessor: (d) => ActiveTableCell(d.cplId, d.cplName, () => onFilterIdSelect(d.cplId)),
        },
        lastUpdatedAtColumn(),
        lastUpdatedByColumn(onFilterIdSelect),
        {
          ...DEFAULT_ACTION_ICON_COLUMN,
          scope: SCOPES.SCHEDULES,
          scopeAction: SCOPE_ACTIONS.WRITE,
          width: 96,
          Cell: (d) => {
            const menuList = this.getMenuList(d);
            return (
              <div className={classNames("col-12 flex align-center", styles.actionItems)}>
                {this.renderStatusIcon(d)}
                {menuList.length > 0 && (
                  <div className={"flex align-center"}>
                    <ActionIcon iconProps={this.getMenuList(d)} />
                  </div>
                )}
              </div>
            );
          },
        },
      ],
      userData
    );

    if (isLoading) return <PageLoadingWithTable />;

    return (
      <div>
        <PageHeader
          name="PageHeader.schedules"
          count={schedulesList.totalCount}
          isPartialLoading={isPartialLoading}
          renderRightSideComponent={() => (
            <div className="flex align-center flex-wrap">
              <LocalizedButton
                iconName="AddFilledIcon"
                text="Button.newSchedule"
                className={ButtonStyles.buttonSpacing}
                url="/schedules/create"
                userData={userData}
                scope={SCOPES.SCHEDULES}
                scopeAction={SCOPE_ACTIONS.WRITE}
              />
              <HeaderButtons
                history={history}
                logsButton
                logsPath={`/logs/source/${AUDIT_LOG_SOURCE.SCHEDULE_ORDERS}`}
                userData={userData}
              />
            </div>
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <div
              className={classNames(
                "flex flex-auto flex-wrap",
                pageStyles.statusGroup,
                isPartialLoading && pageStyles.statusGroupLoading
              )}
            >
              {this.renderStatusIconLegend()}
            </div>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={schedulesList.data}
              ps={ps}
              page={page}
              onSearchFilterSelect={onFilterSelect}
              onFilterChange={onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
              tagTypes={[TAG_TYPE.CPL, TAG_TYPE.SCHEDULE, TAG_TYPE.UPDATED_AT]}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={schedulesList.totalCount}
              onFilterChange={onFilterChange}
              loading={isLoading}
            />
            <Table
              data={schedulesList.data}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              loading={isPartialLoading || isFilterLoading}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
            />
            <FooterControls
              pagination
              data={schedulesList}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const SchedulesListWithFilters = WithFetchList("getSchedulesList", {
  sort: [
    {
      id: "updatedAt",
      desc: true,
    },
  ],
})(SchedulesList);

const mapStateToProps = createSelector(
  (state) => state.schedules,
  (state) => state.userData,
  (schedules, userData) => ({ schedules, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(SchedulesListWithFilters);
