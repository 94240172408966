import moment from "moment";
import { isEmpty } from "lodash";

const getCurrentActionPeriod = (data, actionType) => {
  if (isEmpty(data)) return;
  if (data.statusChanges?.length === 0)
    return {
      fromDate: new Date(),
      toDate: null,
    };
  return data.statusChanges?.find(
    (item) => item.status === actionType && moment(item.toDate, "YYYY-MM-DD").isAfter(moment.now())
  );
};
export default getCurrentActionPeriod;
