import React, { Component } from "react";
import ClassNames from "classnames";
import { Tooltip, Position } from "@blueprintjs/core";
import { isEmpty, uniq, isNumber } from "lodash";
import { Icons } from "prefab";

import { formatValidityPeriod } from "../../../../../utils";
import styles from "../../../../../styles/Campaigns/CampaignTree.module.scss";
import tooltipStyles from "../../../../../styles/ToolTip.module.scss";

const { ChevronUpIcon, ChevronDownIcon } = Icons;
const defaultTargetGroupInfo = [
  {
    id: "criteria",
    label: "Target Type",
    value: "Select target type",
  },
  {
    id: "screens",
    label: "Screens",
    value: "0 (0 Theatres)",
  },
  {
    id: "validity",
    label: "Validity",
    value: "Select Target Group validity",
  },
  {
    id: "maxPlaysPerScreen",
    label: "Max Plays Per Screen",
    value: "-",
  },
];

export default class TargetGroupCard extends Component {
  state = {
    isExpanded: this.props.isExpanded,
  };

  static defaultProps = {
    isExpanded: true,
    showCollapse: false,
  };

  handleCardToggle = (isExpanded) => {
    this.setState({
      isExpanded: isExpanded,
    });
  };

  formatTargetTypeValues = (defaultValue, targetGroupData) => {
    const value = targetGroupData && targetGroupData[defaultValue.id];

    if (isEmpty(value) && !isNumber(value)) return defaultValue.value;

    if (defaultValue.id === "validity") {
      return formatValidityPeriod(value, "DD MMM YYYY");
    } else if (defaultValue.id === "criteria") {
      const targetTypeList = [
        ...(targetGroupData.criteria.inclusions || []),
        ...(targetGroupData.criteria.exclusions || []),
      ].map((item) => item.targetGroupType);

      return (
        <Tooltip
          className={tooltipStyles.toolTip}
          boundary={true}
          position={Position.AUTO}
          content={uniq(targetTypeList).join(", ")}
          usePortal={false}
        >
          <div className={styles.title}>{uniq(targetTypeList).join(" ,")}</div>
        </Tooltip>
      );
    }

    return value;
  };

  render() {
    const { title, showCollapse, children, targetGroupData } = this.props;
    return (
      <div className={ClassNames(styles.cardContainer, styles.targetGroupContainer)}>
        <div className={styles.header}>
          <div className={styles.contentWrapper}>
            <div className={styles.titleContent}>
              <Tooltip
                className={styles.toolTip}
                boundary={true}
                position={Position.AUTO}
                content={title}
                usePortal={false}
              >
                <div className={ClassNames(styles.title, styles.targetGroupTitle)}>{title}</div>
              </Tooltip>
            </div>
          </div>
          {showCollapse && (
            <div className={styles.actions}>
              {this.state.isExpanded ? (
                <span onClick={() => this.handleCardToggle(false)}>
                  <ChevronUpIcon />
                </span>
              ) : (
                <span onClick={() => this.handleCardToggle(true)}>
                  <ChevronDownIcon />
                </span>
              )}
            </div>
          )}
        </div>
        <div className={styles.cardItemWrapper}>
          {defaultTargetGroupInfo.map((ItemDefaultData, index) => {
            return (
              <div key={index} className={styles.itemContainer}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>{ItemDefaultData.label}</div>
                </div>
                <div className={styles.infoValue}>
                  {this.formatTargetTypeValues(ItemDefaultData, targetGroupData)}
                </div>
              </div>
            );
          })}
        </div>
        {this.state.isExpanded && (
          <div className={`${styles.child} ${styles.targetGroup}`}>{children}</div>
        )}
      </div>
    );
  }
}
