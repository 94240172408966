// TODO: Update error message once microcopy is updated

const validateLocationCriteria = (criteria, { id, locationInfo, name }, criteriaActionType) => {
  const { inclusions, exclusions } = criteria;
  const locationTypes = ["province", "state", "country"];
  const errors = [];
  const includedParentLocations = inclusions.map(
    (data) =>
      data.targetGroupType === "location" &&
      locationTypes.includes(data.locationInfo.type) &&
      data.id
  );
  const excludedParentLocations = exclusions.map(
    (data) =>
      data.targetGroupType === "location" &&
      locationTypes.includes(data.locationInfo.type) &&
      data.id
  );

  // Checking duplicates in exclusions and inclusions
  if (exclusions.map((data) => data.id).includes(id)) {
    errors.push(`${name} ${locationInfo.type} is added already in exclusions`);
  } else if (inclusions.map((data) => data.id).includes(id)) {
    errors.push(`${name} ${locationInfo.type} is added already in inclusions`);
  } else if (locationInfo.type === "city" && locationInfo.province) {
    // If added location is city, check its country or province is added in same criteriaActionType
    const { province } = locationInfo;
    const provinceList =
      criteriaActionType === "exclusions" ? excludedParentLocations : includedParentLocations;
    if (province.country && provinceList.includes(province.country.uuid)) {
      errors.push(
        `${province.country.name} is already added as ${criteriaActionType} in which ${name} is included`
      );
    } else if (provinceList.includes(province.uuid)) {
      errors.push(
        `${province.name} is already added as ${criteriaActionType} in which ${name} is included`
      );
    }
  }
  return errors;
};

export { validateLocationCriteria };
