import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty } from "lodash";
import styles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import InfoBlock from "../../../../common/InfoBlock";
import Form from "../../../../common/Form";
import { FORM_FIELD_TYPE } from "../../../../../constants";

export default class BillingDetails extends Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
  };

  static defaultProps = {
    billingCycles: [],
  };

  componentDidMount = () => {
    const { billingCycles, campaign } = this.props;
    if (billingCycles.length > 0 && !campaign.billingCycleId) {
      this.onInputChange(billingCycles[0], "billingCycleId");
    }
  };

  componentDidUpdate = (prevProps) => {
    const { billingCycles } = this.props;
    if (billingCycles.length > 0 && prevProps.billingCycles.length !== billingCycles.length) {
      this.onInputChange(billingCycles[0], "billingCycleId");
    }
  };

  onInputChange = (value, accessor) => {
    this.setState({
      [accessor]: value,
    });
    this.props.onInputChange(value, accessor);
  };

  render() {
    const { hasFormSaved, campaign, billingCycles } = this.props;
    const showError = hasFormSaved && campaign.id && campaign.name;
    return (
      <InfoBlock header="Billing Details" childClassName={styles.childWrapper}>
        <div className={classNames("flex flex-wrap clearfix", styles.formContainer)}>
          <Form
            config={[
              {
                id: "billerName",
                label: "Biller Name",
                type: FORM_FIELD_TYPE.INPUT,
                className: "col-11",
                size: {
                  lg: 4,
                  md: 4,
                  sm: 6,
                },
                placeholder: "Enter Biller Name",
                value: campaign.billerName || "",
                errorMessage: "Please Enter Biller Name",
                error: showError && !campaign?.billerName,
                onChange: (e) => this.onInputChange(e.target.value, "billerName"),
              },
              {
                id: "billingAddress",
                label: "Billing Address",
                type: FORM_FIELD_TYPE.INPUT,
                className: "col-12",
                size: {
                  lg: 8,
                  md: 8,
                  sm: 6,
                },
                placeholder: "Enter Billing Address",
                value: campaign.billingAddress || "",
                errorMessage: "Please Enter Billing Address",
                error: showError && !campaign?.billingAddress,
                onChange: (e) => this.onInputChange(e.target.value, "billingAddress"),
              },
              {
                type: FORM_FIELD_TYPE.RADIO,
                title: "Billing Cycle",
                className: "col-11",
                isHidden: isEmpty(campaign.validity) && billingCycles.length === 0,
                size: {
                  lg: 12,
                  md: 12,
                },
                placeholder: "Select Billing Cycle",
                value: campaign.billingCycleId || billingCycles[0]?.id,
                radioList: billingCycles.map((bc) => ({ label: bc.name, value: bc.id })),
                onChange: (v) =>
                  this.onInputChange(
                    billingCycles.find((bc) => bc.id === v),
                    "billingCycleId"
                  ),
              },
              {
                id: "paymentTermInDays",
                label: "Payment Term (in days)",
                type: FORM_FIELD_TYPE.INPUT,
                className: "col-11",
                size: {
                  lg: 4,
                  md: 4,
                },
                placeholder: "Enter Payment Term",
                value: campaign.paymentTermInDays || "",
                onChange: (e) => this.onInputChange(e.target.value, "paymentTermInDays"),
              },
              {
                id: "purchaseOrderNumber",
                label: "Purchase Order Number",
                type: FORM_FIELD_TYPE.INPUT,
                className: "col-5",
                size: {
                  lg: 8,
                  md: 8,
                },
                placeholder: "Enter Purchase Order Number",
                value: campaign.purchaseOrderNumber || "",
                onChange: (e) => this.onInputChange(e.target.value, "purchaseOrderNumber"),
              },
              {
                id: "isAdvancePaymentRequired",
                label: "Advance Payment Required",
                type: FORM_FIELD_TYPE.CHECK_BOX,
                className: "col-11",
                size: {
                  lg: 4,
                  md: 4,
                },
                checked: !!campaign.isAdvancePaymentRequired,
                onChange: () =>
                  this.onInputChange(
                    !campaign.isAdvancePaymentRequired,
                    "isAdvancePaymentRequired"
                  ),
              },
              {
                id: "advanceAmountInFractionalUnit",
                title: "Advance Payment",
                type: FORM_FIELD_TYPE.NUMERIC_INPUT,
                size: {
                  lg: 4,
                  md: 4,
                },
                isHidden: !campaign?.isAdvancePaymentRequired,
                placeholder: "Enter Advance Amount",
                value: campaign.advanceAmountInFractionalUnit || 0,
                onValueChange: (value) =>
                  this.onInputChange(value, "advanceAmountInFractionalUnit"),
              },
              {
                id: "isTaxExempted",
                title: "Tax",
                label: "Exempt Taxes",
                type: FORM_FIELD_TYPE.CHECK_BOX,
                className: "col-11",
                size: {
                  lg: 12,
                  md: 12,
                },
                checked: !!campaign.isTaxExempted,
                onChange: () => this.onInputChange(!campaign.isTaxExempted, "isTaxExempted"),
              },
            ]}
          />
        </div>
      </InfoBlock>
    );
  }
}
