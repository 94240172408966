import React, { useState } from "react";
import { BUTTON_TYPE } from "prefab";
import PageHeader from "../../../common/PageHeader";
import LocalizedButton from "../../../common/LocalizedButton";
import Popup from "../../../common/Popup";
import styles from "../../../../styles/CampaignCreate/CampaignForm.module.scss";

const CampaignPageHeader = ({
  title,
  onSaveCampaignAsDraft,
  onDiscardCampaign,
  isDiscardDisabled,
  hideDraftButton = false,
}) => {
  const [isPopupOpen, togglePopup] = useState(false);
  return (
    <>
      <PageHeader
        name={title}
        renderRightSideComponent={() => (
          <>
            {!hideDraftButton && (
              <LocalizedButton
                id="draft"
                iconName="DownloadIcon"
                buttonType={BUTTON_TYPE.CONSTRUCTIVE}
                className={styles.draftButton}
                onClick={onSaveCampaignAsDraft}
                text="Button.draft"
              />
            )}
            <LocalizedButton
              id="discard"
              buttonType={BUTTON_TYPE.SECONDARY}
              onClick={() => togglePopup(true)}
              disabled={isDiscardDisabled}
              text="Button.discard"
            />
          </>
        )}
      />
      <Popup
        isOpen={isPopupOpen}
        onClose={() => togglePopup(false)}
        onConfirm={onDiscardCampaign}
        title="Popup.title"
        body="Popup.body"
        confirmButtonText="Popup.ok"
      />
    </>
  );
};

export default CampaignPageHeader;
