import { isEmpty, map, values } from "lodash";
import { DEFAULT_PAGE_SIZE } from "../constants";
import {
  getCampaign,
  getCampaignTargetGroups,
  getCampaignMedia,
  getTargetGroup,
  getMedia,
} from ".";

const fetchChainData = async (reqList) => {
  try {
    const resList = await Promise.all(reqList);
    const finalRes = await Promise.all(resList.map((res) => res.data));
    return {
      success: true,
      data: finalRes,
    };
  } catch (error) {
    return {
      success: false,
      error: error,
    };
  }
};

const generateReqList = (defaultListParams) => [
  getCampaign(defaultListParams[0]),
  getCampaignTargetGroups(...defaultListParams),
  getCampaignMedia(...defaultListParams),
];

const getReqListWithParams = async (id, type) => {
  const defaultListParams = {
    sort: "createdAt:asc",
    page: 1,
    ps: DEFAULT_PAGE_SIZE,
  };
  if (type === "campaign") {
    return generateReqList([id, ...values(defaultListParams)]);
  } else if (type === "targetGroup") {
    const targetGroupRes = await getTargetGroup(id).then((res) => res);
    if (!isEmpty(targetGroupRes.data)) {
      return generateReqList([targetGroupRes.data.campaignId, ...values(defaultListParams)]);
    }
    return [];
  } else if (type === "media") {
    const mediaRes = await getMedia(id).then((res) => res);
    if (!isEmpty(mediaRes.data)) {
      return generateReqList([mediaRes.data.campaignId, ...values(defaultListParams)]);
    }
    return [];
  }
};

const getCampaignTree = async (id, type = "campaign") => {
  const campaignReqList = await getReqListWithParams(id, type);
  const campaignRes = await fetchChainData(campaignReqList);

  if (campaignRes.success) {
    const [campaignDetails, campaignTargetGroups, campaignMedia] = campaignRes.data;
    const targetGroupReqList = campaignTargetGroups.data.map((item) => getTargetGroup(item.id));
    try {
      const targetGroupsRes = await fetchChainData(targetGroupReqList);
      if (targetGroupsRes.success) {
        const targetGroupData = map(targetGroupsRes.data, (targetGroup, index) => ({
          ...targetGroup,
          ...targetGroupsRes.data[index],
        }));
        return {
          ...campaignDetails,
          targetGroups: targetGroupData.map((targetGroup) => ({
            ...targetGroup,
            media: campaignMedia.data.filter((media) => media.targetGroupId === targetGroup.id),
          })),
        };
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  return null;
};

export default getCampaignTree;
