import * as types from "../types/media";

const initialState = {
  isLoading: true,
  isError: null,
  media: {},
  isTabLoading: true,
  mediaSummary: {},
  mediaTheatres: {},
  mediaScreens: {},
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.MEDIA:
      return { ...state, media: payload };
    case types.IS_TAB_LOADING:
      return { ...state, isTabLoading: payload };
    case types.MEDIA_SUMMARY:
      return { ...state, mediaSummary: payload };
    case types.MEDIA_THEATRES:
      return { ...state, mediaTheatres: payload };
    case types.MEDIA_SCREENS:
      return { ...state, mediaScreens: payload };
    default:
      return state;
  }
}
