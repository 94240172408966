const IS_LOADING = "inventory/campaigns/IS_LOADING";
const IS_ERROR = "inventory/campaigns/IS_ERROR";
const TARGET_GROUP = "inventory/campaigns/TARGET_GROUP";
const IS_TAB_LOADING = "inventory/campaigns/campaign/IS_TAB_LOADING";
const TARGET_GROUP_SUMMARY = "inventory/campaigns/TARGET_GROUP_SUMMARY";
const TARGET_GROUP_MEDIA = "inventory/campaigns/TARGET_GROUP_MEDIA";
const TARGET_GROUP_THEATRES = "inventory/campaigns/targetGroup/TARGET_GROUP_THEATRES";
const TARGET_GROUP_SCREENS = "inventory/campaigns/targetGroup/TARGET_GROUP_SCREENS";

export {
  IS_LOADING,
  IS_ERROR,
  TARGET_GROUP,
  TARGET_GROUP_SUMMARY,
  IS_TAB_LOADING,
  TARGET_GROUP_MEDIA,
  TARGET_GROUP_THEATRES,
  TARGET_GROUP_SCREENS,
};
